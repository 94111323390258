import React, { useEffect, useState, Children, isValidElement, cloneElement, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TangiBackNavigation, TangiToast } from '../../../_components/TangiLibrary';
import { Dashboard } from '../../Dashboard';
import { CreateEditAsset } from '_components/CreateEditAsset';
import EditTextModal from '_components/EditTextModal/EditTextModal';
import XrayModal from '_components/XrayModal/XrayModal';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { getAssetMetaData, createTag, getAuthors } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { RootState } from '_helpers/store';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { CurrentTradeSecret, DropdownOption } from '_hocs/XRayAssetCreation/types';
import { Option, Tag } from 'utils/types/assetMetaData/assetMetaData';
import { ACCOUNT_STATUS, CREATED_FROM, RESULT_STATUS } from 'utils/enums';
import { IRoles, isRoleMatch } from 'utils/roles';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { createPDF, generateDropdownOptions, getDepartmentsOptionsFromIds } from '_hocs/XRayAssetCreation/components/utils';
import { getAgreementVersions } from 'redux-toolkit/thunks/clientThunks';
import { clientSettingsActions } from '_actions';
import { PatentExport } from '../PatentExport';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';
import { LoaderContainer, TopPageContainer, GridContainerRight } from '../style';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import AddContributors from '_components/NewUsersModal/TurnToAsset/AddContributors';
import { AccountsOfInventors } from 'utils/types/patent/patent';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import InviteAsContributors from '_components/NewUsersModal/InviteAsContributors';

interface IAssetCreationFromPatentWrapperProps {
  children: React.ReactNode;
  patentNameShort: string;
  clientId: string;
  currentTradeSecret: CurrentTradeSecret;
  handleIsAssetFromPatentCreated: () => void;
  handleDropdownItem: (currTradesecret: CurrentTradeSecret, index: number) => void;
}

const AssetCreationFromPatentWrapper = ({ children, patentNameShort, clientId, currentTradeSecret, handleIsAssetFromPatentCreated, handleDropdownItem }: IAssetCreationFromPatentWrapperProps) => {
  const [showEditTextModal, setShowEditTextModal] = useState<boolean>(false);
  const [showCreateAsset, setShowCreateAsset] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [filesToAttach, setFilesToAttach] = useState<File[]>([]);
  const [defaultTags, setDefaultTags] = useState<Option[]>([]);
  const [defaultDepartments, setDefaultDepartments] = useState<Option[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  //inventors
  const { activeAccount, user, Role } = useSelector((state: RootState) => state.authentication);

  const [names, setNames] = useState<string[]>([]);
  const [showAddContributorsModal, setShowAddContributorsModal] = useState<boolean>(false);
  const [showInviteAsContributorsModal, setShowInviteAsContributorsModal] = useState<boolean>(false);

  const inviteAccountsAdditionalFields = useMemo(() => {
    return { createdFrom: CREATED_FROM.PATENT_TOOL, externalUser: user?.externalUser, status: ACCOUNT_STATUS.DISABLED };
  }, [user]);

  const assetCreationToastProps = useSelector((state: RootState) => state.asset.assetCreationToastProps);
  const { tags, departments, loading: metaDataLoading } = useSelector((state: RootState) => state.assetMetaData);
  const { patentData, loading, accounts } = useSelector((state: RootState) => state.patent);
  const peopleToastProps = useSelector((state: RootState) => state.users.peopleToastProps);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseCreateAsset = () => {
    dispatch(usersActions.setClearInvitedMultiple());
    setShowConfirmModal(false);
    setShowCreateAsset(false);
  };

  const attachNewPdf = (currTradeSecret: CurrentTradeSecret) => {
    const pdfFile = createPDF({ ...currTradeSecret, potentialAssetName: patentNameShort });
    setFilesToAttach([pdfFile]);
  };

  const addTagWithFileName = () => {
    const patentNameTag = tags?.find((tag: Tag) => tag.name === patentNameShort);
    if (!patentNameTag) {
      dispatch(createTag({ name: patentNameShort, client: [clientId] }));
    } else {
      setDefaultTags([{ value: patentNameTag.id, label: patentNameTag.name }]);
    }
  };

  const addTagWithPatentId = () => {
    const patentNameTag = tags?.find((tag: Tag) => tag.name === patentNameShort);
    if (!patentNameTag) {
      dispatch(createTag({ name: patentNameShort, client: [clientId] }));
    } else {
      setDefaultTags([{ value: patentNameTag.id, label: patentNameTag.name }]);
    }
  };
  /**
   * Perform necessary logic after editing the text of the patent.
   *
   * @param {string} editedText - The edited text of the patent.
   * @returns {void}
   */

  const handleEditContinue = (editedText: string): void => {
    attachNewPdf({ text: editedText, title: currentTradeSecret.title });
    handlePatentSourceTypeChange();
    setShowEditTextModal(false);
    addTagWithPatentId();
  };

  /**
   * Renders an array of dropdown options with customized callbacks based on the provided current trade secret and index.
   *
   * @param {CurrentTradeSecret} currTradesecret - The current trade secret object.
   * @param {number} index - The index of the trade secret in the list.
   * @returns {DropdownOption[]} - An array of dropdown options with customized callbacks.
   */
  const renderOptionsWithCustomCallbacks = (currTradesecret: CurrentTradeSecret, index: number): DropdownOption[] => {
    const editAndTurnIntoAssetCB = () => {
      handleDropdownItem(currTradesecret, index);
      setShowEditTextModal(true);
    };
    const turnIntoAssetCB = () => {
      handleDropdownItem(currTradesecret, index);
      addTagWithFileName();
      attachNewPdf(currTradesecret);
      handlePatentSourceTypeChange();
    };
    return generateDropdownOptions(editAndTurnIntoAssetCB, turnIntoAssetCB);
  };

  const handleSuccessfulCreation = () => {
    batch(() => {
      dispatch(assetsActions.setAssetCreationToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: `${t('PATENT_TOOL.TOAST.ASSET_CREATED_SUCCESSFULLY')}` }));
      handleIsAssetFromPatentCreated();
    });
    if (showEditTextModal) {
      setShowEditTextModal(false);
    }
    handleCloseCreateAsset();
  };

  const renderLoader = () => {
    return (
      <LoaderContainer>
        <Spinner animation="border" variant="primary" />
      </LoaderContainer>
    );
  };

  const renderPageTopContainer = () => {
    const isLoading = patentData.status_mapper !== XRAY_JOB_STATUS.SUCCEEDED && patentData.status_mapper !== XRAY_JOB_STATUS.FAILED;
    const path = generateRoute(AppRoutes.PATENT_X_RAY_MAIN_PAGE, { clientId });
    const buttonText = t('PATENT_TOOL.CONTENT.BACK_TO_PATENT_X_RAY_HOME');

    return (
      <TopPageContainer>
        <TangiBackNavigation navigationPath={path} buttonText={buttonText} />
        <GridContainerRight loading={isLoading}>
          <PatentExport />
        </GridContainerRight>
      </TopPageContainer>
    );
  };

  const renderCreateEditAssetModal = () => {
    return (
      <Modal transition="Fade" size="lg" className="modal-asset" show={showCreateAsset} onHide={() => setShowConfirmModal(true)}>
        <CreateEditAsset
          asset={{
            createdFrom: CREATED_FROM.PATENT_TOOL,
            name: `${patentNameShort ? `${patentNameShort}_TS_${currentTradeSecret.title}` : `TS_${currentTradeSecret.title}`}`,
            tags: defaultTags,
            departments: defaultDepartments,
          }}
          filesToAttach={filesToAttach}
          handleCloseAsset={() => setShowConfirmModal(true)}
          onCreate={handleSuccessfulCreation}
          onEdit={null}
          isPatentMode={true}
        />
      </Modal>
    );
  };

  const renderConfirmationModal = () => {
    return (
      <Modal
        transition="Fade"
        backdrop="static"
        size="sm"
        className="modal-close-confirm"
        show={showConfirmModal}
        onHide={() => {
          setShowConfirmModal(true);
        }}
      >
        <Modal.Header />
        <Modal.Body className="my-4">
          <span className="font-weight-normal text-dark text-center mb-4">{t('PATENT_TOOL.MODAL.ARE_YOU_SURE')}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button type="button" className="px-5 btn btn-outline-primary" onClick={handleCloseCreateAsset}>
            {t('GENERAL.BUTTONS_TEXT.YES')}
          </button>
          <button type="button" className="px-5 btn btn-primary text-white" onClick={() => setShowConfirmModal(false)}>
            {t('GENERAL.BUTTONS_TEXT.NO')}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderEdiTextModal = () => {
    return <EditTextModal text={currentTradeSecret?.text || ''} onClose={() => setShowEditTextModal(false)} onContinue={handleEditContinue} isOpen={showEditTextModal} />;
  };

  const renderSeenXrayModal = () => {
    return <XrayModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />;
  };

  const renderAddContributorsModal = () => {
    return (
      <AddContributors
        namesOfInventors={names}
        accounts={accounts}
        showAddContributorsModal={showAddContributorsModal}
        handleSkipAddAccounts={handleSkip}
        onClose={handleOnClose}
        additionalFields={Role === IRoles.EMPLOYEE_MANAGER ? { ...inviteAccountsAdditionalFields, departments: activeAccount.departments } : inviteAccountsAdditionalFields}
        onFinish={handleOnFinishInviteAccounts}
        Role={Role}
      />
    );
  };

  const renderInviteAsContributorsModal = () => {
    return (
      <InviteAsContributors
        namesOfInventors={patentData.inventors}
        handleSkipAddAccounts={handleSkip}
        onClose={handleOnClose}
        showInviteAsContributorsModal={showInviteAsContributorsModal}
        onFinish={handleOnFinishInviteAccounts}
        additionalFields={Role === IRoles.EMPLOYEE_MANAGER ? { ...inviteAccountsAdditionalFields, departments: activeAccount.departments } : inviteAccountsAdditionalFields}
        Role={Role}
      />
    );
  };

  const handleSelectionOfProperContributorModal = () => {
    accounts.length > 0 ? setShowAddContributorsModal(true) : setShowInviteAsContributorsModal(true);
  };

  const handlePatentSourceTypeChange = useCallback(() => {
    if (patentData.source_type === XRAY_SOURCE_TYPE.FILE) {
      setShowCreateAsset(true);
    } else {
      handleSelectionOfProperContributorModal();
    }
  }, [patentData.source_type, accounts]);

  const handleClosing = () => {
    setShowAddContributorsModal(false);
    setShowInviteAsContributorsModal(false);
    setShowCreateAsset(true);
  };

  const handleSkip = () => {
    handleClosing();
    filterAccountsOfInventors();
  };
  const handleOnClose = () => {
    handleClosing();
  };
  const handleOnFinishInviteAccounts = () => {
    dispatch(getAuthors(clientId));
    setShowCreateAsset(true);
    handleCloseOfProperContributorModal();
  };

  const handleCloseOfProperContributorModal = () => {
    accounts.length > 0 ? setShowAddContributorsModal(false) : setShowInviteAsContributorsModal(false);
    dispatch(patentActions.setAccounts([]));
    setNames([]);
  };

  const filterAccountsOfInventors = () => {
    const patentDataAccounts = patentData.accountsOfInventors ?? [patentData.account];
    const accounts = patentDataAccounts
      ?.map((inventor: AccountsOfInventors) => {
        if (inventor.account && typeof inventor.account === 'object') {
          // Return the account object
          return inventor.account;
        } else {
          // Update the names array
          setNames((prevNames) => [...prevNames, inventor.name]);
          // Return null for the filter step
          return null;
        }
      })
      .filter(Boolean);

    accounts.length > 0 && dispatch(patentActions.setAccounts(accounts));
  };
  const renderModals = () => {
    return (
      <>
        {renderEdiTextModal()}
        {renderCreateEditAssetModal()}
        {renderConfirmationModal()}
        {renderSeenXrayModal()}
        {renderAddContributorsModal()}
        {renderInviteAsContributorsModal()}
      </>
    );
  };

  const handlePeopleToastSuccess = () => {
    batch(() => {
      dispatch(usersActions.setPeopleToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
      dispatch(usersActions.setClearUser());
    });
  };

  useEffect(() => {
    dispatch(getAssetMetaData({clientId}));
    if (!loading && (patentData.source_type === XRAY_SOURCE_TYPE.FILE || patentData?.patent_id)) {
      batch(() => {
        dispatch(assetsActions.setClearAsset());
        dispatch(clientSettingsActions.getClientSettings(clientId));
        dispatch(getAgreementVersions({ client: clientId }));
      });
    } else if (!loading && !patentData?.patent_id) {
      history.push(generateRoute(AppRoutes.PATENT_X_RAY_MAIN_PAGE, { clientId }));
    }
  }, [loading, clientId, patentData.patent_id]);

  useEffect(() => {
    if (!metaDataLoading && isRoleMatch(Role, IRoles.EMPLOYEE_MANAGER)) {
      setDefaultDepartments(getDepartmentsOptionsFromIds(departments, activeAccount.departments));
    }
  }, [metaDataLoading, Role]);

  useEffect(() => {
    let seenXrayMapping = user?.seenXrayMapping;
    let seenXrayExtraction = user?.seenXrayExtraction;

    if (!seenXrayMapping) {
      seenXrayMapping = loadStateFromLocalStorage(authConstants.SEEN_XRAY_MAPPING) === true;
    }

    if (!seenXrayExtraction) {
      seenXrayExtraction = loadStateFromLocalStorage(authConstants.SEEN_XRAY_EXTRACTION) === true;
    }

    setIsModalOpen(!(seenXrayMapping && seenXrayExtraction));
  }, []);

  useEffect(() => {
    if (!loading && (patentData.source_type === XRAY_SOURCE_TYPE.FILE || patentData.patent_id) && !showCreateAsset) {
      filterAccountsOfInventors();
    }
  }, [loading, patentData.patent_id, showCreateAsset]);

  return (
    <>
      <Dashboard title="">
        {loading ? (
          renderLoader()
        ) : (
          <>
            {renderPageTopContainer()}

            {/* children with custom props*/}
            {Children.map(children, (child) => {
              if (!isValidElement(child)) return null;
              return cloneElement(child, {
                ...child.props,
                renderOptionsWithCustomCallbacks,
              });
            })}
          </>
        )}
      </Dashboard>

      {/* Modals */}
      {renderModals()}

      {/* Toast */}
      <TangiToast
        {...assetCreationToastProps}
        onSuccess={() => {
          dispatch(assetsActions.setAssetCreationToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
      <TangiToast {...peopleToastProps} onSuccess={handlePeopleToastSuccess} />
    </>
  );
};

export default AssetCreationFromPatentWrapper;
