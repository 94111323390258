import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TangiIconButton } from '_components/TangiLibrary';
import AssetTypeColorBlock from '_components/AssetTypeColorBlock/AssetTypeColorBlock';
import BreadCrumbs from '_components/BreadCrumbs/BreadCrumbs';
import AssetOptions from '_components/AssetOptions/AssetOptions';
import { useWindowSize } from '_hooks/useWindowSize';
import { assetTypeNameFormatter } from 'utils/assetTypes';
import { parseAssetPath } from './utils';
import { IRoles } from 'utils/roles';
import { BUTTON_VARIANTS } from 'utils/theme';
import { AssetCardHeader, ColorAndType } from '_components/ClientAssetCards/style';
import { customAssetOptionsStyles } from './style';

export const AssetActiveCardHeader = ({ asset, permissions, isAdmin, options, isSomeAssetsSelected }) => {
  const Role = useSelector((state) => state.authentication.Role);
  const { t } = useTranslation();

  const isEditDisable = asset?.isThirdParty && Role === IRoles.PARTNER;
  const editTooltipContent = isEditDisable ? t('ASSET_PAGE.TOOLTIP.CANNOT_EDIT_ASSET') : t('ASSET_PAGE.TOOLTIP.EDIT_ASSET');
  const isSharePointType = !!asset?.sharePoint;

  const { isSmallScreen } = useWindowSize();
  const maxItemsToShow = isSmallScreen ? 2 : 3;

  const renderAssetNameAndType = () => {
    const breadcrumbData = isSharePointType ? parseAssetPath(asset.sharePoint) : null;
    const { breadcrumbItems, fullPathString } = breadcrumbData || {};

    return (
      <ColorAndType>
        <AssetTypeColorBlock assetTypeName={asset?.assetType?.name || ''} />
        {isSharePointType && breadcrumbItems ? (
          <BreadCrumbs items={breadcrumbItems} maxItemsToShow={maxItemsToShow} tooltipContent={fullPathString} />
        ) : (
          <span>{asset?.assetType?.name ? assetTypeNameFormatter(asset.assetType.name) : ''}</span>
        )}
      </ColorAndType>
    );
  };

  const renderOptions = () => {
    if (isAdmin && permissions?.Assetedit) {
      return <AssetOptions item={asset} options={options} styles={customAssetOptionsStyles} isDisabled={isSomeAssetsSelected} />;
    } else if (!isAdmin && permissions?.Assetedit) {
      return (
        <Tippy content={editTooltipContent} placement="top-end">
          <span data-testid="edit-asset">
            <TangiIconButton disabled={isEditDisable} icon="edit" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => options[0].handleItem(asset)} />
          </span>
        </Tippy>
      );
    }
    return null;
  };

  return (
    <AssetCardHeader>
      {renderAssetNameAndType()}
      {!isSharePointType && renderOptions()}
    </AssetCardHeader>
  );
};
