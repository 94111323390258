/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { BatchEmployeesParams, CreateMultipleUsersParams, GetUsersParams, UploadBatchEmployeesFileToS3, generatePresignedUrlResponse } from 'utils/types/users/users';
import { Filter, getFilterObj } from '_services/utils/getFilterObj';
import { headers, tokenHeaders } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { Account, AccountAgreement, AccountAssets, AccountMinimalData, BaseUpdateManyResponse } from 'utils/types/account/account';
import { ApiResponse } from 'utils/types/types';
import { RolesResponse } from 'utils/types/role/role';
import { UpdateFullNameParams } from 'utils/types/auth/auth';
import { SelectedAccount } from '../../utils/types/account/account';
import { ACCOUNT_ACTIONS } from 'utils/enums';

class UsersAdapter {
  static accountEndpoint = `${config.apiUrl}${config.apiVersion}/account`;
  static userEndpoint = `${config.apiUrl}${config.apiVersion}/user`;
  static assetsEndpoint = '/assets';
  static agreementsEndpoint = '/agreements';

  async sendAlias(accountId: string) {
    const res = await HttpClientService.get(`${UsersAdapter.accountEndpoint}/aliasid/${accountId}`, { headers: headers() });
    return res.data;
  }

  async createMultipleUsers(data: CreateMultipleUsersParams) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/inviteMultiple`, data, { headers: headers() });
    return res.data;
  }

  async updateFullName(data: UpdateFullNameParams) {
    const res = await HttpClientService.put(
      `${UsersAdapter.userEndpoint}/fullname/${data.userId}`,
      { fullName: data.fullName },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.token}`,
        },
      },
    );
    return res.data;
  }

  async acceptInvitation(token: string): Promise<ApiResponse<Account | AccountMinimalData>> {
    const defaultHeaders = headers();

    const headersWithInvitationToken = {
      ...defaultHeaders,
      [tokenHeaders.InvitationTokenHeader]: token,
    };

    const res = await HttpClientService.get<Account | AccountMinimalData>(`${UsersAdapter.accountEndpoint}/acceptInvite`, {
      headers: headersWithInvitationToken,
    });

    return { data: res.data, status: res.status };
  }

  async deactivateAccount(accountId: string) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/deactivate/${accountId}`, {}, { headers: headers() });
    return res.data;
  }

  async reactivateAccount(accountId: string) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/reactivate/${accountId}`, {}, { headers: headers() });
    return res.data;
  }

  async deleteAccount(accountId: string) {
    const res = await HttpClientService.delete(`${UsersAdapter.accountEndpoint}/${accountId}`, { headers: headers() });
    return res.data;
  }

  async batchEmployees(employees: BatchEmployeesParams) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/batchEmployees`, employees, { headers: headers() });
    return res.data;
  }

  async generateBatchEmployeesPresignedUrl(fileKey: string): Promise<generatePresignedUrlResponse> {
    const res = await HttpClientService.post<generatePresignedUrlResponse>(`${UsersAdapter.accountEndpoint}/generate-batch-employees-presigned-url`, { fileKey }, { headers: headers() });
    return res.data;
  }

  async uploadBatchEmployeesFileToS3({ presignedUrl, employeesJson }: UploadBatchEmployeesFileToS3) {
    try {
      const res = await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(employeesJson),
      });
      if (!res.ok) {
        const errorText = await res.text();
        console.error('Upload failed:', res.status, errorText);
        throw new Error(`Failed to upload: ${res.statusText}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async validateEmployees(employees: BatchEmployeesParams) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/validateEmployees`, employees, { headers: headers() });
    return res.data;
  }

  async createUser(user: any) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/invite`, user, { headers: headers() });
    return res.data;
  }

  async updateUser(user: any, id: string) {
    delete user.agreements;
    delete user.profile;
    const res = await HttpClientService.put(`${UsersAdapter.accountEndpoint}/${id}`, user, { headers: headers() });
    return res.data;
  }

  async updateAccount(account: Partial<Account>, id: string): Promise<Account> {
    const res = await HttpClientService.put<Account>(`${UsersAdapter.accountEndpoint}/${id}`, account, { headers: headers() });
    return res.data;
  }

  async getUsers({ userRoleName, client, pagination, filter, type }: GetUsersParams) {
    const isFormerUsers = type === 'FormerEmployees';
    const filterObj = getFilterObj(filter);

    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/users`, filterObj, {
      headers: headers(),
      params: {
        client: client,
        roleName: userRoleName,
        ...pagination,
        isFormerUsers,
      },
    });
    return res.data;
  }

  async getAuthors(clientId: string) {
    const res = await HttpClientService.get(`${UsersAdapter.accountEndpoint}/authors/${clientId}`, { headers: headers() });
    return res.data;
  }

  async getRoles() {
    const res = await HttpClientService.get<RolesResponse>(`${config.apiUrl}${config.apiVersion}/roles`, { headers: headers() });
    return res.data;
  }

  async getUniqueCountries() {
    const res = await HttpClientService.get<string[]>(`${config.apiUrl}${config.apiVersion}/account/countries`, { headers: headers() });
    return res.data;
  }

  async checkUpdateManyStatus(jobId: string): Promise<BaseUpdateManyResponse> {
    const res = await HttpClientService.post<BaseUpdateManyResponse>(`${UsersAdapter.accountEndpoint}/check-update-many-status`, { jobId }, { headers: headers() });
    return res.data;
  }

  async checkbatchEmployeesImportStatus(jobId: string): Promise<BaseUpdateManyResponse> {
    const res = await HttpClientService.post<BaseUpdateManyResponse>(`${UsersAdapter.accountEndpoint}/check-batch-employees-import-status`, { jobId }, { headers: headers() });
    return res.data;
  }

  async updateManyAccounts(selectedAccounts: SelectedAccount[], allEmployees: boolean, allContractors: boolean, filter: Filter, searchValue: string, action: ACCOUNT_ACTIONS) {
    const filterObj = getFilterObj(filter);
    const res: any = await HttpClientService.post(
      `${UsersAdapter.accountEndpoint}/update-many`,
      { selectedAccounts, allEmployees, allContractors, filter: filterObj, searchValue, action },
      { headers: headers() },
    );
    return res.data;
  }

  async getAccountAssets(accountId: string): Promise<{ _id: string; assets: AccountAssets }> {
    const res = await HttpClientService.get<{ _id: string; assets: AccountAssets }>(`${UsersAdapter.accountEndpoint}/${accountId}${UsersAdapter.assetsEndpoint}`, { headers: headers() });
    return res.data;
  }

  async getAccountAgreements(accountId: string): Promise<{ _id: string; agreements: AccountAgreement[] }> {
    const res = await HttpClientService.get<{ _id: string; agreements: AccountAgreement[] }>(`${UsersAdapter.accountEndpoint}/${accountId}${UsersAdapter.agreementsEndpoint}`, { headers: headers() });
    return res.data;
  }
}

const usersAdapter = new UsersAdapter();
export default usersAdapter;
