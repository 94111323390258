import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';

import './People.scss';
import { TangiSearch, TangiTypography, TangiIconButton } from '../../_components/TangiLibrary';
import { Dashboard } from '../Dashboard';
import TableSkeleton from '../../_components/TableSkeleton/TableSkeleton';
import { getNoDataDisplay } from '../../utils/NoDataTable';
import { customStyles } from './utils/tableUtils';
import { PeopleContainer, FilterSearch, TableContainer, SectionHeader, TableHeaderContainer } from './style';
import { PageHeaderContainer, PageHeader } from '../../utils/globalStyles';
import { NEUTRAL_SHADES } from 'utils/theme';

import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { BackToContainer } from 'pages/ContractTool/style';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { AppRoutes } from 'routing/consts';
import { generateRoute } from 'routing/generateRoute';
import FormerEmployeesFilters from '_components/PeopleFilters/FormerEmployeesFilters';
import { ViewPeople } from '_components/ViewPeople/ViewPeople';
import { useFormerEmployees } from './hooks/useFormerEmployees';

const FormerEmployees = () => {
  const {
    setEmpPagination,
    handleSortEmployee,
    handleClearSearch,
    permissions,
    columns4,
    view,
    empPagination,
    employeeData,
    tablesLoaders,
    isFilterApplied,
    clientSettingsLoading,
    filter,
    permissionEditEmployee,
    handleResetView,
  } = useFormerEmployees();

  const {
    tablesData: { formerEmployees },
  } = useSelector((state) => state.client);
  const searchValue = useSelector((state) => state.users.peoplePage.filters.searchValue);

  const [localSearchInput, setLocalSearchInput] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const history = useHistory();

  const goBackToPeople = () => {
    clientId ? history.push(generateRoute(AppRoutes.PEOPLE, { clientId })) : history.goBack();
  };

  const handleSearchSubmit = useCallback(
    (value) => {
      dispatch(usersActions.setSearchQuery(value.trim()));
    },
    [localSearchInput],
  );

  useEffect(() => {
    return () => {
      dispatch(usersActions.resetFilters());
    };
  }, []);

  const renderFilters = useCallback(() => {
    return (
      <FilterSearch className="search-filter-section-people">
        <Tippy content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('PEOPLE.FILTERS.SEARCH_BY_USER_NAME_ID_OR_EMAIL')}</TangiTypography>}>
          <div>
            <TangiSearch handleSearch={handleSearchSubmit} setSearchValue={setLocalSearchInput} searchValue={localSearchInput} placeholder={t('PEOPLE.FILTERS.SEARCH')} width="272px" />
          </div>
        </Tippy>
        <FormerEmployeesFilters filter={filter} isFilterApplied={isFilterApplied} handleClearSearch={handleClearSearch} />
      </FilterSearch>
    );
  }, [handleSearchSubmit, setLocalSearchInput, localSearchInput, isFilterApplied, handleClearSearch, filter]);

  useEffect(() => {
    setLocalSearchInput(searchValue);
  }, [searchValue]);

  const totalEmployees = useMemo(() => formerEmployees?.metadata && formerEmployees?.metadata?.length > 0 && formerEmployees.metadata[0].total, [formerEmployees, filter, tablesLoaders]);

  return (
    <>
      <Dashboard title="">
        <PageHeaderContainer>
          <PageHeader>
            <BackToContainer>
              <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={goBackToPeople} />
              <span>{t('PEOPLE.TITLE.PEOPLE')}</span>
            </BackToContainer>
          </PageHeader>
        </PageHeaderContainer>
        <PeopleContainer>
          {renderFilters()}
          <SectionHeader>
            <TangiTypography weight="semi-bold" type="heading-md">
              {t('FORMER_EMPLOYEES.TITLE.FORMER_EMPLOYEES')}
            </TangiTypography>
            {totalEmployees && !tablesLoaders.formerEmployeesLoading ? (
              <TableHeaderContainer>
                <TangiTypography color={NEUTRAL_SHADES[800]} dataTestId="employee-number">
                  {t('PEOPLE.TITLE.EMPLOYEES_TOTAL', { totalEmployees })}
                </TangiTypography>
              </TableHeaderContainer>
            ) : (
              ' '
            )}
          </SectionHeader>
          <TableContainer className="overflow-fix" data-testid="employee-table">
            {(permissions?.Employeelist || permissions?.Learnemployeeview) && (
              <DataTable
                pagination
                columns={columns4}
                paginationDefaultPage={empPagination.page}
                onChangeRowsPerPage={(pageRows) => setEmpPagination({ page: 1, limit: pageRows })}
                onChangePage={(page) => setEmpPagination({ ...empPagination, page })}
                paginationServer
                paginationTotalRows={
                  typeof formerEmployees && formerEmployees?.metadata && formerEmployees?.metadata !== 'undefined'
                    ? formerEmployees && formerEmployees?.metadata && formerEmployees?.metadata.length > 0 && formerEmployees.metadata[0].total
                    : 0
                }
                data={employeeData}
                onSort={handleSortEmployee}
                customStyles={customStyles}
                sortIcon={<i className="" />}
                progressPending={tablesLoaders.formerEmployeesLoading || clientSettingsLoading}
                progressComponent={<TableSkeleton />}
                noDataComponent={getNoDataDisplay(t('PEOPLE.TABLE.NO_EMPLOYEES_FOUND'))}
                onRowClicked={(row) => {
                  row.onClick();
                }}
              />
            )}
          </TableContainer>
        </PeopleContainer>
        {view.show && <ViewPeople permissionEditEmployee={permissionEditEmployee} showModal={view.show} type={view.type} mode={view.mode} resetView={handleResetView} />}
      </Dashboard>
    </>
  );
};

export default FormerEmployees;
