import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

import { getIntegrationData } from 'redux-toolkit/thunks/sharePointThunks';
import { sharePointActions } from 'redux-toolkit/slices/sharePointSlice';
import { FormSchema } from '../types';
import { useAppDispatch } from '_helpers';
import { RESULT_STATUS } from 'utils/enums';

interface Props {
  shouldFetch: boolean;
  resetForm: (data: FormSchema) => void;
}

const useEditModeFormReset = ({ shouldFetch, resetForm }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchIntegrationData = async () => {
      if (!shouldFetch) return;

      try {
        const resultAction = await dispatch(getIntegrationData());
        const payload = unwrapResult(resultAction);
        const { secrets, sites } = payload ?? {};

        resetForm({
          applicationId: secrets?.applicationId || '',
          directoryId: secrets?.directoryId || '',
          clientSecret: '****************************************', // Client secret should not be shown
          sites: sites ?? [],
          users: [], //will be populated after Connect Sites or Folders step
          isGrantedPermission: true
        });
      } catch (error) {
        dispatch(sharePointActions.setIntegrationDataToastProps({ show: true, type: RESULT_STATUS.ERROR, text: t('GENERAL.ERROR.SOMETHING_WENT_WRONG') }));
      }
    };

    fetchIntegrationData();
  }, [shouldFetch, resetForm, dispatch]);
};

export default useEditModeFormReset;
