import { GroupedRecipientsCategoryData, RecipientCategoryType, ShareMultipleAssetsRequestBody } from 'utils/types/assets/assets';
import { MultipleAcknowledgementTableData } from './Tables/types';
import { SelectOption } from './useHandleRecipientEntitySearch/types';
import { GetTotalRecipientsCountInputs } from './types';
import { Partnership } from 'utils/types/partnership/partnership';

export const getRecipientEntityAlreadyBeenNotifiedMessage = (type: RecipientCategoryType) => {
  const baseStrPath = 'ASSET_PAGE.ERROR_MESSAGE';
  switch (type) {
    case RecipientCategoryType.BUSINESS_UNIT:
      return `${baseStrPath}.BUSINESS_UNIT_BEEN_NOTIFIED`;
    case RecipientCategoryType.DEPARTMENT:
    case RecipientCategoryType.PARTNER_DEPARTMENT:
      return `${baseStrPath}.DEPARTMENT_BEEN_NOTIFIED`;
    case RecipientCategoryType.PARTNER:
      return `${baseStrPath}.PARTNER_BEEN_NOTIFIED`;
    case RecipientCategoryType.INDIVIDUAL:
      return `${baseStrPath}.RECIPIENT_BEEN_NOTIFIED`;
  }
};

export const isRecipientEntityIncludedInEveryAsset = (id: string, type: RecipientCategoryType, tableData: MultipleAcknowledgementTableData[], partnershipData?: Partnership): boolean => {
  if (!tableData?.length) return false;

  const typeToPropertyMap: Record<RecipientCategoryType, keyof MultipleAcknowledgementTableData> = {
    [RecipientCategoryType.INDIVIDUAL]: 'recipients',
    [RecipientCategoryType.BUSINESS_UNIT]: 'businessUnits',
    [RecipientCategoryType.DEPARTMENT]: 'departments',
    [RecipientCategoryType.PARTNER]: 'partners',
    [RecipientCategoryType.PARTNER_DEPARTMENT]: 'partnersDepartments',
  };

  const property = typeToPropertyMap[type];
  if (!property) return false;

  return tableData.every((assetData) => {
    const entities = assetData[property] as Array<{ account?: { _id: string }; categoryId?: string }>;

    const existsInCurrentCategoryType = entities.some((entity) => (type === RecipientCategoryType.INDIVIDUAL ? entity.account?._id === id : entity.categoryId === id));

    if (type === RecipientCategoryType.INDIVIDUAL) {
      const groupCategories = Object.keys(typeToPropertyMap).filter((key) => key !== RecipientCategoryType.INDIVIDUAL) as RecipientCategoryType[];

      // check if recipient exists in any of the groups
      const existsInGroups = groupCategories.some((group) => {
        const groupKey = typeToPropertyMap[group];
        const groupsData = assetData[groupKey] as Array<{ items: Array<{ account?: { _id: string } }> }>;

        return groupsData.some((groupEntity) => groupEntity.items.some((item) => item.account?._id === id));
      });

      return existsInGroups || existsInCurrentCategoryType;
    }
 
    if (type === RecipientCategoryType.PARTNER_DEPARTMENT) {
      return tableData.every((assetData) => {
        const existsDirectly = assetData.partnersDepartments.some((group) => group.categoryId === id);
    
        // Find the department group matching the given id to extract its partnership
        const departmentGroup = assetData.partners.find((group) => group.categoryId === partnershipData?._id );
        const partnershipId = departmentGroup?.partnership?._id;
    
        const existsViaPartnership = !!partnershipId &&
          assetData.partners.some((partnerGroup) => partnerGroup.categoryId === partnershipId);
        
        return existsDirectly || existsViaPartnership;
      });
    }

    return existsInCurrentCategoryType;
  });
};

export const buildShareMultipleAssetsData = (selectOptions: SelectOption[]): Omit<ShareMultipleAssetsRequestBody, 'assetsIds'> =>
  selectOptions.reduce<Omit<ShareMultipleAssetsRequestBody, 'assetsIds'>>(
    (data, option) => {
      const { type, id } = option;
      switch (type) {
        case RecipientCategoryType.INDIVIDUAL:
          data.recipients.push({ email: option.email, id });
          break;
        case RecipientCategoryType.BUSINESS_UNIT:
          data.businessUnits.push(id);
          break;
        case RecipientCategoryType.DEPARTMENT:
        case RecipientCategoryType.PARTNER_DEPARTMENT:
          data.departments.push(id);
          break;
        case RecipientCategoryType.PARTNER:
          data.partnerships.push(id);
          break;
      }
      return data;
    },
    { recipients: [], partnerships: [], departments: [], businessUnits: [] },
  );

export const isGroupIncludedInSelectedOptions = (selectOptions: SelectOption[]) => selectOptions.some(({ type }) => type !== RecipientCategoryType.INDIVIDUAL);

export const getTotalRecipientsCountPerAsset = (data: GetTotalRecipientsCountInputs) => {
  if (!data) return 0;

  const { recipients = [], partners = [], partnersDepartments = [], departments = [], businessUnits = [] } = data;

  const countNestedItems = (groups: GroupedRecipientsCategoryData[]) => groups.flatMap((group) => group.items || []).length;

  return recipients.length + countNestedItems(partners) + countNestedItems(departments) + countNestedItems(businessUnits) + countNestedItems(partnersDepartments);
};

export const getTotalRecipientsCountForMultipleAssets = (tableData: MultipleAcknowledgementTableData[]) => {
  return tableData.reduce((total, data) => total + getTotalRecipientsCountPerAsset(data), 0);
};

export const parseIndividualsRecipients = (individuals: GroupedRecipientsCategoryData[]) => {
  const flattenItems = individuals.flatMap((group) =>
    group.items.map((item) => ({
      ...item,
      partnership: (group.partnership && Object.keys(group.partnership).length > 0)
      ? group.partnership
      : undefined
    })),
  );
  return [{
    ...individuals[0],
    items: flattenItems,
  }];
};
