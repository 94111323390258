import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

import { ERROR_SHADES, NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const StyledLabelContainer = styled(Form.Label)`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  margin-bottom: ${SPACING[0]};
`;

export const StyledInputGroup = styled(InputGroup)<{ $isError: boolean; $isDisabled: boolean }>`
  ${({ $isError, $isDisabled }) => css`
    &:focus-within .input-group-text {
      border: ${$isError ? `1px solid ${ERROR_SHADES.BOOTSTRAP}` : `1px solid ${PRIMARY_SHADES.BOOTSTRAP_FOCUS}`};
      border-left-width: 0;
    }

    &:hover .input-group-text {
      border: 1px solid ${$isError ? `${ERROR_SHADES.BOOTSTRAP}` : $isDisabled ? NEUTRAL_SHADES[400] : NEUTRAL_SHADES[600]};
    }
    &:hover .form-control {
      border: 1px solid ${$isError ? `${ERROR_SHADES.BOOTSTRAP}` : $isDisabled ? NEUTRAL_SHADES[400] : NEUTRAL_SHADES[600]};
      border-right-width: 0;
    }
  `}
`;

export const StyledInput = styled(Form.Control)<{hasVisibilityToggle?: boolean}>`
  padding: ${SPACING[2.5]};
  ${({hasVisibilityToggle}) => (hasVisibilityToggle && 'border-right: none')};

  &.is-invalid {
    background-image: none;
    padding-right: ${SPACING[2.5]};
    border: 1px solid ${ERROR_SHADES.BOOTSTRAP};
    border-right-width: 0;
    &:focus {
      border: 1px solid ${ERROR_SHADES.BOOTSTRAP};
      border-right-width: 0;
    }
  }
  ::placeholder {
    color: ${NEUTRAL_SHADES[800]} !important;
    font-family: 'DIN2014-Regular' !important;
  }
  &:disabled {
    background-color: ${NEUTRAL_SHADES[200]};
    border-color: ${NEUTRAL_SHADES[400]};
    color: ${NEUTRAL_SHADES[600]};
    ::placeholder {
      color: ${NEUTRAL_SHADES[600]} !important;
    }
  }
`;

export const StyledInputButton = styled(InputGroup.Text)<{ $isError: boolean; $isDisabled: boolean }>`
  ${({ $isError, $isDisabled }) => css`
    cursor: pointer;
    padding-right: 10px;
    background-color: ${$isDisabled ? NEUTRAL_SHADES[200] : 'transparent'};
    border-color: ${$isError ? ERROR_SHADES.BOOTSTRAP : $isDisabled && NEUTRAL_SHADES[400]};
    color: ${$isDisabled && NEUTRAL_SHADES[600]};
  `}
`;

export const customStarStyles: CSSProperties = {
  marginLeft: SPACING[0],
  marginBottom: SPACING[0],
};

export const ErrorContainer = styled.div`
  min-height: ${SPACING[3.5]};
`;
