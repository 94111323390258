import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  width: fit-content;
  margin-left: auto;
  align-items: center;
  .people-options-dropdown {
    margin-top: ${SPACING[2]};
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

export const Headline = styled.h2`
  font-weight: 700;
  font-size: 21px;
  color: black;
`;

export const AccountDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[3]};
  border: 1px solid #dcdcdc;
  border-radius: ${SPACING[1]};
`;

export const AccountDetails = styled.div`
  display: flex;
  font-size: 14px;
  font-family: 'DIN2014-Regular';
  margin-top: ${SPACING[2]};
`;

export const AccountDataSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  b {
    font-family: 'DIN Alternate';
    font-weight: 700;
  }
  .view-account-status {
    display: flex;
    align-items: center;
    gap: ${SPACING[2]};
  }
  span:not(:last-of-type) {
    margin-bottom: ${SPACING[2]};
  }
  &:nth-of-type(2) {
    span:first-of-type {
      margin-bottom: ${SPACING[1]};
    }
  }
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const ellipsisWordTypographyStyles = `
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
`;

export const AssetNameContainer = styled.div`
  display: flex;
  max-width: 100%;
  gap: ${SPACING[2]};
`;

export const ValueContainer = styled.div`
  max-width: 100%;
  gap: ${SPACING[2]};
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  gap: ${SPACING[1]};
`;

export const DisplayedTags = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const RemainingCountContainer = styled.div`
  white-space: nowrap;
  overflow: visible;
`;
