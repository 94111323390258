/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ActivityLogData, assetFields, AUDIT_TYPE, MODIFICATION_TYPE } from 'utils/types/activityLog/asset/types';
import { TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Container, FlexDiv, FlexSpan } from '_components/ActivityLog/style';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { extractPeople, mapGroupType } from '_components/ActivityLog/utils';
import { Recipient } from 'utils/types/assets/assets';

interface Props {
  data: ActivityLogData[];
}

const StyledSpan = styled.span`
  margin-top: ${SPACING[0.5]};
`;

export const AssetActivityNotifyAccess = ({ data }: Props) => {
  const { t } = useTranslation();

  const item = data[0]?.to[0];
  const group = mapGroupType(item?.groupType);

  const showAccessNotification = data[0]?.type === assetFields.ACCESS_CHANGE && !item?.hasAccessChanged && item?.actionType === MODIFICATION_TYPE.REMOVED && item?.recipientAccount;

  const recipientName = item?.recipientAccount?.displayName;

  const renderNotificationText = (people: Recipient[]) => {
    const isRemovedAction = item?.actionType === AUDIT_TYPE.REMOVED;
    const isAddedAction = item?.actionType === AUDIT_TYPE.ADDED;
    const hasAccessChanged = item?.hasAccessChanged;

    const notificationText = (() => {
      if (isRemovedAction && hasAccessChanged === false) {
        return t('ACTIVITY_LOG.ASSET.REMOVED_GROUP_ACCESS_REMAINS', { GROUP: group.toLowerCase() });
      }
      if (isRemovedAction && hasAccessChanged === true) {
        return t('ACTIVITY_LOG.ASSET.NO_LONGER_ACCESS');
      }
      if (isAddedAction && hasAccessChanged === false) {
        return t('ACTIVITY_LOG.ASSET.ADDED_GROUP_WITH_ACCESS', { GROUP: group.toLowerCase() });
      }
      if (isAddedAction && hasAccessChanged === true) {
        return t('ACTIVITY_LOG.ASSET.WAS_NOTIFIED', {
          ACTION: t('ACTIVITY_LOG.ASSET.WAS'),
        });
      }
    })();

    return (
      <FlexSpan>
        {people.map((person) => (
          <React.Fragment key={person._id}>
            <Avatar size={AVATAR_SIZES.XS} name={person.fullName} email={person.email} />
            <TangiTypography type="subheading" weight="bold">
              {person.fullName || person.email}
            </TangiTypography>
          </React.Fragment>
        ))}
        <StyledSpan>{notificationText}</StyledSpan>
      </FlexSpan>
    );
  };
  
  return (
    <Container>
      {data.map((item: any, index: number) => (
        <FlexDiv key={index}>{renderNotificationText(extractPeople(item.to))}</FlexDiv>
      ))}
      <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
        {item?.items?.[0]?.partnership ?  
        t('ACTIVITY_LOG.AUTOMATIC_PARTNER_ADDED', {
          GROUP: group,
          ACTION: item?.actionType,
        })
        :
        t('ACTIVITY_LOG.AUTOMATIC_ADDED', {
          GROUP: group,
          ACTION: item?.actionType,
        })}
      </TangiTypography>
      {showAccessNotification && (
        <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
          {t('ACTIVITY_LOG.STILL_HAS_ACCESS', {
            NAME: recipientName,
          })}
        </TangiTypography>
      )}
    </Container>
  );
};
