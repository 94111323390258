import { ReactNode } from 'react';
import { TableColumn } from 'react-data-table-component';
import { GroupedRecipientsAssetData, GroupedRecipientsCategoryData, RecipientCategoryType, SharedRecipient, SharedRecipientAccount } from 'utils/types/assets/assets';

//---------------------------------------------Table Manager/MultipleAcknowledgement raw data------------------------------------------------------//

export interface MultipleAcknowledgementTableData {
  id: string;
  assetName: string;
  assetType: string;
  recipients: SharedRecipient[];
  businessUnits: GroupedRecipientsCategoryData[];
  departments: GroupedRecipientsCategoryData[];
  partners: GroupedRecipientsCategoryData[];
  partnersDepartments: GroupedRecipientsCategoryData[];
  contributorsIds: string[];
}

//------------------------------------------MultipleAcknowledgement (main table) Rows/Columns type-------------------------------------------------//

export interface MultipleAcknowledgementTableRowData {
  _id: string;
  name: string;
  type: string;
  itemsNum: number;
}

//-------------------------------------------Recipients and Groups Rows/Columns types-------------------------------------------------------------//

export type RecipientEntityRowData = ({ type: RecipientEntity.GROUP } & GroupTableRowData) | ({ type: RecipientEntity.RECIPIENT } & RecipientTableRowData);

export interface GroupTableRowData {
  _id: string;
  name: string;
  sentDate: string;
  acknowledgedNum: number;
  recipientsNum: number;
  collapseContent: ReactNode;
  groupType: GroupType;
  additionalInfo?: GroupedRecipientsAssetData;
}

export interface RecipientTableRowData {
  _id: string;
  fullName: string;
  email: string;
  sentDate: string;
  acknowledgedOn: string;
  isContributor: boolean;
  isEmptyAvatar: boolean;
  onClickDelete: () => void;
  acknowledged: boolean;
  partnership?: GroupedRecipientsAssetData;
}

//----------------------------------------------------general types--------------------------------------------------------//

export enum RecipientEntity {
  GROUP = 'group',
  RECIPIENT = 'recipient',
}

export type GroupType = Exclude<RecipientCategoryType, RecipientCategoryType.INDIVIDUAL>;

export interface TablePermissions {
  removeRecipient?: boolean;
}

export interface DeleteRecipientData {
  assetId: string;
  recipientId: string;
}

//------------------------------------------types for util functions---------------------------------------------------------//

export interface BuildAllGroupTableRowsInput {
  id: string;
  recipients: SharedRecipient[];
  businessUnits: GroupedRecipientsCategoryData[];
  partnersDepartments: GroupedRecipientsCategoryData[];
  departments: GroupedRecipientsCategoryData[];
  partners: GroupedRecipientsCategoryData[];
  recipientsTableColumns: TableColumn<RecipientEntityRowData>[];
  contributorsIds: string[];
  handleDeleteRecipient: (data: DeleteRecipientData) => void;
}

export interface BuildRecipientRowInput {
  _id: string;
  assetId: string;
  fullName: string;
  email: string;
  sentDate: string;
  acknowledgedOn: string | null;
  contributorsIds: string[];
  acknowledged: boolean;
  handleDeleteRecipient: (data: DeleteRecipientData) => void;
  account: SharedRecipientAccount | null;
  partnership?: GroupedRecipientsAssetData;
}

export interface BuildGroupRowInput {
  _id: string;
  assetId: string;
  name: string;
  recipients: SharedRecipient[];
  recipientsTableColumns: TableColumn<RecipientEntityRowData>[];
  contributorsIds: string[];
  handleDeleteRecipient: (data: DeleteRecipientData) => void;
  groupType: GroupType;
  additionalInfo?: GroupedRecipientsAssetData;
}
