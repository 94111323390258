/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { PARTNERSHIP_STATUS, RESULT_STATUS } from 'utils/enums';
import {
  getPartnershipById,
  createPartnership,
  updatePartnership,
  deactivatePartnership,
  getPartnerships,
  getPartnershipAccountsById,
  getPartnershipAssetsById,
} from 'redux-toolkit/thunks/partnershipThunks';
import { PartnershipAccountsRes, PartnershipAssetsRes, Partnerships, PartnershipWithData } from 'utils/types/partnership/partnership';
import { ToastProps } from 'utils/types/types';
import { PartnerUserFormType } from '_components/PartnershipInviteUsersModal/types';

interface partnershipState {
  loading: boolean;
  partnershipCreateLoading: boolean;
  error: string;
  partnership: PartnershipWithData;
  addPartnerModalIsOpen: boolean;
  partnershipToastProps: ToastProps;
  partnershipCreateSuccess: boolean;
  partnershipUpdateSuccess: boolean;
  partnershipDeactivateSuccess: boolean;
  partnerMenuIsOpen: boolean;
  modalPage: number;
  usersModalIsOpen: boolean;
  usersModalType: PartnerUserFormType;
  finishCreationPartnershipModalIsOpen: boolean;
  partnerships: Partnerships;
  loadingPartnerships: boolean;
  partnershipAccountsRes: PartnershipAccountsRes | null;
  partnershipAccountsLoading: boolean;
  partnershipAssetsRes: PartnershipAssetsRes | null;
  partnershipAssetsLoading: boolean;
}

const initialState: partnershipState = {
  loading: false,
  partnership: {
    client: '',
    country: '',
    createdAt: '',
    isActive: true,
    location: '',
    logo: '',
    name: '',
    updatedAt: '',
    __v: 0,
    _id: '',
    createdBy: '',
    updatedBy: '',
    status: PARTNERSHIP_STATUS.ACTIVE,
    accounts: [],
    agreements: [],
    deactivatedOn: null,
    deactivatedBy: null,
  },
  error: '',
  addPartnerModalIsOpen: false,
  partnershipToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  partnershipCreateSuccess: false,
  partnershipUpdateSuccess: false,
  partnershipDeactivateSuccess: false,
  partnerMenuIsOpen: false,
  modalPage: 1,
  usersModalIsOpen: false,
  usersModalType: PartnerUserFormType.ADD_INITAL,
  finishCreationPartnershipModalIsOpen: false,
  partnershipCreateLoading: false,
  partnerships: { active: [], disabled: [] },
  loadingPartnerships: false,
  partnershipAccountsRes: null,
  partnershipAccountsLoading: false,
  partnershipAssetsRes: null,
  partnershipAssetsLoading: false,
};

const partnershipSlice = createSlice({
  name: 'partnershipData',
  initialState,
  reducers: {
    changeAddModalVisibility(state, action: PayloadAction<boolean>) {
      state.addPartnerModalIsOpen = action.payload;
    },
    setPartnershipToastProps(state, action: PayloadAction<ToastProps>) {
      state.partnershipToastProps = action.payload;
    },
    clearPartnership(state) {
      state.partnershipCreateSuccess = false;
      state.partnershipUpdateSuccess = false;
      state.partnershipDeactivateSuccess = false;
      state.loading = false;
      state.loadingPartnerships = false;
      state.partnershipToastProps = { show: false, text: '', type: RESULT_STATUS.BLANK };
      state.error = '';
    },
    toggleDrawer(state, action: PayloadAction<boolean>) {
      state.partnerMenuIsOpen = action.payload;
    },
    setModalPage(state, action: PayloadAction<number>) {
      state.modalPage = action.payload;
    },
    setInviteUsersModalState(state, action: PayloadAction<boolean>) {
      state.usersModalIsOpen = action.payload;
    },
    setInviteUsersModalType(state, action: PayloadAction<PartnerUserFormType>) {
      state.usersModalType = action.payload;
    },
    setFinishCreationPartnershipModalState(state, action: PayloadAction<boolean>) {
      state.finishCreationPartnershipModalIsOpen = action.payload;
    },
    resetPartnerships(state) {
      state.partnerships = { active: [], disabled: [] };
    },
  },
  extraReducers: (builder) => {
    // getPartnershipById
    builder.addCase(getPartnershipById.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPartnershipById.fulfilled, (state, action: PayloadAction<PartnershipWithData>) => {
      state.loading = false;
      state.partnership = action.payload;
    });
    builder.addCase(getPartnershipById.rejected, (state, action: any) => {
      state.loading = false;
      state.error = action.error.message;
    });

    //getAllPartnerships
    builder.addCase(getPartnerships.pending, (state) => {
      state.loadingPartnerships = true;
      state.error = '';
    });
    builder.addCase(getPartnerships.fulfilled, (state, action: PayloadAction<Partnerships>) => {
      state.partnerships = action.payload;
      state.loadingPartnerships = false;
    });
    builder.addCase(getPartnerships.rejected, (state, action: any) => {
      state.loadingPartnerships = false;
      state.error = action.error.message;
    });
    // createPartnership
    builder.addCase(createPartnership.pending, (state) => {
      state.partnershipCreateLoading = true;
      state.loadingPartnerships = true;
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createPartnership.fulfilled, (state, action: PayloadAction<any>) => {
      const text = i18next.t('PARTNERSHIP_PAGE.TOAST.PARTNERSHIP_CREATED');
      state.partnership = action.payload;
      state.partnerships = {
        ...state.partnerships,
        active: [action.payload, ...state.partnerships.active],
      };
      state.partnershipCreateLoading = false;
      state.partnershipCreateSuccess = true;
      state.loadingPartnerships = false;
      state.partnershipToastProps = { show: true, type: RESULT_STATUS.SUCCESS, text };
    });
    builder.addCase(createPartnership.rejected, (state, action: any) => {
      state.partnershipCreateLoading = false;
      state.error = action.error.message;
      state.loadingPartnerships = false;
      state.partnershipCreateSuccess = false;
    });

    // updatePartnership
    builder.addCase(updatePartnership.pending, (state) => {
      state.partnershipCreateLoading = true;
      state.loading = true;
      state.loadingPartnerships = true;
      state.error = '';
    });
    builder.addCase(updatePartnership.fulfilled, (state, action: PayloadAction<any>) => {
      const text = i18next.t('PARTNERSHIP_PAGE.TOAST.PARTNERSHIP_UPDATED');
      state.loading = false;
      state.partnershipUpdateSuccess = true;
      state.loadingPartnerships = false;
      state.partnerships = action.payload.updatedPartnerships;
      state.partnershipToastProps = { show: true, type: RESULT_STATUS.SUCCESS, text };
    });
    builder.addCase(updatePartnership.rejected, (state, action: any) => {
      state.loading = false;
      state.loadingPartnerships = false;
      state.error = action.error.message;
      state.partnershipUpdateSuccess = false;
    });

    // deactivatePartnership
    builder.addCase(deactivatePartnership.pending, (state) => {
      state.partnershipCreateLoading = true;
      state.loadingPartnerships = true;
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deactivatePartnership.fulfilled, (state, action: PayloadAction<any>) => {
      const text = i18next.t('PARTNERSHIP_PAGE.TOAST.PARTNERSHIP_DEACTIVATED');
      state.loading = false;
      state.partnershipCreateLoading = false;
      state.partnershipDeactivateSuccess = true;
      state.partnerships = action.payload.updatedPartnerships;
      state.partnershipToastProps = { show: true, type: RESULT_STATUS.SUCCESS, text };
    });
    builder.addCase(deactivatePartnership.rejected, (state, action: any) => {
      state.loading = false;
      state.loadingPartnerships = false;
      state.error = action.error.message;
      state.partnershipDeactivateSuccess = false;
    });

    // getPartnershipAccountsById
    builder.addCase(getPartnershipAccountsById.pending, (state) => {
      state.partnershipAccountsLoading = true;
      state.error = '';
    });
    builder.addCase(getPartnershipAccountsById.fulfilled, (state, action: PayloadAction<PartnershipAccountsRes>) => {
      state.partnershipAccountsLoading = false;
      state.partnershipAccountsRes = action.payload;
    });
    builder.addCase(getPartnershipAccountsById.rejected, (state, action: any) => {
      state.partnershipAccountsLoading = false;
      state.error = action.error.message;
    });

    // getPartnershipAssetsById
    builder.addCase(getPartnershipAssetsById.pending, (state) => {
      state.partnershipAssetsLoading = true;
      state.error = '';
    });
    builder.addCase(getPartnershipAssetsById.fulfilled, (state, action: PayloadAction<PartnershipAssetsRes>) => {
      state.partnershipAssetsLoading = false;
      state.partnershipAssetsRes = action.payload;
    });
    builder.addCase(getPartnershipAssetsById.rejected, (state, action: any) => {
      state.partnershipAssetsLoading = false;
      state.error = action.error.message;
    });
  },
});

export const partnershipActions = partnershipSlice.actions;

export default partnershipSlice.reducer;
