import { AccountsTableContainer } from "./styles";
import AdditionalAccountsTooltip from "./AdditionalAccountsTooltip";
import { SharedWith } from "utils/types/partnership/partnership";
import AccountAndAvatar from "./AccountAndAvatar";


export const AccountsList = ({ accounts }: { accounts: SharedWith[] }) => {
  const numAccountsToDisplay = 1;

  return (
    <AccountsTableContainer data-testid="accounts-table-container">
      {accounts.slice(0, numAccountsToDisplay).map((account: SharedWith, index: number) => (
        <AccountAndAvatar account={account} key={index} />
      ))}
      {accounts.length > numAccountsToDisplay && <AdditionalAccountsTooltip accounts={accounts.slice(numAccountsToDisplay)} />}
    </AccountsTableContainer>
  );
};