import styled, { css } from 'styled-components';

import { TangiCheckbox } from '_components/TangiLibrary';
import { PRIMARY_SHADES, NEUTRAL_SHADES, ERROR_SHADES, SPACING, BORDER_RADIUS } from '../../utils/theme';
import { DuoContainer, TableHeaderText } from '../../utils/globalStyles';

export const AssetTableContainer = styled.div`
  .rdt_TableRow:not(:last-of-type) {
    border-bottom: 1px solid ${NEUTRAL_SHADES[200]};
  }
`;

export const AssetName = styled(TableHeaderText)<{ isLocked?: boolean }>`
  ${({ isLocked = false }) => css`
    color: ${isLocked ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  `}
`;

export const ColorAndType = styled.div<{ isLocked?: boolean }>`
  ${({ isLocked = false }) => css`
    display: flex;
    gap: ${SPACING[3]};
    font-family: 'DIN2014-Regular';
    font-size: 14px;
    align-items: center;
    color: ${isLocked ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK};
    span {
      padding-top: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  `}
`;

export const AssetTagsContainer = styled.div<{ isLocked?: boolean }>`
  ${({ isLocked = false }) => css`
    font-family: 'DIN2014-Regular';
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: ${SPACING[2]};
    color: ${isLocked ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK};
    span:nth-of-type(1),
    span:nth-of-type(2) {
      white-space: nowrap;
    }
    span:nth-of-type(1) {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 152px;
    }
    span:nth-of-type(2) {
      cursor: default;
    }
  `}
`;

export const RecipientNumber = styled.div<{ allAcknowledged: boolean }>`
  ${({ allAcknowledged }) => css`
    font-family: 'DIN2014-DemiBold';
    color: ${allAcknowledged ? NEUTRAL_SHADES.BLACK : ERROR_SHADES[600]};
    span:first-of-type {
      margin-right: ${SPACING[1]};
    }
    span:nth-of-type(2) {
      font-family: 'DIN2014-Regular';
    }
  `}
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  padding-left: ${SPACING[1]};
  width: 80px;
  justify-content: flex-end;
`;

export const customTableStyles = {
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[200]} !important`,
      minHeight: '42px',
      maxHeight: '42px',
    },
  },
  headCells: {
    style: {
      paddingTop: '10px',
      paddingBottom: '10px',
      '&:nth-of-type(1)': {
        paddingRight: '16px',
      },
      '&:nth-of-type(2)': {
        marginRight: '36px',
      },
    },
  },
  rows: {
    style: {
      maxHeight: '42px',
      minHeight: '42px',
      border: 'none',
    },
    highlightOnHoverStyle: {
      backgroundColor: PRIMARY_SHADES[100],
      outline: 'none',
      cursor: 'pointer',
    },
  },
  cells: {
    style: {
      '&:nth-of-type(1)': {
        paddingRight: '16px',
      },
      '&:nth-of-type(2)': {
        marginRight: '36px',
      },
    },
  },
  pagination: {
    style: {
      marginBottom: SPACING[4],
    },
  },
};

export const conditionalRowStyles = [
  {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    when: (row: any) => row.isLocked,
    style: {
      background: NEUTRAL_SHADES[100],
      cursor: 'default',
    },
  },
];

export const customAssetOptionsStyles = {
  dropdownStyles: { left: `-${SPACING[5]}`, top: SPACING[6] },
};

export const AssetTableRowCheckBox = styled(TangiCheckbox)`
  .form-check-input[type='checkbox'] {
    cursor: pointer;
    border-radius: ${BORDER_RADIUS[2]};
    outline: none;
    box-shadow: none;
    margin: ${SPACING[0]};
  }
`;

export const NameAndCheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NameAndThirdPartyContainer = styled(DuoContainer)`
  height: ${SPACING[6]};
`;

export const CheckBoxContainer = styled.div`
  padding: ${SPACING[3]};
  flex-shrink: 0;
`;
