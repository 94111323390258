import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';

import { en } from './translations/en';
import { ja } from './translations/ja';
import { zh } from './translations/zh'
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { LANGUAGE } from 'translations/enums';
import { IRoles } from 'utils/roles';

const resources = { en, ja, zh };

// Initialize i18n with the default language
i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE.ENGLISH,
  interpolation: {
    escapeValue: false,
  },
});

function I18nConfig() {
  const userLanguage = useSelector((state: RootState) => state.authentication.user?.language);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const Role = useSelector((state: RootState) => state.authentication.Role);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    let languageByRole;

    if (Role === IRoles.LAWYER) {
      languageByRole = userLanguage ?? activeClient?.language ?? LANGUAGE.ENGLISH;
    } else {
      languageByRole = userLanguage ?? activeAccount?.client?.language ?? LANGUAGE.ENGLISH;
    }
    if (!languageByRole) languageByRole = LANGUAGE.ENGLISH;

    setSelectedLanguage(languageByRole);
  }, [userLanguage, activeAccount, Role, activeClient]);

  useEffect(() => {
    // Change the language in i18n without re-initializing it depeding on the selected language
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return null;
}

export default I18nConfig;
