import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { TangiTypography, TangiSvgIcon, TangiIconButton } from '_components/TangiLibrary';
import AssetTypeColorBlock from '../../_components/AssetTypeColorBlock/AssetTypeColorBlock';
import { formatDate } from '../../utils/dateUtils';
import { assetTypeNameFormatter } from '../../utils/assetTypes';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { SPACING, SUCCESS_SHADES, NEUTRAL_SHADES } from '../../utils/theme';
import { AssetTypeContainer, DeactivationPopupBody, partnershipLinkStyles } from './styles';
import { FlexContainer } from 'utils/globalStyles';
import { AccountsList } from './AccountsList';

export const tableTypes = {
  DOCUMENTS: 'documents',
  PEOPLE: 'people',
  PEOPLE_NEW: 'people_new',
  ASSETS_SHARED_WITH_PARTNER: 'assets shared with partner',
  ASSETS_PARTNERS_CONTRIBUTORS: 'assets partners are contributors',
};

export const getColumns = (type, isAgreementsWithFiles) => {
  const { t } = useTranslation();
  if (type === tableTypes.DOCUMENTS) {
    return [
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.TITLE')}</TangiTypography>,
        selector: ({ name }) => name,
        grow: 1,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.SIGNED')}</TangiTypography>,
        selector: ({ status }) => status,
        fieldName: 'status',
        center: true,
        grow: 0,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.VERSION')}</TangiTypography>,
        selector: ({ version }) => version,
        center: true,
        grow: 1,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.EXECUTED_ON')}</TangiTypography>,
        selector: ({ executedate }) => executedate,
        maxWidth: '180px',
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.EXPIRES_ON')}</TangiTypography>,
        selector: ({ expireddate }) => expireddate,
        maxWidth: '180px',
      },
      {
        name: '',
        id: 'download',
        selector: ({ download }) => download,
        width: `${SPACING[8]}`,
        grow: 0,
        omit: !isAgreementsWithFiles,
        right: true,
        compact: true,
        style: { paddingRight: `${SPACING[4]}` },
      },
    ];
  }
  if (type === tableTypes.PEOPLE) {
    return [
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.NAME')}</TangiTypography>,
        selector: ({ name }) => name,
        grow: 1,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.STATUS')}</TangiTypography>,
        selector: ({ accountStatus }) => accountStatus,
        fieldName: 'accountStatus',
        grow: 0,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.DEPARTMENTS')}</TangiTypography>,
        selector: ({ departments }) => departments,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.ASSETS')}</TangiTypography>,
        selector: ({ assetsCount }) => assetsCount,
        center: true,
        compact: true,
        grow: 1,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.CREATION_DATE')}</TangiTypography>,
        selector: ({ createdAt }) => createdAt,
        fieldName: 'createdAt',
        maxWidth: '160px',
      },
      {
        name: '',
        selector: ({ editdelete }) => editdelete,
        center: true,
        compact: true,
        grow: 0,
      },
    ];
  }
  if (type === tableTypes.ASSETS_SHARED_WITH_PARTNER) {
    return [
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.ASSET_NAME')}</TangiTypography>,
        selector: ({ asset }) => asset,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.TYPE')}</TangiTypography>,
        selector: ({ assetType }) => assetType,
        minWidth: SPACING[10],
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.SHARED_WITH')}</TangiTypography>,
        selector: ({ accounts }) => accounts,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.CREATION_DATE')}</TangiTypography>,
        selector: ({ createdAt }) => createdAt,
        width: '160px',
        grow: 0,
      },
    ];
  }
  if (type === tableTypes.ASSETS_PARTNERS_CONTRIBUTORS) {
    return [
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.ASSET_NAME')}</TangiTypography>,
        selector: ({ asset }) => asset,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.TYPE')}</TangiTypography>,
        selector: ({ assetType }) => assetType,
        minWidth: SPACING[10],
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.CONTRIBUTORS')}</TangiTypography>,
        selector: ({ accounts }) => accounts,
      },
      {
        name: <TangiTypography weight="semibold">{t('PARTNERSHIP_PAGE.TABLE_HEADERS.CREATION_DATE')}</TangiTypography>,
        selector: ({ createdAt }) => createdAt,
        width: '160px',
        grow: 0,
      },
    ];
  }
};

export const prepareAssetsTablesRows = ({ assets, clientId }) => {

  const renderLinkToAssetPage = ({ assetName, assetId }) => {
    return (
      <Link
        to={{
          pathname: `/client/${clientId}/asset/${assetId}`,
        }}
        data-testid="partnership-asset-link"
      >
        <TangiTypography customStyles={partnershipLinkStyles}>{assetName}</TangiTypography>
      </Link>
    );
  };

  const renderAssetType = (assetType) => {
    return (
      <AssetTypeContainer data-testid="partnership-asset-type">
        <AssetTypeColorBlock assetTypeName={assetType || ''} />
        <TangiTypography>{assetTypeNameFormatter(assetType)}</TangiTypography>
      </AssetTypeContainer>
    );
  };

  const rows = assets.map((asset) => {
    const accounts = asset.sharedWith
    const assetName = asset.name;
    const createdAt = asset.createdAt;
    const assetType = asset.assetType.name;
    const assetId = asset._id;

    return {
      asset: renderLinkToAssetPage({ assetName, assetId }),
      accounts: <AccountsList accounts={accounts} />,
      createdAt: <TangiTypography>{createdAt ? formatDate(createdAt) : ''}</TangiTypography>,
      assetType: renderAssetType(assetType),
    };
  });

  return rows;
};

export const prepareAgreementsTablesRows = (agreements, handleDownloadAgreement, downloadAgreementsLoading) => {
  const formatDateCell = (date) => (date ? formatDate(date) : '');

  return agreements.map((agreement, index) => {
    const { _id, agreementType, version, signed_date, expiry_date } = agreement;

    const statusCell = agreement?.signed ? (
      <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
    ) : (
      <TangiTypography color={NEUTRAL_SHADES[600]}>{i18next.t('PARTNERSHIP_PAGE.TABLE.MISSING')}</TangiTypography>
    );

    const renderDownloadCell = () => {
      const agreementWithFile = agreement?.files?.find((file) => file.fileId && file.name && file.fileType);
      const agreementOrderNumber = index + 1;

      return agreementWithFile ? (
        <Tippy content={i18next.t('PARTNERSHIP_PAGE.TOOLTIP.DOWNLOAD_AGREEMENT')}>
          <div>
            <TangiIconButton
              loading={downloadAgreementsLoading[agreementOrderNumber]}
              variant={BUTTON_VARIANTS.TERTIARY_GREY}
              onClick={() => handleDownloadAgreement({ agreementId: _id, uniqueFileKey: agreementWithFile.uniqueFileKey, agreementOrderNumber })}
              icon="download"
            />
          </div>
        </Tippy>
      ) : null;
    };

    return {
      id: _id,
      name: <TangiTypography>{agreementType.name}</TangiTypography>,
      version: <TangiTypography>{version?.name || ''}</TangiTypography>,
      executedate: <TangiTypography>{formatDateCell(signed_date)}</TangiTypography>,
      expireddate: <TangiTypography>{formatDateCell(expiry_date)}</TangiTypography>,
      status: statusCell,
      download: renderDownloadCell(),
    };
  });
};

export const renderDeactivationPopupBody = () => {
  const { t } = useTranslation();
  return (
    <DeactivationPopupBody>
      <TangiTypography type="subheading">{t('PARTNERSHIP_PAGE.DEACTIVATION_POPUP_BODY.WILL_LOSE_ACCESS')}</TangiTypography>
      <TangiTypography type="subheading">{t('PARTNERSHIP_PAGE.DEACTIVATION_POPUP_BODY.CAN_ACTIVATE_LATER')}</TangiTypography>
      <TangiTypography type="subheading">
        <b className="popup-bold-text">{t('PARTNERSHIP_PAGE.DEACTIVATION_POPUP_BODY.NOTE')}: </b>
        {t('PARTNERSHIP_PAGE.DEACTIVATION_POPUP_BODY.CAN_FIND_IN_SIDEMENU')}
      </TangiTypography>
    </DeactivationPopupBody>
  );
};

export const renderDeactivationPopupTitle = (partnershipName = '') => {
  const { t } = useTranslation();
  return (
    <FlexContainer>
      <TangiTypography type="heading-lg">{t('PARTNERSHIP_PAGE.DEACTIVATION_POPUP_TITLE.DEACTIVATE')}</TangiTypography>
      <TangiTypography type="heading-lg" weight={partnershipName ? 'semibold' : 'regular'}>
        {partnershipName}?
      </TangiTypography>
    </FlexContainer>
  );
};
