import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { clientSettingsActions } from '../../_actions/clientSettingsActions';
import { getAccountAssets } from 'redux-toolkit/thunks/usersThunks';
import useRole from '_hooks/useRole';

export const useViewEmployee = (accountData) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const metaDataState = useSelector((state) => state.assetMetaData);
  const settings = useSelector((state) => state.clientSettings.settings);

  const { isLawyer, isAdmin } = useRole();
  const isAdminOrLawyer = isLawyer || isAdmin;

  const [ownedSortState, setOwnedSortState] = useState({ selector: '', direction: '' });
  const [sharedSortState, setSharedSortState] = useState({ selector: '', direction: '' });

  useEffect(() => {
    if (!metaDataState) {
      dispatch(getAssetMetaData({clientId, partnershipId: null }));
    }
    if (!settings) {
      dispatch(clientSettingsActions.getClientSettings(clientId));
    }
  }, [settings, metaDataState]);

  useEffect(() => {
    if (isAdminOrLawyer && accountData?.id && !accountData?.assets) {
      dispatch(getAccountAssets(accountData.id));
    }
  }, [isAdminOrLawyer]);

  const handleOwnedSort = (column, sortDirection) => {
    setOwnedSortState({
      selector: column.selector,
      direction: sortDirection,
    });
  };

  const handleSharedSort = (column, sortDirection) => {
    setSharedSortState({
      selector: column.selector,
      direction: sortDirection,
    });
  };

  return {
    settings,
    ownedSortState,
    sharedSortState,
    handleSharedSort,
    handleOwnedSort,
    isAdminOrLawyer,
  };
};
