import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { createAgreementVersion, getAgreementVersions } from 'redux-toolkit/thunks/clientThunks';
import { clientSettingsActions } from '../../_actions';
import { updatePartnership } from 'redux-toolkit/thunks/partnershipThunks';
import { TangiAlert, TangiButton, TangiInput, TangiSelector } from '../TangiLibrary';
import { Agreement } from '../Agreement';
import { countriesOptions } from '../../utils/getOptions';
import { IRoles } from '../../utils/roles';
import { apiToFormFormat, partnershipFormToApiFormat } from './util';
import CameraIcon from '../../assets/icons/camera.svg';
import { Container, FormContainer, PhotoContainer, DuoContainer, Separator, SectionTitle, ButtonsContainer, OuterCircle, InnerCircle, CameraButton, LogoContanier, CameraImg } from './style';

const PartnershipForm = ({ isEditMode = true, handleClose }) => {
  const settings = useSelector((state) => state.clientSettings.settings);
  const agreementLoading = useSelector((state) => state.agreement.loading);
  const agreementError = useSelector((state) => state.agreement.error);
  const { agreementVersions, newAgreementVersion, newAgreementVersionIndex, loading: versionsLoading } = useSelector((state) => state.client);
  const { partnershipUpdateSuccess, error: partnershipError, partnership, loading: partnershipLoading } = useSelector((state) => state.partnership);

  const [logo, setLogo] = useState(isEditMode && partnership?.logo ? [partnership?.logo] : []);
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });

  const { clientId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: isEditMode ? apiToFormFormat(partnership) : {},
  });

  const nameChanged = useWatch({
    control: control,
    name: 'name',
  });

  //  -----------------      utils     -----------------
  // TODO: we can use the result of validation to notify the user if the size of the image is too big
  const fileSizeValidator = (file) => {
    const maxSize = 2000000;
    if (file.size > maxSize) {
      return {
        code: 'file-too-large',
        message: `File should be less than ${maxSize / 1000000} mb`,
      };
    }
    return null;
  };

  // TODO we can notify the user of the types of the accepted files
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['.jpg', '.png', '.jpeg'],
    validator: fileSizeValidator,
    onDrop: (acceptedFiles) => {
      setLogo(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    noClick: true,
    noKeyboard: true,
  });

  const handleFiles = (acceptedFiles, fileRejections, id) => {
    if (acceptedFiles.length > 0) {
      setValue(id, acceptedFiles);
    }
  };

  const handleCreateOption = async (inputValue, type, index) => {
    dispatch(createAgreementVersion({ agreement: { name: inputValue, client: [clientId] }, index }));
  };

  const handleOpen = (e) => {
    e.preventDefault();
    open();
  };

  const onSubmitForm = async (data) => {
    let payload = partnershipFormToApiFormat(data);
    if (!isEmpty(logo)) {
      payload = {
        ...payload,
        logo: logo,
      };
    }
    if (isEditMode) {
      payload = {
        ...payload,
        _id: data._id,
      };
      dispatch(updatePartnership(payload));
    }
  };

  useEffect(() => {
    batch(() => {
      dispatch(clientSettingsActions.getClientSettings(clientId));
      dispatch(getAgreementVersions({ client: clientId }));
    });
  }, []);

  useEffect(() => {
    if (partnershipUpdateSuccess) {
      handleClose();
    }
  }, [partnershipUpdateSuccess]);

  useEffect(() => {
    if (newAgreementVersion && newAgreementVersionIndex !== null) {
      const versionsValue = { value: newAgreementVersion.id, label: newAgreementVersion.name };
      setValue(`agreements[${newAgreementVersionIndex}].version`, versionsValue);
    }
  }, [newAgreementVersion]);

  useEffect(() => {
    if (partnershipError) {
      setAlertProps(() => {
        return { show: true, type: 'error', text: partnershipError };
      });
    } else if (agreementError?.message) {
      setAlertProps({ show: true, type: 'error', text: agreementError.message });
    }
  }, [partnershipError, agreementError]);

  //  -----------------      Components     -----------------
  const renderPhotoContainer = () => {
    return (
      <PhotoContainer>
        <OuterCircle {...getRootProps()} className="outer-circle">
          <input {...getInputProps()} />
          <InnerCircle $isLogo={!!logo.length} className="inner-circle">
            <CameraButton onClick={handleOpen}>
              {!isEmpty(logo) && <LogoContanier width="120" height="120" src={logo[0]?.preview ? logo[0].preview : logo[0]} />}
              <CameraImg src={CameraIcon} alt="add-logo" />
            </CameraButton>
          </InnerCircle>
        </OuterCircle>
      </PhotoContainer>
    );
  };
  const renderInputs = () => {
    return (
      <>
        <TangiInput required label="Name" name="name" register={register} error={errors.name} />
        <DuoContainer>
          <TangiSelector label="Country" name="country" options={countriesOptions()} control={control} error={errors.country} />
          <TangiInput label="Address" name="location" register={register} error={errors.location} />
        </DuoContainer>
      </>
    );
  };
  const renderAgreementsOptions = () => {
    if (!isEditMode && settings.agreements) {
      settings.agreements[IRoles.PARTNER].forEach((agreementType, index) => {
        setValue(`agreements[${index}].agreementType`, agreementType.id);
      });
    }
    return isEditMode && partnership?.agreements
      ? partnership?.agreements?.map((agreement, index) => (
          <Agreement
            key={index}
            register={register}
            files={agreement.files}
            agreementVersions={agreementVersions}
            versionsLoading={versionsLoading}
            control={control}
            agreementIndex={index}
            agreement={agreement.agreementType}
            handleFiles={handleFiles}
            handleCreateOption={handleCreateOption}
          />
        ))
      : settings.agreements &&
          settings.agreements[IRoles.PARTNER].map((agreement, index) => (
            <Agreement
              key={index}
              register={register}
              agreementVersions={agreementVersions}
              versionsLoading={versionsLoading}
              control={control}
              agreementIndex={index}
              agreement={agreement}
              handleFiles={handleFiles}
              handleCreateOption={handleCreateOption}
            />
          ));
  };

  const renderButtons = () => {
    return (
      <ButtonsContainer>
        <TangiButton variant="tertiary" text="Cancel" onClick={handleClose} data-testid="cancel-edit-partnership" />
        <TangiButton text={isEditMode ? 'Save changes' : 'Create partner'} disabled={!nameChanged} type="submit" loading={partnershipLoading || agreementLoading} />
      </ButtonsContainer>
    );
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmitForm)}>
        <FormContainer>
          {renderPhotoContainer()}
          {renderInputs()}
          <Separator />
          <SectionTitle>
            <span className="documents-title">{t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.DOCUMENTS')}</span>
          </SectionTitle>
          {renderAgreementsOptions()}
          <Separator />
          <TangiAlert {...alertProps} />
          {renderButtons()}
        </FormContainer>
      </Form>
    </Container>
  );
};

export default PartnershipForm;
