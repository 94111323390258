import * as Sentry from '@sentry/react';

/**
 * Logs messages and exceptions to Sentry with structured data.
 *
 * @param {Object} options - Log options.
 * @param {boolean} [options.captureException=true] - Whether to log as an exception.
 * @param {string} options.message - The log message.
 * @param {string} [options.level='error'] - The log level ('error', 'warning', 'info', etc.).
 */
export const writeLogToSentry = ({ message, level = 'error' }) => {
  const logData = {
    level,
    extra: {
      location: window.location.href,
      timestamp: new Date().toISOString(),
    },
  };

  if (level === 'error') {
    const error = new Error(message);
    error.name = message; // This becomes the title in Sentry
    Sentry.captureException(message, logData);
  } else {
    Sentry.captureMessage(message, logData);
  }
};
