import styled from 'styled-components';
import { Form, Modal } from 'react-bootstrap';

import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import { FlexColumn } from '_components/AssetOverview/style';

export const ModalContentContainer = styled.div`
  width: ${(props) => props.width ?? '760px'};
  height: ${(props) => props.height ?? 'auto'};
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 20px;
  gap: 24px;
  height: calc(100% - 76px);
`;

export const StyledForm = styled(Form)`
  padding-bottom: 24px;
  border-bottom: 1px solid ${NEUTRAL_SHADES[300]};
`;

export const FormContentContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
`;

export const SelectContainer = styled.div`
  width: 90%;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AckStatusContainer = styled(FlexRow)`
  width: 128px;
  padding-left: 40px;
`;

export const TableTitle = styled(FlexRow)`
  font-size: 16px;
  font-family: 'DIN2014-DemiBold';
  gap: ${SPACING[1]};
`;

export const TableContainer = styled(FlexColumn)`
  gap: ${SPACING[2]};
`;

export const customTableStyles = {
  responsiveWrapper: {
    style: {
      border: `1px solid ${NEUTRAL_SHADES[300]}`,
      borderRadius: SPACING[2],
    },
  },
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[200]} !important`,
    },
  },
  rows: {
    style: {
      maxHeight: '42px',
      minHeight: '42px',
      border: 'none',
    },
  },
};
