import { assetMetaDataCreatableParams, assetMetaDataParams } from 'utils/types/assetMetaData/assetMetaData';
import { headers } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { AssetMetaDataValue } from 'utils/types/assets/assets';
import { AxiosResponse } from 'axios';
import _ from 'lodash';

class AssetMetaDataAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;
  static assetEndpoint = AssetMetaDataAdapter.baseEndPoint + '/asset';

  static defaults = {
    PAGE: 1,
    LIMIT: 1000,
    SORTBY: 'createdAt:asc',
  };

  async createTag(tag: assetMetaDataCreatableParams) {
    const res = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/tag`, tag, { headers: headers() });
    return res.data;
  }

  async createProject(project: assetMetaDataCreatableParams) {
    const res = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/project`, project, { headers: headers() });
    return res.data;
  }

  async createDepartment(department: assetMetaDataCreatableParams) {
    const res = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/department`, department, { headers: headers() });
    return res.data;
  }

  async createAssetType(assetType: assetMetaDataCreatableParams) {
    const res = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/assetType`, assetType, { headers: headers() });
    return res.data;
  }

  async createBusinessValue(businessValue: assetMetaDataCreatableParams) {
    const res = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/businessValue`, businessValue, { headers: headers() });
    return res.data;
  }

  async createBusinessUnit(businessUnit: assetMetaDataCreatableParams) {
    const res = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/businessUnit`, businessUnit, { headers: headers() });
    return res.data;
  }

  async createAssetMetaData(assetMetaData: assetMetaDataParams) {
    const res: AxiosResponse<AssetMetaDataValue> = await HttpClientService.post(`${AssetMetaDataAdapter.baseEndPoint}/assetMetaData`, assetMetaData, { headers: headers() });
    return res.data;
  }

  async getMetaData(clientId: string) {
    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/assetMetaData/all/${clientId}`, {
      headers: headers(),
    });
    if (res && Array.isArray(res.data)) {
      const groupedAssetMetaData = _(res.data)
        .groupBy((asset: assetMetaDataParams) => asset.type)
        .value();
      return groupedAssetMetaData;
    }
    return {};
  }

  async getTags(clientId: string) {
    const page = AssetMetaDataAdapter.defaults.PAGE;
    const limit = AssetMetaDataAdapter.defaults.LIMIT;
    const sortBy = AssetMetaDataAdapter.defaults.SORTBY;

    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/tags`, {
      headers: headers(),
      params: {
        client: clientId,
        page,
        limit,
        sortBy,
      },
    });
    return res.data;
  }

  async getProjects(clientId: string) {
    const page = AssetMetaDataAdapter.defaults.PAGE;
    const limit = AssetMetaDataAdapter.defaults.LIMIT;
    const sortBy = AssetMetaDataAdapter.defaults.SORTBY;

    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/projects`, {
      headers: headers(),
      params: {
        client: clientId,
        page,
        limit,
        sortBy,
      },
    });
    return res.data;
  }

  async getDepartments(clientId: string, partnership?: string | null | undefined) {
    const page = AssetMetaDataAdapter.defaults.PAGE;
    const limit = AssetMetaDataAdapter.defaults.LIMIT;
    const sortBy = AssetMetaDataAdapter.defaults.SORTBY;
    
    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/departments`, {
      headers: headers(),
      params: {
        client: clientId,
        page,
        limit,
        sortBy,
        partnership: partnership === null ? "null" : partnership,
      },
    });
    return res.data;
  }

  async getBusinessUnits(clientId: string) {
    const page = AssetMetaDataAdapter.defaults.PAGE;
    const limit = AssetMetaDataAdapter.defaults.LIMIT;
    const sortBy = AssetMetaDataAdapter.defaults.SORTBY;

    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/businessUnits`, {
      headers: headers(),
      params: {
        client: clientId,
        page,
        limit,
        sortBy,
      },
    });
    return res.data;
  }

  async getAssetTypes(clientId: string) {
    const page = AssetMetaDataAdapter.defaults.PAGE;
    const limit = AssetMetaDataAdapter.defaults.LIMIT;
    const sortBy = AssetMetaDataAdapter.defaults.SORTBY;

    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/assetTypes`, {
      headers: headers(),
      params: {
        client: clientId,
        page,
        limit,
        sortBy,
      },
    });
    return res.data;
  }

  async getBusinessValues(clientId: string) {
    const page = AssetMetaDataAdapter.defaults.PAGE;
    const limit = AssetMetaDataAdapter.defaults.LIMIT;
    const sortBy = AssetMetaDataAdapter.defaults.SORTBY;

    const res = await HttpClientService.get(`${AssetMetaDataAdapter.baseEndPoint}/businessValues`, {
      headers: headers(),
      params: {
        client: clientId,
        page,
        limit,
        sortBy,
      },
    });
    return res.data;
  }

  async getAllRecipients() {
    const res = await HttpClientService.get(`${AssetMetaDataAdapter.assetEndpoint}/allRecipients`, {
      headers: headers(),
    });
    return res.data;
  }
}

const assetMetaDataAdapter = new AssetMetaDataAdapter();
export default assetMetaDataAdapter;
