/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import _ from 'lodash';

import { RecipientCategory, RecipientsPartners } from 'utils/types/assets/assets';
import { RenderCategoryRecipients } from './types';

export const filterRecipientsByName = (array: any[], value: string): any[] => {
  return array.filter((elem) => elem?.fullName?.includes(value) || elem.email.includes(value));
};

export const filterPartnershipsRecipientsByName = (
  partners?: RecipientsPartners,
  value?: string
): RecipientsPartners => {
  if (!partners) {
    return { partialPartnerships: [], fullPartnerships: [] };
  }

  if (!value) {
    return partners;
  }

  const { partialPartnerships = [], fullPartnerships = [] } = partners;

  const filteredFullPartnerships = fullPartnerships.map((fullPartnership) => {
    return {
      ...fullPartnership,
      partners: filterRecipientsByName(fullPartnership.partners, value),
    };
  });

  const filteredPartialPartnerships = partialPartnerships.map((partialPartnership) => {
    const newIndividuals = partialPartnership.individuals
      ? {
          ...partialPartnership.individuals,
          partners: filterRecipientsByName(partialPartnership.individuals.partners, value),
        }
      : undefined;

    const newPartnerDepartments = partialPartnership.partnerDepartments?.map((dept) => {
      return {
        ...dept,
        partners: filterRecipientsByName(dept.partners, value),
      };
    });

    let newPartnersCount = 0;
    if (newIndividuals?.partners) {
      newPartnersCount += newIndividuals.partners.length;
    }
    if (newPartnerDepartments) {
      newPartnersCount += newPartnerDepartments.reduce(
        (sum, d) => sum + d.partners.length,
        0
      );
    }

    return {
      ...partialPartnership,
      individuals: newIndividuals,
      partnerDepartments: newPartnerDepartments,
      partnersCount: newPartnersCount,
    };
  });

  return {
    partialPartnerships: filteredPartialPartnerships,
    fullPartnerships: filteredFullPartnerships,
  };
};


export const hasNoPartnerships = (recipientsPartners: any[]): boolean => {
  return recipientsPartners.some((recipient) => !recipient?.account?.partnership?._id || !recipient?.account?.partnership?.name);
};

export const filterCategoriesByName = (categories: RecipientCategory[], value: string) => {
  return categories
    .map((category) => ({
      ...category,
      items: filterRecipientsByName(category.items, value),
    }))
    .filter((category) => category.items.length > 0);
};

export const groupAndCombineCategories = (categories: RecipientCategory[], renderCategoryRecipients: RenderCategoryRecipients, headerTranslation: string): React.ReactNode[] => {
  const groupedCategories = _.groupBy(categories, 'categoryId');

  return Object.entries(groupedCategories).map(([categoryId, categoriesGroup]) => {
    const combinedItems = categoriesGroup.flatMap((category) => category.items);
    const categoryName = categoriesGroup[0].categoryName;

    const newCategory: RecipientCategory = {
      categoryId,
      categoryName,
      categoryType: categoriesGroup[0].categoryType,
      items: combinedItems,
    };

    return renderCategoryRecipients(newCategory, headerTranslation);
  });
};

export const checkIfPartnershipsAreEmpty = (filteredPartners: RecipientsPartners): boolean => {
  const allFullPartnershipsEmpty = filteredPartners.fullPartnerships.every((fp) => fp.partners.length === 0);

  const allPartialPartnershipsEmpty = filteredPartners.partialPartnerships.every((pp) => pp.partnersCount === 0);

  return allFullPartnershipsEmpty && allPartialPartnershipsEmpty;
};
