import { jwtDecode, JwtPayload } from 'jwt-decode';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';

// TODO: fix the any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRememberMyDeviceTokens = (cognitoUser: any) => {
  let clientId = '';
  let localStorageDeviceGroupKey = '';
  let localStorageDeviceKey = '';
  let localStorageRandomPasswordKey = '';

  if (cognitoUser) {
    clientId = cognitoUser.pool.clientId;
    localStorageDeviceGroupKey = `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.deviceGroupKey`;
    localStorageDeviceKey = `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.deviceKey`;
    localStorageRandomPasswordKey = `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.randomPasswordKey`;
  } else {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.includes('deviceGroupKey')) {
        localStorageDeviceGroupKey = key
      }
      if (key?.includes('deviceKey')) {
        localStorageDeviceKey = key
      }
      if (key?.includes('randomPasswordKey')) {
        localStorageRandomPasswordKey = key
      }
    }
  }

  const deviceGroupKey = localStorage.getItem(localStorageDeviceGroupKey);
  const deviceKey = localStorage.getItem(localStorageDeviceKey);
  const randomPasswordKey = localStorage.getItem(localStorageRandomPasswordKey);

  return {
    deviceGroup: {
      key: localStorageDeviceGroupKey,
      value: deviceGroupKey as string,
    },
    deviceKey: {
      key: localStorageDeviceKey,
      value: deviceKey as string,
    },
    randomPasswordKey: {
      key: localStorageRandomPasswordKey,
      value: randomPasswordKey as string,
    },
  };
};

const checkTokenExpiration = (minutes = 50) => {
  const accessToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);
  const decodedToken: JwtPayload = jwtDecode(accessToken as string);

  const currentTime = Math.floor(Date.now() / 1000);
  const validUntil = (currentTime + minutes * 60) * 1000;
  const expiration = decodedToken.exp! * 1000;

  return validUntil > expiration;
};

const cognitoUtil = { getRememberMyDeviceTokens, checkTokenExpiration };

export default cognitoUtil;
