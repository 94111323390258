export enum LANGUAGE_LABEL {
  ENGLISH = 'English',
  JAPANESE = 'Japanese',
  CHINESE ="Chinese"
}
export enum LANGUAGE {
  ENGLISH = 'en',
  JAPANESE = 'ja',
  CHINESE = 'zh',
}

export const LANGUAGES = [LANGUAGE.ENGLISH, LANGUAGE.JAPANESE, LANGUAGE.CHINESE];

export const languageOptions = [
  { value: LANGUAGE.ENGLISH, label: LANGUAGE_LABEL.ENGLISH },
  { value: LANGUAGE.JAPANESE, label: LANGUAGE_LABEL.JAPANESE },
  { value: LANGUAGE.CHINESE, label: LANGUAGE_LABEL.CHINESE },
];
