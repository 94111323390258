import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { TangiIconButton } from '../TangiLibrary';
import AssetOptions from '../AssetOptions/AssetOptions';
import AssetTypeColorBlock from '../AssetTypeColorBlock/AssetTypeColorBlock';
import AcknowledgementTooltip from '../ClientAssetCards/components/AcknowledgementTooltip/AcknowledgementTooltip';
import ThirdPartyTag from '_components/ThirdPartyTag/ThirdPartyTag';
import { assetsActions } from '../../redux-toolkit/slices/assetsSlice';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { LOCKED_ASSET, assetTypeNameFormatter } from 'utils/assetTypes';
import { formatDate } from 'utils/dateUtils';
import { IRoles, checkAssetSharePermission } from 'utils/roles';
import { TableHeaderText, TableBodyText } from 'utils/globalStyles';
import {
  AssetName,
  ColorAndType,
  AssetTagsContainer,
  RecipientNumber,
  ActionsContainer,
  customAssetOptionsStyles,
  AssetTableRowCheckBox,
  NameAndCheckBoxContainer,
  NameAndThirdPartyContainer,
  CheckBoxContainer,
} from './style';
import { useMemo } from 'react';

export const createTableColum = (permissions, isAllVisibleAssetsSelected, handleSelectMultAssets) => {
  const { t } = useTranslation();
  return [
    {
      name: (
        <NameAndCheckBoxContainer>
          <CheckBoxContainer>
            <AssetTableRowCheckBox
              checked={isAllVisibleAssetsSelected}
              onChange={handleSelectMultAssets}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </CheckBoxContainer>
          <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.NAME')}</TableHeaderText>
        </NameAndCheckBoxContainer>
      ),
      selector: ({ name }) => name,
      fieldName: 'name',
      maxWidth: '300px',
      compact: true,
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.ASSET_TYPE')}</TableHeaderText>,
      selector: ({ assetType }) => assetType,
      fieldName: 'assetType',
      maxWidth: '240px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.TAGS')}</TableHeaderText>,
      selector: ({ tags }) => tags,
      fieldName: 'tags',
      minWidth: '240px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.CREATION_DATE')}</TableHeaderText>,
      selector: ({ createdAt }) => createdAt,
      fieldName: 'createdAt',
      maxWidth: '144px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.RECIPIENTS')}</TableHeaderText>,
      selector: ({ recipients }) => recipients,
      omit: !permissions?.Assetshare,
      maxWidth: '120px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.ACTIONS')}</TableHeaderText>,
      selector: ({ action }) => action,
      fieldName: 'action',
      maxWidth: '120px',
    },
  ];
};

// const renderMagicIcon = (value) => {
//   return (
//     value?.createdFrom === CREATED_FROM.EMAIL_FINDER && (
//       <Tippy content={<div className="text-xs">{`${i18next.t('ASSET_PAGE.TOOLTIP.ASSETS_CREATED_BY_EMAIL_XRAY')} ${formatDate(value?.createdAt)}`}</div>}>
//         <StyledMagicIcon />
//       </Tippy>
//     )
//   );
// };

export const makeTableRow = ({
  value,
  handleDistributeAsset,
  role,
  options,
  permissions,
  isAdmin,
  handleUnlockAsset,
  handleRowClick,
  selectedAssets,
  updateSelectedAssets,
  dispatch,
  isSomeAssetsSelected,
}) => {
  const allAcknowledged = value?.recipients?.every((recipient) => recipient.acknowledged === true);
  const isLocked = value?.needsToBeAcknowledgedByMe || (value?.isThirdParty && !value?.hasAccessToThirdPartyAsset);
  const row = { id: value.id, clientId: value.client._id, isLocked };
  const isShowRecipients = !!value?.recipients?.length && checkAssetSharePermission(role, permissions, value?.isThirdParty);
  const isEditDisable = value?.isThirdParty && role === IRoles.PARTNER;
  const editTooltipContent = isEditDisable ? i18next.t('ASSET_PAGE.TOOLTIP.CANNOT_EDIT_ASSET') : i18next.t('ASSET_PAGE.TOOLTIP.EDIT_ASSET');
  const isAssetSelected = selectedAssets.some((selctedAsset) => selctedAsset.id === value.id);
  const hasNotifyPermission = useMemo(() => checkAssetSharePermission(role, permissions, value?.isThirdParty), [role,permissions,value])

  const handleUpdateSelectedAssets = () => {
    if (isLocked || value?.sharePoint) return;
    updateSelectedAssets(value);
  };

  return {
    id: value.id,
    clientId: value.client._id,
    isLocked,
    hasNotifyPermission,
    name: (
      <NameAndThirdPartyContainer data-testid="table-asset-name" isLocked={isLocked}>
        <NameAndCheckBoxContainer>
          <CheckBoxContainer onClick={handleUpdateSelectedAssets}>
            <AssetTableRowCheckBox
              disabled={value?.sharePoint || isLocked || !hasNotifyPermission}
              checked={isAssetSelected}
              onChange={handleUpdateSelectedAssets}
              onClick={(event) => {
                event.stopPropagation();
              }}
            />
          </CheckBoxContainer>
          <AssetName isLocked={isLocked} onClick={() => handleRowClick(row)}>
            {value.name}
          </AssetName>
        </NameAndCheckBoxContainer>
        {value?.isThirdParty && <ThirdPartyTag asset={value} />}
      </NameAndThirdPartyContainer>
    ),
    assetType: (
      <ColorAndType isLocked={isLocked} onClick={() => handleRowClick(row)}>
        <AssetTypeColorBlock assetTypeName={isLocked ? LOCKED_ASSET : value?.assetType?.name || ''} />
        <span>{isLocked ? LOCKED_ASSET : value?.assetType?.name ? assetTypeNameFormatter(value.assetType.name) : ''}</span>
      </ColorAndType>
    ),
    tags: (
      <AssetTagsContainer isLocked={isLocked} onClick={() => handleRowClick(row)}>
        {!!value?.tags?.length && (
          <>
            <span>{value.tags[0].name}</span>
            {value.tags.length > 1 && (
              <Tippy
                content={value.tags.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
                placement="top-end"
              >
                <span>+ {value.tags.length - 1}</span>
              </Tippy>
            )}
          </>
        )}
      </AssetTagsContainer>
    ),
    createdAt: <TableBodyText onClick={() => handleRowClick(row)}>{value?.createdAt && !value.needsToBeAcknowledgedByMe ? formatDate(value?.createdAt) : ''}</TableBodyText>,
    recipients: (
      <Tippy
        disabled={!isShowRecipients}
        content={<AcknowledgementTooltip acknowledgedRecipientsList={value.acknowledgedAccountsList} notAcknowledgedRecipientsList={value.notAcknowledgedAccountsList} />}
        placement="top-end"
      >
        {isShowRecipients && (
          <RecipientNumber allAcknowledged={allAcknowledged} onClick={() => handleRowClick(row)}>
            {!isLocked && (
              <>
                <span>
                  {allAcknowledged
                    ? value?.recipients?.length
                    : value?.acknowledgedAccountsList?.length
                    ? value?.acknowledgedAccountsList?.length + '/' + value?.recipients?.length
                    : '0/' + value?.recipients?.length}
                </span>
                <span>{i18next.t('ASSET_PAGE.ASSET_TABLE.RECIPIENTS')}</span>
              </>
            )}
          </RecipientNumber>
        )}
      </Tippy>
    ),
    action: (
      <>
        {value?.isThirdParty && !value?.hasAccessToThirdPartyAsset ? null : value?.needsToBeAcknowledgedByMe ? (
          <ActionsContainer>
            <Tippy content={i18next.t('ASSET_PAGE.TOOLTIP.ACKNOWLEDGE_ASSET')} placement="top-end">
              <span>
                <TangiIconButton icon="lock" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => handleUnlockAsset(value)} />
              </span>
            </Tippy>
          </ActionsContainer>
        ) : (
          <ActionsContainer>
            {checkAssetSharePermission(role, permissions, value?.isThirdParty) && (
              <Tippy content={i18next.t('ASSET_PAGE.TOOLTIP.NOTIFY')} placement="top-end">
                <span>
                  <TangiIconButton
                    disabled={isSomeAssetsSelected || value?.sharePoint}
                    variant={BUTTON_VARIANTS.TERTIARY_GREY}
                    onClick={() => {
                      dispatch(assetsActions.initialSelectedAssets());
                      handleDistributeAsset(value);
                    }}
                    icon="send"
                  />
                </span>
              </Tippy>
            )}
            {isAdmin ? (
              <Tippy content={i18next.t('ASSET_PAGE.TOOLTIP.MORE_OPTIONS')} placement="top-end">
                <span>
                  <AssetOptions item={value} options={options} styles={customAssetOptionsStyles} isDisabled={isSomeAssetsSelected ||  value?.sharePoint} />
                </span>
              </Tippy>
            ) : (
              <>
                {permissions?.Assetedit && (
                  <Tippy content={editTooltipContent} placement="top-end">
                    <span>
                      <TangiIconButton disabled={isEditDisable || isSomeAssetsSelected } icon="edit" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => options[0].handleItem(value)} />
                    </span>
                  </Tippy>
                )}
              </>
            )}
          </ActionsContainer>
        )}
      </>
    ),
  };
};