/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import { useTranslation } from 'react-i18next';

import { ActivityLogData, AUDIT_TYPE, ActivityGroup, LeanAccount, LeanClient, SharePointSubAuditType } from 'utils/types/activityLog/asset/types';
import { TangiTypography, TangiSvgIcon } from '_components/TangiLibrary';

import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Container, FlexDiv, FlexSpan } from '_components/ActivityLog/style';
import { NEUTRAL_SHADES } from 'utils/theme';
import { getDisplayName, groupTypeMappingToDisplay } from '_components/ActivityLog/utils';
import AssetActivityPartnerLabel from './AssetActivityPartnerLabel';

interface Props {
  data: ActivityLogData[];
  initiator: {
    account: LeanAccount | undefined;
    client: LeanClient;
  };
  auditType: string;
  subAuditType?: string;
}

export const AssetActivityGroupNotify = ({ initiator, data, auditType, subAuditType }: Props) => {
  const { account, client } = initiator;
  const { t } = useTranslation();
  const displayText = getDisplayName(account, client);

  const isSharePointSharedEvent = subAuditType === SharePointSubAuditType.SHARED && auditType === AUDIT_TYPE.SHARE_POINT;

  const renderAsTeam = (team: ActivityLogData) => {
    const groupTypeDisplay = groupTypeMappingToDisplay[team?.groupType || ''] || team.groupType;

    return (
      <FlexSpan>
        {team.groupType === ActivityGroup.PARTNER ? (
          <>
            {team.logo ? (
              <Avatar size={AVATAR_SIZES.XS} image={team.groupName} />
            ) : (
              <TangiSvgIcon component="partnerEmptyStateIcon" color={NEUTRAL_SHADES.WHITE}  size={AVATAR_SIZES.XS}/>
            )}
            <TangiTypography type="subheading" weight="bold">
            {team.groupName} ({team.to.length})
            </TangiTypography>
          </>
        ) : team.partnershipOfGroup ?
        ( 
          <AssetActivityPartnerLabel
            partnershipName={team.partnershipOfGroup.name}
            partnershipLogo={team.partnershipOfGroup.logo}
            groupName={team.groupName}
            groupTypeDisplay={groupTypeDisplay}
            numOfRecipients={team.to.length}
            size={AVATAR_SIZES.XS}
          />
        ) : 
        ( <>
            <TangiTypography type="subheading">{groupTypeDisplay}</TangiTypography>
            <TangiTypography type="subheading" weight="bold">
              {team.groupName} ({team.to.length}) 
            </TangiTypography>
          </>
        )}
      </FlexSpan>
    );
  };

  const renderNotificationText = (item: ActivityLogData) => {
    const title = isSharePointSharedEvent ? 'ACTIVITY_LOG.ASSET.WAS_SHARED_ON_ASSET' : 'ACTIVITY_LOG.ASSET.WAS_NOTIFIED';
    return (
      <FlexSpan>
        {renderAsTeam(item)}
        {t(title, {
          ACTION: t('ACTIVITY_LOG.ASSET.WAS'),
        })}
      </FlexSpan>
    );
  };

  return (
    <Container>
      {data.map((item: any, index: number) => (
        <FlexDiv key={index}>{renderNotificationText(item)}</FlexDiv>
      ))}
      <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
        {t('ACTIVITY_LOG.BY', { NAME: displayText })}
      </TangiTypography>
    </Container>
  );
};
