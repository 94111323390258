import styled from 'styled-components';
import { FlexSpan } from '../style';
import { TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';

interface PartnerItem {
  _id: string;
  name: string;
  logo: string;
}

interface Props {
  items: PartnerItem[];
}

const PartnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActivityPartner = ({ items }: Props) => {
  return (
    <PartnerContainer>
      {items.map((item) => (
        <FlexSpan key={item._id}>
          <Avatar image={item.logo ?? 'partnerEmptyStateIcon'} />
          <TangiTypography type="subheading">{item.name}</TangiTypography>
        </FlexSpan>
      ))}
    </PartnerContainer>
  );
};

export default ActivityPartner;
