import { AdditionalAccounts, CustomTooltip, TooltipArrow, TooltipBody, TooltipHeader } from "./styles";
import { TangiTypography } from '_components/TangiLibrary';
import { useEffect, useRef, useState } from "react";
import { SharedWith } from "utils/types/partnership/partnership";
import { createPortal } from "react-dom";
import { NEUTRAL_SHADES } from "utils/theme";
import AccountAndAvatar from "./AccountAndAvatar";

const AdditionalAccountsTooltip = ({ accounts }: { accounts: SharedWith[] }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  const handleOpen = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY + 5, // Position right below the button
        left: rect.left + window.scrollX - 160 // Align with the left side
      });
    }
    setIsPopoverOpen(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node) &&
      targetRef.current &&
      !targetRef.current.contains(event.target as Node)
    ) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isPopoverOpen]);

  return (
    <>
      <AdditionalAccounts data-testid="additional-accounts-num" ref={targetRef} onClick={handleOpen}>
        <TangiTypography>+{accounts.length} others</TangiTypography>
      </AdditionalAccounts>

      {isPopoverOpen &&
        createPortal(
          <CustomTooltip
            ref={tooltipRef}
            style={{
              top: position.top,
              left: position.left,             
            }}
          >
            <TooltipArrow />
            <TooltipHeader>
              <TangiTypography color={NEUTRAL_SHADES[800]}>{accounts.length} recipients</TangiTypography>
            </TooltipHeader>
            <TooltipBody>
              {accounts.map((account: SharedWith, index: number) => (
                <AccountAndAvatar account={account} key={index} />
              ))}
            </TooltipBody>
          </CustomTooltip>
          , document.body // Renders outside the table
        )}
    </>
  );
};

export default AdditionalAccountsTooltip;
