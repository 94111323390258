export const zh = {
    translation: {
      LOBBY_PAGE: {
        TITLE: '欢迎来到 Tangibly',
        SUB_TITLE: "选择如何开启您的商业机密之旅",
        DISCOVER_AND_PROTECT: "发现、记录和保护商业机密",
        RECONNECT: "哎呀！需要重新连接",
        GO_TO_HOMEPAGE: "前往主页",
        LOGIN: "登录",
        CARDS: {
          PREDICTED_PORTFOLIO: {
            TITLE: "Predicted Portfolio™",
            SUB_TITLE: "揭示隐藏在您专利组合中的知识产权。这些商业机密是必须管理的关键资产",
          },
          COMPETITIVE_INTELLIGENCE: {
            TITLE: "竞争情报",
            SUB_TITLE: "通过预测竞争对手的商业机密来获得优势",
          },
          CONTINUATION_ANALYSIS: {
            TITLE: "延续性分析",
            SUB_TITLE: "轻松起草延续性权利要求，从您的专利中提取更多价值",
          },
          PATENT_X_RAY: {
            TITLE: "专利 X 光",
            SUB_TITLE: "人工智能预测与专利相关的潜在商业机密",
          },
          INVENTION_DISCLOSURE: {
            TITLE: "发明披露",
            SUB_TITLE: "通过对比专利权利要求和商业机密，做出明智的专利申请决定",
          },
          MANAGE: {
            TITLE: "管理",
            SUB_TITLE: "通过对资产进行编目、限制访问、验证合同和培训员工来使用最佳实践标准",
          },
          ASSETS_CATALOG: {
            TITLE: "资产目录",
            SUB_TITLE: "像对待宝贵资产一样对待您的商业机密",
          },
          ADD_EMPLOYEES: {
            TITLE: "添加员工",
            SUB_TITLE: "跟踪您员工的访问权限、合规性和最佳实践培训",
          },
          COLLABORATE_WITH_PARTNERS: {
            TITLE: "与合作伙伴协作",
            SUB_TITLE: "管理与合作伙伴交换敏感信息所需的一切",
          },
          LEARN: {
            TITLE: "学习",
            SUB_TITLE: "培训您的员工了解什么是商业机密以及如何正确处理它们",
          },
          DISCOVER: {
            TITLE: "发现",
            SUB_TITLE: "利用 Tangibly 的人工智能简化专利工作流程并揭示有关您商业机密的洞察力",
          },
          SELECT_COURSES: {
            TITLE: "选择课程",
            SUB_TITLE: "您可以选择哪些培训课程对您的员工是强制性的，或者只是建议",
          },
          TRACK_PROGRESS: {
            TITLE: "跟踪进度",
            SUB_TITLE: "轻松跟踪哪些员工已完成所需的培训，以及哪些员工仍有一项或多项培训需要完成",
          },
        },
        REQUEST_ACCESS_MODAL: {
          DISCOVER: {
            HEADER: "发现",
            TITLE: "Tangibly 的 X-Ray™ 平台",
            SUB_TITLE: "X-Ray™ 是一款完全集成的基于人工智能的工具，专为内部法律团队和律师事务所设计，旨在帮助识别商业机密并衡量和管理风险。",
            POINT1: "使用 Patent X-Ray™ 识别商业机密",
            POINT2: "使用 Contract X-Ray™ 评估您对商业机密的保护",
            POINT3: "使用 Email X-Ray™ 识别和管理风险",
          },
          PREDICTED_PORTFOLIO: {
            HEADER: "预测组合",
            TITLE: "Tangibly 的预测组合工具",
            SUB_TITLE: "Tangibly 的 SaaS 平台将您的机密信息保护为可依法执行的商业机密",
            POINT1: "发现您从未知道的商业机密",
            POINT2: "了解您商业机密组合的优势和劣势",
            POINT3: "了解您的商业机密如何与竞争对手抗衡",
          },
          MANAGE: {
            HEADER: "管理",
            TITLE: "Tangibly 的管理平台",
            SUB_TITLE: "Tangibly 是唯一致力于商业机密的 SaaS 平台，可以说是最重要的知识产权资产类别。Tangibly 的人工智能是您的副驾驶，可帮助您识别商业机密、衡量和管理风险，并为您的专利流程添加新功能。",
            POINT1: "标记商业机密",
            POINT2: "通知并确认对商业机密的访问",
            POINT3: "培训员工和合作伙伴",
            POINT4: "与合作伙伴安全地协作",
          },
          BUTTON_TEXT: "请求访问",
        },
        BANNER: {
          BUTTON_TEXT: "查看用户",
          HEADER_TEXT: "{{NEED_TO_BE_ACKNOWLEDGED_NUM}} 新增SharePoint成员",
        },
        SHARE_POINT_MEMBERS_MODAL: {
          HEADER: "新的 SharePoint 成员",
          SUB_HEADER: "**已添加 {{NEWMEMBERSNUM}} 新用户 ，这些用户最近通过Tangibly 连接到 SharePoint 网站/文件夹的共享。",
          USER: "用户",
          ADDED_ON: "添加于",
        },
      },
      PARTNERSHIP_PAGE: {
        HEADERS: {
          PEOPLE: "人员",
          ASSETS_SHARED_WITH_PARTNERS: "与合作伙伴共享的资产",
          ASSETS_CREATED: "合作伙伴贡献的资产",
          SUMMARY: "摘要",
          DOCUMENTS: "文件",
        },
        SUMMARY: {
          ONBOARDED_ON: "加入时间：",
          CREATED_BY: "创建者",
          NUMBER_OF_EMPLOYEES: "员工人数：",
        },
        TABLE: {
          MISSING: "缺失",
          NO_ASSETS: "无资产",
          NO_DOCUMENTS: "无文件",
          NO_PEOPLE_YET: "尚无人员",
        },
        TOOLTIP: {
          EDIT: "编辑",
          DOWNLOAD_AGREEMENT: "下载协议",
        },
        DROPDOWN_BUTTON: {
          DEACTIVATE: "停用",
        },
        TOAST: {
          PARTNERSHIP_CREATED: "合作伙伴创建成功",
          PARTNERSHIP_UPDATED: "合作伙伴更新成功",
          PARTNERSHIP_DEACTIVATED: "合作伙伴停用成功",
        },
        TABLE_HEADERS: {
          TITLE: "标题",
          SIGNED: "已签署",
          VERSION: "版本",
          EXECUTED_ON: "执行日期",
          EXPIRES_ON: "到期日",
          NAME: "名称",
          STATUS: "状态",
          ASSETS: "资产",
          CREATION_DATE: "创建日期",
          ACTIONS: "操作",
          ASSET_NAME: "资产名称",
          TYPE: "类型",
          SHARED_WITH: "共享对象",
          CONTRIBUTORS: "贡献者",
        },
        DEACTIVATION_POPUP_BODY: {
          WILL_LOSE_ACCESS: "停用此合作伙伴后，它将无法访问 Tangibly™，只能通过电子邮件确认和查看资产。",
          CAN_ACTIVATE_LATER: "您可以稍后根据需要激活该帐户。",
          CAN_FIND_IN_SIDEMENU: "您可以在侧边菜单的合作伙伴部分中找到已停用的合作伙伴。",
          NOTE: "注意",
        },
        DEACTIVATION_POPUP_TITLE: {
          DEACTIVATE: "停用",
        },
      },
      PARTNERSHIP_ADD_EDIT_PARTNER: {
        INPUT_LABEL: {
          EMAIL: "电子邮箱",
          DISPLAY_NAME: "显示名称",
          PARTNER_NAME: "合作伙伴名称",
          ADDRESS: "地址",
        },
        SELECT_LABEL: {
          COUNTRY: "国家/地区",
        },
        ERROR_MESSAGE: {
          INVALID_EMAIL_MESSAGE: "请输入有效的电子邮件地址",
        },
        TITLE: {
          EDIT: "编辑 {{NAME}}",
          ADD_USER_TO: "添加用户至 {{NAME}}",
          ADD_USERS_TO: "添加用户至 {{PARTNER_NAME}}",
          EDIT_PARTNER: "编辑合作伙伴",
          CREATE_NEW_PARTNER_IN: "创建新合作伙伴",
          ALL_SET: "一切就绪!",
          ADD_PARTNER_LOGO: "添加合作伙伴标志",
          ADD_RELEVANT_DOCUMENTS: "添加相关文件",
          DOCUMENTS: "Documents",
        },
        DESCRIPTION: {
          SHARE_ASSETS: "您现在可以与新合作伙伴的用户共享资产",
        },
        TOGGLE: {
          STATUS: "状态：",
          ACTIVE: "活动状态",
        },
        PARTNER_TOOLTIP: {
          ACTIVE: "活动账户可以创建资产并查看所有分配的课程。",
          NONACTIVE: "非活动账户只能作为一次性查看者，查看管理员共享的特定资产。",
          EDIT_DISABLED: "当合作伙伴被禁用时，您无法编辑人员。",
          PARTNERSHIP_DISABLED: "当合作伙伴被禁用时，您无法编辑合作伙伴。",
        },
        CHECKBOX: {
          OKTA_MESSAGE: "此用户正在使用 Okta 作为单点登录（SSO）",
          ACTIVE: "活动状态",
        },
        TOAST: {
          USER_ADDED_SUCCESSFULLY: "用户添加成功",
        },
      },
      PREDICTED_PORTFOLIO: {
        HEADER: {
          TITLE: "预测组合™",
          CHIP: "测试版",
          UPDATE: "更新于",
          CHIP_TOOLTIP: "该功能目前处于测试阶段。根据您提供的数据，我们将不断学习和改进，为您提供最佳结果。",
        },
        BANNER: {
          TITLE: "您正在浏览的是 Tangibly 示例项目",
          INFO: "想了解贵公司的预测投资组合和战略分析？请联系我们！",
          LEARN_MORE: "了解更多信息",
          REQUEST_ACCESS: "申请访问",
        },
        WIDGET: {
          PATENT: "专利",
          POTENTIAL_TREDE_SECRETS: "潜在的商业机密",
          SUBJECTS: "主题",
        },
        CARD: {
          TITLE: "顶级主题的潜在商业机密",
        },
        PORTFOLIO_TABLE: {
          TITLE: "投资组合数据",
          POTENTIAL_TRADE_SECRET: "潜在商业机密",
          PATENT: "专利",
          YEAR: "年",
          SUBJECTS: "主题",
          EXPORT_DATA: "导出数据",
          TOTAL_ITEMS: "{{NUMBER}} 项",
        },
        COMPETITIVE_BOX_TEXT: "查看贵公司的竞争分析",
        CHARTS: {
          SUBJECT: "主题",
          SUBJECTS: "主题",
          POTENTIAL_TRADE_SECRETS: "潜在商业机密",
          PATENTS: "专利",
          PATENT_NUMBER: "专利号",
        },
        FILTERS: {
          COMPENY: "公司",
          PATENT: "专利",
          YEAR: "年份",
          SUBJECT: "主题",
          SEARCH: "搜索",
          CLEAR_ALL: "清除所有",
        },
      },
      COMPETITIVE_ANALYSIS: {
        HEADER: {
          TITLE: "竞争分析",
        },
        CHART: {
          COMPETITORS: "竞争对手",
        },
        WIDGET: {
          COMPANIES: "公司",
        },
        CARDS: {
          PERCENTAGE_CHART: {
            TITLE: "按公司和主题划分的潜在商业机密",
          },
          BY_COMPANIES_CHART: {
            TITLE: "按公司划分的潜在商业机密",
            TOTAL_TEXT: "潜在商业机密总数",
          },
          BY_SUBJECT_CHART: {
            TITLE: "按主题划分的潜在商业机密",
            TOOLTIP_COMPANY_TITLE: "公司",
            TOOLTIP_POTENTIAL_TRADE_SECRETS_TITLE: "潜在商业机密",
          },
        },
        DATA_TABLE: {
          TITLE: "投资组合和竞争对手数据",
          POTENTIAL_TRADE_SECRET: "潜在商业机密",
          COMPANY: "公司",
          PATENT: "专利",
          YEAR: "年份",
          SUBJECTS: "主题",
          EXPORT_DATA: "导出数据",
        },
      },
      ASSET_PAGE: {
        USER: 'User',
        RECIPIENT_TABLE: {
          RECIPIENTS: "接收者",
          ACK_DATE: "确认日期",
          ACK_STATUS: "确认状态",
          ACTIONS: "操作",
          COMPLIANT: "合规",
          PENDING: "待处理",
          SENT_DATE: "Sent date",
          ACKNOWLEDGEMENT: "Acknowledgement",
        },
        WIDGET: {
          USERS_WERE_CREATED_BY_FINDER: "用户由Finder创建",
          NO_USERS_CREATED_BY_FINDER: "没有用户由Finder创建",
          USERS_WERE_CREATED_BY_TRACKER: "用户由Tracker创建",
          NO_USERS_CREATED_BY_TRACKER: "没有用户由Tracker创建",
        },
        TAB: {
          OVERVIEW: "概述",
          ACTIVITY: "活动",
          RECIPIENTS: "接收者",
        },
        CONTENT: {
          ASSET_REQUIRES_ACKNOWLEDGEMENT: "此资产需要您的确认",
          CONTACT_PARTNER_TO_GAIN_ACCESS: "请联系合作伙伴以获取第三方资产的访问权限",
          NO_RECIPIENTS_YET: "暂无接收者",
          CONFIRMATION_TEXT: "请在此处键入您的姓名，以确认您承认此信息被视为商业机密，并且您将遵守所有保密、雇佣和/或服务义务。 要确认<strong> {{AMOUNTS}}</strong>",
          SINGEL_REQUEST_CONFIRMATION_TEXT: "请在此处键入您的姓名，以确认您承认此信息被视为商业机密，并且您将遵守所有保密、雇佣和/或服务义务。",
          ENTER_NAME: "资产，请在下方输入您的姓名",
          MANAGE_SIGNATURE_REQUESTS: "管理所有签名请求",
          SHARED_ON: "分享于：",
          BY: "由 {{SHARED_BY}}",
          DESCRIPTION: "描述",
          CONTRIBUTORS: "贡献者",
          CONTRIBUTOR: "贡献者",
          FILES: "文件",
          NO_ACCESS: "无访问权限",
          ASSET_LABEL_LOCATION: "资产标签和位置",
          METADATA: "元数据",
          CREATED_ON: "创建于",
          CREATED_BY: "创建者",
          LAST_MODIFIED_ON: "上次修改于",
          REFERENCE_NUMBER: "参考编号",
          COPY: "复制",
          COPIED: "已复制！",
          TAGS: "标签",
          BUSINESS_VALUE: "商业价值",
          PROJECTS: "项目",
          DEPARTMENTS: "部门",
          OTHERS_1: "其他 1",
          OTHERS_2: "其他 2",
          DETAILS: "详情",
          NO_DETAILS_DATA: "此资产没有详细信息数据",
          NOT_SIGNED: "未签署",
          NO_EXTERNAL_USERS: "没有外部用户",
          EXTERNAL_USERS: "外部用户",
          IMPORTANCE: "分类",
          BUSINESS_RELATED: "业务相关",
          COMMERCIAL_PRODUCT: "商业产品",
          UNKNOWN_FILE: "未知文件",
          A_LINK_IS_ATTACHED: "附有链接",
          NO_FILES_OR_URLS_INCLUDED: "未包含文件/URL",
          LINK_TO_SHAREPOINT_FILE: "SharePoint文件链接",
          NO_ACCESS_TO_FILE: "无法访问文件",
          FILE_DELETED: "文件已删除",
          ASSET_DELETED: "资产已删除",
          NO_ASSETS_YET: "No assets yet",
          RECIPIENTS: "接收者",
          ALL: '全部'
        },
        TITLE: {
          ASSETS: "资产",
          SEND_ASSET_NOTIFICATION:'发送关于 "{{ASSET_NAME}}" 的通知',
          PEOPLE_WITH_ACCESS: "有权访问的人",
          CONFIRMATION_IS_REQUIRED: "需要确认",
          INFORMATION_IS_CONFIDENTIAL: "以下信息是机密信息，包含商业机密：",
          SEND_ASSET_NOTIFICATION_DEFAULT: "发送资产通知",
          SEND_MULTIPLE_ASSETS_NOTIFICATION: "发送有关 {{NUMBER}} 个资产的通知",
        },
        TOOLTIP: {
          GRID_VIEW: "网格视图",
          LIST_VIEW: "列表视图",
          ASSETS_CREATED_BY_EMAIL_XRAY: "资产由Email X-Ray Finder创建于",
          ASSETS_CREATED_BY_PATENT_XRAY: "资产由Patent X-Ray创建于",
          EDIT_ASSET: "编辑资产",
          CANNOT_EDIT_ASSET: "您无法编辑第三方创建的资产",
          RECIPIENTS: "接收者",
          NOTIFY: "通知",
          THIRD_PARTY: "第三方",
          ASSET_CREATED_BY_PARTNER: "合作伙伴创建的资产",
          ASSET_CREATED_BY_CLIENT: "客户创建的资产",
          PRIVILEGE_LOG_DOWNLOAD: "权限日志下载",
          COMING_SOON: "即将推出",
          DELETED_SUCCESSFULLY: "{{NAME}} 已成功删除",
          EMAILS_SENT: "{{NAME}} 电子邮件已发送！",
          SOMETHING_WENT_WRONG: "出错了，请重试",
          CREATED_BY_FINDER: "用户由Email X-Ray Finder于{{createdAt}} 创建",
          ASSETS_AWAITING_YOUR_SIGNATURE: "有 {{NEED_TO_BE_ACKNOWLEDGED_NUM}} 项资产等待您的签名",
          NAVIGATE_TO_ALL_SIGNATURE_REQUESTS: "导航到所有签名请求",
          CREATED_BY_TRACKER: "用户由Email X-Ray Tracker于 {{createdAt}} 创建",
          CONTRIBUTORS_CAN_BE_DELETED: "可删除贡献者",
          ONLY_IN_EDIT_ASSET_POPUP: "仅在编辑资产弹出窗口中",
          FINDER_IS_AVAILABLE: "Finder在X-Ray计划中可用",
          CONTACT_US: "联系我们了解更多",
          ACKNOWLEDGED: "已确认",
          NOT_ACKNOWLEDGED: "未确认",
          AND_MORE: "以及更多...",
          ACKNOWLEDGE_ASSET: "确认资产",
          MORE_OPTIONS: "更多选项",
          SHARE_POINT_ACCESS: "Tangibly已无法访问此资产位置。要继续跟踪此资产，请在设置页面上授予访问权限。",
          INACCESSIBLE: "无法访问",
        },
        BUTTONS_TEXT: {
          CREATE_ASSET: "创建新资产",
          ACKNOWLEDGE: "确认",
          CLEAR_ALL: "清除所有",
          BACK_TO_ALL_ASSETS: "所有资产",
        },
        INPUT: {
          SEARCH_BY_NAME_OR_ID: "按名称/ID搜索",
          SEARCH_OPTION: "搜索选项",
          FULL_NAME: "全名",
          SEARCH_BY_NAME_OR_EMAIL: "按姓名或电子邮件搜索",
          SEARCH_ON_ASSETS: "在资产上搜索",
        },
        MODAL: {
          CLOSE_ASSET: "请确认关闭此资产",
          ARE_YOU_SURE: "请确认",
          DELETE_ASSET: "请确认删除该资产",
          DELETE_FILE: "请确认删除",
          ADD_USER_TO: "将用户添加到 {{NAME}}",
          DELETE_RECIPIENT: "删除接收者",
          RECIPIENTS_WONT_VIEW_ASSET: "此接收者将无法查看该资产。\n 您确定要删除此接收者吗？",
          PLEASE_READ_THE_FOLLOWING_INFORMATION: "请仔细阅读以下信息",
          FILE_RENAME: "文件已重命名。已删除您文件名中的特殊字符",
          GROUP_NOTIFICATION_BANNER: {
            TITLE: "现在您可以快速发送群组通知",
            DESCRIPTION: "轻松向您的部门、业务单位和合作伙伴发送批量通知。",
            NEW: "新的",
          },
        },
        DROP_DOWN: {
          BATCH_UPLOAD: "批量上传",
          DOWNLOAD_EXCEL: "下载Excel模板",
          UPLOAD_EXCEL: "上传Excel文件",
          EDIT_ASSET: "编辑资产",
          NOTIFY: "通知",
          DELETE_ASSET: "删除资产",
          DEPARTMENT: "部门",
          NO_DEPARTMENTS: "没有部门",
          PROJECTS: "项目",
          TAG: "标签",
          BUSINESS_VALUE: "商业价值",
          CONTRIBUTOR: "贡献者",
          ASSET_TYPE: "资产类型",
          CATEGORIZATION: "分类",
          BUSINESS_RELATED: "业务相关",
          COMMERCIAL_PRODUCT: "商业产品",
          RECIPIENTS: "接收者",
        },
        ERROR_MESSAGE: {
          RECIPIENT_EXISTS: "接收者已存在",
          NOT_VALID_EMAIL: "电子邮件无效",
          EMAIL_IS_REQUIRED: "必填电子邮箱",
          RECIPIENT_BEEN_NOTIFIED: "此收件人已收到通知",
          DEPARTMENT_BEEN_NOTIFIED: "该部门已收到通知",
          PARTNER_BEEN_NOTIFIED: "此合作伙伴已收到通知",
          BUSINESS_UNIT_BEEN_NOTIFIED: "该业务部门已收到通知",
        },
        CARD: {
          NO_ASSETS_FOUND: "未找到资产",
          EMPLOYEES: "员工",
          CONTRACTORS: "合同工",
          PARTNERS: "合作伙伴",
          LAWYERS: "律师",
          NOT_REGISTERED: "未注册",
          NO_RECIPIENT_FOUND: "未找到符合您搜索条件的接收者。请尝试按其他名称搜索。",
          ASSET_HAS_NO_RECIPIENTS: "此资产尚无接收者",
          DEPARTMENT: "部门",
          BUSINESS_UNIT: "业务部门",
        },
        TOAST: {
          ASSET_EDITED_SUCCESSFULLY: "资产已成功编辑",
          ASSETS_DOWNLOADED_SUCCESSFULLY: "文件已成功下载，且密码已发送至您的电子邮件。",
        },
        FILTERS: {
          MORE_FILTERS: "更多筛选条件",
          SEARCH_BY_ASSET: "按资产名称或ID、文件名、标签或位置搜索",
        },
        CREATE_EDIT_ASSET: {
          TITLE: {
            EDIT: "编辑",
            CREATE_ASSET: "创建资产",
            FILL_DETAILS: "填写明细",
            METADATA: "元数据",
            ASSET_DETAILS: "资产明细",
          },
          LABEL: {
            TITLE: "标题",
            DESCRIPTION: "描述",
            FILE_LOCATION: "文件位置",
            FILE_LABEL: "文件标签",
            ASSETS_PREFIX: "资产前缀",
            REFERENCE_NUMBER: "参考编号",
            CONTRIBUTORS: "贡献者",
            TAGS: "标签",
            SELECT_ASSET_TYPE: "选择资产类型",
            BUSINESS_VALUE: "商业价值",
            PROJECTS: "项目",
            DEPARTMENTS: "部门",
            BUSINESS_RELATED: "业务相关",
            OTHERS_1: "其他 1",
            OTHERS_2: "其他 2",
            ADD_FILES: "添加文件",
            IMPORTANCE: "分类",
            COMMERCIAL_PRODUCT: "商业产品",
            PUBLISHED: "已发布",
          },
          PLACEHOLDERS: {
            ENTER_TITLE: "输入标题",
            ENTER_DESCRIPTION: "输入描述",
            ENTER_FILE_LOCATION: "输入文件位置",
            ENTER_FILE_LABEL: "文件标签",
            EDIT_REFERENCE_NUMBER: "编辑参考编号",
            ENTER_ADDITIONAL_INFORMATION: "输入其他信息",
          },
          TOOLTIP: {
            CLOSE_ASSET: "关闭资产",
          },
          ERROR_MESSAGE: {
            TITLE_FIELD_REQUIRED: "必须填写标题字段",
            LOCATION_AND_LABEL_REQUIRED: "添加文件位置时，必须同时填写位置与标签",
            FILE_LOCATION_LINK_EXISTS: "如果存在文件位置链接，则为必填项",
            FILE_LABEL_EXISTS: "如果存在文件标签，则为必填项",
            REFERENCE_NUMBER_MUST_BE_EXACTLY: "参考编号必须正好是6个字符",
            REFERENCE_NUMBER_IS_REQUIRED: "必须填写参考编号",
            ONLY_DIGITS: "只允许数字！",
            CONTRIBUTORS_FIELD_IS_REQUIRED: "必填贡献者字段",
            TAGS_FIELD_IS_REQUIRED: "必填标签字段",
            TYPE_ASSET_FIELD_IS_REQUIRED: "必填资产类型字段",
          },
          ADD_FILES: {
            DROP_FILES: "将文件拖放到此处...",
            DROP_FILE: "将文件拖放到此处...",
            DRAG_AND_DROP: "将一些文件拖放到此处，或点击选择文件",
            DRAG_AND_DROP_FILE: "将文件拖放到此处，或点击从计算机浏览",
            DRAG_AND_DROP_FILE_EDIT_MODE: "将新文件拖放到此处，或点击",
            DRAG_AND_DROP_CLICK: "从您的计算机浏览",
            REJECT_PLEASE_UPLOAD_ONLY_EXCEPTED: "请仅上传兼容的格式！ 以后将增多更多格式！",
            UPLOAD_ONLY_ACCEPTED_FORMATS: "仅上传接受的格式（.pdf、.doc、.docx、.xls、.xlsx、.ppt、.pptx、.jpeg、.png、.jpg）。",
            FILE_CANNOT_CONTAIN_SPECIAL_CHARACTERS: "文件名称不能包含特殊字符（例如！@#$...）",
            ACCEPTED_FORMATS_AND_SIZE_LIMIT: "接受的格式：{{UPLOAD_ACCEPTED_FORMATS}}。上传的文件总大小不得超过 {{UPLOAD_LIMIT_IN_MB}}MB。",
            FILE_RENAME: "",
            EMAIL_CONTENT_WILL_BE_CONVERTED_TO_PDF: "您的电子邮件内容将转换为 PDF 文件",
          },
          EDIT_MODE_ASSET_ITEM: {
            UPLOADED: "已上传",
          },
        },
        BATCH_UPLOAD: {
          UPLOAD_MULTIPLE_ASSETS: "批量上传 - 上传多个资产",
          UP_TO_50_ASSETS: "最多 50 个资产",
          DRAG_AND_DROP: "拖放一些图像或浏览您的文件",
          DROP_THE_FILES: "将文件拖放到此处...",
          DONT_CLOSE_WINDOW: "请勿关闭窗口。正在上传资产...",
          VALIDATING_ASSETS: "正在验证资产...",
          BUTTONS_TEXT: {
            DOWNLOAD_EXCEL_TEMPLATE: "下载Excel模板",
            UPLOAD_NEW_FILE: "上传新文件",
            CONFIRM_UPLOAD: "确认上传",
          },
          ERROR_MESSAGE: {
            CANT_UPLOAD_MORE_THAN_50: "无法上传超过 50 个资产",
            NO_ASSETS_UPLOADED: "未上传资产",
            THERE_ARE: "有",
            ERRORS: "错误",
            NO_ERRORS_FOUND: "未发现错误",
            UPLOAD_ONLY_ACCEPTED_FORMATS: "请仅上传兼容的格式！",
            ROW: "行",
            IS_REQUIRED: "为必填项",
            DOESNT_EXIST: "不存在",
            ASSETS_ADDED_TO_TANGIBLY: "新资产将添加到Tangibly",
          },
        },
        ASSET_TABLE: {
          TAGS: "标签",
          ASSET_TYPE: "资产类型",
          NAME: "名称",
          CREATION_DATE: "创建日期",
          RECIPIENTS: "接收者",
          ACTIONS: "操作",
        },
        DEPARTMENT: "部门",
        BUSINESS_UNIT: "业务部门",
        PARTNERSHIP: "合伙",
        GROUPS_NOTIFICATION: "注意：我们将用团队通知取代个人通知。\n收件人将作为其团队的一部分进行管理。",
      },
      PEOPLE: {
        TITLE: {
          PEOPLE: "人员",
          EMPLOYEES: "员工",
          EMPLOYEES_TOTAL: "{{totalEmployees}} 名员工",
          CONTRACTORS: "合同工",
          CONTRACTORS_TOTAL: "{{totalContractors}} 名合同工",
        },
        LABEL: {
          VERSION: "版本",
          EXECUTED_ON: "执行于",
          EXPIRES_ON: "到期于",
        },
        SELECT: {
          STATUS: "状态",
          AGREEMENTS: "协议",
          TRAINING: "培训",
          START_DATE: "开始日期",
          ACTIVATE_USER: "激活用户",
          DEACTIVATE_USER: "停用用户",
          DELETE_USER: "删除用户",
          ROLE: "角色",
          COUNTRIES: "国家/地区",
          FILTER_EMPTY_STATE: "{{filterName}} 尚未分配给人员",
          BUSINESS_UNIT: "业务部",
          HIRE_DATE: "聘用日期",
          SHARE_POINT: "已连接到SharePoint",
          RESEND_ACTIVATION: "Resend Activation",
          ALL: "从所有页面中选择",
          CLEAR: "清除选择",
        },
        AGREEMENT_ADD_FILE: {
          DRAG_AND_DROP: "将文件拖放到此处，或者",
          CHOOSE_FILE: "选择文件",
        },
        TOOLTIP: {
          CREATED_BY_FINDER: "用户由Email X-Ray Finder于 {{createdAt}} 创建",
          CREATED_BY_TRACKER: "用户由Email X-Ray Tracker于 {{createdAt}} 创建",
          EDIT: "编辑用户",
          TRADE_SECRET_ACCESS: "商业机密访问权限",
          SHARE_POINT: "用户已连接到SharePoint",
        },
        DROPDOWN: {
          BATCH_UPLOAD: "批量上传",
          DOWNLOAD_EXCEL: "下载Excel模板",
          UPLOAD_EXCEL: "上传Excel文件",
        },
        INPUT: {
          SEARCH_BY_DISPLAY_NAME_OR_EMAIL: "按名称/电子邮件搜索",
        },
        FILTERS: {
          SEARCH_BY_USER_NAME_ID_OR_EMAIL: "按名称、ID或电子邮件搜索",
          SEARCH: "搜索",
        },
        TABLE: {
          NO_EMPLOYEES_FOUND: "未找到员工",
          NO_CONTRACTORS_FOUND: "未找到合同工",
        },
        MODAL: {
          TITLE: {
            CONFIRM_DELETION: "确认删除",
            CONFIRM_DEACTIVATION: "确认停用",
            ADD_USER_TO: "将用户添加到 {{NAME}}",
            CONFIRM_ACTIVATION: "确认激活",
          },
          MODAL_BODY: {
            DELETE_ACCOUNT: "您即将永久删除此帐户",
            DELETE_ACCOUNT_CONFIRMATION: "如果您确认删除此帐户，它将从系统中永久删除，并且所有数据都将丢失。",
            NON_REVERSIBLE_ACTION: "此操作不可撤销",
            NO_REVERSAL: "此操作不可撤销",
            DEACTIVATE_ACCOUNT_TITLE: "您即将停用此帐户",
            DEACTIVATE_ACCOUNT_TEXT: "如果您停用此帐户，它将失去对Tangibly的访问权限，并且只能通过电子邮件确认和查看资产。",
            REVERSIBLE_ACTION: "此操作可撤销",
            ACTIVATE_ACCOUNT_LATER: "该帐户能后期激活",
            REACTIVATE_ACCOUNT_TITLE: "您即将激活此帐户",
            REACTIVATE_ACCOUNT_TEXT: "如果您确认激活此帐户，它将获得对Tangibly的访问权限和一封确认电子邮件。",
            DEACTIVATE_ACCOUNT_LATER: "该帐户能后期停用。",
            I_AGREE: "同意",
            PLEASE_READ: "以下信息敬请仔细过目",
          },
          CHECKBOX_TEXT: {
            I_UNDERSTAND_REMOVE: "我确认此用户帐户将从系统中永久删除且无法恢复。",
          },
        },
        BATCH_UPLOAD: {
          UPLOAD_MULTIPLE_ASSETS: "批量上传 - 上传多个资产",
          UP_TO_50_EMPLOYEES: "最多 50 名员工",
          DRAG_AND_DROP: "拖放一些图像，或浏览您的文件",
          DROP_THE_FILES: "将文件拖放到此处...",
          DONT_CLOSE_WINDOW: "请勿关闭窗口。正在上传员工...",
          VALIDATING_EMPLOYEES: "正在验证员工...",
          BUTTONS_TEXT: {
            DOWNLOAD_EXCEL_TEMPLATE: "下载Excel模板",
            UPLOAD_NEW_FILE: "上传新文件",
            CONFIRM_UPLOAD: "确认上传",
          },
          ERROR_MESSAGE: {
            CANT_UPLOAD_MORE_THAN_50: "超过 50 名员工无法上传",
            NO_EMPLOYEES_UPLOADED: "未上传员工",
            NO_ASSETS_UPLOADED: "未上传资产",
            THERE_ARE: "存在 {{batchErrors}} 个错误",
            NO_ERRORS_FOUND: "未发现错误",
            UPLOAD_ONLY_ACCEPTED_FORMATS: "请仅上传兼容的格式！",
            ROW: "行",
            EMPLOYEES_ADDED_TO_TANGIBLY: "新员工将添加到Tangibly",
            EMPLOYEES_UPDATED: "existing employees will be updated",
          },
        },
        INVITE_FORM: {
          TITLE: {
            AGREEMENTS: "协议",
          },
          SELECT_A_ROLE: "选择一个角色以上传协议",
          MANAGERS_NOTIFICATION: "部门经理将仅查看与其部门相关的资产。",
          ACTIVE_TOGGLE: "活跃",
          CHECKBOX_OKTA_MESSAGE: "此用户正在使用 Okta 作为 SSO",
          ACTIVE_TOOLTIP: "活跃帐户有权创建资产并查看所有已分配的课程。",
          NONACTIVE_TOOLTIP: "非活跃帐户只能一次性查看器查看管理员共享的特定资产。",
          INPUT_LABEL: {
            EMAIL: "电子邮件",
            DISPLAY_NAME: "显示名称",
            TITLE: "标题",
            PHONE: "电话号码",
            LINKEDIN_PROFILE: "LinkedIn 个人资料",
            EMPLOYEE_ID: "员工编号",
            COUNTRY: "国家",
            LOCATION: "地区",
            HIRE_DATE: "入职日期",
            EXIT_DATE: "离职日期",
            OTHERS_1: "其他1",
            OTHERS_2: "其他2",
            STATUS: "状态",
          },
          SELECTOR: {
            ROLE: "角色",
            DEPARTMENTS: "部门",
            BUSINESS_UNITS: "业务部",
          },
          PLACEHOLDER: {
            EMAIL_PLACEHOLDER: "email@example.com",
            ENTER_ADDITIONAL_INFORMATION: "输入额外信息",
          },
          VALIDATION_MESSAGE: {
            VALID_EMAIL: "请输入有效的电子邮件",
            NEVER_SHARE_EMAIL: "我们绝不会与任何人分享您的电子邮件",
            VALID_LINKEDIN: "请输入有效的 LinkedIn 链接",
          },
          DEPARTMENT_MODAL: {
            TITLE: "资产通知",
            SUBTITLE_ADD: "添加的部门/业务部门可能会收到资产通知，并授予该用户访问权限。",
            SUBTITLE_EDIT: "用户部门/业务单位的变化可能会影响对通知资产的访问。",
          },
        },
        VIEW_EMPLOYEE: {
          ACCOUNT_DETAILS: "帐户明细",
          EXECUTED_ON: "执行于 {{SIGNED_DATE}}",
          PENDING: "待处理",
          HEADER: {
            AGREEMENTS: "协议",
            TRAINING: "培训",
            ASSETS_CREATED: "已创建资产 ({{ASSET_LENGTH}})",
            ASSETS_SHARED: "已共享资产 ({{ASSET_SHARED_LENGTH}})",
            ASSETS_CONTRIBUTED: "已贡献资产 ({{ASSET_LENGTH}})",
          },
          ACCOUNT_DATA: {
            NAME: "姓名：",
            EMAIL: "电子邮件：",
            TITLE: "职位：",
            DEPARTMENTS: "部门：",
            BUSINESS_UNITS: "业务部：",
            STATUS: "状态：",
            ROLE: "角色：",
            HIRE_DATE: "聘用日期：",
            EXIT_DATE: "离职日期：",
          },
          TABLE: {
            CREATED_ON: "创建于",
            SHARED_BY: "分享者",
            SHARED_ON: "分享于",
            ASSET: "资产",
            TYPE: "类型",
            TAGS: "标签",
            RECIPIENTS: "接收者",
            STATUS: "状态",
            ASSET_COLUMN: "资产",
            TAGS_COLUMN: "标签",
            CREATION_DATE: "创建日期",
            SENT_DATE: "发送日期",
            ACKNOWLEDGEMENT: "确认",
            NO_DATA: "未找到数据",
          },
        },
        MULTISELECT: {
          TITLE: {
            ACTIVATE_USERS: "激活 {{totalSelected}} 个帐户？",
            DEACTIVATE_USERS: "停用 {{totalSelected}} 个帐户？",
          },
          WARNING: "请仔细阅读以下信息",
          MODAL_ACTIVATE_BODY: {
            ACTIVATE_NOTE: "您即将激活帐户",
            ACTIVATE_TEXT: "他们将获得 Tangibly 的访问权限并收到一封激活电子邮件。",
            REVERSAL_NOTE: "此操作可逆",
            REVERSAL_TEXT: "如果您愿意，您可以稍后停用帐户。",
            MULTIPLE_STATUS: "<strong> 注意：</strong>当前处于活动状态的帐户不会发生任何变化。待定帐户将收到新的激活电子邮件。",
          },
          MODAL_DEACTIVATE_BODY: {
            DEACTIVATE_NOTE: "您即将停用帐户",
            DEACTIVATE_TEXT: "他们将失去对 Tangibly 的访问权限，并且只能通过电子邮件确认和查看资产。",
            REVERSAL_NOTE: "此操作可逆",
            REVERSAL_TEXT: "如果您愿意，您可以稍后激活帐户。",
            MULTIPLE_STATUS: "<strong>注意：</strong>当前处于非活动状态的帐户不会发生任何变化。",
          },
          ACTION: {
            USERS_ACTIVATION: "用户激活",
            USERS_DEACTIVATION: "用户停用",
            ACTIVATING: "激活 {{ totalSelected }} 个帐户",
            ACTIVATED: "{{ activated }} 个帐户已激活",
            ACTIVATION_EMAIL: "邀请邮件已成功发送",
            FAILED_ACTIVATION: "{{ failed }} 用户激活失败",
            DEACTIVATING: "停用 {{ totalSelected }} 帐户",
            DEACTIVATED: "{{ deactivated }} 帐户已停用",
            DEACTIVATION_EMAIL: "对 <strong> {{clientName}} </strong> 的访问权限已被撤销",
            FAILED_DEACTIVATION: "{{ failed }} 用户停用失败",
            RETRY_DEACTIVATION: "重试停用",
            TRY_AGAIN: "再试一次",
            DISABLE_ACTION: "等到上一个操作完成后再对帐户执行新操作",
          },
        },
        TRAINING_TOOLTIP: {
          NOT_COMPLETED: "未完成",
          TRAINING_COMPLETED: "培训完成",
        },
        PEOPLE_AND_CONTRACTORS_TABLE: {
          NAME: "姓名",
          STATUS: "地位",
          COMPLIANT: "合规",
          TRAINING: "训练",
          DEPARTMENT: "部门",
          BUSINESS_UNIT: "业务部门",
          START_DATE: "聘用日期",
          EXIT_DATE: "退出日期",
          ACTIONS: "操作",
          ACKNOWLEDGMENT: "致谢",
          AGREEMENTS: "协议",
          IS_SIGNED: "已签署",
          NOT_SIGNED: "未签名",
          AND_MORE: "以及 {{count}} 个以上",
          MISSING: "丢失的",
          TRAINING_COMPLETED: "培训完成",
          ASSETS_RECEIVED: "收到的资产",
        },
      },
      DISCOVERY_LOBBY: {
        BUTTONS_TEXT: {
          CONTACT_SUPPORT: "联系客服",
          SEARCH: "搜索",
          UPDATE_XRAY_DATA: "更新 X 光数据",
          UPLOAD_PATENT_APPLICATION: "上传专利申请",
          ANALYZE: "分析",
          ANALYZE_AND_DOWNLOAD_REPORT: "分析并下载报告",
          UPLOAD_CONTRACT_FILE: "上传合同文件",
        },
        TITLE: {
          EMAIL_XRAY: "电子邮件 X 光™",
          PATENT_XRAY: "专利 X 光™",
          CONTRACT_XRAY: "合同分析器",
          TRADE_SECRET_FINDER: "商业机密查找器",
          ASSET_EXPOSURE_TRACKER: "资产泄露监控",
          INSIGHTS: "洞察",
          LAST_UPDATE_REQUEST: "上次更新请求于 {{DATE}}",
          EXTRACTION: "提取",
          MAPPING: "图谱",
          INVENTION_DISCLOSURE: "发明披露",
          OR: "或",
          CONTINUATION_ANALYSIS: "延续分析",
        },
        CARD_TEXT: {
          JOIN_PILOT_GROUP: "加入试点小组并解锁电子邮件 X 光功能以升级您的资产管理",
          FIND_TRADE_SECRETS_FROM_PATENTS: "从专利中查找并编辑商业机密",
          FIND_TRADE_SECRETS_FROM_GOOGLE_PATENTS: "Tangibly 的人工智能可以帮助您定位潜在的商业机密。",
          SEARCH_FOR_POTENTIAL_TRADE_SECRETS: "在电子邮件中搜索潜在的商业机密",
          MONITOR_TRADE_SECRETS_EXPOSURE: "监控现有商业机密的泄露情况",
          ASSETS_FROM_PATENTS: "专利基础上创建的资产",
          ASSETS_FROM_INVENTION_DISCLOSURE: "发明披露基础上创建的资产",
          ASSETS_BY_FINDER: "该资产由 Finder 创建",
          PEOPLE_BY_TRACKER: "该人员由 Tracker 添加",
          WRITE_SMARTER_PATENT_APPLICATIONS: "Tangibly 的人工智能能评估与潜在商业机密相关的披露程度。",
          CONTINUATION_ANALYSIS_DESCRIPTION: "Tangibly 的人工智能将起草延续的权利要求供您参考，从而节省时间和金钱。",
          LOAD_MORE: "加载更多。",
          UPLOAD_CONTRACT: "请上传合同，系统将检查其质量以及是否涵盖所有注意事项",
          CONTRACT_ANALYZER: "请上传合同，并检查您的商业机密保护程度以及是否已解决所有重要的商业机密条款。",
          PASTE_INVENTION_DISCLOSURE_HERE: "请粘贴您的发明披露，以发现潜在的商业机密",
        },
        PLACEHOLDER: {
          ENTER_PATENT_NUMBER: "输入专利号",
          PATENT_XRAY: "输入专利申请号",
        },
        XRAY_STATUS_NOTIFICATION: {
          YOU_CAN_CONTINUE: "别担心，您可以继续在其他页面上工作，稍后再回到这里查看结果。",
        },
        INVENTION_DISCLOSURE_NOTIFICATION: {
          ANALYSIS_IN_PROGRESS: "分析进行中。",
          WE_FOUND_POTENTIAL_TS_IN_IDF: "我们创建了独立权利要求并识别了潜在的商业机密",
        },
        CONTINUATION_ANALYSIS_NOTIFICATION: {
          WE_ARE_FINDING: "正在处理续案分析，报告即将自动下载。",
          RECENT_REPORT_DOWNLOAD: "下载近期的报告",
          REPORT_FOR: "{{PATENT_IDENTIFIER}} 的报告",
        },
        PATENT_NOTIFICATION: {
          WE_ARE_FINDING: "我们正在查找商业机密，这可能需要花几分钟的时间。",
          RECENT_SEARCH: "近期搜索",
          FOUND_RESULTS_FROM_PATENT: "我们从专利 “{{PATENT_ID}}” 中找到了 {{RESULT_NUM}} 个商业机密结果",
          FOUND_RESULTS_FROM_FILE: "我们从文件 “{{FILE_NAME}}” 中找到了 {{RESULT_NUM}} 个商业机密结果",
        },
        PATENT_NOTIFICATION_BUTON: {
          SEE_RESULTS: "查看结果",
        },
        PATENT_NOTIFICATION_ERROR: {
          WE_DID_NOT_FIND_PATENT: "哎呀。系统未找到您要查找的专利。",
          SOMETHING_WENT_WRONG: "哎呀。出错了。",
          TRY_SEARCHING_AGAIN: "重新搜索。",
          TRY_SEARCHING_AGAIN_EXPORT: "哎呀。出错了。请重新搜索。",
          TRY_IN_CORRECT_FORMAT: "请尝试搜索其他专利号或检查号码格式是否正确（无特殊字符）。",
          NO_CLAIMS: "该专利 “{{PATENT_ID}}” 不包含权利要求。",
          NO_CLAIMS_IN_FILE: "哎呀。系统在您的文档中未找到相关数据。",
          MAKE_SURE_FILE_CONTAINS_CLAIMS: "烦请确保您的文件“权利要求”部分中包含序号的权利要求列表。",
          NO_RESULTS: "哎呀。系统未找到您要查找的专利。",
          PROBLEM_READING_FILE: "读取文件失败。",
          PLEASE_UPLOAD_AGAIN: "请重新上传。",
          PLEASE_UPLOAD_ACCEPTED_FORMAT: "请上传兼容格式的文件。",
          NO_RESULTS_FOR_LATEST_SEARCH: "哎呀。您最近搜索尚无结果。",
          NO_SATISFACTORY_RESULTS: "哎呀。您最近搜索尚无结果。",
          NO_DESCRIPTION_IN_DOCUMENT: "哎呀。该文档不包含说明书。",
          TRY_UPLOADING_DIFFERENT_DOCUMENT: "请上传其他文档。",
        },
        PATENT_APPLICATION: {
          WANT_TO_SEARCH: "想搜索正在申请中的专利吗？",
          UPLOAD_PATENT_WORD_FILE: "上传专利申请 word 文件",
        },
        UPLOAD_PATENT_APPLICATION: {
          SEARCH_WITH_PATENT_FILE: "使用专利申请文件搜索",
          UPLOAD_PATENT_APPLICATION_FILE: "上传专利申请文件",
          DRAG_AND_DROP_WORD_FILE: "将 word 文件拖放到此处或",
          UPLOAD_WORD_FILE: "请上传 word 文件。",
        },
        UPLOAD_CONTRACT_APPLICATION: {
          UPLOAD_CONTRACT_APPLICATION_FILE: "上传合同文件",
          RECENT_UPLOAD: "最近上传",
          WE_FOUND_RESULTS: "文件 “{{FILE_NAME}}” 中已找到以下结果",
          WE_ARE_FINDING: "正在检查您文档的商业机密保护评分，这可能需要花几分钟的时间。",
          TRADE_SECRET_PROTECTION_SCORE: "商业机密保护评分",
          DOCUMENT_TRADE_SECRET_PROTECTION_SCORE: "文档商业机密保护评分",
          PARAMETERS: "参数",
          RECOMMENDATIONS: "建议",
        },
        CONTRACT_RESPONSE: {
          CONFIDENTIALITY_PROVISION: {
            LABEL: "保密条款",
            CONTENT: "有效的合同必须提供保密性，以保护所共享的机密信息。保密条款还限制接收方对信息的使用。对于法律或监管披露要求以及信息公开等情况，定义了例外。接收方必须保持信息的保密性，禁止未经授权的披露或使用，具体例外情况包括法律义务或预先存在的公开知识。",
            RECOMMENDATION: "接收方应将所有机密信息视为秘密和保密信息，未经披露方的书面同意，不得直接或间接地（无论是书面、口头或其他方式）向任何其他人传达或披露机密信息，除非符合本协议的条款。",
          },
          TRADE_SECRET: {
            LABEL: "提到了商业秘密保护。",
            CONTENT: "合同应明确列出商业秘密作为受保护的知识产权类型之一。",
            RECOMMENDATION: "信息是指所有信息，包括但不限于任何知识产权、与系统、运营、计划、意图、市场机会、客户、供应商、专有技术、商业秘密、业务事务、业务方法和业务概念相关的信息，无论其形式是口头的、有形的还是书面的。",
          },
          CLEARLY_DEFINED: {
            LABEL: "商业秘密有明确定义",
            CONTENT: "商业秘密应明确定义，以便接收方清楚什么是（或不是）商业秘密。",
            RECOMMENDATION: "商业秘密是指：a) 公众普遍不知晓的信息；b) 因为信息不为公众所知而为其持有者带来经济利益的信息；c) 持有者采取合理措施保持其秘密性的信息。",
          },
          TERMINATION: {
            LABEL: "期限和终止",
            CONTENT: "合同应有明确的有效期限。应清楚地列出提前终止或延长的事件或选项。",
            RECOMMENDATION: "本协议的有效期为ABC年。协议可由双方共同同意终止。",
          },
          RETURNING_OR_DESTROYING: {
            LABEL: "在期限结束时归还/销毁机密信息",
            CONTENT: "接收方在期限结束时删除、归还或销毁机密信息的义务应明确。",
            RECOMMENDATION: "接收方应在以下较早的时间内，立即归还或销毁所有副本（无论以何种形式复制或存储），包括根据本协议披露的所有机密信息的笔记和衍生物：(a) 本协议所涉及交易的完成或终止；(b) 本协议的终止；(c) 或披露方要求接收方归还或销毁机密信息的时间。",
          },
          SURVIVAL_CLAUSE: {
            LABEL: "存续条款",
            CONTENT: "合同中的存续条款应说明，即使在协议终止后，保密义务仍然持续，以保护所披露的信息，例外情况包括因接收方无过错而使信息成为公众知识的情况。",
            RECOMMENDATION: "保密义务应持续至以下较晚的时间：(a) 信息因接收方无过错而不再是机密信息的时间，或 (b) 自披露方披露之日起 DEF 年。",
          },
          DISCOVERING_INFORMATION: {
            LABEL: "仲裁或调解中的允许发现",
            CONTENT: "如果希望采用替代性争议解决（ADR）方式，如调解或仲裁，可以定义发现的数量和范围。",
            RECOMMENDATION: "在ADR开始之前，每一方有权进行有限的发现，包括有权要求合理数量的文件，提出不超过二十个质询，并进行不超过三次的证词采集。",
          },
          LIMITS_ON_LIABILITY: {
            LABEL: "关于商业秘密损失的责任限制",
            CONTENT: "如果需要，可以定义对任何商业秘密损失的责任限制。",
            RECOMMENDATION: "对于违反本协议中任何保密条款的责任应限于 XYZ。",
          },
          SHARING_WITHOUT_RESTRICTIONS: {
            LABEL: "第三方披露",
            CONTENT: "任何允许的第三方披露应明确规定。",
            RECOMMENDATION: "接收方不得在未经披露方事先书面同意的情况下，将收到的任何机密信息披露给任何第三方。",
          },
          MANDATORY_DISCLOSURE: {
            LABEL: "强制性披露",
            CONTENT: "应明确规定强制性披露，例如向法院或监管机构的披露。",
            RECOMMENDATION: "如果接收方被要求向法院或监管机构传达机密信息，或因强制性法律而同样有义务披露此类信息，接收方应立即通知披露方此类要求披露的条款，并将与披露方合作以遵守要求并保持机密信息的保密性。",
          },
        },
        CHIP: {
          BETA: "测试版",
          NEW: "全新 AI 功能",
          ERROR: "该文档不能保护您的商业机密",
          SUCCESS: "该文档保护您的商业机密",
          WARNING: "该文档部分保护您的商业机密",
        },
        POPUPS: {
          TITLE: "新功能",
          BUTTONS: {
            NEXT: "下一步",
            OK_I_GET_IT: "明白了！",
          },
          PATENT_TITLE: "专利 X 光提取与分析",
          SUBTITLE: "识别您或竞争对手专利周围的潜在商业机密，并获得全面的保护。",
        },
        INVENTION_DISCLOSURE: {
          WORDS_COUNT: "{{WORDS_COUNT}} 个字",
          TEXT_TOO_LONG_ERROR: "我们可以搜索最多 {{MAX_WORDS}} 个字",
          TEXT_TOO_SHORT_ERROR: "输入太短。我们只能搜索长度超过 {{MIN_WORDS}} 个字的文本",
          TEXTAREA_PLACEHOLDER: "粘贴最多 {{MAX_WORDS}} 个字的文本",
        },
        INVENTION_DISCLOSURE_ERROR: {
          TEXT_TOO_SHORT: "哎呀。文本太短了。",
          WE_CAN_ONLY_SEARCH_MIN_WORDS: "长度超过 {{MIN_WORDS}} 个字的文本无法搜索",
        },
        INFO_TEXT: {
          PATENT_XRAY: "识别您或竞争对手专利周围的潜在商业机密，并获得全面的保护。",
        },
      },
      DISCOVERY_JOIN: {
        TITLE: {
          XRAY_FEATURES: "X 光功能",
          JOIN_PRIVATE_BETA: "加入 Tangibly 的私人测试版，以解锁 X 光功能，从而增强您的商业机密管理",
          FINDER: "商业机密查找器",
          TRACKER: "资产泄露监控",
          PATENT_XRAY: "专利 X 光",
          SEARCHING_USING_KEYWORDS: "使用关键字搜索商业机密",
          MATCHING_FILES: "匹配文件以检测商业机密的泄露情况",
          FINDING_FROM_PATENTS: "从专利中查找和编辑商业机密",
        },
        BUTTONS_TEXT: {
          JOIN_BETA: "加入测试版",
        },
        TOAST: {
          ERROR: "出错了，请稍后再试",
        },
      },
      PATENT_TOOL: {
        DROPDOWN_BUTTON: {
          TURN_INTO_ASSET: "转化为资产",
          EDIT_AND_TURN_INTO_ASSET: "编辑并转化为资产",
        },
        TOAST: {
          ASSET_CREATED_SUCCESSFULLY: "资产创建成功",
        },
        LOADER: {
          FINDING_TRADE_SECRETS: "从专利 “{{PATENT_ID}}” 中查找商业机密",
          THIS_CAN_TAKE_1_3_MINUTES: "这可能需要 1-3 分钟，请不要离开",
        },
        BUTTON: {
          REGROUP_RESULTS: "重新组合结果",
        },
        CONTENT: {
          ASSET_CREATED: "资产已创建",
          BACK_TO_PATENT_X_RAY_HOME: "专利 X 光",
          BACK_TO_CONTRACT_ANALYZER_HOME: "合同分析器",
          BACK_TO_INVENTION_DISCLOSURE_HOME: "发明披露",
          TRADE_SECRETS_RESULTS: "从专利 “{{PATENT_ID}}” 中获得的商业机密结果",
          INVENTORS: "发明人",
          RESULTS: "结果",
        },
        MODAL: {
          ARE_YOU_SURE: "您确定要关闭此资产吗？",
        },
        ADD_NEW_CONTRIBUTORS: {
          ERROR_MESSAGE: {
            EMAIL_SHOULD_BE_UNIQUE: "每个电子邮件都应该是独一无二的",
            EMAIL_IS_REQUIRED: "电子邮件为必填项",
            INVALID_EMAIL: "请输入有效的电子邮件",
          },
          TOAST: {
            USERS_ADDED_SUCCESSFULLY: "成功添加了 {{NUM}} 位用户",
          },
          TOOLTIP: {
            FOUND_EXISTING_USERS: "我们在系统中找到了与该姓名匹配的现有用户，他们可能与您正在创建的资产的贡献者相关。",
            ADD_INVENTORS_AS_USERS: "将以下发明人作为用户添加到系统中，以便他们成为您正在创建的资产的贡献者。 聘用日期可后期在“人员”页面上编辑。",
          },
          INPUT_LABEL: {
            EMAIL: "电子邮件",
            ROLE: "角色",
            HIRE_DATE: "聘用日期",
          },
          BUTTON: {
            ADD_CONTRIBUTORS: "添加贡献者",
          },
          ADD_INVENTORS_AS_CONTRIBUTORS: "将专利发明人添加为贡献者",
          SUGGESTED_CONTRIBUTORS: "建议的贡献者",
          NOT_RIGHT_PERSON: "该人员不正确 ？",
          NEW_USERS: "新用户",
        },
        EDIT_TEXT: {
          EDIT_SUBJECT: "编辑主题",
        },
        PLACEHOLDER: {
          ENTER_PATENT_NUMBER: "输入专利号",
        },
        ERROR_PAGE: {
          SOMETHING_WENT_WRONG: "哎呀！出错了。",
          SEARCH_AGAIN: "重新搜索",
          NEW_SEARCH: "新搜索",
          WE_DID_NOT_FIND: "哎呀。我们没有找到您要查找的专利。",
          TRY_IN_CORRECT_FORMAT: "尝试搜索其他专利号或检查号码格式是否正确（无特殊字符）。",
          NO_CLAIMS: "专利 “{{PATENT_ID}}” 不包含权利要求。",
          XRAY: "X 光",
        },
      },
      PATENT_APPLICATION_TOOL: {
        CONTENT: {
          TRADE_SECRETS_RESULTS: "从 word 文件 “{{FILE_NAME}}” 中获得的商业机密结果",
        },
        NOTIFICATION: {
          NO_CLAIMS: "我们注意到上传的文件未以正确的格式编写，因此结果可能会受到影响。尝试上传更新的文件，其中包含“权利要求”部分中编号的权利要求列表。",
        },
      },
      PATENT_MAPPING_TOOL: {
        TITLE: {
          POTENTIAL_TS: "文件 “{{FILE_NAME}}” 中披露的潜在商业机密",
          POTENTIAL_TS_ID: "专利 “{{PATENT_ID}}” 的潜在商业机密",
          POTENTIAL_TS_TITLE: "“{{PATENT_TITLE}}” 的潜在商业机密",
          IS_MAPPING_TOOL: {
            TITLE: "地图",
          },
          PATENT_NUMBER: "专利号",
          YEAR: "年份",
          INVENTORS: "发明人",
          RESULTS: "结果",
          MAPPING_ANALYSIS_IN_PROGRESS: "地图分析进行中",
          MAPPING_ANALYSIS_NOT_FOUND: "未找到此专利分析",
          NO_REFERENCE: "说明书中未找到参考文献",
          DISCLOSURE_FOUND_IN_FILE: "文件中已发现披露",
          MISSING_IN_FILE: "文件中缺少此内容",
        },
        NOTIFICATION: {
          NO_CLAIMS: "上传的文件格式不正确，因此结果可能会受到影响。请上传其他文件，其中包含“权利要求”部分中序列的权利要求列表。",
          DESC_NOT_FOUND: "上传的文件格式不正确，因此结果可能会受到影响。",
        },
        HIGHLIGHTS: {
          OVERALL_DISCLOSURE: "总体披露",
          DESCRIPTIONS_FOUND_IN_FILE: "在文件中找到的描述",
          FOUND: "是",
          NOT_FOUND: "否",
          SPECIFICATION_FOUND: "说明书状态",
        },
        EXTRA: {
          MADE_IMPROVEMENTS_IN_FILE: "在申请文件中进行了改进吗？",
          UPLOAD_MODIFIED_FILE: "上传修改后的文件",
        },
        TOOLTIP: {
          DESCRIPTION_FOUND_IN_FILE: "在文件中找到的描述",
          SHOW_ORIGINAL_TEXT: "显示原文",
          MISSING_IN_FILE: "文件中缺失",
          NO_REFERENCE_IN_THE_SPECIFICATION: "在说明书中未找到参考",
          COPY: "复制",
          COPIED: "已复制！",
          ORIGINAL_TEXT_COPIED: "原文已复制！",
          ORIGINAL_TEXT: "原文",
          WE_USE_OPEN_AI_TECHNOLOGY: "我们使用 Open AI 技术提取商业机密",
          IT_CAN_STILL_BE_A_TRADE_SECRET: "尽管在说明书中发现了披露，但它仍然可能是一个商业机密，具体取决于披露的内容",
          STRONG_CANDIDATE_FOR_A_TRADE_SECRET: "由于缺乏披露，这是商业机密的有力候选者。",
          ALIAS_ID_COPY: "要通过电子邮件创建资产，请将您的别名链接粘贴到新电子邮件的收件人字段中，并附上您的资产文件。",
          ALIAS_LINK: "别名链接",
        },
        POPUP: {
          HOW_TO_USE_THIS_FEATURE: "如何使用此功能",
          WAIT_HOW_CAN: "等等，专利怎么会包含商业机密？它是一份公开文件！",
          EXTRACTION_TEXT: "专利的撰写方式通常不会披露关键信息。专利 X 光是一种人工智能引擎，旨在将您指向与专利技术相关的潜在商业机密的方向。从结果中您将看到，专利 X 光将商业机密划分为关键类别，并在每个类别中阐明了几个潜在的商业机密领域。",
          DONT_SHOW_THIS_AGAIN: "不要再显示这个",
          OK_I_GET_IT: "好的，我明白了！",
          EXPORT: "导出",
          MAPPING_ANALYSIS: "映射分析  地毯式搜索分析？",
          MAPPING_TEXT: "根据专利中披露的内容，专利 X 光建议的商业机密可能是或可能不是实际的商业机密。我们的映射功能在专利说明书中查找潜在商业机密的引用。将鼠标悬停在结果上，如果在说明书中找到了相关的披露内容，它将显示在右侧的弹出窗口中。这是说明书中的实际文本。",
        },
        TOAST: {
          FILES_EXPORT: "文件导出",
          CONTINUATION_ANALYSIS_EXCEL: "专利 X 光续案分析 Excel 报告 {{patentIdentifier}}",
          CONTINUATION_ANALYSIS_WORD: "专利 X 光续案分析 Word 报告 {{patentIdentifier}}",
          MAPPING_ANALYSIS: "专利 X 光映射分析报告 {{patentId}}",
          TRADE_SECRET: "专利 X 光商业机密分析报告 {{patentId}}",
          WORD_NOT_EXPORTED: "Word 文档未导出。",
          PLEASE_TRY_AGAIN: "请重试",
        },
        MODAL: {
          EXPORT_PATENT_DATA: "导出专利 X 光数据",
          MAPPING_ANALYSIS_EXCEL: "图谱分析 Excel",
          EXTRACTION_ANALYSIS_EXCEL: "提取分析 Excel",
          TRADE_SECRET_ANALYSIS_PDF: "商业机密分析 PDF",
        },
      },
      GENERAL: {
        BUTTONS_TEXT: {
          CANCEL: "取消",
          DISCARD: "放弃",
          SKIP: "跳过",
          SAVE_CHANGES: "保存更改",
          ADD_USER: "添加用户",
          ADD_USER_CAPITAL: "添加用户",
          UPDATE_USER: "更新用户",
          LATER: "我稍后处理",
          ADD: "添加",
          GO_TO_ASSET_PAGE: "转到资产页面",
          CONTINUE: "继续",
          BACK: "返回",
          DONE: "完成",
          EDIT: "编辑",
          YES: "是",
          NO: "否",
          COMPLETE: "完成",
          DELETE: "删除",
          APPLY: "应用",
          NOTIFY: "通知",
          CONFIRM: "确认",
          CLEAR_ALL: "清除所有",
          SEARCH: "搜索",
          SUBMIT: "提交",
          I_AM_SURE: "我确定",
          SAVE: "保存",
          REQUEST_ACCESS: "请求访问",
          SAVE_AND_CONTINUE: "保存并继续",
          MAYBE_LATER: "稍后",
          LEAVE: "离开",
          CONNECT: "连接",
          MANAGE: "管理",
          OK: "确定",
          SAVE_AND_CONFIRM: "Save & Confirm",
          ACTIVATE: "Activate",
          DEACTIVATE: "Deactivate",
          ADD_AND_NOTIFY: "Add and Notify",
          REMOVE: "Remove",
        },
        TIPPY: {
          MORE_ACTIONS: "更多操作",
        },
        UPLOAD_FILES_MODAL: {
          DRAG_AND_DROP_FILE: "将文件拖放到此处或",
          SELECT_FILE_FROM_COMPUTER: "从您的计算机选择文件",
          DRAG_AND_DROP_FILE_EDIT_MODE: "将新文件拖放到此处或点击",
          DRAG_AND_DROP_CLICK: "从您的计算机浏览",
          FILE_IS_NOT_SUPPORTED: "不支持此文件类型。",
          FILE_IS_TOO_LARGE: "文件 {{FILE_NAME}} 太大。请上传小于 {{FILE_SIZE}}MB 的文件",
          FILES_UPLOADED_SHOULD_NOT_EXCEED: "上传的文件总数不应超过 {{UPLOAD_LIMIT_IN_MB}}MB。",
        },
        ERROR: {
          SOMETHING_WENT_WRONG: "出错了，请稍后再试",
        },
        SORT_BY_DROPDOWN: {
          NEWEST: "最新",
          RECENTLY_MODIFIED: "最近修改",
          SORT_BY: "排序方式：",
        },
        CHIP: {
          NEW: "新",
        },
        ACTION_TOOLBAR: {
          SELECTED: "{{totalSelected}} 选定",
        },
      },
      DASHBOARD_PAGE: {
        HEADER: {
          TITLE: "仪表板",
          DEPARTMENTS: {
            TITLE: "显示数据为",
            ALL_DEPARTMENT: "所有部门",
            NO_DEPARTMENT: "没有部门",
          },
          EXPORT_DATA: "导出数据",
        },
        FIRST_TIME_ENTRY_TEXT: "我确认在 {{CLIENT_NAME}} 工作期间，我不会不正当地披露或使用任何前任或现任雇主的任何商业机密。未经前任或现任雇主（商业机密的所有者）的书面授权，我不会将此类商业机密带入 {{CLIENT_NAME}} 或加载到 {{CLIENT_NAME}} 的 Tangibly 账户中。",
        GET_LOGIN_ENTRY_TEXT: "本平台上的信息和资产是 {{CLIENT_NAME}} 的知识产权，或由 {{CLIENT_NAME}} 合法拥有，应被视为高度机密。未经 {{CLIENT_NAME}} 的书面同意，不得下载、共享或复制信息。",
        GET_LOGIN_ENTRY_NOTE: "<strong>注意：</strong> Tangibly 平台是管理辅助工具，并非旨在提供全面的信息。账户所有者可能拥有其他描述不同或未列出的商业机密。",
        ASSETS: "资产",
        NEW_ASSETS: "新增资产",
        ASSET_TYPES: "资产类型",
        PROJECTS: "项目",
        NEW_PROJECTS: "新增项目",
        TOP_PROJECTS: "热门项目（资产数量）",
        PEOPLE: "人员",
        NEW_USERS: "新用户",
        TOP_CONTRIBUTORS: "贡献最多的人",
        AVG_NEW_WEEK: "平均每周新增",
        ALL_ASSETS: "所有资产",
        EMPLOYEES: "员工",
        CONTRACTORS: "合同工",
        PARTNERS: "合作伙伴",
        WIDGETS: {
          EMPTY_STATE: {
            TITLE: "未找到资产",
            BUTTON_CTA_ASSETS: "创建资产",
            DESCRIPTION: "创建资产，开始获取结果",
          },
          COMMON: {
            NAME: "名称",
            ASSETS: "资产",
            TOTAL: "总计",
            TYPES: "类型",
            PAST_12_MONTH: "过去12个月",
          },
          ASSETS_BY_TYPE: "资产类型",
          ASSETS_OVER_TIME: "长期资产",
          TOTAL_ASSETS: "总资产",
          DATA_BY_DEPARTMENTS: {
            TITLE: "各部门数据",
            EMPTY_STATE: {
              TITLE: "未找到部门",
              BUTTON_CTA_ASSETS: "转到资产",
              DESCRIPTION: "更新使用部门的资产",
            },
          },
          ASSETS_DATA: "资产数据",
          LABEL: {
            OTHER: "其他",
            NO_DEPARTMENT: "没有部门",
            WEEK: "周",
            GROUP: "小组",
            UNPUBLISHED_ASSETS: "未公布资产",
            CREATED_BY_3RD_PARTY: "第三方创建的资产",
            EMPLOYEES: "员工",
            CONTRIBUTORS: "贡献者",
            EXTERNAL: "外部",
          },
          PEOPLE_STATUS: "人员状况",
          CONTRIBUTORS: "贡献最多的人",
          TOP_USED: {
            TAGS_TITLE: "最常用的标签",
            TAGS: "标签",
            BUSINESS_VALUE_TITLE: "最高使用商业价值",
            BUSINESS_VALUE: "商业价值",
          },
          USERS: "用户",
          PROJECTS_BY_ASSETS: {
            TITLE: "按资产分列的头部项目",
            PROJECT: "项目",
            EMPTY_STATE: {
              TITLE: "未找到项目",
              BUTTON_CTA_ASSETS: "转到资产",
              DESCRIPTION: "使用项目更新资产",
            },
          },
          PEOPLE_BY_ROLE: "按角色划分的人员",
          INSIGHTS: {
            TITLE: "洞察",
            MOST_NOTIFIED_ASSET: "最受关注的资产",
            MOST_SHARED_BY: "共享最多的是",
            TOP_ASSET_CREATOR: "头部资产创建者",
            EMPTY: "无",
          },
        },
        TABLES: {
          ASSET_TABLE: {
            ASSET: "资产",
            TYPE: "类型",
            CREATED_ON: "创建于",
            RECIPIENTS: "接收者",
            PROGRESS: "进度",
            COMPLIANT: "合规",
          },
          PEOPLE_AND_CONTRACTORS_TABLE: {
            NAME: "名称",
            STATUS: "状态",
            COMPLIANT: "合规",
            TRAINING: "培训",
            START_DATE: "入职日期",
            EXIT_DATE: "离职日期",
            ACTIONS: "举措",
            ACKNOWLEDGMENT: "确认",
            AGREEMENTS: "协议",
            IS_SIGNED: "已签署",
            NOT_SIGNED: "未签署",
            AND_MORE: "和 {{count}} 更多",
            MISSING: "缺失",
            TRAINING_COMPLETED: "培训完成",
            ASSETS_RECEIVED: "获得的资产",
          },
        },
      },
      SIDE_BAR: {
        HOME: "首页",
        PREDICTED_PORTFOLIO: "Predicted Portfolio™",
        COMPETITIVE_INTELLIGENCE: "竞争情报",
        DISCOVER: "发掘",
        PATENT_XRAY: "专利X-光",
        CONTINUATION_ANALYSIS: "续案分析",
        INVENTION_DISCLOSURE: "发明披露",
        CONTRACT_ANALYZER: "合同分析器",
        EMAIL_TRACKER: "电子邮件监测",
        TRAINING: "培训",
        MANAGE: "管理",
        DASHBOARD: "仪表板",
        ASSETS: "资产",
        PEOPLE: "人员",
        PARTNERS: "合作伙伴",
        PARTNER_DRAWER: {
          PARTNERS: "合作伙伴",
          ADD_PARTNER: "添加合作伙伴",
          SEARCH_BY_NAME: "按名称搜索",
          ENABLED: "已启用",
          DISABLED: "已禁用",
          PARTNER_NOT_FOUND: "未找到合作伙伴",
          TRY_ANOTHER_NAME: "尝试用其他名称搜索。",
          NO_ACTIVE_PARTNERS_TO_SHOW: "未显示激活的合作伙伴",
          NO_DISABLED_PARTNERS_TO_SHOW: "未显示禁用的合作伙伴",
          AI_TECHNOLOGY: "利用人工智能技术确保交易各方都有合同义务履行其协议",
          DATA_AND_MANAGEMENT: "简化数据处理和管理",
          ENSURE_AGREEMENTS: "确保所有协议均按其条款执行",
          NO_CONFUSION: "不会混淆共享资料的状态",
        },
        SWITCH_CLIENTS: "切换客户",
        COMPETITIVE_ANALYSIS: "竞争分析",
        XRAY: "X-光",
        LEARN: "学习",
        SETTINGS: "设置",
        HOMEPAGE: "首页",
      },
      LEARN_PAGE: {
        MY_COURSES: "我的课程",
        SAVE_CHANGES: "保存更改",
        REQUIRED: "必填项",
        GO_BACK_TO_COURSES: "返回课程",
        CONTINUE_TO_QUIZ: "继续测验",
        I_CONFIRM: "我确认已观看课程",
        TAKE_TO_QUIZ: "参加测验",
        DO_NOT_SEE_VIDEO: "看不到视频？",
        CLICK_HERE: "点击这里",
      },
      SETTINGS_PAGE: {
        WELCOME_MESSAGE: "您在此处所做的选择将影响组织内的所有用户。       请仔细查看并设置这些选项，因为有些设置以后无法在 Tangibly 平台上更改。       如有任何疑问，或将来需要更改这些设置，请联系我们的客户支持团队。",
        UPLOAD_FILES: "上传文件",
        SET_ASSET_TYPE: "设置资产类型",
        COMPLIANCE_REQUIREMENTS: "合规要求",
        CONFIRM: "确认",
        SAVE: "保存",
        CONFIRM_START_USING: "确认并开始使用 Tangibly",
        LANGUAGE: {
          SYSTEM_LANGUAGE: "系统语言",
          YOU_CAN_CHANGE: "您可以稍后在配置文件区更改账户专用语言",
        },
        IMPORTANCE_FIELDS: {
          ASSET_CATEGORY: "资产分类",
          YOU_CAN_CHANGE: "您可以根据贵公司的需要更改以下参数。创建新资产时，下面设置的类别将作为资产类别选项显示给员工。",
          RESET_TO_DEFAULT: "重置为默认值",
          ENTER_CATEGORY: "输入类别",
          TEXT: "文本",
          FIELD_IS_REQUIRE: "该字段为必填字段",
        },
        CHANGE_LANGUAGE: "更改语言",
        PLACEHOLDER: {
          SELECT_ONE: "选择一种",
        },
        TITLE: {
          PERMISSIONS: "许可",
          EMPLOYEES: "员工",
          CONTRACTORS: "合同工",
          PARTNERS: "合作伙伴",
          ORGANIZATION_SETTINGS: "组织设置",
          ACCOUNTS: "账户",
        },
        TIPPY: {
          SETTING: "“这些设置无法在 Tangibly 平台上更改。 如果您需要更改，请联系我们的客户支持团队。",
          ACCOUNTS: "账户",
        },
        SHAREPOINT: {
          HEADER: "SharePoint 集成",
          NOT_CONNECTED: {
            DESCRIPTION: "您的 Tangibly 账户有权与 SharePoint 集成 ，直接在 SharePoint 上管理资产文件，并与员工轻松共享。",
          },
          CONNECTED: {
            DESCRIPTION: "希望断开与 SharePoint 列表或更改验证信息？联系我们",
            SHAREPOINT_CONNECTED: "您已连接SharePoint",
          },
        },
        CREATE_ASSET_TYPE: "创建资产类型",
      },
      LOGIN_PAGE: {
        RESET_PASSWROD_MODAL: {
          RESET_PASSWORD: "重置密码",
          VERIFICATION_CODE: "验证码和链接已发送至您的电子邮件。",
          ENTER_USERNAME: "在此输入用户名：",
          ENTER_EMAIL: "请输入您的电子邮件地址",
          USERNAME: "用户名",
          EMAIL: "电子邮件",
        },
        NAVBAR: {
          HOME: "首页",
          DASHBOARD: "仪表板",
          LOGIN: "登录",
        },
        ERROR_OCCURRED: "出错了",
        CARD: {
          TRADE_SECRETS_SECURED: "帮助您保护商业机密",
          KEEPING_TRADE_SECRETS: "保护您的商业机密<strong>安全</strong>是我们的首要任务",
        },
        LOGIN_FORM: {
          LOGIN: "登录",
          USERNAME: "用户名",
          EMAIL: "电子邮件",
          PASSWORD: "密码",
          SIGN_IN: "登录",
          FORGOT_PASSWORD: "忘记密码？",
          OTP_SIX_DIGITS: "验证码为 6 位数",
          WRONG_OTP: "验证码错误",
          LOGIN_AGAIN: "出错了，请重新登录",
        },
        TWO_FACTOR_AUTH: {
          SET_UP: "设置双重因素认证",
          IN_ORDER_TO_CONTINUE: "要继续操作，请完成以下步骤：",
          STEP_1: "1. 在移动设备上下载 “Google Authenticator ”应用程序",
          STEP_2: "2. 在应用程序中点击 “+”按钮并扫描下面的二维码",
          STEP_3: "3. 输入收到的 6 位数的验证码",
          ENTER_CODE: "输入下面应用程序中的验证码。连接后，我们将记住您的手机号",
          YOU_CAN_USE_IT: "以便您每次登录时使用。",
          NOTE: "注意：每次登录时，您都需要打开身份验证程序以获取更新的 6 位数字验证码。",
          ENTER_OTP: "请输入 Authenticator 应用程序中的 验证码来验证您的帐户",
          HAVING_TROUBLE: "登录有问题？- 请联系我们",
        },
        REMEMBER_MY_DEVICE: {
          REMEMBER_MY_DEVICE: "记住我的设备",
        },
      },
      EMAIL_FINDER: {
        TOAST: {
          ASSET_CREATED_SUCCESSFULLY: "资产创建成功",
        },
        MODAL: {
          CLOSE_ASSET: "您确定要关闭此资产？",
          ADD_USER_TO: "添加用户到",
        },
        CONTENT: {
          BACK_TO_X_RAY_HOME: "X-光 首页",
          BACK_TO_FINDER: "返回查找器",
          TO: "前往 {{NAME}",
        },
        TITLE: {
          FINDER: "查找",
          KEYWORDS: "关键字",
          FILES: "文件",
          PARTICIPANTS: "参与者",
        },
        DISCOVERY_SUBTITLE: {
          PAGE_LAST_UPDATED_ON: "本页面最后更新于",
          REFRESH_THE_PAGE: "刷新以查看最新信息。",
          WE_ARE_REFRESHING: "我们正在刷新数据，可能需要几个小时。不用担心，您可以继续在系统上工作。",
        },
        BUTTONS_TEXT: {
          UPDATE_XRAY_DATA: "更新 X 光数据",
          TURN_INTO_ASSET: "转为资产",
        },
        TABLE: {
          NO_DATA: "未找到数据",
        },
        TABLE_HEADERS: {
          EMAILS_SUBJECT: "电子邮件 主题",
          KEYWORDS: "关键字",
          FILES: "文件",
          PARTICIPANTS: "参与者",
          DATE: "日期",
        },
        FETCH_FINDER_DATA: {
          DID_YOU_SIGN_IN: "您使用 Google 登录了吗？",
          AFTER_AUTHENTICATED_CLICK: "验证后，请单击下面的按钮以",
          RECEIVE_NEW_DATA: "接收新数据。",
          CONTINUE_WORKING: "数据刷新可能需要一些时间，但不用担心，您可以继续工作。",
          BUTTON_TEXT: {
            START_REFRESHING: "开始刷新数据",
          },
        },
      },
      EMAIL_TRACKER: {
        CONTENT: {
          BACK_TO_XRAY: "X光 首页",
          ASSETS_AT_RISK_FOUND: "由于在电子邮件中发现匹配文件，资产面临泄露风险",
        },
        TITLE: {
          TRACKER: "监测",
        },
        TABLE: {
          NO_DATA: "未找到数据",
        },
        TABLE_HEADERS: {
          ASSET: "资产",
          FILE_MATCH: "文件匹配",
          PEOPLE_EXPOSED: "泄露人员",
          DATE: "日期",
          ACTIONS: "行动",
        },
        TOOLTIP: {
          NOTIFY: "通知",
          REGISTERED: "已注册",
          NON_REGISTERED: "非注册用户",
          ADD_USERS: "添加用户",
        },
        TOAST: {
          SUCCESS_REQUEST_ACCESS: "谢谢，我们将尽快与您联系",
        },
      },
      EMAIL_TRACKER_MAIN_PAGE: {
        NOTIFICATION: {
          TITLE: "您正在查看的是 Tangibly 示例项目",
          DESCRIPTION: "想查看贵公司的电子邮件监测情况？请联系我们！",
        },
      },
      POLICY: {
        OPTIONS: {
          DOWNLOAD: "下载",
          EDIT: "编辑",
          UPLOAD: "上传",
        },
        UPLOAD: {
          TIP: "政策",
          TRADE_SECRET_TITLE: "商业机密政策",
          LLM_TITLE: "LLM政策",
          SAVE: "保存",
        },
        NEW_TEXT_NOTICE: "新！商业机密政策",
        POLICY_PHRASE_TOP_TRADE_SECRET: "管理员可将商业机密政策文件上传到",
        POLICY_PHRASE_TOP_LLM: "管理员可以上传 LLM 政策文件",
        POLICY_PHRASE_BOTTOM: "向所有员工显示。",
        TOAST: {
          SUCCESS: "政策更改成功",
          ERROR: "失败，请重试",
        },
      },
      HEADER: {
        LOG_OUT: "退出登录",
        SETTINGS: "Settings",
      },
      LAWYER_CLIENTS: {
        ASSETS: "资产",
        NEW_ASSETS: "新资产",
        NO_ASSETS: "无资产",
        VIEW_ALL_ASSETS_OF: "查看 {{CLIENT_NAME}} 的所有资产",
        CREATE_FIRST_ASSETS_WITH: "使用 {{CLIENT_NAME}} 创建您的第一个资产",
        NO_CLIENTS_AT_THE_MOMENT: "暂无该客户",
        PROBLEM_LOADING_CLIENTS: "加载客户时出现问题。",
        TITLE: "选择要创建的客户",
        SUBTITLE: "从顶部栏的下拉菜单中选择所需的客户，即可在平台上轻松切换客户。",
        CHOOSE_CLIENT: "选择客户",
      },
      ACTIVITY_LOG: {
        SHAREPOINT: {
          HEADER_MESSAGE: "以下数据反映的是 SharePoint 的活动日志。",
          LAST_UPDATED_ON: "最后更新于 {{DATE}}",
        },
        ASSET: {
          ASSET_EDITED: "已编辑资产",
          ASSET_MOVED: "移动资产",
          ASSET_REMOVED: "删除资产",
          ASSET_CREATED: "创建资产",
          WERE_NOTIFIED: "+{{NAMES}}收到资产通知",
          WERE_SHARED_ON_ASSET: "+{{NAMES}}在资产中共享",
          WAS_SHARED_ON_ASSET: "{{ACTION}}已共享资产",
          WAS_NOTIFIED: "{{ACTION}}已通知资产",
          WAS: "曾为",
          WERE: "曾为",
          TITLE: "标题",
          IN: '在',
          DESCRIPTION: "说明书",
          ASSET_TYPE: "类型",
          TAG: "标签",
          PROJECT: "项目",
          DEPARTMENT: "部门",
          BUSINESS_VALUE: "业务价值",
          FILES: "文件",
          CONTRIBUTOR: "贡献者",
          OTHERS1: "其他 1",
          OTHERS2: "其他 2",
          IMPORTANCE: "分类",
          FILE_LOCATION: "文件位置",
          FILE_LABEL: "文件标签",
          FILE_LOCATION_AND_LABEL: "文件标签和位置",
          COMMERCIAL_PRODUCT: "商业产品",
          BUSINESS_RELATED: "商业相关",
          COMMERCIAL_PRODUCT_AND_BUSINESS_RELATED: "商业产品和业务相关",
          RECIPIENTS: "资产通知",
          RECIPIENT_REMOVED: "通知已删除",
          ACKNOWLEDGED_ASSET: "确认资产",
          DISCLAIMER: "在此日期之前，活动日志数据详情不完整",
          SHARE_POINT_DISCLAIMER: "在此日期之后，由于无法访问该资产的位置，我们无法再访问该资产的活动日志",
          ASSET_CONNECTED: "资产关联",
          ASSET_DISCONNECTED: "资产已断开连接",
          ADDED_GROUP_WITH_ACCESS: "已添加到有权访问此资产的 {{GROUP}}",
          REMOVED_GROUP_ACCESS_REMAINS: "已从有权访问此资产的 {{GROUP}} 中移除",
          NO_LONGER_ACCESS: "不再有权访问此资产",
          BUSINESS_UNIT: "业务部门",
          PARTNER: "伙伴",
        },
        CREATED: "创建",
        EDITED: "已编辑",
        DELETED: "删除",
        ADDED: "已添加",
        BY: "由 {{NAME}",
        ERROR_STATE: "哎呀，我们暂时无法显示资产活动",
        TRY_RELOAD: "尝试重新加载页面",
        PREVIOUS_VALUE: "先前价值",
        UPDATED_VALUE: "最新价值",
        REMOVED: "已删除",
        AUTOMATIC_ADDED: "自动 - {{GROUP}} {{ACTION}}",
        AUTOMATIC_PARTNER_ADDED: "自动 - 合作伙伴 {{GROUP}} {{ACTION}}",
        STILL_HAS_ACCESS: "{{NAME}} 仍可访问此资产，因为该资产是通过其他原因共享的",
      },
      INVENTION_DISCLOSURE: {
        MODAL: {
          TITLE: "发明披露文本",
          DELETE_ITEM: "删除项目？",
          DELETE_ITEM_CONTENT: "删除此项目后，您将无法恢复或查看其内容，也无法查看其与关联的其他项目。",
          DONT_SHOW_AGAIN_DELETE: "删除项目时不要再向我显示此内容",
          EDIT_ITEM: "编辑项目？",
          EDIT_ITEM_CONTENT: "在编辑项目时，您将无法看到该项目以及关联的其他项目。",
          DONT_SHOW_AGAIN_EDIT: "编辑项目时不要再显示此内容",
          LEAVE_PAGE_TITLE: "离开页面？",
          UNSAVED_CHANGES: "您未保存的更改将丢失",
          LEAVE_PAGE_BUTTON: "离开页面",
        },
        TOOLTIP: {
          ASSOCIATIONS: "{{NUMBER}} 关联",
          ASSOCIATION: "{{NUMBER}} 关联",
          DRAG_TO_REORDER_CLAIMS: "拖动，重新排列权利要求",
        },
        TITLE: "发明披露中的权利要求和潜在商业机密关联",
        SEE_ORIGINAL_TEXT: "查看原文",
        PATENT_CLAIMS: "专利权利要求",
        POTENTIAL_TRADE_SECRETS: "潜在商业机密",
        SHORT_INPUT: "输入文本过短可能会影响搜索结果。请尝试搜索至少 {{OPTIMAL_WORDS}} 个字的文本",
        WRITE_YOUR_CLAIM_HERE: "在此写下您的权利要求",
        TABS: {
          CLAIMS: "权利要求",
          SPEC: "说明书",
          COMING_SOON: "马上来",
          ADD_CLAIM: "增加权利要求",
        },
        PLACEHOLDER: {
          WRITE_YOUR_CLAIM_HERE: "写下你的权利要求",
        },
      },
      UNDER_CONSTRUCTION_PAGE: {
        TITLE: "安全更新进行中",
        SUBTITLE: "服务暂时不可用",
      },
      IP_AUDIT: {
        HEADER: "快速启动",
        BUTTON_TEXT: {
          START_NOW: "立即开始",
          UPGRADE_TO_AUDIT: "升级解锁",
          VIEW: "查看",
          COMPLETED: "已完成",
        },
        TOAST: {
          ERROR: "失败，请重试",
        },
        COMING_SOON: "马上来",
        CONTACT_US: "联系我们",
        BANNER_TEXT: "采取这些 {{TOTAL}} 步骤来保护你的商业机密，并迅速确定你已经采取了合理的措施。",
        IP_AUDIT_STEPS: {
          STEP: "步骤 {{INDEX}}",
          STEP_OF: "步骤 {{INDEX}} 的 {{TOTAL}}",
          COMPLETED: "已完成",
          STANDARD_VERSION: "我们的标准版本",
          OVERVIEW: {
            NAME: "概述",
            TITLE: "{{Total}}将纳入合理措施类别的步骤",
            DESCRIPTION: "识别、评估和管理知识产权 (IPR)，确保其得到有效保护、合理利用，并与公司的业务战略保持一致。",
            COMPLETE_FLOW_BTN: "完整工作流",
            HR_PROCESS_DESCRIPTION: "人力资源是保护商业机密的第一道也是最后一道防线。",
            SYSTEM_SECURITY_DESCRIPTION: "这些系统对于防止数字入侵至关重要。",
            LLM_POLICY_DESCRIPTION: "LLM 和co-pilots在对您的宝贵信息进行保密时面临巨大风险。请在下面上传您的 LLM 政策。",
            TRADE_SECRET_POLICY_DESCRIPTION: "商业机密保护从这里开始--没有相关的政策，一切都只能靠运气。",
            KEY_ASSETS_SYSTEMS_DESCRIPTION: "我们可以快速帮助您创建商业机密组合。",
            CONTRACTS_DESCRIPTION: "您的保密协议和/或其他合同中是否有适当的保密条款？",
            PROGRESS_BAR_TEXT: "{{COMPLETED}} 完成",
          },
          TRADE_SECRET_POLICY: {
            NAME: "商业机密政策",
            DESCRIPTION: "商业机密保护从这里开始--没有相关的政策，一切都只能靠运气。 请在上传您的商业机密政策。",
            DOWNLOAD_POLICY: "您还没有商业机密政策？",
            NO_TRADE_SECRET_POLICY: "我没有商业机密政策",
            FALSE_COMPLETION: "制定商业秘密政策非常重要。商业秘密政策是基础 - 使其强大。",
          },
          SYSTEM_SECURITY: {
            NAME: "系统安全",
            SELECTION: "请选择贵组织正在使用的系统：",
            NONE_OF_THE_ABOVE: "以上都不是",
            DESCRIPTION: "这些系统对于防止数字入侵至关重要。",
            FALSE_COMPLETION: "系统安全平台对于守卫数字门户至关重要。确保你正在使用一个",
          },
          HR_PROCESS: {
            NAME: "人力资源流程",
            DESCRIPTION: "这些流程是保护商业机密的第一道防线，也是最后一道防线。",
            PROTOCOL: "贵组织是否将机密数据处理协议纳入员工入职和离职流程？",
            TRAINING: "您的员工是否接受过有关机密数据处理的培训？",
            FALSE_COMPLETION: "建立保护机密信息的公司文化。确保入职和培训到位",
          },
          LLM_POLICY: {
            NAME: "LLM政策",
            DESCRIPTION: "LLM 和co-pilots在对您的宝贵信息进行保密时面临巨大风险。请在下面上传您的 LLM 政策。",
            DOWNLOAD_POLICY: "您还没有LLM政策？",
            NO_LLM_POLICY: "我没有LLM政策",
            FALSE_COMPLETION: "制定 AI 政策非常重要。明确如何负责任地使用 LLM 和其他 AI 系统。",
          },
          CONTRACTS: {
            NAME: "合同",
            CONTRACT_XRAY: "合同分析器",
            CARD_TITLE: "不确定您的保密条款？",
            CARD_DESCRIPTION: "在此上传您的合同，并使用我们的合同分析器检查您的商业机密得到了怎么样的保护，以及是否所有重要的商业机密条款都得到了处理。",
            ANALYZE_CONTRACT: "分析合同文件",
            PROVISIONS: "您的保密协议和/或其他合同中是否有合适的保密条款？",
            FALSE_COMPLETION: "保密协议是保密讨论的必要第一步。它是保护您宝贵资产安全的关键",
          },
          KEY_ASSETS_SYSTEMS: {
            NAME: "主要记录系统",
            DESCRIPTION: "我们可以快速帮助您创建商业机密组合。",
            SELECTION: "选择贵组织正在使用的以下平台，我们将在 Tangibly 平台上为您创建资产：",
            CRM: "客户关系管理",
            FINANCIAL_SAAS: "金融软件即服务",
            KNOWLEDGE_MGMT_ELN: "知识管理/ELN",
            HRMS: "人力资源管理系统",
            QUALITY_MGMT_SYSYTEMS: "质量管理系统",
            COMPETITIVE_INTELLIGENCE: "竞争情报",
            VENDOR_RELATIONSHIP_MGMT: "供应商关系管理",
            ASSETS_CREATED_NOTIFICATION: "创建新资产。查看资产页面",
            CONFIDENTIAL_DATA: "机密数据",
            POPUP_HEADER: "我们正在创建您的新资产",
            POPUP_DESCRIPTION: "您将能够在资产页面中看到它们。",
            POPUP_SECONDARY_DESCRIPTION: "请不要关闭此浏览器选项卡。",
            FALSE_COMPLETION: "许多系统中都有机密信息——知道它们在哪里。",
          },
          KEY_ASSETS_PATENT_X_RAY: {
            NAME: "预测组合中的关键资产",
          },
          CLEAR_ANSWER: "明确的答案",
        },
        POPUP: {
          DISCARD_CHANGES: "丢弃未保存的更改？",
          UNSAVE_INFORMATION: "您未保存的信息将丢失。",
        },
      },
      SHARE_POINT_INTEGRATION: {
        SP_INTEGRATION_MODAL: {
          BODY_TEXT: "您的 Tangibly 账户可与 SharePoint 互通，直接在 SharePoint 上管理资产文件，并轻松与员工共享。",
          BODY_NOTE: "您可以从 Tangibly 的设置页面管理 SharePoint 列表。",
          HEADER_NOTE: "我们为您准备了新的列表！",
          CONNECT_NOW: "立即连接",
        },
        SP_INTEGRATION_BANNER: {
          TEXT: "SharePoint 整合",
        },
        ERROR: "无法连接到 SharePoint。请稍后重试。",
        LAST_SYNCED: "上次同步时间为 {{DATE}}",
        TOOLTIP: {
          SYNC: "同步",
        },
      },
      SHARE_POINT_WIZARD: {
        MAIN: {
          TITLE: "整合 Tangibly 和 SharePoint",
          SUBMIT_BUTTON: "整合",
          SUBMIT_BUTTON_EDIT: "发送确认并保存",
        },
        HOW_IT_WORKS: {
          TITLE: "如何使用",
          DESCRIPTION: "通过与 Sharepoint 的轻松整合，Tangibly 可帮助您快速发现、管理和保护技术以及商业机密。 文件历史记录、访问和活动都会直接导入 Tangibly，工作流的通知也会自动发送给接收者。 这是商业机密管理的终极最佳实践，可确保任何可访问信息的人都知道该信息被视为商业机密。",
          SECURITY: "安全",
          SECURITY_DESCRIPTION: "Tangibly 符合 SOC2 标准，并在安全协议和架构方面进行了大量投资。有关我们安全实践的更多详情，请参阅 “Tangibly 的安全”。",
          DATA_MANAGEMENT: "数据管理",
          DATA_MANAGEMENT_DESCRIPTION: "“通过整合 Sharepoint，我们将 Tangibly 的功能带给您，同时将您的商业机密保留在现有环境中。文件中的信息绝不会在 Tangibly 的基础架构中进行分析、处理、传输或存储。Tangibly 只跟踪元数据和活动。您仍可完全控制 Tangibly 在 SharePoint 环境中的访问级别。此外，您提供的凭证按照最高行业标准进行加密，Tangibly 员工或技术支持工程师永远无法看到，从而最大限度地确保您敏感资产的安全性和隐私性。 相关文件夹可作为整合的一部分添加。这些文件夹将作为资产（只有元数据，没有内容）添加到 Tangibly 中，此外还将显示可访问这些文件夹/文件的用户列表。”",
          ACTIVITY_LOG: "活动日志",
          ACTIVITY_LOG_DESCRIPTION: "监测 Sharepoint 环境中每个用户和文件的活动，确保商业机密和知识库的安全。",
          USERS_AND_MANAGEMENT: "用户和管理",
          USERS_AND_MANAGEMENT_DESCRIPTION: "用户会自动添加到 Tangibly，帮助公司随时更新 Sharepoint 上的用户列表，提示他们正在接触的文件/文件夹，并跟踪他们在 Sharepoint 上的活动。",
        },
        ALLOW_ACCESS: {
          TITLE: "允许访问",
          DESCRIPTION: "允许 Tangibly 访问您的 SharePoint 帐户,通过以下键入你的所在组织的SharePoint凭证。 您可以在 <strong>Microsoft Entra 管理中心</strong>中找到所需的信息。",
          EDIT_MODE_DESCRIPTION: "SharePoint凭证",
          LABEL: {
            FOLLOW_THE_STEP_BELOW: "<strong>请按照以下步骤操作：</strong>",
            OPEN_APPLICATIONS_MENU: "1. 在 Microsoft Entra 管理中心左侧菜单中输入 “应用程序”。",
            ACCESS_APP_REGISTRATIONS: "2. 输入 ”应用程序注册”",
            SELECT_OR_CREATE_APP: "3. 输入相关应用程序或创建新注册",
            APPLICATION_ID: "4.粘贴在下面的<strong>应用程序（客户端）ID </strong>",
            DIRECTORY_ID: "5. 粘贴到下面的<strong>目录（租户）ID </strong>",
            OPEN_CERTIFICATES_AND_SECRETS: "6. 转到 “认证和保密 ”页面",
            ADD_CLIENT_SECRET: "7. 添加新的客户机密并对其进行描述",
            CLIENT_SECRET: "8. 粘贴到下面的<strong>客户密值</strong>",
            API_PERMISSIONS_STEP: "9. 转到“API权限”",
            GRANT_PERMISSIONS: "10. 授予以下权限：<strong>Files.Read.All</strong>; <strong>GroupMember.Read.All</strong>; <strong>Sites.Read.All</strong>; <strong>User.Read.All</strong>; 完成此步骤后，选中下面的复选框。",
            LEARN_HOW_TO_DO_IT: "学习如何去做",
            CONFIRM_PERMISSIONS_GRANTED: "我已经授予了权限",
            MOCROSOFT_ADMIN_CENTER_URL: "转到 Microsoft 365 管理中心。",
            GRANTED_PERMISSIONS: "我已授予权限",
          },
          TOOLTIP: {
            APPLICATION_ID: "在“概览”页面复制“应用程序（客户端）ID”",
            DIRECTORY_ID: "在“概览”页面复制“目录（租户）ID”",
            CLIENT_SECRET: "在“证书和机密”页面复制“客户密值”。您可能需要创建一个新的客户机密 ，以便Tangibly集成能够复制它。",
          },
          RECOMMENDATION_BANNER: {
            SAVE_CLIENT_SECRET_NOTE: "我们建议将您客户密值的保存在一个可访问的地方，以便您以后可以在需要时找到它。",
            RECOMMENDATION_BANNER: "此步骤对于成功完成整合是必需的",
          },
          GRANT_MODAL: {
            HEADER: "授予的权限",
            SUB_HEADER: "按照以下步骤授予必要的许可：",
            STEP_1: "1. 点击“添加权限”",
            STEP_2: "2. 选择“Microsoft Graph”",
            STEP_3: "3. 选择“应用程序权限”",
            STEP_4: "4. 选择以下复选框：",
            STEP_5: "5. 选择“委托权限”",
            STEP_6: "6. 选中以下复选框：",
            STEP_7: "7. 点击“添加权限”",
            STEP_8: "8. 点击“授予组织名称管理许可”",
          },
          ERROR: "插入的一个或多个凭据无效。请检查并重新输入正确的信息。",
        },
        CONNECT_SITES_OR_FOLDERS: {
          TITLE: "连接网站和文件夹",
          ALLOW_TRACK_SITES_OR_FOLDERS: "允许Tangibly跟踪特定的网站或文件夹从您的SharePoint帐户。",
          INSERT_SITE_LINK_AND_FOLDERS: "插入SharePoint站点链接和文件夹位置（路径）：",
          LABEL: {
            SITE_LINK: "SharePoint网站链接",
            GIVE_ACCESS_TO: "允许访问：",
            FOLDER_LOCATION: "SharePoint文件夹位置",
          },
          RADIO_OPTIONS: {
            SPECIFIC_FOLDERS: "特定的文件夹",
            ALL_CONTENT: "网站内所有内容",
          },
          PLACEHOLDER: {
            SITE_LINK: "tangiblyinc.sharepoint.com/sites/Tangibly_site",
            FOLDER_LOCATION: "如 商业机密/Tangibly",
          },
          BUTTON: {
            ADD_SITE: "添加不同的站点",
            ADD_FOLDER: "添加文件夹",
          },
          TOOLTIP: {
            SITE_LINK: "复制网站的网址，同时在您的网站首页",
            FOLDER_LOCATION: "复制文件夹位置（路径）。使用斜杠（/）分隔目录。",
          },
          ERROR: {
            INVALID_SITE_LINK: "无效的SharePoint站点链接。",
            INVALID_SITE_OR_FOLDER: "无效的SharePoint站点/文件夹位置。确保使用斜杠（/）分隔目录。",
            FINDING_MEMBERS: "查找SharePoint成员出错",
            INVALID_WEBSITE: "您插入的网站可能无效。尝试插入另一个网站",
          },
        },
        ADD_USERS: {
          TITLE: "添加用户",
          HEADER: "我们在上一步插入的Sharepoint文件夹/站点中发现<strong> {{COUNT}}成员</strong>。Sharepoint成员将作为用户添加到Tangibly系统，以便获得您的文件活动日志和数据的记录。用户将出现在人员页面中。选择您想要创建资产的用户，并通过选择他们的状态为活动来查看所有分配的课程。",
          CURRENT_TANGIBLY_USERS: "当前Tangibly用户（{{COUNT}}）",
          DISPLAY_NAME: "显示名称",
          ROLE: "角色",
          STATUS: "状态",
          STATUS_TOOLTIP: "活跃帐户可以创建资产并查看所有分配的课程。非活动帐户只能是管理员共享的特定资产的一次性查看者。",
          NEW_USERS: "新用户（{{COUNT}}）",
          ACTIVE: "活跃",
        },
        SEND_ACKNOWLEDGEMENT: {
          TITLE: "发送确认",
          DESCRIPTION: "发送确认电子邮件是保证敏感文件安全的必要步骤。 确认电子邮件将发送到在上一步插入的文件夹/站点中找到的所有Sharepoint成员以及每个新成员。",
        },
        LEAVE_WIZARD: {
          TITLE: "离开SharePoint集成？",
          DESCRIPTION: "集成将不会完成。您可以从Tangibly的设置页面连接SharePoint集成。",
        },
        LOADER: {
          INTEGRATING: {
            HEADER: "整合Tangibly & SharePoint",
            HEADER_EDIT: "引进新资产",
            DESCRIPTION1: "这可能需要几分钟。",
            DESCRIPTION2: "请不要关闭此浏览器选项卡。",
          },
          MEMBERS: {
            HEADER: "查找SharePoint成员",
          },
        },
      },
      IDLE_HANDLER: {
        SESSION_ENDED: "档期结束了",
        SESSION_EXPIRED: "当前会话已过期，请重新登录。",
      },
      HOME_PAGE: {
        LOGIN: "登录",
        RECONNECT: "哎呀！我们需要将您重新连接到系统",
        GO_TO_HOMEPAGE: "转至主页",
        DISCOVER_AND_PROTECT: "发现、记录和保护商业秘密",
        OKTA_TEXT: "要访问您的帐户，请转到您的 Okta 仪表板并登录",
      },
      PARTNER_NOTIFY_MODAL: {
        TITLE: "资产通知",
        SUBTITLE: "<strong>将用户添加到合作伙伴/部门可能会影响对通知资产的访问。</strong>",
        NOTE: "<strong>注意：</strong>您可以从合作伙伴中移除此用户，但他/她以后可能会失去对这些资产的访问权限。",
        TABLE_HEADERS: {
          ASSET: "资产",
          TAGS: "标签",
          CREATION_DATE: "创建日期",
        },
      },
      FORMER_EMPLOYEES: {
        TITLE: {
          FORMER_EMPLOYEES: "前雇员",
        },
        SELECT: {
          EXIT_DATE: "退出日期",
        },
      },
      QUIZ_PAGE: {
        BUTTON: {
          GO_BACK_TO_COURSE: "返回课程",
          SUBMIT_QUIZ: "提交测验",
          COMPLETE_QUIZ: "完成测验",
        },
        EMPTY_STATE: {
          NO_QUIZ: "本课程仍没有测验。",
          STAY_TUNED: "敬请关注！",
        },
        THANK_YOU_FOR_PARTICIPATING: "感谢您的参与！您答对了 {{USER_SCORE}}，但学习不会停止！在完成课程之前，请查看正确答案。",
        I_HAVE_REVIEWED_THE_ANSWERS: "我已经检查了正确答案",
      },
    },
  };