import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormerTableData } from '../Components/TableData/TableData';
import useLocalStorage from '_hooks/useLocalStorage';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { getRoleNameEmployee, isUserIsRole } from 'utils/roles';
import { mapFieldNameToServer } from '../utils/tableUtils';
import { dispatchGetUsersByType } from '../../../utils/customHooks';
import { CREATED_FROM } from '../../../utils/enums';
import { ACCOUNT_TYPE } from '../../../utils/enums';
import { getFormerEmpColumns } from 'utils/formerEmplyeesUtils';

export const useFormerEmployees = () => {
  const { clientId } = useParams();
  const { tablesLoaders } = useSelector((state) => state.client);
  const {
    tablesData: { formerEmployees },
  } = useSelector((state) => state.client);
  const permissions = useSelector((state) => state.authentication.permissions);
  const activeAccount = useSelector((state) => state.authentication.activeAccount);
  const { loading: clientSettingsLoading } = useSelector((state) => state.clientSettings);
  const filter = useSelector((state) => state.users.peoplePage.filters.selectedOptions);
  const searchValue = useSelector((state) => state.users.peoplePage.filters.searchValue);

  const initialPagination = { page: 1, limit: 10 };

  const [employeeData, setEmployeeData] = useState([]);
  const [sortState, setSortState] = useLocalStorage('tableSorts', {});
  const [activeSelectorEmployee, setActiveSelectorEmployee] = useState(() => ({
    selector: sortState.employee?.field || '',
    direction: sortState.employee?.direction || '',
  }));

  const [empPagination, setEmpPagination] = useState(initialPagination);
  const [view, setView] = useState({ show: false, type: '', mode: 'add' });

  const isUserIsLearnType = useMemo(() => isUserIsRole(activeAccount, 'Learn'), [activeAccount]);
  const roleNameEmployee = useMemo(() => getRoleNameEmployee(isUserIsLearnType), [isUserIsLearnType]);

  const isFilterApplied = useMemo(() => {
    const anyFilterApplied = Object.values(filter).some((valuesArray) => valuesArray.length > 0);
    return anyFilterApplied || searchValue !== '';
  }, [filter, searchValue]);

  const permissionEditEmployee = useMemo(() => permissions.Employeeedit || permissions.Contractoredit || permissions.Learnemployeeedit, [permissions]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columns4 = getFormerEmpColumns(activeSelectorEmployee);

  const isCreatedFromSharePoint = (value) => value.createdFrom === CREATED_FROM.SHARE_POINT;

  useEffect(() => {
    dispatchGetUsersByType(dispatch, roleNameEmployee, clientId, 'FormerEmployees', { ...empPagination, search: searchValue }, filter, sortState.employee);
  }, [empPagination, searchValue, filter, sortState.employee, dispatch]);

  const [hoveredEmployeeId, setHoveredEmployeeId] = useState(null);

  useEffect(() => {
    const processedEmployees = FormerTableData({
      data: formerEmployees?.data,
      handleViewData,
      setHoveredId: setHoveredEmployeeId,
      isCreatedFromSharePoint,
      type: ACCOUNT_TYPE.FORMER_EMPLOYEES,
      t,
    });
    setEmployeeData(processedEmployees);
  }, [formerEmployees, t, filter, hoveredEmployeeId]);

  const handleSortEmployee = (column, sortDirection) => {
    setActiveSelectorEmployee({
      selector: column.fieldName,
      direction: sortDirection,
    });
    const field = mapFieldNameToServer(column.fieldName);

    const sort = [field, ':', sortDirection].join('');
    setEmpPagination({ ...empPagination, sortBy: sort });
    setSortState({
      ...sortState,
      employee: { field, direction: sortDirection },
    });
  };

  const handleViewData = (data, type, mode) => {
    dispatch(usersActions.setViewEditAccount(data));
    setView({ show: true, type, mode });
  };

  const handleClearSearch = useCallback(() => {
    dispatch(usersActions.clearSearchQuery());
  }, [dispatch]);

  const handleResetView = useCallback(() => {
    setView({ show: false, type: '', mode: 'add' });
    dispatch(usersActions.resetViewEditAccount());
  }, []);

  return {
    setEmpPagination,
    handleSortEmployee,
    handleClearSearch,
    permissions,
    columns4,
    view,
    empPagination,
    employeeData,
    tablesLoaders,
    isFilterApplied,
    clientSettingsLoading,
    filter,
    permissionEditEmployee,
    handleResetView,
  };
};
