import { FlexRow } from "_components/AssetRecipients/style";
import styled from "styled-components";
import { SPACING } from "utils/theme";

export const ThirdPartyContainer = styled.div`
    display: flex;
    padding: 0 0 0 ${SPACING[1.5]};
`

export const RowContainer = styled(FlexRow)`
    gap: ${SPACING[1]};
`;