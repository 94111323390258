import { IRoles } from 'utils/roles';
import { z } from 'zod';

export const folderSchema = z.object({
  path: z.string().min(1, { message: 'Required' }),
  isExist: z.boolean().optional(),
});

export const siteSchema = z.discriminatedUnion('includeContent', [
  z.object({
    siteLink: z.string().min(1, { message: 'Required' }),
    isSiteExist: z.boolean().optional(),
    includeContent: z.literal('specificFolders'),
    folders: z.array(folderSchema).min(1, { message: 'You must add at least one folder.' }),
  }),
  z.object({
    siteLink: z.string().min(1, { message: 'Required' }),
    isSiteExist: z.boolean().optional(),
    includeContent: z.literal('allContent'),
    folders: z.array(folderSchema).length(0),
  }),
]);

const initialSiteSchema = z.object({
  siteLink: z.string().min(1, { message: 'Required' }),
  includeContent: z.union([z.literal('specificFolders'), z.literal('allContent')]).default('allContent'), // Set default to 'allContent'
  folders: z.array(folderSchema).length(0),
});

export const userSchema = z.object({
  email: z.string(),
  statusActive: z.boolean(),
  displayName: z.string().min(1, { message: 'Display name is required' }),
  role: z.object({
    value: z.nativeEnum(IRoles),
    label: z.string(),
  }),
  externalUser: z.boolean().optional(),
});

// -------------------- Root schema --------------------
export const schema = z.object({
  applicationId: z.string().min(1, { message: 'Required' }),
  directoryId: z.string().min(1, { message: 'Required' }),
  clientSecret: z.string().min(1, { message: 'Required' }),
  isGrantedPermission: z.boolean().refine((value) => value === true, { message: 'You must grant permission.' }),
  sites: z.array(z.union([initialSiteSchema, siteSchema])).min(1, { message: 'You must add at least one site.' }),
  users: z.array(userSchema).min(0),
});
// ------------------------------------------------------

export const secretsSchema = schema.pick({
  applicationId: true,
  directoryId: true,
  clientSecret: true,
}).partial({ clientSecret: true }); // Allows clientSecret to be optional
