import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { authenticate } from 'redux-toolkit/thunks/authThunks';
import { useAppDispatch } from '_helpers';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { Loader } from '../Loader';
import { useLoggedInRedirect } from '../../utils/customHooks';
import { AppRoutes } from 'routing/consts';
import { writeLogToSentry } from 'utils/sentryLogs';

function getCodeFromQuery(url) {
  return url.split('=')[1];
}

export const OktaCallback = () => {
  const { getTokensFromCode, authenticateOkta } = useContext(AuthContext);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const code = getCodeFromQuery(location.search);

  const [canRedirect, setCanRedirect] = useState(false);
  useLoggedInRedirect(true, canRedirect);

  useEffect(() => {
    (async () => {
      if (code) {
        try {
          const tokens = await getTokensFromCode(code);
          const idToken = await authenticateOkta(tokens);
          const result = await dispatch(authenticate({ idToken }));
          const authRes = unwrapResult(result);
          if (!authRes) {
            const message = 'OktaCallback: Error in /authenticate. User or account is null or undefined.';
            writeLogToSentry({ message });
            history.push(AppRoutes.MAIN);
          }
          setCanRedirect(true);
        } catch (error) {
          writeLogToSentry({ message: `OktaCallback: Authentication failed: ${error}` });
          history.push(AppRoutes.MAIN);
        }
      } else {
        history.push(AppRoutes.MAIN);
      }
    })();
  }, [code]);

  return <Loader isLoading />;
};
