import { FlexRow } from '_components/AssetRecipients/style';
import { TangiTypography } from '_components/TangiLibrary';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import { useTranslation } from 'react-i18next';

interface AssetActivityPartnerLabelProps {
  partnershipName: string;
  partnershipLogo?: string;
  groupName: string | undefined;
  groupTypeDisplay: string | undefined;
  numOfRecipients?: number; 
  size?: string,
}

const AssetActivityPartnerLabel = ({
  partnershipName,
  partnershipLogo,
  groupName,
  groupTypeDisplay,
  numOfRecipients,
  size,
}: AssetActivityPartnerLabelProps) => {
  const { t } = useTranslation();
  
  return (
    <FlexRow>
      <PartnershipOrClientLogo
        isPartnership
        name={partnershipName}
        logo={partnershipLogo}
        size={size}
      />
      <TangiTypography type="subheading" weight="bold">
        {`${groupName} ${numOfRecipients ? `(${numOfRecipients})` : ''} - ${groupTypeDisplay} ${t('ACTIVITY_LOG.ASSET.IN')} ${partnershipName}`}
      </TangiTypography>
    </FlexRow>
  );
};

export default AssetActivityPartnerLabel;
