import { SharedRecipient, GroupedRecipientsCategoryData } from 'utils/types/assets/assets';
import { ToastProps } from 'utils/types/types';

export type ToastDataHandler = (data: ToastProps) => void;

export enum DistributeParentComponent {
  AssetInnerPage = 'asset',
  ClientAssets = 'client_assets',
}

export interface GetTotalRecipientsCountInputs {
  recipients?: SharedRecipient[];
  partners?: GroupedRecipientsCategoryData[];
  departments?: GroupedRecipientsCategoryData[];
  businessUnits?: GroupedRecipientsCategoryData[];
  partnersDepartments?: GroupedRecipientsCategoryData[];
}
