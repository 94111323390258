import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AssetsSkeleton from './components/AssetsSkeleton/AssetsSkeleton';
import AssetCardActive from './components/AssetCardActive/AssetCardActive';
import AssetCardLocked from './components/AssetCardLocked/AssetCardLocked';
import AssetCardsEmptyState from './components/AssetsEmptyState/AssetCardsEmptyState';
import { SharePointStatusEnums } from 'utils/types/assets/assets';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { SPACING } from 'utils/theme';

export const AssetCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-auto-rows: minmax(232px, auto);
  column-gap: ${SPACING[5]};
  row-gap: ${SPACING[4]};
  a {
    color: inherit;
  }
`;

export const ClientAssetCards = ({ assets, handleDistributeAsset, loading, assetOptions, handleUnlockAsset, isAdmin, updateSelectedAssets }) => {
  const permissions = useSelector((state) => state.authentication.permissions);
  const selectedAssets = useSelector((state) => state.asset.selectedAssets);
  const isSomeAssetsSelected = !!selectedAssets?.length;

  if (loading) {
    return <AssetsSkeleton />;
  }

  if (!assets?.data?.length) {
    return <AssetCardsEmptyState />;
  }

  const isAttachmentSharePointExist = (asset) => asset?.sharePoint?.status === SharePointStatusEnums.DELETED;

  const isAssetLocked = (asset) => asset?.needsToBeAcknowledgedByMe || (asset?.isThirdParty && !asset?.hasAccessToThirdPartyAsset) || isAttachmentSharePointExist(asset);

  return (
    <AssetCardsContainer data-testid="assets">
      {assets.data.map((asset) => (
        <React.Fragment key={asset.id}>
          {isAssetLocked(asset) ? (
            <AssetCardLocked asset={asset} handleUnlockAsset={handleUnlockAsset} />
          ) : (
            <>
              {permissions?.Assetview && !isSomeAssetsSelected ? (
                <NavLink to={{ pathname: generateRoute(AppRoutes.ASSET_INNER_PAGE, { clientId: asset.client._id, assetId: asset.id }) }}>
                  <AssetCardActive
                    asset={asset}
                    isAdmin={isAdmin}
                    permissions={permissions}
                    handleDistributeAsset={handleDistributeAsset}
                    options={assetOptions}
                    updateSelectedAssets={updateSelectedAssets}
                  />
                </NavLink>
              ) : (
                <AssetCardActive
                  asset={asset}
                  isAdmin={isAdmin}
                  permissions={permissions}
                  handleDistributeAsset={handleDistributeAsset}
                  options={assetOptions}
                  updateSelectedAssets={updateSelectedAssets}
                />
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </AssetCardsContainer>
  );
};
