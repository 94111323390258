import { TangiTypography } from '_components/TangiLibrary/TangiTypography';
import { AVATAR_SIZES, NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { GroupType } from '../types';
import { usePartnership } from '_hooks/usePartnership';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import { GroupedRecipientsAssetData, RecipientCategoryType } from 'utils/types/assets/assets';
import { FlexContainer } from 'utils/globalStyles';
import styled from 'styled-components';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { ThirdPartyTag } from '_components/AssetRecipients/_components/ThirdPartyTag';
import { useTranslation } from 'react-i18next';
import { EllipsisTypography } from 'pages/People/Components/MetaDataListDisplay/MetaDataListDisplay';
import { useMemo } from 'react';
import { ThirdPartyContainer } from './styles';

interface Props {
  groupId: string;
  groupName: string;
  groupType: GroupType;
  recipientsNum: number;
  additionalInfo?: GroupedRecipientsAssetData;
}

export const RowContainer = styled(FlexContainer)`
  gap: ${SPACING[1]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GroupDisplay = ({ groupId, recipientsNum, groupType, groupName, additionalInfo }: Props) => {
  const partnership = usePartnership(groupId);
  const { t } = useTranslation();

  const isDepartment = useMemo(() => groupType === RecipientCategoryType.PARTNER_DEPARTMENT || groupType === RecipientCategoryType.DEPARTMENT, [groupType]);
  const isBusinessUnit = useMemo(() => groupType === RecipientCategoryType.BUSINESS_UNIT, [groupType]);
  const isPartner = useMemo(() => groupType === RecipientCategoryType.PARTNER_DEPARTMENT || groupType === RecipientCategoryType.PARTNER, [groupType]);

  const getDepartmentTippyText = () => {
    switch(groupType){
      case RecipientCategoryType.PARTNER_DEPARTMENT:
        return `${t('ACTIVITY_LOG.ASSET.PARTNER')} - ${additionalInfo?.name ?? ''}`;
      case RecipientCategoryType.PARTNER:
        return `${t('ACTIVITY_LOG.ASSET.PARTNER')} - ${groupName}`;
      default:
        return '';
    }
  };

  return (
    <RowContainer>
      {groupType === RecipientCategoryType.PARTNER && partnership && (
        <PartnershipOrClientLogo isPartnership={true} name={partnership.name} logo={partnership.logo} size={partnership?.logo && AVATAR_SIZES.XS} />
      )}

      {(isDepartment || isBusinessUnit)  ? (
        <>
          <TangiSvgIcon component="department" color={NEUTRAL_SHADES.WHITE} />
          <EllipsisTypography color={NEUTRAL_SHADES.BLACK} weight="semibold">{`${groupName}  - `}</EllipsisTypography>
          <TangiTypography color={NEUTRAL_SHADES.BLACK} weight="regular">{`${isDepartment ?  t('ASSET_PAGE.DEPARTMENT') : t('ASSET_PAGE.BUSINESS_UNIT')}`}</TangiTypography>
        </>
      ) : (
        <TangiTypography color={NEUTRAL_SHADES.BLACK} weight="semibold">{`${groupName}`}</TangiTypography>
      )}
      <TangiTypography type="subheading" weight="regular" color={NEUTRAL_SHADES[800]}>
        • {recipientsNum}
      </TangiTypography>

      {isPartner && (
        <ThirdPartyContainer>
          <ThirdPartyTag tooltipText={getDepartmentTippyText()}/>
        </ThirdPartyContainer>
      )}
    </RowContainer>
  );
};
