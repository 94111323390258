import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { ExcelRenderer } from 'react-excel-renderer';
import Dropzone from 'react-dropzone';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch } from '_helpers';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { batchEmployees as batchAndCreateEmployees, validateEmployees, generateBatchEmployeesPresignedUrlAndUploadToS3 } from '../../redux-toolkit/thunks/usersThunks';
import { TangiIconButton } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { MIME_TYPES } from '../../utils/enums';
import { excelExporters } from '../../utils/excelExporters';
import uploadLogo from '../../assets/img/CloudUpload.png';
import { ErrorMessage, MessagesTitle, DownloadButton, ErrorContainer, DropZoneContainer, ModalContainer } from './style';

const EmployeesValidationModal = ({ handleClose, clientId, isLearn }) => {
  const acceptedFilesList = `${MIME_TYPES.XLS}, ${MIME_TYPES.XLSX}`;

  const metaDataState = useSelector((state) => state.assetMetaData);
  const settings = useSelector((state) => state.clientSettings.settings);
  const agreementVersions = useSelector((state) => state.client.agreementVersions);
  const { batchErrors, batchEmployees, existsEmployees, validatedBatch, uploadedBatchEmployees, batchLoading } = useSelector((state) => state.users);

  const [progress, setProgress] = useState(0);
  const [noData, SetNoData] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (uploadedBatchEmployees.length && !batchLoading) {
      handleClose();
    }
  }, [uploadedBatchEmployees, batchLoading]);

  useEffect(() => {
    if (batchEmployees.length === 0) {
      if (validatedBatch) SetNoData(true);
      setProgress(0);
    } else {
      checkUploadProgress();
    }
  }, [batchEmployees]);

  const uploadData = async () => {
    const fileKey = uuidv4();
    try {
      const result = await dispatch(generateBatchEmployeesPresignedUrlAndUploadToS3({ fileKey, employeesJson: batchEmployees }));
      unwrapResult(result);
    } catch (error) {
      return null;
    }

    dispatch(
      batchAndCreateEmployees({
        clientId,
        isLearn,
        fileKey,
      }),
    );

    setProgress(80);
  };

  const checkUploadProgress = () => {
    if (batchErrors.length === 0) {
      setProgress(80);
    } else {
      setProgress(50);
    }
  };
  const checkUploadStatus = () => {
    if (batchEmployees?.length) {
      switch (batchErrors?.length) {
        case 0:
          if (!batchLoading) return 'EmployeesSuccess';
          return 'UploadingEmployees';
        default:
          if (!batchLoading) return 'EmployeesWithErrors';
          break;
      }
    }
    return '';
  };
  const exportExcelEmployees = () => {
    SetNoData(false);
    excelExporters.exportEmployeeTemplate(metaDataState, settings.agreements, isLearn, agreementVersions);
  };
  const onDrop = useCallback((acceptedFiles) => {
    SetNoData(false);
    const Employees = new Blob([acceptedFiles[0]]);
    ExcelRenderer(Employees, async (err, resp) => {
      const employeesRows = resp.rows
        .slice(1)
        .map((row) => row.slice(0, 23))
        .filter((data) => data.find((value) => value));

      const fileKey = uuidv4();
      try {
        const result = await dispatch(generateBatchEmployeesPresignedUrlAndUploadToS3({ fileKey, employeesJson: employeesRows }));
        unwrapResult(result);
      } catch (error) {
        return null;
      }

      dispatch(
        validateEmployees({
          clientId,
          isLearn,
          fileKey,
        }),
      );
    });
  });
  const resetBatch = () => {
    SetNoData(false);
    dispatch(usersActions.setResetBatchEmployees());
    setProgress(0);
  };

  return (
    <ModalContainer>
      {/* temporary fix with inline styles */}
      <div className="pt-2 mb-2" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <div>
          <h2 className="ff-din-DemiBold ">{t('PEOPLE.BATCH_UPLOAD.UPLOAD_MULTIPLE_ASSETS')}</h2>
        </div>
        <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} disabled={batchLoading && batchEmployees?.length > 0 && batchErrors?.length === 0} onClick={handleClose} />
      </div>
      <ProgressBar now={progress} />
      <br />
      {batchEmployees?.length > 0 ? (
        <MessagesTitle data-testid="empBatchMessage" valid={batchErrors?.length === 0}>
          {batchErrors?.length > 0 ? t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.THERE_ARE', { batchErrors: batchErrors?.length }) : t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.NO_ERRORS_FOUND')}{' '}
        </MessagesTitle>
      ) : noData && !batchLoading ? (
        <ErrorMessage>{t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.NO_EMPLOYEES_UPLOADED')}</ErrorMessage>
      ) : (
        <div className="d-flex justify-content-end">
          <DownloadButton type="button" className="btn btn-link" onClick={() => exportExcelEmployees()}>
            {t('PEOPLE.DROPDOWN.DOWNLOAD_EXCEL')}
          </DownloadButton>
        </div>
      )}
      <Dropzone accept={acceptedFilesList} onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div>
            {!isDragActive && !batchLoading && batchErrors?.length === 0 && batchEmployees?.length === 0 && (
              <DropZoneContainer {...getRootProps()} className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-3 dropZone">
                <input {...getInputProps()} />
                <span>
                  <img src={uploadLogo} alt="upload" />
                  <br />
                  {t('PEOPLE.BATCH_UPLOAD.DRAG_AND_DROP')}
                </span>
              </DropZoneContainer>
            )}
            {isDragActive && !isDragReject && (
              <div {...getRootProps()} className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-3 dropZone" style={{ cursor: 'pointer' }}>
                <input {...getInputProps()} />
                <span>
                  <i aria-hidden="true" className="fas fa-upload me-2" />
                  {t('PEOPLE.BATCH_UPLOAD.DROP_THE_FILES')}
                </span>
              </div>
            )}
            {isDragActive && isDragReject && (
              <div {...getRootProps()} className="container w-100 bg-danger p-5 text-center rounded text-white mt-3 dropZone" style={{ cursor: 'pointer' }}>
                <input {...getInputProps()} />
                <span>{t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.UPLOAD_ONLY_ACCEPTED_FORMATS')}</span>
              </div>
            )}
            {checkUploadStatus() === 'EmployeesWithErrors' && (
              <ErrorContainer data-testid="empErrorsContainer" {...getRootProps()} className="container w-100 bg-secondary p-3 text-center rounded text-muted dropZone">
                {batchErrors.map((error, index) => {
                  return (
                    <ErrorMessage key={index}>
                      {t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.ROW')} {error.row}: [{error.field}] {error.message}
                    </ErrorMessage>
                  );
                })}
              </ErrorContainer>
            )}
            {checkUploadStatus() === 'EmployeesSuccess' && (
              <DropZoneContainer {...getRootProps()} className="container w-100 bg-secondary p-3 text-center rounded text-muted dropZone">
                <ErrorMessage data-testid="empSuccessContainer">
                  {batchEmployees.length - existsEmployees.length} {t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.EMPLOYEES_ADDED_TO_TANGIBLY')}
                  {existsEmployees.length} {t('PEOPLE.BATCH_UPLOAD.ERROR_MESSAGE.EMPLOYEES_UPDATED')}
                </ErrorMessage>
              </DropZoneContainer>
            )}
            {batchLoading && (
              <DropZoneContainer {...getRootProps()} className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-3 dropZone">
                <div className="d-flex flex-column align-items-center">
                  <Spinner animation="border" variant="primary" />
                  <br />
                  {checkUploadStatus() === 'UploadingEmployees' ? <div>{t('PEOPLE.BATCH_UPLOAD.DONT_CLOSE_WINDOW')}</div> : <div>{t('PEOPLE.BATCH_UPLOAD.VALIDATING_EMPLOYEES')}</div>}
                </div>
              </DropZoneContainer>
            )}
          </div>
        )}
      </Dropzone>
      <br />
      <div className="d-flex justify-content-end">
        <button
          className="mym-4 trans-btn btn btn-lg pxp-5 btn-primary btn-pad text-white"
          hidden={!(batchEmployees?.length > 0 && batchErrors?.length > 0) && !(noData && !batchLoading && batchEmployees.length === 0)}
          onClick={resetBatch}
          type="submit"
        >
          {t('PEOPLE.BATCH_UPLOAD.BUTTONS_TEXT.UPLOAD_NEW_FILE')}
        </button>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="mym-4 trans-btn btn btn-lg pxp-5 btn-primary btn-pad text-white"
          hidden={(batchEmployees?.length > 0 && batchErrors?.length > 0) || (noData && batchEmployees.length === 0)}
          disabled={!(batchErrors?.length === 0 && batchEmployees?.length > 0) || (batchLoading && batchEmployees?.length > 0 && batchErrors?.length === 0)}
          onClick={uploadedBatchEmployees.length > 0 ? () => handleClose() : () => uploadData()}
          type="submit"
        >
          {t('PEOPLE.BATCH_UPLOAD.BUTTONS_TEXT.CONFIRM_UPLOAD')}
        </button>
      </div>
    </ModalContainer>
  );
};
export default EmployeesValidationModal;
