export const getAssetInnerPageMetaDataValues = (assetInnerPageMetaData) => {
  const departments = assetInnerPageMetaData.departments.filter(department => !!department).map((department) => ({
    value: department.id,
    label: department.name,
  }));
  const projects = assetInnerPageMetaData.projects.filter(project => !!project).map((project) => ({
    value: project.id,
    label: project.name,
  }));
  const businessValues = assetInnerPageMetaData.businessValues.filter(businessValue => !!businessValue).map((businessValue) => ({
    value: businessValue.id,
    label: businessValue.name,
  }));

  return { departments, projects, businessValues };
};

export const getAssetContributorsValues = (contributors) => {
  return contributors.filter(contributor => !!contributor).map((contributor) => ({
    value: contributor.id,
    label: contributor.displayName,
  }));
};

export const isArrayValid = (arr, type) => {
  return Array.isArray(arr) && arr.length > 0 && arr.every(item => typeof item === type);
};

