import { writeLogToSentry } from "./sentryLogs";

/**
 * Loads state data from local storage using the specified key.
 * @param {string} key - The key used to retrieve data from local storage.
 * @returns {object | null} The loaded state data or null if an error occurs.
 */
export const loadStateFromLocalStorage = (key: string) => {
  try {
    const serializedState = JSON.parse(localStorage?.getItem(key) || 'null');
    return serializedState;
  } catch (err) {
    writeLogToSentry({ message: `loadStateFromLocalStorage key: ${key} err: ${err}`, level: 'error' });
    console.log(`There was an error getting the key ${key} from local storage`);
    return null;
  }
};

/**
 * Saves state data to local storage using the specified key.
 * @param {string} key - The key to store the data under in local storage.
 * @param {unknown} state - The state data to be stored.
 */
export const saveStateToLocalStorage = (key: string, state: unknown) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch(err) {
    writeLogToSentry({ message: `saveStateToLocalStorage key: ${key} err: ${err}`, level: 'error' });
    console.log(`Could not save data with the key ${key} to local storage`);
  }
};

/**
 * Removes state data from local storage using the specified key.
 * @param {string} key - The key of the data to be removed from local storage.
 */
export const removeStateFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch {
    console.log(`There was a problem removing ${key} from local storage`);
  }
};
