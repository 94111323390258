import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const AgreementContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const FormSkeletonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: ${SPACING[3]};
`;

export const ViewEmployeeSkeletonContainer = styled(FormSkeletonContainer)`
  display: block;
`;
