/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import FormData from 'form-data';
import {
  Asset,
  Assets,
  GetAllAssetsClientParams,
  GetAssets,
  GetPrivilegeLogData,
  PrivilegeLogAsset,
  FilePreviewRequest,
  GetAssetAuditLog,
  AllAssetsFilesResponse,
  DeleteDecryptedFileFromRequest,
  CheckShareStatusResponse,
  ShareMultipleAssetsResponse,
  GetAssetsGroupedRecipientsBody,
  GetAssetsGroupedRecipientsResponse,
  ShareMultipleAssetsRequestBody,
  GetRecipientsResponse,
} from 'utils/types/assets/assets';
import { getFilterObj } from '_services/utils/getFilterObj';
import { headers, headersmultipart, extractLanguageAndClientIdByRole, tokenHeaders } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { LANGUAGE } from 'translations/enums';
import { ActivityLogResponse } from 'utils/types/activityLog/asset/types';
import { ENTITY_TYPES, UPLOAD_FILE_TYPES } from 'utils/enums';
import { KeyAssetsFromSystemData } from '_components/IpAudit/types';
import { AssetForAcknowledgement, PreviewToken } from 'utils/types/assets/acknowledgementAsset';

class AssetsAdapter {
  static baseEndpoint = `${config.apiUrl}${config.apiVersion}`;

  static assetsEndpoint = `${config.apiUrl}${config.apiVersion}/assets`;

  static assetEndpoint = `${config.apiUrl}${config.apiVersion}/asset`;

  static fileEndpoint = `${config.apiUrl}${config.apiVersion}/file`;
  static auditLogEndpoint = `${config.apiUrl}${config.apiVersion}/audit_log`;

  customHeaders = (token: string) => {
    if (token) {
      const header = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      } as AxiosRequestHeaders;
      return header;
    } else {
      return headers();
    }
  };

  async getAssetsById(assetId: string) {
    const res = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}`, {}, { headers: headers(), params: { id: assetId } });
    return res;
  }

  async getAssets({ sortValue, searchValue, page, limit, filter }: GetAssets): Promise<Assets> {
    const filterObj = getFilterObj(filter) ?? {};
    const params = {
      sortBy: sortValue,
      page: page,
      limit: limit,
      search: '',
    };
    if (searchValue) {
      params.search = searchValue.trim();
    }
    const res: AxiosResponse<Assets> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}`, filterObj, { headers: headers(), params });
    return res.data;
  }

  async getIpAuditAssets(): Promise<Assets> {
    const res: AxiosResponse<Assets> = await HttpClientService.get(`${AssetsAdapter.assetsEndpoint}/ip-audit`, { headers: headers() });
    return res.data;
  }

  async getPrivilegeLogData({ sortValue, searchValue, filter }: GetPrivilegeLogData): Promise<PrivilegeLogAsset[]> {
    const filterObj = getFilterObj(filter) ?? {};
    const params = {
      sortBy: sortValue,
      search: '',
    };
    if (searchValue) {
      params.search = searchValue.trim();
    }
    const res: AxiosResponse<PrivilegeLogAsset[]> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/privilege_log`, filterObj, { headers: headers(), params });
    return res.data;
  }

  async createAsset(assetData: any): Promise<Asset> {
    // eslint-disable-next-line no-unused-expressions
    assetData.files && (assetData.uploadFiles = assetData.files);
    delete assetData.files;
    const res: AxiosResponse<Asset> = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}`, assetData, { headers: headers() });
    return res.data;
  }

  async shareContributors(contributorsData: any) {
    const res = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/shareContributors`, contributorsData, { headers: headers() });
    return res.data;
  }

  async uploadFiles(assetData: any, createdAssetId: string) {
    const formData = new FormData();
    const filesToUpload = assetData.uploadFiles;
    filesToUpload.forEach((fileToUpload: any, index: string) => {
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append(index, fileToUpload.name);
    });
    formData.append('entityId', createdAssetId);

    const params = { client: assetData.client, type: UPLOAD_FILE_TYPES.ASSETS, id: createdAssetId, entityType: ENTITY_TYPES.ASSET };

    const res = await HttpClientService.post(`${AssetsAdapter.fileEndpoint}`, formData, {
      headers: headersmultipart(),
      params,
    });
    return res;
  }

  async validateAssets(assets: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/validate`, assets, { headers: headers() });
    return res.data;
  }

  async createAssets(assets: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/create_multiple`, assets, { headers: headers() });
    return res.data;
  }

  async createAssetsIpAudit(payload: KeyAssetsFromSystemData): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/create_multiple_ip_audit`, payload, { headers: headers() });
    return res.data;
  }

  async updateAsset(assetData: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.put(`${AssetsAdapter.assetEndpoint}/${assetData.id}`, assetData, { headers: headers() });
    return res.data;
  }

  async uploadUpdateFiles(assetData: any) {
    const formData = new FormData();
    const filesToUpload = assetData.newfiles;
    filesToUpload.forEach((fileToUpload: any, index: string) => {
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append(index, fileToUpload.name);
    });
    formData.append('entityId', assetData.id);

    const params = { client: assetData.client, type: UPLOAD_FILE_TYPES.ASSETS, id: assetData.id, entityType: ENTITY_TYPES.ASSET };

    const res = await HttpClientService.post(`${AssetsAdapter.fileEndpoint}`, formData, {
      headers: headersmultipart(),
      params,
    });
    return res;
  }

  async getFileUrl(data: FilePreviewRequest, token: string, isPreview: boolean) {
    const header = isPreview
      ? {
          'Content-Type': 'application/json',
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        }
      : this.customHeaders(token);

    const urlForFile = isPreview ? '/file/path' : '/file/authenticated/path';

    const { uniqueFileKey, assetId } = data;

    const res = await HttpClientService.get(`${AssetsAdapter.baseEndpoint}${urlForFile}`, {
      headers: header,
      params: { uniqueFileKey, type: UPLOAD_FILE_TYPES.ASSETS, entityType: ENTITY_TYPES.ASSET, id: assetId },
    });
    return res.data;
  }

  async deleteAssetFilePreview(data: DeleteDecryptedFileFromRequest, token: string, isAuthenticated: boolean) {
    const header = isAuthenticated
      ? this.customHeaders(token)
      : {
          'Content-Type': 'application/json',
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        };

    const { fileKeyWithExtension, assetId } = data;

    const res = await HttpClientService.delete(`${AssetsAdapter.fileEndpoint}/${isAuthenticated ? 'authenticated/delete' : 'delete'}`, {
      headers: header,
      params: { fileKey: fileKeyWithExtension, type: UPLOAD_FILE_TYPES.ASSETS, id: assetId },
    });

    return res.data;
  }

  async shareMultipleAssets(body: ShareMultipleAssetsRequestBody): Promise<ShareMultipleAssetsResponse> {
    const res: AxiosResponse<ShareMultipleAssetsResponse> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/recipient`, body, { headers: headers() });
    return res.data;
  }

  async checkShareStatus(jobId: string): Promise<CheckShareStatusResponse> {
    const res: AxiosResponse<CheckShareStatusResponse> = await HttpClientService.get(`${AssetsAdapter.assetsEndpoint}/recipients-check-status/${jobId}`, {
      headers: headers(),
    });
    return res.data;
  }

  async getAssetsGroupedRecipients(body: GetAssetsGroupedRecipientsBody): Promise<GetAssetsGroupedRecipientsResponse> {
    const res: AxiosResponse<GetAssetsGroupedRecipientsResponse> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/get_recipients`, body, { headers: headers() });
    return res.data;
  }

  async getRefNumber(clientId: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.get(`${AssetsAdapter.assetEndpoint}/refnumber/${clientId}`, { headers: headers() });
    return res.data;
  }

  async deleteAsset(assetId: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.delete(`${AssetsAdapter.assetEndpoint}/${assetId}`, { headers: headers() });
    return res.data;
  }

  async deleteRecipient(assetId: string, recipientId: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.delete(`${AssetsAdapter.assetEndpoint}/${assetId}/recipient/${recipientId}`, { headers: headers() });
    return res.data;
  }

  async getAssetRecipients(assetsIds: string[]): Promise<GetRecipientsResponse> {
    const res: AxiosResponse<GetRecipientsResponse> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/get_recipients`, { assetsIds }, { headers: headers() });
    return res.data;
  }

  async getAssetsForAcknowledgement(token: string) {
    const res: AxiosResponse<AssetForAcknowledgement[]> = await HttpClientService.get(`${AssetsAdapter.assetEndpoint}/acknowledge`, {
      headers: {
        'Content-Type': 'application/json',
        [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
      },
    });
    return res.data;
  }

  async acknowledgeAndGetPreviewToken(code: string, token: string) {
    const { language } = extractLanguageAndClientIdByRole();
    const parsedCode: number = parseInt(code, 10);

    const res: AxiosResponse<PreviewToken> = await HttpClientService.post(
      `${AssetsAdapter.assetEndpoint}/acknowledge`,
      { passCode: parsedCode },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': language || LANGUAGE.ENGLISH,
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  }

  async requestPreviewOTP(token: string) {
    const { language } = extractLanguageAndClientIdByRole();
    const res: AxiosResponse<{ result: string }> = await HttpClientService.get(`${AssetsAdapter.assetEndpoint}/preview`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': language || LANGUAGE.ENGLISH,
        [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
      },
    });
    return res.data;
  }

  async previewAssetWithOtp(token: string, otp: string, { sortValue, page, limit }: GetAssets = { page: 1, limit: 15 }): Promise<Assets> {
    const { language } = extractLanguageAndClientIdByRole();
    const otpCode: number = parseInt(otp, 10);
    const params = {
      sortBy: sortValue,
      page: page,
      limit: limit,
    };
    const res: AxiosResponse<any> = await HttpClientService.post(
      `${AssetsAdapter.assetEndpoint}/preview`,
      { otpCode },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': language || LANGUAGE.ENGLISH,
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        },
        params,
      },
    );
    return res.data;
  }

  async getAllAssets({ limit, clientId, accountId }: GetAllAssetsClientParams) {
    const res = await HttpClientService.post(
      `${AssetsAdapter.assetsEndpoint}`,
      { accountId },
      {
        headers: headers(),
        params: {
          client: clientId,
          limit,
        },
      },
    );
    return res.data;
  }

  async getAssetsPendingAcknowledgement(clientId: string) {
    const res = await HttpClientService.get(`${AssetsAdapter.assetsEndpoint}/pendingAcknowledgment`, {
      headers: headers(),
      params: {
        client: clientId,
      },
    });
    return res.data;
  }

  async getAssetAuditLog({ assetId, page, limit }: GetAssetAuditLog): Promise<ActivityLogResponse> {
    const res: AxiosResponse<ActivityLogResponse> = await HttpClientService.get(`${AssetsAdapter.auditLogEndpoint}/${assetId}`, {
      headers: headers(),
      params: {
        page,
        limit,
        entityType: ENTITY_TYPES.ASSET,
      },
    });
    return res.data;
  }

  async downloadAllAssetsFiles(clientId: string): Promise<AllAssetsFilesResponse> {
    const url: string = `${AssetsAdapter.assetEndpoint}/download_all_assets_files`;

    const response: AxiosResponse<AllAssetsFilesResponse> = await HttpClientService.get(url, {
      headers: headers(),
      params: {
        client: clientId,
      },
    });

    return response.data as AllAssetsFilesResponse;
  }

  async checkDownloadAssetsFilesStatus(jobId: string): Promise<Blob | AllAssetsFilesResponse> {
    const url: string = `${AssetsAdapter.assetEndpoint}/download_assets_files_status`;

    const response: AxiosResponse<ArrayBuffer> = await HttpClientService.get(url, {
      responseType: 'arraybuffer',
      headers: headers(),
      params: {
        jobId,
      },
    });

    const contentType = response.headers['content-type'];

    if (contentType === 'application/zip') {
      return new Blob([response.data], { type: 'application/zip' });
    } else {
      const text = new TextDecoder().decode(response.data);
      return JSON.parse(text) as AllAssetsFilesResponse;
    }
  }

  async getAssetInnerPageMetaData(businessValuesIds: string[], departmentsIds: string[], projectsIds: string[]) {
    const res = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/getAssetInnerPageMetaData`, { businessValuesIds, departmentsIds, projectsIds }, { headers: headers() });
    return res.data;
  }

  async getAssetContributors(contributorsIds: string[]) {
    const res = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/assetContributors`, { accountsIds: contributorsIds }, { headers: headers() });
    return res.data;
  }

  async getPreviewAssetInnerPageMetaData(businessValuesIds: string[], departmentsIds: string[], projectsIds: string[], token: string) {
    const headers = {
      'Content-Type': 'application/json',
      [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
    };
    const res = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/getPreviewAssetInnerPageMetaData`, { businessValuesIds, departmentsIds, projectsIds }, { headers });
    return res.data;
  }
  async getPreviewAssetContributors(contributorsIds: string[], token: string) {
    const headers = {
      'Content-Type': 'application/json',
      [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
    };
    const res = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/previewAssetContributors`, { accountsIds: contributorsIds }, { headers });
    return res.data;
  }
}

const assetsAdapter = new AssetsAdapter();
export default assetsAdapter;
