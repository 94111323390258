import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { AgreementContainer, FormSkeletonContainer, ViewEmployeeSkeletonContainer } from './style';

export const AgreementSkeleton = () => {
  return (
    <AgreementContainer>
      <Skeleton height={175} count={1} />
    </AgreementContainer>
  );
};

export const AgreementSkeletonForm = () => {
  return Array.from({ length: 2 }).map((_, index) => (
    <FormSkeletonContainer key={index}>
      <Skeleton height={20} width={20} />
      <Skeleton height={20} width={800} />
    </FormSkeletonContainer>
  ));
};

export const AgreementSkeletonViewEmployee = () => {
  return Array.from({ length: 2 }).map((_, index) => (
    <ViewEmployeeSkeletonContainer key={index}>
      <Skeleton height={40} />
    </ViewEmployeeSkeletonContainer>
  ));
};

export default AgreementSkeleton;
