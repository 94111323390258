import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { NOTIFICATION_VARIANTS, TangiCheckboxV2, TangiModal, TangiNotification, TangiSecureInput, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import GrantModalBody from './components/GrantModal/GrantModalBody';
import GrantModalHeader from './components/GrantModal/GrantModalHeader';
import InstructionsTooltip from '../InstructionsTooltip/InstructionsTooltip';
import useToast from '_hooks/useToast';
import { Star } from '_components/Star';
import { useSharePointWizardContext } from '../../context/useSharePointWizardContext';
import { FormSchema } from '_components/SharePointWizard/types';
import { RootState } from '_helpers';
import { Step } from './types';
import { microsoftAdminCenterUrl } from './constants';
import {
  sharePointAllowAccessStep1,
  sharePointAllowAccessStep2,
  sharePointAllowAccessStep3,
  sharePointAllowAccessStep4,
  sharePointAllowAccessStep5,
  sharePointAllowAccessStep6,
  sharePointAllowAccessStep7,
  sharePointAllowAccessStep8,
  sharePointAllowAccessStep9,
} from '../../assets';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';
import { customStarStyles } from '../AddUsersStep/styles';
import { Container, goToMicrosoftLink, GrantedPermissions, grantedPermissions, grantedPermissionsHowToDoIt, grantModal, ListItem } from './style';

const AllowAccessStep = () => {
  const { showSnackbar } = useToast();
  const stepsAsyncErrors = useSelector((state: RootState) => state.sharePoint.stepsAsyncErrors);

  const { currentStepId, isEditMode } = useSharePointWizardContext();
  const { t } = useTranslation();
  const [isGrantModal, setisGrantModal] = useState(false);

  const isError = !!stepsAsyncErrors[currentStepId];

  const initialSteps: Step[] = ['OPEN_APPLICATIONS_MENU', 'ACCESS_APP_REGISTRATIONS', 'SELECT_OR_CREATE_APP'];
  const credentialSteps: Step[] = ['OPEN_CERTIFICATES_AND_SECRETS', 'ADD_CLIENT_SECRET'];

  const renderStep = (step: Step) => {
    const hoverImage = {
      OPEN_APPLICATIONS_MENU: sharePointAllowAccessStep1,
      ACCESS_APP_REGISTRATIONS: sharePointAllowAccessStep2,
      SELECT_OR_CREATE_APP: sharePointAllowAccessStep3,
      OPEN_CERTIFICATES_AND_SECRETS: sharePointAllowAccessStep6,
      ADD_CLIENT_SECRET: sharePointAllowAccessStep7,
      API_PERMISSIONS_STEP: sharePointAllowAccessStep9,
    };

    return (
      <ListItem key={step}>
        <TangiTypography>{t(`SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.${step}`)}</TangiTypography>
        <Tippy
          placement="right"
          render={() => <InstructionsTooltip imageSrc={hoverImage[step]} translationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.TOOLTIP.APPLICATION_ID" />}
          trigger="mouseenter"
          animation={false}
        >
          <span>
            <TangiSvgIcon component="help" size={SPACING[2.5]} color={NEUTRAL_SHADES[800]} />
          </span>
        </Tippy>
      </ListItem>
    );
  };

  return (
    <Container>
      <TangiTypography type="subheading">
        <Trans t={t} i18nKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.DESCRIPTION" />
        <a href={microsoftAdminCenterUrl} target="_blank" rel="noopener noreferrer">
          <TangiTypography color={PRIMARY_SHADES[800]} customStyles={goToMicrosoftLink}>
            {t('SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.MOCROSOFT_ADMIN_CENTER_URL')}
          </TangiTypography>
        </a>
      </TangiTypography>
      <TangiTypography>
        <Trans t={t} i18nKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.FOLLOW_THE_STEP_BELOW" />
      </TangiTypography>
      {initialSteps.map(renderStep)}
      <TangiSecureInput<FormSchema>
        required
        lableTranslationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.APPLICATION_ID"
        name="applicationId"
        labelIcon
        savePassword={false}
        renderLabelTooltipContent={() => <InstructionsTooltip imageSrc={sharePointAllowAccessStep4} translationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.TOOLTIP.APPLICATION_ID" />}
        labelTooltipPlacement="right"
      />
      <TangiSecureInput<FormSchema>
        required
        lableTranslationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.DIRECTORY_ID"
        name="directoryId"
        labelIcon
        savePassword={false}
        renderLabelTooltipContent={() => <InstructionsTooltip imageSrc={sharePointAllowAccessStep5} translationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.TOOLTIP.DIRECTORY_ID" />}
        labelTooltipPlacement="right"
      />
      {credentialSteps.map(renderStep)}

      <TangiSecureInput<FormSchema>
        required
        lableTranslationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.CLIENT_SECRET"
        name="clientSecret"
        labelIcon
        savePassword={false}
        renderLabelTooltipContent={() => <InstructionsTooltip imageSrc={sharePointAllowAccessStep8} translationKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.TOOLTIP.CLIENT_SECRET" />}
        labelTooltipPlacement="right"
        disabled={isEditMode}
        hasVisibilityToggle={!isEditMode}
      />
      <TangiNotification variant={NOTIFICATION_VARIANTS.INFO}>
        <TangiTypography>{t('SHARE_POINT_WIZARD.ALLOW_ACCESS.RECOMMENDATION_BANNER.SAVE_CLIENT_SECRET_NOTE')}</TangiTypography>
      </TangiNotification>
      {renderStep('API_PERMISSIONS_STEP')}
      <article>
        <TangiTypography customStyles={grantedPermissions}>
          <Trans t={t} i18nKey="SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.GRANT_PERMISSIONS" />
        </TangiTypography>

        <span onClick={() => setisGrantModal(true)}>
          <TangiTypography customStyles={grantedPermissions + grantedPermissionsHowToDoIt} color={PRIMARY_SHADES[800]}>
            {t('SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.LEARN_HOW_TO_DO_IT')}
          </TangiTypography>
        </span>
      </article>

      <GrantedPermissions>
        <TangiCheckboxV2 name="isGrantedPermission" label={t('SHARE_POINT_WIZARD.ALLOW_ACCESS.LABEL.GRANTED_PERMISSIONS')} />
        {!isEditMode && <Star customStyles={customStarStyles} />}
      </GrantedPermissions>

      <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>
        <TangiTypography>{t('SHARE_POINT_WIZARD.ALLOW_ACCESS.RECOMMENDATION_BANNER.RECOMMENDATION_BANNER')}</TangiTypography>
      </TangiNotification>

      <TangiModal
        show={isGrantModal}
        onHide={() => setisGrantModal(false)}
        body={<GrantModalBody handleClose={() => setisGrantModal(false)} />}
        header={<GrantModalHeader />}
        containerStyle={grantModal}
      />

      {isError && showSnackbar('error', 'top', 'center', t('SHARE_POINT_WIZARD.ALLOW_ACCESS.ERROR'))}
    </Container>
  );
};

export default AllowAccessStep;
