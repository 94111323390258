import { RecipientCategoryType } from 'utils/types/assets/assets';
import { BuildSelectOptionsInputData, SelectOption, BaseGroupData, RecipientData, RecipientSelectOption, GroupSelectOption, DepartmentOrUnitGroupProps, PartnerGroupProps } from './types';
import { AvatarEmail } from '_components/Avatar/AvatarEmail';
import { NEUTRAL_SHADES } from 'utils/theme';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import i18next from 'i18next';
import { ThirdPartyTag } from '_components/AssetRecipients/_components/ThirdPartyTag';
import { ChipLabelContainer } from './styles';
import { Partnership } from 'utils/types/partnership/partnership';
import { ThirdPartyContainer } from '../Tables/components/styles';
import { IRoles } from 'utils/roles';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import { AVATAR_SIZES } from 'utils/componentUtils';

export const buildSelectOptions = (data: BuildSelectOptionsInputData & { role?: IRoles }): SelectOption[] => {
  const { recipients, departments, partnersDepartments, businessUnits, partners, role } = data;

  return [
  ...(recipients?.length ? parseRecipients(recipients) : []),
  ...(departments?.length ? parseDepartmentsOrUnits({ groups: departments, type: RecipientCategoryType.DEPARTMENT, role }) : []),
  ...(businessUnits?.length ? parseDepartmentsOrUnits({ groups: businessUnits, type: RecipientCategoryType.BUSINESS_UNIT, role }) : []),
  ...(partners?.length ? parsePartners({ groups: partners, type: RecipientCategoryType.PARTNER }) : []),
  ...(partnersDepartments?.length ? parseDepartmentsOrUnits({ groups: partnersDepartments, type: RecipientCategoryType.PARTNER_DEPARTMENT, role }) : []),
]};

const parseDepartmentsOrUnits = ({ groups, type, role }: DepartmentOrUnitGroupProps & { role?: IRoles }): GroupSelectOption[] => {
  const renderGroupContent = (group: BaseGroupData) => {
    switch (type) {
      case RecipientCategoryType.DEPARTMENT:
        return <TangiTypography>{`${group.name}  - ${i18next.t('ASSET_PAGE.DEPARTMENT')}`}</TangiTypography>;
      case RecipientCategoryType.BUSINESS_UNIT:
        return <TangiTypography>{`${group.name} - ${i18next.t('ASSET_PAGE.BUSINESS_UNIT')}`}</TangiTypography>;
      case RecipientCategoryType.PARTNER_DEPARTMENT:
        return (
          <>
            {renderPartnershipDepartmentLabel(group.name, group.partnershipData, role)}
            <ThirdPartyTag />
          </>
        );
      default:
        return '';
    }
  };

  return groups
    .filter((group) => group.id)
    .map((group) => ({
      id: group.id,
      name: group.name,
      type,
      value: group.name,
      partnership: group.partnership,
      partnershipData: group.partnershipData,
      label: (
        <ChipLabelContainer>
          <TangiSvgIcon component="department" color={NEUTRAL_SHADES.WHITE} />
          {renderGroupContent(group)}
        </ChipLabelContainer>
      ),
    }));
};

const parsePartners = ({ groups, type }: PartnerGroupProps): GroupSelectOption[] => {
  return groups.map((group) => ({
    id: group._id,
    name: group.name,
    type,
    value: group.name,
    partnership: group._id,
    partnershipData: { ...group },
    label: (
      <ChipLabelContainer>
        {renderPartnershipLabel(group.name, group.logo)}
        <ThirdPartyTag />
      </ChipLabelContainer>
    ),
  }));
};
export const renderPartnershipLabel = (partnershipName: string, partnershipLogo?: string) => {
  return (
    <>
        <PartnershipOrClientLogo isPartnership name={partnershipName} logo={partnershipLogo} size={partnershipLogo && AVATAR_SIZES.XS} />
        <TangiTypography>{`${partnershipName} - ${i18next.t('ACTIVITY_LOG.ASSET.PARTNER')}`}</TangiTypography>
    </>
  );
};

export const renderPartnershipDepartmentLabel = (name: string, partnership?: Partnership, role?: IRoles) => {
  const isPartner = role === IRoles.PARTNER;

  return <TangiTypography>{`${name} - ${i18next.t('ASSET_PAGE.DEPARTMENT')} ${!isPartner && partnership?.name ? `${i18next.t('ACTIVITY_LOG.ASSET.IN')} ${partnership?.name}` : ''}`}</TangiTypography>;
};

export const renderRecipientLabel = ({ displayName, email }: { displayName?: string; email: string }) => {
  return (
    <>
      {displayName && <AvatarEmail size="xs" isLinkDisabled name={displayName} email={email} styles="me-2" data-testid="select-label-avatar" image="" />}
      <TangiTypography>
        {displayName ? `${displayName} -` : ''} {email}
      </TangiTypography>
    </>
  );
};

const parseRecipients = (recipients: RecipientData[]): RecipientSelectOption[] => recipients.map(mapRecipient);

const mapRecipient = ({ id, _id, displayName, email, partnership }: RecipientData): RecipientSelectOption => ({
  id: id ?? _id,
  name: displayName,
  value: displayName ?? email,
  type: RecipientCategoryType.INDIVIDUAL,
  email,
  label: (
    <ChipLabelContainer>
      {renderRecipientLabel({ displayName, email })}
      {partnership && (
        <ThirdPartyContainer>
          <ThirdPartyTag />
        </ThirdPartyContainer>
      )}
    </ChipLabelContainer>
  ),
});
