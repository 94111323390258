import { TangiSvgIcon, TangiSvgIconTypes, TangiTypography } from '_components/TangiLibrary';
import { RecipientContent, RecipientGroupHeaderContainer, Separator } from '../style';
import { NEUTRAL_SHADES } from 'utils/theme';
import { SharedRecipient } from 'utils/types/assets/assets';

interface PartnershipGroupProps {
  icon: TangiSvgIconTypes;
  title: string;
  partnersCount: number;
  partners: SharedRecipient[];
  showSeparator?: boolean;
  renderRecipientRow: (r: SharedRecipient) => React.ReactNode;
}

function PartnershipGroup({ icon, title, partnersCount, partners, renderRecipientRow, showSeparator = false }: PartnershipGroupProps) {
  return (
    <>
      <RecipientContent>
        <RecipientGroupHeaderContainer>
          <TangiSvgIcon component={icon} color={NEUTRAL_SHADES[1100]} size="24px" />
          <TangiTypography type="subheading" weight="semibold">
            {title}
          </TangiTypography>
          <TangiTypography type="subheading" weight="regular" color={NEUTRAL_SHADES[800]}>
            • {partnersCount}
          </TangiTypography>
        </RecipientGroupHeaderContainer>
        {partners.map((recipient) => renderRecipientRow(recipient))}
      </RecipientContent>
      {showSeparator && <Separator />}
    </>
  );
}
export default PartnershipGroup