import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { batch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '_helpers';
import _ from 'lodash';

import { assetMetaDataActions } from 'redux-toolkit/slices/assetMetaDataSlice';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { createAssetType, createBusinessValue, createDepartment, createProject, createTag, createAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { createAsset as createNewAsset, getRefNumber, updateAsset as updateExistingAsset, getAssetInnerPageMetaData, getAssetContributors } from '../../redux-toolkit/thunks/assetsThunks';
import { clientSettingsActions } from '../../_actions/clientSettingsActions';
import { useIsMount } from '../../utils/customHooks';
import { responseStatus, assetMetaDataEnums, assetMetaDataFields } from '../../_constants/assetConstants';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { IRoles } from 'utils/roles';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { X_RAY_TOOLS } from '_services/utils/MixPanel/const';
import { getAssetContributorsValues, getAssetInnerPageMetaDataValues, isArrayValid } from './utils';

export const useCreateEditAsset = (isEditMode, asset, handleCloseAsset, onCreate, onEdit, isPatentMode) => {
  const dispatch = useAppDispatch();

  const { clientId } = useParams();
  const { permissions, activeAccount, Role, user } = useSelector((state) => state.authentication);
  const { error, assetLoading, createUpdateAssetLoading, assetStatus, fullReferenceId, createRefLoading } = useSelector((state) => state.asset);
  const settings = useSelector((state) => state.clientSettings.settings);
  const { invitedMultipleAccounts } = useSelector((state) => state.users);
  const {
    authors: contributors,
    businessValues,
    tags,
    assetTypes,
    projects,
    loading: metaDataLoading,
    departments,
    creatableLoading,
    newProject,
    newTag,
    newDepartment,
    newAssetType,
    newBusinessValue,
    newBusinessRelated,
    businessRelated,
    commercialProduct,
    newCommercialProduct,
    isMetaDataFetched,
  } = useSelector((state) => state.assetMetaData);
  const { isAssetCreatedIndex, isMappingTool } = useSelector((state) => state.patent);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);

  const [editFiles, setEditFiles] = useState([]);
  const [editUrls, setEditUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  const [statusMessage, setStatusMessage] = useState(null);

  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);

  const isAssetInnerPageMetaDataObjectsFetched = useMemo(() => {
    const arrayType = 'object';
    return isArrayValid(asset?.businessValues, arrayType) || isArrayValid(asset?.projects, arrayType) || isArrayValid(asset?.departments, arrayType);
  }, [asset]);

  const convertArrayToObjects = (arr) => {
    return arr?.map((value, index) => ({
      value: value,
      label: `Category ${index + 1} - ` + value,
      score: index + 1,
    }));
  };

  const importanceFields = useMemo(() => {
    return convertArrayToObjects(settings?.importanceFields);
  }, [settings?.importanceFields]);

  const fetchMetaData = async () => {
    let assetInnerPageMetaData = { departments: [], projects: [], businessValues: [] };

    const shouldFetch = asset.businessValues?.length > 0 || asset.projects?.length > 0 || asset.departments?.length > 0;

    if (isAssetInnerPageMetaDataObjectsFetched) {
      assetInnerPageMetaData = {
        departments: asset.departments,
        projects: asset.projects,
        businessValues: asset.businessValues,
      };
    } else if (shouldFetch) {
      const assetInnerPageMetaDataResult = await dispatch(
        getAssetInnerPageMetaData({
          businessValuesIds: asset.businessValues,
          departmentsIds: asset.departments,
          projectsIds: asset.projects,
        }),
      );
      assetInnerPageMetaData = unwrapResult(assetInnerPageMetaDataResult);
    }

    const { departments, projects, businessValues } = getAssetInnerPageMetaDataValues(assetInnerPageMetaData);

    setValue('departments', departments);
    setValue('projects', projects);
    setValue('businessValues', businessValues);
  };

  const fetchContributors = async () => {
    let assetContributors = [];

    if (isArrayValid(asset?.contributor, 'object')) {
      assetContributors = asset.contributor;
    } else {
      try {
        const contributorsResults = await dispatch(getAssetContributors(asset.contributor));
        assetContributors = unwrapResult(contributorsResults);
      } catch (error) {
        console.error('Error fetching contributors:', error);
      }
    }

    const contributorsValues = getAssetContributorsValues(assetContributors);

    setValue('contributor', contributorsValues);
  };

  // FORM
  const {
    setValue,
    control,
    register,
    handleSubmit,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: !isEditMode && asset && { ...asset },
  });

  // Data fetching
  useEffect(() => {
    if (!settings.permissions) {
      dispatch(clientSettingsActions.getClientSettings(clientId));
    }
    if (!isEditMode) {
      dispatch(getRefNumber(clientId));
    }
  }, []);

  // clear previous asset data
  useEffect(() => {
    batch(() => {
      dispatch(assetsActions.setClearAssetStatus());
      dispatch(assetMetaDataActions.setClearCreatable());
    });
    return () => {
      batch(() => {
        dispatch(assetMetaDataActions.setClearCreatable());
        dispatch(assetsActions.setClearAssetStatus());
      });
    };
  }, []);

  // Asset population in Edit Asset
  useEffect(() => {
    if (isEditMode && isMetaDataFetched) {
      reset(transformAPItoFormData(asset));
      if (asset.files) {
        setEditFiles(asset.files);
      }
      if (asset.urls) {
        setEditUrls(asset.urls);
      }
    }
  }, [isEditMode, isMetaDataFetched]);

  // Files Error Message will pop after the component is mounted to avoid that,
  // After the mounting we setting the files field in the form and turning on validation
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      setValue('files', files, { shouldValidate: true });
    }
  }, [files]);

  const transformAPItoFormData = (data) => {
    const form = {};
    for (const [key, value] of Object.entries(data)) {
      switch (key) {
        case 'contributor':
          form[key] = getOptionsFromIds(contributors, value, 'displayName');
          break;
        case 'assetType':
          form[key] = getOptionsFromId(assetTypes, value._id);
          break;
        case 'tags':
          form[key] = getOptionsFromIds(tags, value);
          break;
        case 'projects':
          form[key] = getOptionsFromIds(projects, value);
          break;
        case 'departments':
          form[key] = getOptionsFromIds(departments, value);
          break;
        case 'businessValues':
          form[key] = getOptionsFromIds(businessValues, value);
          break;
        case 'importance':
          form[key] = transformSelectedImportance(importanceFields, value);
          break;
        case 'assetMetaData':
          const extractedValues = mergeAndExtractValues(data[key], businessRelated, commercialProduct);
          assetMetaDataFields.forEach((field) => (form[field] = extractedValues[field]));
          break;
        default:
          form[key] = data[key];
          break;
      }
    }
    return form;
  };

  const mergeAndExtractValues = (currMetadataObjectsArray, array1, array2) => {
    // Merge arrays and remove duplicates based on '_id'
    const allMetadataObjectsArray = _.unionBy(array1, array2, '_id');

    const result = {};

    currMetadataObjectsArray.forEach((object) => {
      const foundObject = allMetadataObjectsArray.find((metadataObj) => metadataObj._id === object._id);
      if (foundObject) {
        const { _id, value, type } = foundObject;

        if (!result[type]) {
          result[type] = { value: _id, label: value, type: type };
        }
      }
    });

    return result;
  };

  const transformSelectedImportance = (allOptions, selectedOption) => {
    const matchedOption = allOptions.find((option) => option.value === selectedOption.value);

    if (matchedOption) {
      const { value, label, score } = matchedOption;
      return { value, label, score };
    }

    return null;
  };

  useEffect(() => {
    if (!isEditMode && !!fullReferenceId) {
      setValue('refNumber', fullReferenceId.refNumber);
    }
  }, [fullReferenceId, isEditMode]);

  useEffect(() => {
    setStatusMessage(getStatusProps(assetStatus));
    if ((assetStatus === responseStatus.editSuccess || assetStatus === responseStatus.uploadSuccess) && isEditMode) {
      if (_.isFunction(onEdit)) {
        onEdit();
      }
    } else if (assetStatus === responseStatus.createSuccess || assetStatus === responseStatus.uploadSuccess) {
      if (_.isFunction(onCreate)) {
        onCreate();
      }
    }
  }, [assetStatus]);

  const createInputValueWithRole = useCallback(
    (inputValue) => {
      return Role === IRoles.PARTNER ? `${inputValue} by ${activeAccount?.displayName}` : inputValue;
    },
    [Role, activeAccount?.displayName],
  );

  // creating new projects handlers
  const handleCreateOption = async (inputValue, type) => {
    const inputValueWithRole = createInputValueWithRole(inputValue, Role);

    const payload = {
      name: inputValueWithRole,
      client: [clientId],
    };

    switch (type) {
      case assetMetaDataEnums.project:
        dispatch(createProject(payload));
        break;
      case assetMetaDataEnums.tag:
        dispatch(createTag(payload));
        break;
      case assetMetaDataEnums.department:
        dispatch(createDepartment(payload));
        break;
      case assetMetaDataEnums.businessValue:
        dispatch(createBusinessValue(payload));
        break;
      case assetMetaDataEnums.assetType:
        dispatch(createAssetType(payload));
        break;
      case assetMetaDataEnums.businessRelated:
      case assetMetaDataEnums.commercialProduct:
        const objectToPost = {
          value: inputValueWithRole,
          clientID: clientId,
          type,
        };
        dispatch(createAssetMetaData(objectToPost));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (newProject) {
      const projectCurrentValues = getValues().projects;
      if (projectCurrentValues) {
        setValue('projects', [...projectCurrentValues, { value: newProject.id, label: newProject.name }]);
      } else {
        setValue('projects', [{ value: newProject.id, label: newProject.name }]);
      }
    }
  }, [newProject]);

  useEffect(() => {
    if (newBusinessValue) {
      const businessValuesCurrentValues = getValues().businessValues;
      if (businessValuesCurrentValues) {
        setValue('businessValues', [...businessValuesCurrentValues, { value: newBusinessValue.id, label: newBusinessValue.name }]);
      } else {
        setValue('businessValues', [{ value: newBusinessValue.id, label: newBusinessValue.name }]);
      }
    }
  }, [newBusinessValue]);

  useEffect(() => {
    if (newTag) {
      const tagsCurrentValues = getValues().tags;
      if (tagsCurrentValues) {
        setValue('tags', [...tagsCurrentValues, { value: newTag.id, label: newTag.name }]);
      } else {
        setValue('tags', [{ value: newTag.id, label: newTag.name }]);
      }
    }
  }, [newTag]);

  useEffect(() => {
    if (asset) {
      fetchMetaData();
    }
    if (asset?.contributor?.length) {
      fetchContributors();
    }
  }, []);

  useEffect(() => {
    if (newDepartment) {
      const departmentsCurrentValues = getValues().departments;
      if (departmentsCurrentValues) {
        setValue('departments', [...departmentsCurrentValues, { value: newDepartment.id, label: newDepartment.name }]);
      } else {
        setValue('departments', [{ value: newDepartment.id, label: newDepartment.name }]);
      }
    }
  }, [newDepartment]);

  useEffect(() => {
    if (newBusinessRelated) {
      setValue('businessRelated', { value: newBusinessRelated._id, label: newBusinessRelated.value });
    }
  }, [newBusinessRelated]);

  useEffect(() => {
    if (newCommercialProduct) {
      setValue('commercialProduct', { value: newCommercialProduct._id, label: newCommercialProduct.value });
    }
  }, [newCommercialProduct]);

  useEffect(() => {
    if (newAssetType) {
      setValue('assetType', { value: newAssetType.id, label: newAssetType.name });
    }
  }, [newAssetType]);

  // Asset population with default values in patentMode
  useEffect(() => {
    if (isPatentMode && isMetaDataFetched) {
      const tradeSecretAssetType = assetTypes.find((assetType) => assetType.name === 'Trade Secret');
      setValue('assetType', { value: tradeSecretAssetType.id, label: tradeSecretAssetType.name });
    }
  }, [isMetaDataFetched, isPatentMode]);

  // Asset population in asset creation after inviting multiple accounts
  useEffect(() => {
    if (invitedMultipleAccounts.length > 0) {
      const contributors = [];
      invitedMultipleAccounts.map((item) => {
        contributors.push({ value: item.id, label: item.displayName });
      });
      setValue('contributor', contributors);
    }
  }, [invitedMultipleAccounts]);

  // Checks if the account has permission in client Settings
  const checkPermissions = (permissionType) => {
    if (!activeAccount || !settings.permissions) {
      return false;
    }

    if (Role === IRoles.LAWYER || Role === IRoles.EMPLOYEE_ADMIN) {
      return true;
    }

    const roleKey = Role.split(' ')[0];
    const rolePermissions = settings.permissions[roleKey];

    return rolePermissions ? rolePermissions[permissionType] : false;
  };

  const updateAsset = (assetData) => {
    assetData.client = clientId;
    dispatch(updateExistingAsset(assetData));
    mixpanelEvents.editAsset(assetData.id, clientId, clientName, Role, user.email);
  };

  const createAsset = (assetData) => {
    assetData.client = clientId;
    batch(() => {
      dispatch(createNewAsset(assetData));
      dispatch(usersActions.setClearInvitedMultiple());
      if (isAssetCreatedIndex != -1) {
        dispatch(patentActions.setAssetCreatedToTradeSecret(isAssetCreatedIndex));
        dispatch(patentActions.setIsAssetCreatedIndex(-1));
      }
    });

    const createdFromTool = isMappingTool ? X_RAY_TOOLS.MAPPING : X_RAY_TOOLS.EXTRACTION;

    if (clientId && clientName) {
      dispatch(
        mixpanelEvents.createAsset({
          clientId,
          createdFrom: assetData.createdFrom ?? 'Asset Page',
          role: Role,
          clientName,
          createdFromTool,
          userEmail: user.email,
        }),
      );
    }
  };

  const getStatusProps = (assetStatus) => {
    switch (assetStatus) {
      case responseStatus.assetFailed:
      case responseStatus.editFailed:
        return { message: error?.message, color: '#F75676' };
      case responseStatus.createSuccess:
      case responseStatus.editSuccess:
      case responseStatus.uploadSuccess:
        return { message: statusMsg.UPLOAD_SUCCESS, color: '#008000' };
      case responseStatus.uploadStarted:
        return { message: statusMsg.UPLOAD_STARTED, color: '#FC7C5F' };
      default:
        return null;
    }
  };

  return {
    metaDataLoading,
    isEditMode,
    setValue,
    register,
    handleSubmit,
    getValues,
    errors,
    createRefLoading,
    handleCreateOption,
    control,
    checkPermissions,
    contributors,
    fullReferenceId,
    permissions,
    tags,
    departments,
    projects,
    assetTypes,
    businessValues,
    businessRelated,
    newBusinessRelated,
    createAsset,
    updateAsset,
    assetLoading,
    asset,
    editFiles,
    setEditFiles,
    editUrls,
    setEditUrls,
    files,
    setFiles,
    urls,
    setUrls,
    statusMessage,
    createUpdateAssetLoading,
    creatableLoading,
    setError,
    clearErrors,
    invitedMultipleAccounts,
    importanceFields,
    commercialProduct,
    newCommercialProduct,
    settings,
  };
};

// -------- utils ------------

const statusMsg = {
  UPLOAD_SUCCESS: 'Asset successfully uploaded',
  UPLOAD_STARTED: 'Please wait while your files are uploaded and encrypted',
};

// Returns option object from id
export const getOptionsFromId = (data, id, name = 'name') => {
  const item = data.find((i) => i.id === id);
  if (!id) {
    return undefined;
  }
  return {
    value: item && item.id,
    label: item && item[name],
  };
};

// Returns option object from ids
export const getOptionsFromIds = (data, _ids = [], name) => {
  const options = [];
  _ids.forEach((_id) => {
    options.push(getOptionsFromId(data, _id?._id, name));
  });
  return options;
};

// Creating options object for Select and MultiSelect fields
export const getOptions = (results, value = 'id', label = 'name') => {
  const options =
    results &&
    results.map((result) => ({
      value: result[value],
      label: result[label],
    }));
  return options;
};

// Utility function to map values from an array or return an empty array
const mapArrayValues = (array) => (Array.isArray(array) ? array.map((item) => item.value) : []);

/**
 * Transforms input data to API-compatible format.
 *
 * @param {Object} data - The input data to be transformed.
 * @returns {Object} - The transformed data in API-compatible format.
 */
export const transformToApiData = (data) => {
  const apiData = {
    id: data.id,
    name: data.name || '',
    description: data.description || '',
    refNumber: data.refNumber || '',
    files: data.files || '',
    others1: data.others1 || '',
    others2: data.others2 || '',
    createdFrom: data.createdFrom || '',
    assetType: data.assetType?.value || '',
    tags: mapArrayValues(data.tags),
    businessValues: mapArrayValues(data.businessValues),
    contributor: mapArrayValues(data.contributor),
    projects: mapArrayValues(data.projects),
    departments: mapArrayValues(data.departments),
    assetMetaData: [],
    // isPublished: data.isPublished || false, TODO: Uncomment if we want this field to be relevant
    importance: data.importance
      ? {
          value: data.importance.value || '',
          score: data.importance.score || '',
        }
      : undefined,
  };

  // Additional processing for assetMetaDataFields
  for (const key of Object.keys(assetMetaDataFields)) {
    const enumValue = assetMetaDataFields[key];
    if (data[enumValue] && data[enumValue].value) {
      apiData.assetMetaData.push(data[enumValue].value);
    }
  }

  return apiData;
};
