import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { isRoleMatch, IRoles } from '../../../utils/roles';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from '../../../utils/theme';
import { TangiTypography } from '_components/TangiLibrary';
import { FlexContainerGap1 } from '_components/PartnersSideMenuDrawer/style';
import { TangiCheckbox } from '_components/TangiLibrary/TangiCheckbox';

const fieldMapping = {
  startdate: 'dateOfJoining',
  name: 'displayName',
  accountStatus: 'status',
};

export const mapFieldNameToServer = (fieldName) => fieldMapping[fieldName] || fieldName;

const mapFieldNameToClient = (fieldName) => {
  const invertedMapping = Object.fromEntries(Object.entries(fieldMapping).map(([key, value]) => [value, key]));
  return invertedMapping[fieldName] || fieldName;
};

function getSortIcon(activeSelectorRole, field) {
  const selector = mapFieldNameToClient(field);
  return selector === field && activeSelectorRole.direction === 'asc' ? faSortUp : faSortDown;
}

export const getColumns = (activeSelectorRole = false, role = '', selectAllVisible = () => {}, isAllVisibleSelected = false, isAllSelected = false, accountType = '') => {
  const shouldRenderCheckbox = !(role && isRoleMatch(role, IRoles.EMPLOYEE_MANAGER));
  return [
    {
      name: (
        <>
          {shouldRenderCheckbox && (
            <div onClick={(e) => e.stopPropagation()}>
              <TangiCheckbox onChange={selectAllVisible} checked={isAllVisibleSelected || isAllSelected} dataTestId={`select-all-visible-${accountType}`} />
            </div>
          )}
          <FlexContainerGap1>
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.NAME')}
            </TangiTypography>
            <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'name')} data-testid="name-col" />
          </FlexContainerGap1>
        </>
      ),
      selector: ({ name }) => name,
      sortable: true,
      fieldName: 'name',
      minWidth: '224px',
      grow: 1,
      style: {
        width: '240px',
      },
    },
    {
      name: (
        <FlexContainerGap1>
          <TangiTypography weight="bold" color="inherit">
            {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.STATUS')}
          </TangiTypography>
          <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'accountStatus')} data-testid="status-col" />
        </FlexContainerGap1>
      ),
      selector: ({ accountStatus }) => accountStatus,
      fieldName: 'accountStatus',
      sortable: true,
      grow: 1,
      style: {
        width: '100px',
      },
    },
    {
      name: (
        <TangiTypography weight="bold" color="inherit">
          {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.DEPARTMENT')}
        </TangiTypography>
      ),
      selector: ({ departments }) => departments,
      sortable: false,
      grow: 1,
      style: {
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '180px',
      },
    },
    {
      name: (
        <TangiTypography weight="bold" color="inherit">
          {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.BUSINESS_UNIT')}
        </TangiTypography>
      ),
      selector: ({ businessUnits }) => businessUnits,
      sortable: false,
      grow: 1,
      style: {
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '180px',
      },
    },
    {
      name: (
        <TangiTypography weight="bold" color="inherit">
          {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.TRAINING')}
        </TangiTypography>
      ),
      selector: ({ training }) => training,
      sortable: false,
      style: { width: '150px' },
    },
    {
      name: (
        <FlexContainerGap1>
          <FlexContainerGap1 style={{ flexDirection: 'column', gap: 0 }}>
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.START_DATE')}
            </TangiTypography>
            <TangiTypography color={NEUTRAL_SHADES[800]} type="sub-body">
              {i18next.t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.EXIT_DATE')}
            </TangiTypography>
          </FlexContainerGap1>
          <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'startdate')} data-testid="date-col" />
        </FlexContainerGap1>
      ),
      selector: ({ startdate }) => startdate,
      sortable: true,
      fieldName: 'startdate',
      maxWidth: '100px',
    },
    {
      name: <></>,
      selector: ({ editdelete }) => editdelete,
      omit: role && isRoleMatch(role, IRoles.EMPLOYEE_MANAGER),
      style: { justifyContent: 'flex-end', display: 'flex' },
      maxWidth: '80px',
    },
  ];
};

export const customStyles = {
  tableWrapper: {
    style: {
      style: {
        margin: '0 auto',
        overflowX: 'auto',
      },
    },
  },
  table: {
    style: {
      width: '100%',
      tableLayout: 'auto',
    },
  },
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[200]}`,
    },
  },
  headCells: {
    style: {
      color: `${NEUTRAL_SHADES[1100]}`,
      '&:not(:last-of-type)': {
        marginLeft: '10px',
      },
    },
  },
  rows: {
    style: {
      '&:hover': {
        backgroundColor: `${NEUTRAL_SHADES[50]}`,
      },
    },
  },
  cells: {
    style: {
      '&:hover': {
        cursor: 'pointer',
      },
      '&:not(:last-of-type)': {
        marginLeft: '10px',
      },
      '&:first-of-type': {
        '& div:not(.tangi-typography)': {
          overflow: 'visible !important',
        },
      },
    },
  },
};

export const isRowSelected = (row, selectedEmployees, selectedContractors) => {
  return selectedEmployees.some((employee) => employee.id === row.id) || selectedContractors.some((contractor) => contractor.id === row.id);
};

export const getConditionalRowStyles = (selectedEmployees, selectedContractors) => [
  {
    when: (row) => isRowSelected(row, selectedEmployees, selectedContractors),
    style: {
      backgroundColor: PRIMARY_SHADES[50],
    },
  },
];
