import { TangiCollapse, TangiTypography } from '_components/TangiLibrary';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import { t } from 'i18next';
import { CSSObject } from 'styled-components';
import { NEUTRAL_SHADES } from 'utils/theme';
import { RecipientsCard, FlexRow } from '../style';
import { ThirdPartyTag } from './ThirdPartyTag';

interface PartnershipCardProps {
  name: string;
  recipientsCount: number;
  children: React.ReactNode;
  labelSuffix?: string;
  labelStyles?: CSSObject;
  logo?: string;
}

export const PartnershipCard: React.FC<PartnershipCardProps> = ({ name, recipientsCount, children, labelSuffix, labelStyles, logo }) => {
  return (
    <>
      {recipientsCount > 0 && (
        <RecipientsCard>
          <TangiCollapse
            label={
              <FlexRow data-testid='partnership-container'>
                <PartnershipOrClientLogo isPartnership name={name} logo={logo} />
                <TangiTypography type="heading-md" weight="semibold">
                  {name}
                </TangiTypography>
                <TangiTypography type="subheading" weight="regular" color={NEUTRAL_SHADES[800]}>
                  • {recipientsCount} {t('ASSET_PAGE.CONTENT.RECIPIENTS')}
                  {labelSuffix && ` (${labelSuffix})`}
                </TangiTypography>
                <ThirdPartyTag />
              </FlexRow>
            }
            content={children}
            labelStyles={labelStyles}
          />
        </RecipientsCard>
      )}
    </>
  );
};
