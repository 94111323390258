import { TangiSvgIcon } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { NEUTRAL_SHADES } from 'utils/theme';
import { AvatarSizes } from '_components/Avatar/style';

interface Props {
  logo?: string;
  name: string;
  size?: string;
  isPartnership: boolean;
}

const PartnershipOrClientLogo = ({ name, logo, size = AVATAR_SIZES.SM, isPartnership }: Props) => {
  const iconSize = `${AvatarSizes[size]?.size}px`;

  return (
    <>{logo ? <Avatar size={size} name={name} tooltip={false} image={logo} /> : isPartnership && <TangiSvgIcon component="partnerEmptyStateIcon" color={NEUTRAL_SHADES.WHITE} size={iconSize} />}</>
  );
};

export default PartnershipOrClientLogo;
