import { RootState } from '_helpers';
import { useSelector } from 'react-redux';
import { buildSelectOptions } from './utils';
import { useMemo } from 'react';
import { BaseGroupData } from './types';
import useRole from '_hooks/useRole';

export const useHandleRecipientEntitySearch = () => {
  const { authors, departments: allDepartments, businessUnits, loading: metadataLoading } = useSelector((state: RootState) => state.assetMetaData);
  const { partnerships, loadingPartnerships } = useSelector((state: RootState) => state.partnership);
  const { Role } = useRole()

  const partners = [...partnerships.active, ...partnerships.disabled];

  const departments = (allDepartments as BaseGroupData[]).filter((dept) => !dept.partnership);
  const filteredPartnersDepartments = (allDepartments as BaseGroupData[]).filter((dept) => dept.partnership);

  const partnersDepartments = filteredPartnersDepartments.map((dept) => {
    return {
      ...dept,
      partnership: dept.partnership,
      partnershipData: partners.find((p) => p._id === dept.partnership), 
    };
  });

  const selectOptions = useMemo(
    () => (!loadingPartnerships && !metadataLoading ? buildSelectOptions({ recipients: authors, departments, partnersDepartments: partnersDepartments , businessUnits, partners, role: Role }) : []),
    [loadingPartnerships || metadataLoading, authors, allDepartments, businessUnits, partnerships],
  );

  return { isLoading: loadingPartnerships || metadataLoading, selectOptions };
};
