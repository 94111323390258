import styled, { css, CSSObject } from 'styled-components';
import { StyledCard, Divider } from '../../utils/globalStyles';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from '../../utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
  background: ${NEUTRAL_SHADES[50]};
  padding-top: ${SPACING[4]};
`;

export const RecipientsAndWidgets = styled.div`
  display: flex;
  gap: 24px;
  background: ${NEUTRAL_SHADES[50]};
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RecipientsContainer = styled(ContentColumn)`
  max-height: calc(100vh - 304px);
  overflow-y: auto;
  position: sticky;
  position: -webkit-sticky; // Safari
  top: 0px;
  flex: 1;
  padding-bottom: ${SPACING[4]};
`;

export const WidgetsContainer = styled(ContentColumn)`
  min-width: 284px;
  width: 284px;
  position: sticky;
  position: -webkit-sticky; // Safari
  top: 0px;
`;

export const Card = styled(StyledCard)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
`;

export const RecipientsCard = styled(Card)`
  padding: 24px;
`;

export const RecipientRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const AvatarContainer = styled(FlexRow)`
  gap: 8px;
  width: fit-content;
`;

export const RecipientsDivider = styled(Divider)`
  margin-bottom: 0px;
`;

// --- Partnership --- //

export const PartnershipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const partnershipLinkStyles = `
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

// --- Widgets --- //

export const WidgetCard = styled(Card)<{ isEmpty: boolean }>`
  ${({ isEmpty }) => css`
    min-height: 104px;
    gap: ${isEmpty ? 16 : 8}px;
    padding: 16px;
  `}
`;

export const WidgetNum = styled.div`
  color: ${PRIMARY_SHADES[800]};
  font-family: 'DIN2014-Bold';
  font-size: 40px;
  line-height: 40px;
`;

export const WidgetDataRow = styled(FlexRow)`
  gap: 8px;
  align-items: center;
`;

// --- Empty State --- //

export const EmptyCard = styled(Card)`
  align-items: center;
  justify-content: center;
  height: 336px;
`;

export const NoSearchTextContainer = styled.div`
  width: 280px;
  .tangi-typography {
    text-align: center;
  }
`;

export const ProChipContainer = styled.div`
  margin-left: auto;
  position: relative;
  left: 8px;
  bottom: 8px;
  cursor: default;
`;

// --- PRO Tooltip --- //

export const TooltipContainer = styled.div`
  background: ${NEUTRAL_SHADES.WHITE};
  display: flex;
  flex-direction: column;
  border: 2px solid ${PRIMARY_SHADES[400]};
  padding: 16px;
  gap: 8px;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

export const RecipientContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const RecipientGroupHeaderContainer = styled(FlexRow)`
  padding: ${SPACING[1]} 0;
`;

export const Separator = styled.div`
  border-bottom: 1px solid ${NEUTRAL_SHADES[200]};
  margin: 12px 0;
`;

export const recipientsCardLabelStyles:CSSObject = {
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',
  gap: `${SPACING[2.5]}`,
};

export const LineBreakContainer = styled.div`
  white-space: pre-wrap;
`;