import styled from 'styled-components';

import { FlexSpan } from '_components/ActivityLog/style';
import { Avatar } from '_components/Avatar';
import { TangiTypography } from '_components/TangiLibrary';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { SPACING } from 'utils/theme';

interface Props {
  people: { displayName: string; email: string; _id: string }[];
  marginTop?: string;
}

const Container = styled.div`
  padding: ${SPACING[0]} ${SPACING[7]} ${SPACING[0]} ${SPACING[3.5]};
`;

export const AssetActivityGroupNotifyContent = ({ people, marginTop }: Props) => {
  const renderByAsAccount = (personAccount: { displayName: string; email: string; _id: string }) => {
    return (
      <FlexSpan key={personAccount._id} marginTop={marginTop}>
        <Avatar size={AVATAR_SIZES.XS} name={personAccount.displayName || personAccount.email || ''} email={personAccount.email || ''} />
        <TangiTypography type="subheading" weight="bold">
          {personAccount.displayName || personAccount.email}
        </TangiTypography>
      </FlexSpan>
    );
  };

  return <Container style={{ marginTop }}>{people.map((person) => renderByAsAccount(person))}</Container>;
};
