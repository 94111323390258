import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import { TangiTypography } from '_components/TangiLibrary';

export const PeopleContainer = styled.div`
  width: 93%;
  margin: 32px auto 0;
`;

// ----- Search section ----- //

export const FilterSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

// ----- Table sections ----- //

export const SectionHeader = styled.div`
  margin-top: 40px;
  margin-bottom: ${SPACING[3]};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableContainer = styled.div`
  overflow: visible !important;

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// ----- Table Cells ----- //
export const ActionsCell = styled.div`
  display: flex;
  gap: 8px;
`;

export const StartExitDates = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: 'DIN2014-Regular';
  padding: 10px 0px 8px 4px;
  color: ${NEUTRAL_SHADES[1100]};
  div:last-of-type {
    font-size: 12px;
    color: ${NEUTRAL_SHADES[800]};
  }
`;

export const MagicIconContainer = styled.div`
  margin: 2px 0px 0px 8px;
`;

export const CheckboxContainer = styled.div`
  margin-right: -3px;
  margin-bottom: 3px;
`;

export const NameCellContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: nowrap;
  gap: ${SPACING[2]};
  align-items: center;
`;

export const NameCellFirstChild = styled.div`
  flex-shrink: 0;
  overflow: visible !important;
`;

export const PeopleNameTypography = styled(TangiTypography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  min-width: 0;
  max-width: 180px;
`;
