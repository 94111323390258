import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 3.5%;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const DuoTitleContainer = styled(TitleContainer)`
  margin-bottom: 4px;
`;

export const ButtonContainer = styled.div`
  width: fit-content;
`;

export const PartnerHeadline = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const Section = styled.div`
  font-family: 'DIN2014-Regular';
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  &:nth-of-type(4) {
    gap: 8px;
  }
`;
export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 16px;
  border-radius: 4px;
  background-color: ${NEUTRAL_SHADES.WHITE};
  border: 1px solid ${NEUTRAL_SHADES[150]};
`;
export const SummaryTextContainer = styled.div`
  display: flex;
  gap: 4px;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

export const SummaryAvatarContainer = styled(SummaryTextContainer)`
  gap: 8px;
`;

export const NoPeopleComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  font-weight: 200;
  font-size: 15px;
  padding: 25px;
`;

export const DuoCenteredContainer = styled(FlexContainer)`
  align-items: center;
  height: fit-content;
`;

export const DropdownItemContainer = styled(DuoCenteredContainer)`
  gap: 12px;
  min-width: ${SPACING[9]};
  left: 132px;
`;

export const AccountsTableContainer = styled(FlexContainer)`
  align-items: flex-end;
`;

export const AdditionalAccounts = styled.div`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DuoAvatarContainer = styled(FlexContainer)`
  .tangi-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DuoAvatarTableContainer = styled(FlexContainer)`
  align-items: baseline;

  .tangi-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 96px;
  }
`;

export const DisplayNameContainer = styled.div`
  max-width: 90%;
`;

export const AssetTypeContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .tangi-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
`;

export const partnershipLinkStyles = `
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const DeactivationPopupBody = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[3]};
  .popup-bold-text {
    font-family: 'DIN2014-DemiBold';
  }
`;

export const customTableStyles = {
  table: {
    style: {
      overflow: 'visible !important',
    },
  },
  tableWrapper: {
    style: {
      overflow: 'visible !important',
    },
  },
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[200]} !important`,
      minHeight: '42px',
      maxHeight: '42px',
      border: 'none !important',
    },
  },
  headCells: {
    style: {
      paddingTop: '10px',
      paddingBottom: '12px',
    },
  },
  rows: {
    style: {
      borderBottom: `1px solid ${NEUTRAL_SHADES[300]} !important`,
      overflow: 'visible !important',
    },
  },
  pagination: {
    style: {
      borderTop: 'unset',
    },
  }
};

export const CustomTooltip = styled.div`
  position: absolute;
  width: 316px;
  background: ${NEUTRAL_SHADES.WHITE};
  border-radius: 4px;
  box-shadow: 1px 2px 8px 0px #00000026;
  z-index: 1000;
  height: 300px;
`;

export const TooltipHeader = styled.div`
  padding: ${SPACING[2]};
  border-bottom: 1px solid ${NEUTRAL_SHADES[200]};
`;

export const TooltipBody = styled.div`
  padding: ${SPACING[2]};
  display: flex;
  flex-direction: column;
  gap:${SPACING[2]};
  overflow-y: scroll;
  height: calc(100% - 40px);

  ::-webkit-scrollbar {
    width: ${SPACING[1.5]};
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  top: -5px; 
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white; 
`;