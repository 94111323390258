import styled from 'styled-components';

import { FlexSpan } from '_components/ActivityLog/style';
import { Avatar } from '_components/Avatar';
import { TangiTypography } from '_components/TangiLibrary';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Recipient } from 'utils/types/assets/assets';
import { SPACING } from 'utils/theme';

type FlatSimplePerson = { displayName: string; email: string; _id: string };
type NestedRecipients = Recipient[][];
type FlatPeople = FlatSimplePerson[];

interface Props {
  people: NestedRecipients | FlatPeople;
  marginTop?: string;
}

const Container = styled.div`
  padding: ${SPACING[0]} ${SPACING[7]} ${SPACING[0]} ${SPACING[3.5]};
`;

export const AssetActivityNotifyContent = ({ people, marginTop }: Props) => {
  const renderByAsAccount = (personAccount: Recipient | FlatSimplePerson) => {
    const displayName = (personAccount as Recipient).fullName ?? (personAccount as FlatSimplePerson).displayName ?? personAccount.email;

    return (
      <FlexSpan key={personAccount._id} marginTop={marginTop}>
        <Avatar size={AVATAR_SIZES.XS} name={displayName} email={personAccount.email || ''} />
        <TangiTypography type="subheading" weight="bold">
          {displayName}
        </TangiTypography>
      </FlexSpan>
    );
  };

  const isNested = Array.isArray(people[0]);

  const children = isNested ? (people as NestedRecipients).flatMap((nestedArray) => nestedArray.map(renderByAsAccount)) : (people as FlatPeople).map(renderByAsAccount);

  return <Container style={{ marginTop }}>{children}</Container>;
};
