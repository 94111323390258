import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import CoursesProgress from '_components/CoursesProgress/CoursesProgress';
import MetaDataListDisplay from '../MetaDataListDisplay/MetaDataListDisplay';
import { TangiCheckbox } from '_components/TangiLibrary';
import { AvatarEmail } from '_components/Avatar/AvatarEmail';
import { PeopleOptions } from '_components/PeopleOptions/PeopleOptions';
import { TangiAccountStatus } from '_components/TangiLibrary';
import { AVATAR_SIZES } from '../../../../utils/componentUtils';
import { formatDate } from '../../../../utils/dateUtils';
import { SPACING } from '../../../../utils/theme';
import { CREATED_FROM } from '../../../../utils/enums';
import { isRoleMatch, IRoles } from '../../../../utils/roles';
import { ACCOUNT_TYPE } from '../../../../utils/enums';
import { MetaDataListDisplayType } from '../MetaDataListDisplay/types';
import { ReactComponent as MagicIcon } from '../../../../assets/tangiIcons/discovery.svg';
import { ActionsCell, StartExitDates, MagicIconContainer, NameCellContainer, NameCellFirstChild, PeopleNameTypography, CheckboxContainer } from '../../style';

const renderMagicIcon = (value, t) => {
  return value?.createdFrom === CREATED_FROM.EMAIL_FINDER ? (
    <MagicIconContainer>
      <Tippy content={<div className="text-xs">{t('PEOPLE.TOOLTIP.CREATED_BY_FINDER', { createdAt: formatDate(value?.createdAt) })}</div>}>
        <MagicIcon />
      </Tippy>
    </MagicIconContainer>
  ) : (
    value?.createdFrom === CREATED_FROM.EMAIL_TRACKER && (
      <MagicIconContainer>
        <Tippy content={<div className="text-xs">{t('PEOPLE.TOOLTIP.CREATED_BY_TRACKER', { createdAt: formatDate(value?.createdAt) })}</div>}>
          <MagicIcon />
        </Tippy>
      </MagicIconContainer>
    )
  );
};

const Actions = ({ account, user, handleViewData }) => {
  return (
    <ActionsCell>
      {user._id !== account.user && (
        <PeopleOptions accountStatus={account.status} accountId={account.id} handleEdit={() => handleViewData(account, 'Add Contractors', 'edit')} dropdownStyles={{ right: SPACING[5] }} />
      )}
    </ActionsCell>
  );
};

export const TableData = ({ data, user, Role, handleViewData, hoveredId, setHoveredId, selectedUsers, handleCheckboxToggle, isAllSelected, type, isCreatedFromSharePoint, t }) => {
  if (!data || data.length === 0) return [];

  return data.reduce((acc, value) => {
    const isMyself = user?.username === value.username;
    const isCheckboxDisabled = isAllSelected || isMyself;

    acc.push({
      id: value.id,
      onClick: () => handleViewData(value, `Add ${type === ACCOUNT_TYPE.CONTRACTOR ? 'Contractors' : 'Employees'}`, 'view'),
      name: (
        <NameCellContainer data-testid={`user-${value.id}`}>
          <NameCellFirstChild onMouseEnter={() => setHoveredId(value.id)} onMouseLeave={() => setHoveredId(null)}>
            {!isMyself && (isAllSelected || selectedUsers.some((users) => users.id === value.id) || hoveredId === value.id) && !(Role && isRoleMatch(Role, IRoles.EMPLOYEE_MANAGER)) ? (
              <CheckboxContainer>
                <TangiCheckbox
                  name={value.id}
                  dataTestId={value.id}
                  onChange={() => handleCheckboxToggle(value.id, value.status, value.email)}
                  checked={selectedUsers.some((selectedUser) => selectedUser.id === value.id) || isAllSelected}
                  disabled={isCheckboxDisabled}
                />
              </CheckboxContainer>
            ) : (
              <AvatarEmail showSharePointIcon={isCreatedFromSharePoint(value)} dataTestId={value.id} size={AVATAR_SIZES.XS} isLinkDisabled name={value?.displayName || '-'} email={value.email} />
            )}
          </NameCellFirstChild>
          <Tippy content={<div className="font-bold text-xs">{value?.email || '-'}</div>}>
            <span onClick={() => handleViewData(value, `Add ${type === ACCOUNT_TYPE.CONTRACTOR ? 'Contractors' : 'Employees'}`, 'view')}>
              <PeopleNameTypography weight="semibold">{value?.displayName || '-'}</PeopleNameTypography>
            </span>
          </Tippy>
          {renderMagicIcon(value, t)}
        </NameCellContainer>
      ),
      startdate: (
        <StartExitDates>
          <div data-testid={`${type}-hire-date`}>{value?.dateOfJoining ? formatDate(value.dateOfJoining) : ''}</div>
          <div data-testid={`${type}-exit-date`}>{value?.dateOfLeaving ? formatDate(value.dateOfLeaving) : ''}</div>
        </StartExitDates>
      ),
      departments: <MetaDataListDisplay metadataArray={value?.departments ?? []} type={MetaDataListDisplayType.DEPARTMENT} />,
      businessUnits: <MetaDataListDisplay metadataArray={value?.businessUnits ?? []} type={MetaDataListDisplayType.BUSINESS_UNIT} />,
      training: <CoursesProgress coursesArray={value.courseProgression} />,
      editdelete: <Actions account={value} user={user} handleViewData={handleViewData} />,
      accountStatus: <TangiAccountStatus status={value.status} />,
    });
    return acc;
  }, []);
};

export const FormerTableData = ({ data, handleViewData, setHoveredId, type, isCreatedFromSharePoint, t }) => {
  if (!data || data.length === 0) return [];

  return data.reduce((acc, value) => {
    acc.push({
      id: value.id,
      onClick: () => handleViewData(value, `Add ${type === ACCOUNT_TYPE.FORMER_EMPLOYEES ? 'FormerEmployees' : 'Employees'}`, 'view'),
      name: (
        <NameCellContainer data-testid={`user-${value.id}`}>
          <NameCellFirstChild onMouseEnter={() => setHoveredId(value.id)} onMouseLeave={() => setHoveredId(null)}>
            <AvatarEmail showSharePointIcon={isCreatedFromSharePoint(value)} dataTestId={value.id} size={AVATAR_SIZES.XS} isLinkDisabled name={value?.displayName || '-'} email={value.email} />
          </NameCellFirstChild>
          <Tippy content={<div className="font-bold text-xs">{value?.email || '-'}</div>}>
            <span onClick={() => handleViewData(value, `Add ${type === ACCOUNT_TYPE.FORMER_EMPLOYEES ? 'FormerEmployees' : 'Employees'}`, 'view')}>
              <PeopleNameTypography weight="semibold">{value?.displayName || '-'}</PeopleNameTypography>
            </span>
          </Tippy>
          {renderMagicIcon(value, t)}
        </NameCellContainer>
      ),
      exitdate: (
        <StartExitDates>
          <div data-testid={`${type}-exit-date`}>{value?.dateOfLeaving ? formatDate(value.dateOfLeaving) : ''}</div>
        </StartExitDates>
      ),
      accountStatus: <TangiAccountStatus status={value.status} />,
    });
    return acc;
  }, []);
};
