import * as ImportedIcon from './svgFiles';
import { NEUTRAL_SHADES } from '../../../utils/theme';
import { IconContainer } from './style';


export type TangiSvgIconTypes = keyof typeof ImportedIcon;
export interface ITangiSvgIconProps {
  color?: string;
  component: TangiSvgIconTypes;
  size?: string | undefined;
  className?: string;
}

export const TangiSvgIcon = ({ color = NEUTRAL_SHADES.BLACK, component, size = undefined, className = '' }: ITangiSvgIconProps) => {
  const Icon = ImportedIcon[component];

  return (
    <IconContainer color={color} size={size} className={`tangi-icon-container ${className}`}>
      <Icon className={`tangi-icon-svg ${className}`} data-testid={`icon-${component}`} />
    </IconContainer>
  );
};
