import { TangiChip } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { ChipContainer } from '_components/ThirdPartyTag/style';
import Tippy from '@tippyjs/react';

type ThirdPartyTagProps = {
  tooltipText?: string;
};

export const ThirdPartyTag = ({ tooltipText }: ThirdPartyTagProps) => {
  const { t } = useTranslation();

  return (
    <ChipContainer>
      <Tippy content={<div className="font-bold text-xs">{tooltipText}</div>} disabled={!tooltipText}>
      <div>
        <TangiChip text={t('ASSET_PAGE.TOOLTIP.THIRD_PARTY')} variant={'tag'} />
      </div>
      </Tippy>
    </ChipContainer>
  );
};
