import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

import { TangiAccountStatus, TangiButton } from '../TangiLibrary';
import { PeopleOptions } from '../PeopleOptions/PeopleOptions';
import { AgreementSkeletonViewEmployee } from '../AgreementSkeleton/AgreementSkeleton';
import TableSkeleton from '_components/TableSkeleton/TableSkeleton';
import ViewItem from './components/ViewItem';
import AgreementsSection from './components/AgreementsSection';
import { getColumns, tableTypes, shouldAddComma } from './utils';
import { useViewEmployee } from './useViewEmployee';
import { formatDate } from '../../utils/dateUtils';
import { isUserIsRole } from '../../utils/roles';
import { ACCOUNT_STATUS } from 'utils/enums';
import editIcon from '../../assets/icons/Edit-white.svg';
import { Container, Section, Headline, AccountDataContainer, AccountDetails, AccountDataSection, ButtonsContainer } from './styles';
import { customTableStyles } from '_components/ClientAssetsTable/style';

export const ViewEmployee = ({ handleClose, handleClickEdit, permissionsToAddAgreements }) => {
  const user = useSelector((state) => state.authentication.user);
  const activeAccount = useSelector((state) => state.authentication.activeAccount);
  const { assetsLoading, agreementsLoading, account: accountData } = useSelector((state) => state.users.peoplePage.viewEditAccount);

  const { departments, courseProgression, agreements, role, assets, displayName, title, dateOfJoining, businessUnits, email, status, dateOfLeaving } = accountData;
  const { ownedSortState, sharedSortState, handleSharedSort, handleOwnedSort, settings, isAdminOrLawyer } = useViewEmployee(accountData);

  const isUserLearnType = useMemo(() => isUserIsRole(activeAccount, 'Learn'), [activeAccount]);

  const { t } = useTranslation();

  if (!accountData) return null;

  return (
    <Container>
      {permissionsToAddAgreements && status !== ACCOUNT_STATUS.TERMINATED && (
        <ButtonsContainer>
          {user._id !== accountData.user && <PeopleOptions accountId={accountData.id} onFinish={handleClose} accountStatus={status} dropdownStyles={{ right: '110px' }} handleEdit={handleClickEdit} />}
          <TangiButton text={t('GENERAL.BUTTONS_TEXT.EDIT')} icon={editIcon} size="small" onClick={handleClickEdit} />
        </ButtonsContainer>
      )}
      <Section>
        <AccountDataContainer>
          <div>{t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DETAILS')}</div>
          <AccountDetails>
            <AccountDataSection>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.NAME')}
                <b> {displayName}</b>
              </span>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.EMAIL')}
                <b> {email}</b>
              </span>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.TITLE')}
                <b> {title?.length ? title : '-'}</b>
              </span>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.DEPARTMENTS')}
                <b> {departments?.length > 0 ? departments?.map((department, index) => `${shouldAddComma(departments.length, index) ? ', ' : ''}${department.name}`) : '-'}</b>
              </span>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.BUSINESS_UNITS')}
                <b> {businessUnits?.length > 0 ? businessUnits?.map((unit, index) => `${shouldAddComma(businessUnits.length, index) ? ', ' : ''}${unit.name}`) : '-'}</b>
              </span>
            </AccountDataSection>
            <AccountDataSection>
              <span className="view-account-status">
                <div>{t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.STATUS')}</div>
                <TangiAccountStatus status={status} />
              </span>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.ROLE')}
                <b> {role.name}</b>
              </span>
              <span>
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.HIRE_DATE')} <b> {dateOfJoining ? formatDate(dateOfJoining) : '-'}</b>
              </span>
              <span data-testid="exit-date">
                {t('PEOPLE.VIEW_EMPLOYEE.ACCOUNT_DATA.EXIT_DATE')} <b> {dateOfLeaving ? formatDate(dateOfLeaving) : '-'}</b>
              </span>
            </AccountDataSection>
          </AccountDetails>
        </AccountDataContainer>
      </Section>
      {!isUserLearnType && (
        <>
          <Section>
            <Headline>{t('PEOPLE.VIEW_EMPLOYEE.HEADER.AGREEMENTS')}</Headline>
            {agreementsLoading ? <AgreementSkeletonViewEmployee /> : <AgreementsSection agreements={agreements} settings={settings} role={role} />}
          </Section>

          {isAdminOrLawyer && (
            <>
              <Section data-testid="assets-created-table">
                <Headline>{t('PEOPLE.VIEW_EMPLOYEE.HEADER.ASSETS_CREATED', { ASSET_LENGTH: assets?.created?.length ?? 0 })}</Headline>
                <DataTable
                  columns={getColumns(tableTypes.ASSETS_CREATED, ownedSortState)}
                  data={assets?.created}
                  onSort={handleOwnedSort}
                  sortIcon={<i className="" />}
                  customStyles={customTableStyles}
                  progressPending={assetsLoading}
                  progressComponent={<TableSkeleton />}
                />
              </Section>
              <Section data-testid="assets-contributors-table">
                <Headline>{t('PEOPLE.VIEW_EMPLOYEE.HEADER.ASSETS_CONTRIBUTED', { ASSET_LENGTH: assets?.assetsContributors?.length ?? 0 })}</Headline>
                <DataTable
                  columns={getColumns(tableTypes.ASSETS_CONTRIBUTORS, sharedSortState)}
                  data={assets?.assetsContributors}
                  onSort={handleSharedSort}
                  sortIcon={<i className="" />}
                  customStyles={customTableStyles}
                  progressPending={assetsLoading}
                  progressComponent={<TableSkeleton />}
                />
              </Section>
              <Section data-testid="assets-shared-table">
                <Headline>{t('PEOPLE.VIEW_EMPLOYEE.HEADER.ASSETS_SHARED', { ASSET_SHARED_LENGTH: assets?.sharedWith?.length ?? 0 })}</Headline>
                <DataTable
                  columns={getColumns(tableTypes.ASSETS_SHARED, sharedSortState)}
                  data={assets?.sharedWith}
                  onSort={handleSharedSort}
                  sortIcon={<i className="" />}
                  customStyles={customTableStyles}
                  progressPending={assetsLoading}
                  progressComponent={<TableSkeleton />}
                />
              </Section>
            </>
          )}
        </>
      )}
      <Section>
        <Headline>{t('PEOPLE.VIEW_EMPLOYEE.HEADER.TRAINING')}</Headline>
        {courseProgression?.map((course, index) => (
          <ViewItem key={index} imgUrl={course.featuredVideoUrl} title={course.title} status={course.progression} showStatus />
        ))}
      </Section>
    </Container>
  );
};
