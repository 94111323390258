import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { TangiToast } from '_components/TangiLibrary';
import AddPartnershipModal from '_components/AddPartnershipModal/AddPartnershipModal';
import PartnerSideMenuDrawer from '_components/PartnersSideMenuDrawer/PartnerSideMenuDrawer';
import { RootState } from '_helpers';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { getPartnerships } from 'redux-toolkit/thunks/partnershipThunks';
import { PartnerUserFormType } from '_components/PartnershipInviteUsersModal/types';

interface Props {
  handleNavLinkClick: () => void;
}

const PartnershipCreationSection = ({ handleNavLinkClick }: Props) => {
  const allPartnership = useSelector((state: RootState) => state.partnership.partnerships);
  const { partnershipToastProps, partnerMenuIsOpen, partnershipCreateSuccess } = useSelector((state: RootState) => state.partnership);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Boolean(allPartnership?.active?.length) && !Boolean(allPartnership?.disabled?.length) && partnerMenuIsOpen) {
      dispatch(getPartnerships());
    }
  }, [partnerMenuIsOpen]);

  return (
    <>
      <AddPartnershipModal />
      <PartnerSideMenuDrawer toggled={partnerMenuIsOpen} onToggle={handleNavLinkClick} partnerships={allPartnership} onPartnershipClick={handleNavLinkClick} />
      <TangiToast
        {...partnershipToastProps}
        onSuccess={() => {
          if (partnershipCreateSuccess) {
            dispatch(partnershipActions.setInviteUsersModalType(PartnerUserFormType.ADD_INITAL));
            dispatch(partnershipActions.setInviteUsersModalState(true));
          }
          dispatch(partnershipActions.clearPartnership());
        }}
      />
    </>
  );
};

export default PartnershipCreationSection;
