import { ACCOUNT_STATUS } from 'utils/enums';
import { IRoles } from 'utils/roles';

export enum PartnerUserFormType {
  EDIT = 'EDIT',
  ADD = 'ADD',
  ADD_INITAL = 'ADD_INITAL',
}

export type SelectorType = {
  value: string | number | boolean;
  label: string;
};

export type PartnerAccountInput = {
  email: string;
  displayName: string;
  externalUser: boolean;
  departments?: { id: string; name: string }[];
  role?: { value: IRoles }; 
  isActive?: boolean;
  status?: ACCOUNT_STATUS;
  id?: string;
};

export type PartnerAccountFormEntry = {
  email: string;
  displayName: string;
  externalUser: boolean;
  departments?: { id: string; name: string }[];
  role?: { value: IRoles }; 
  isActive?: boolean;
};

export interface UserInput {
  email?: string;
  name?: string;
  externalUser?: boolean;
  role: IRoles | { value: IRoles };
  status?: ACCOUNT_STATUS;
  departments: SelectorType[] | SelectorType["value"][];
  displayName?: string;
  isActive?: boolean;
}