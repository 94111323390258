import { Avatar } from "_components/Avatar";
import { DuoAvatarTableContainer } from "./styles";
import { TangiSvgIcon } from "_components/TangiLibrary";
import Tippy from "@tippyjs/react";
import { AVATAR_SIZES } from "utils/componentUtils";
import { NEUTRAL_SHADES } from "utils/theme";
import { TangiTypography } from '_components/TangiLibrary';
import { SharedWith } from "utils/types/partnership/partnership";

interface Props {
  account: SharedWith;
  key: number;
}

const AccountAndAvatar = ({ account, key }: Props) => {
  return (
    <DuoAvatarTableContainer key={key}>
      {
        !!account.partner ?
          <>
            {
              !!account.partner.logo ?
                <Avatar size={AVATAR_SIZES.XS} name={account.partner.name} tooltip={false} image={account.partner.logo} />
                :
                <TangiSvgIcon component="partnerEmptyStateIcon" color={NEUTRAL_SHADES.WHITE} size={AVATAR_SIZES.XS} />
            }
            <Tippy content={account.partner.name}>
              <div>
                <TangiTypography>{account.partner.name}</TangiTypography>
              </div>
            </Tippy>
          </>
          : !!account?.department ?
            <>
              <TangiSvgIcon component="groupIcon" size={AVATAR_SIZES.XS} color={NEUTRAL_SHADES.WHITE} />
              <Tippy content={account.department.name}>
                <div>
                  <TangiTypography>{account.department.name}</TangiTypography>
                </div>
              </Tippy>
            </>
            :
            <>
              <Avatar size={AVATAR_SIZES.XS} name={account?.fullName || account.email} email={account.email} />
              <Tippy content={account?.fullName || account.email}>
                <div>
                  <TangiTypography>{account?.fullName || account.email}</TangiTypography>
                </div>
              </Tippy>
            </>
      }
    </DuoAvatarTableContainer>

  );
};

export default AccountAndAvatar;
