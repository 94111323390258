import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector, batch } from 'react-redux';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TangiAccountStatus, TangiButton, TangiTypography, TANGI_BUTTON_SIZES } from '../../_components/TangiLibrary';
import PartnershipTableSkeleton from '../../_components/TableSkeleton/PartnershipTableSkeleton';
import { AvatarEmail } from '../../_components/Avatar/AvatarEmail';
import { PeopleOptions } from '../../_components/PeopleOptions/PeopleOptions';
import AssetAcknowledgmentSummary from '../../_components/AssetAcknowledgmentSummary/AssetAcknowledgmentSummary';

import { acknowledgedCount, extractAssetAcknowledgment } from '../../utils/acknowledgementUtils';
import { formatDate } from '../../utils/dateUtils';
import { getColumns, tableTypes } from './utils';
import { PARTNERSHIP_STATUS } from 'utils/enums';
import { AVATAR_SIZES } from '../../utils/componentUtils';
import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import {
  ButtonContainer,
  NoPeopleComponent,
  DuoAvatarContainer,
  DisplayNameContainer,
  customTableStyles,
  DuoTitleContainer,
  DuoCenteredContainer,
} from './styles';
import { MetaDataListDisplayType } from 'pages/People/Components/MetaDataListDisplay/types';
import MetaDataListDisplay from 'pages/People/Components/MetaDataListDisplay/MetaDataListDisplay';
import { getPartnershipAccountsById, getPartnershipById } from 'redux-toolkit/thunks/partnershipThunks';
import PartnershipInviteUsersModal from '_components/PartnershipInviteUsersModal/PartnershipInviteUsersModal';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { PartnerUserFormType } from '_components/PartnershipInviteUsersModal/types';

export const PertnerPeopleSection = ({ partnershipId }) => {
  const { partnership, partnershipAccountsLoading, partnershipAccountsRes } = useSelector((state) => state.partnership);

  const initialPagination = { page: 1, limit: 10 };
  const accountsLength = partnershipAccountsRes?.metadata?.[0]?.total;
  const { status: userStatus, invitedMultipleAccounts } = useSelector((state) => state.users);

  const [people, setPeople] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [currentPartnerData, setCurrentPartnerData] = useState({});

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isPartnershipDisabled = useMemo(() => partnership?.status === PARTNERSHIP_STATUS.DISABLED, [partnership]);

  useEffect(() => {
    dispatch(getPartnershipAccountsById({ partnershipId, pagination }));
  }, [partnershipId, pagination]);

  useEffect(() => {
    if(invitedMultipleAccounts?.length>0 || userStatus){
      dispatch(getPartnershipAccountsById({ partnershipId, pagination }));
    }
  }, [invitedMultipleAccounts,userStatus]);

  useEffect(() => {
      dispatch(getPartnershipAccountsById({ partnershipId, pagination }));
  }, [isPartnershipDisabled]);


  useEffect(() => {
    if (partnershipAccountsRes?.data) {
      const accounts = partnershipAccountsRes?.data.map((account) => {
        return {
          id: account._id,
          name: (
            <DuoAvatarContainer>
              <AvatarEmail size={AVATAR_SIZES.XS} isLinkDisabled name={account?.displayName || account.email} email={account.email} />
              <Tippy content={account.displayName || ''}>
                <DisplayNameContainer>
                  <TangiTypography className="text-capitalize" weight="semibold">
                    {account?.displayName || account.email || '-'}
                  </TangiTypography>
                </DisplayNameContainer>
              </Tippy>
            </DuoAvatarContainer>
          ),
          accountStatus: <TangiAccountStatus status={account.status} />,
          departments: <MetaDataListDisplay metadataArray={account.departments.map(department => ({ name: department.name })) ?? []} type={MetaDataListDisplayType.DEPARTMENT} />,
          assetsCount: (
            <span>
              {account?.assets?.length ? (
                <AssetAcknowledgmentSummary
                  acknowledgedCount={acknowledgedCount(account?.assets)}
                  assetsAcknowledgmentInfo={extractAssetAcknowledgment(account?.assets)}
                  totalShared={account?.assets?.length}
                />
              ) : (
                ''
              )}
            </span>
          ),
          createdAt: <TangiTypography>{account?.createdAt ? formatDate(account.createdAt) : ''}</TangiTypography>,
          editdelete: <PeopleOptions accountStatus={account.status} accountId={account._id} shouldReloadPage partnership={partnership} dropdownStyles={{ right: SPACING[4] }} handleEdit={!isPartnershipDisabled && (() => handleOpenEditPartnerModal(account))} />,
        };
      });
      setPeople(accounts);
    }
  }, [partnershipAccountsRes, isPartnershipDisabled, partnership]);

    const handleOpenPartnershipModal = () => {
      dispatch(partnershipActions.setInviteUsersModalType(PartnerUserFormType.ADD))
    dispatch(partnershipActions.setInviteUsersModalState(true))
  };

  const handleClosePartnerModal = () => {
    batch(() => {
      dispatch(usersActions.setClearInvitedMultiple());
      dispatch(partnershipActions.setInviteUsersModalState(false));
      dispatch(getPartnershipById(partnershipId));
    });
    setCurrentPartnerData({});
  };
  const handleOpenEditPartnerModal = (account) => {
    setCurrentPartnerData({
      status: account.status,
      displayName: account.displayName,
      email: account.email,
      externalUser: account.externalUser,
      id: account._id,
      departments: account.departments,
    });
    dispatch(partnershipActions.setInviteUsersModalState(true))
    dispatch(partnershipActions.setInviteUsersModalType(PartnerUserFormType.EDIT))
  };



  const renderPeopleSectionHeader = () => {
    return (
      <DuoTitleContainer>
        <DuoCenteredContainer>
          <TangiTypography type="heading-md" weight="semibold">
            {t('PARTNERSHIP_PAGE.HEADERS.PEOPLE')}
          </TangiTypography>
          {!!accountsLength && <TangiTypography color={NEUTRAL_SHADES[800]}>{`(${accountsLength})`}</TangiTypography>}
        </DuoCenteredContainer>
        {!!accountsLength && !partnershipAccountsLoading && (
          <ButtonContainer>
            <Tippy content={t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.EDIT_DISABLED')} disabled={!isPartnershipDisabled}>
              <span>
                <TangiButton text={t('GENERAL.BUTTONS_TEXT.ADD_USER_CAPITAL')} svgIcon="add" size={TANGI_BUTTON_SIZES.SMALL} onClick={handleOpenPartnershipModal} disabled={isPartnershipDisabled} />
              </span>
            </Tippy>
          </ButtonContainer>
        )}
      </DuoTitleContainer>
    );
  };

  return (
    <>
      {renderPeopleSectionHeader()}
      <div className="people-table-container overflow-fix" data-testid={'partner-people-table'}>
        <DataTable
          pagination
          noDataComponent={
            <NoPeopleComponent>
              <p>{t('PARTNERSHIP_PAGE.TABLE.NO_PEOPLE_YET')}</p>
              <ButtonContainer>
                <TangiButton text={t('GENERAL.BUTTONS_TEXT.ADD_USER_CAPITAL')} svgIcon="add" size={TANGI_BUTTON_SIZES.SMALL} onClick={handleOpenPartnershipModal} disabled={isPartnershipDisabled} />
              </ButtonContainer>
            </NoPeopleComponent>
          }
          customStyles={customTableStyles}
          persistTableHead={true}
          columns={getColumns(tableTypes.PEOPLE, false)}
          data={people}
          progressPending={partnershipAccountsLoading}
          progressComponent={<PartnershipTableSkeleton />}
          sortIcon={<i className="" />}

          paginationDefaultPage={pagination.page}
          onChangeRowsPerPage={(pageRows) => setPagination({ page: 1, limit: pageRows })}
          onChangePage={(page) => setPagination({ ...pagination, page })}
          paginationServer
          paginationTotalRows={accountsLength}
        />
        <PartnershipInviteUsersModal onFinish={handleClosePartnerModal} partnerData={currentPartnerData}/>
      </div>
    </>
  );
};
