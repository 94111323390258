import styled from 'styled-components';

import { getThumbnailUrl } from 'utils/getThumbnailUrl';
import successIcon from '../../../assets/img/check-circle-solid.png';
import pendingIcon from '../../../assets/img/exclamation-circle-solid.png';
import { SPACING } from 'utils/theme';

const ViewItemContainer = styled.div`
  display: flex;
  height: 54px;
  width: 100%;
  border: 1px solid #dcdcdc;
  padding-right: ${SPACING[3]};
  text-align: center;
  align-items: center;
  border-radius: ${SPACING[1]};
  .item-name {
    margin-left: 18px;
  }
  .item-status-data {
    display: flex;
    margin-left: auto;
    color: #a0a0a0;
    gap: 18px;
  }
  img {
    margin-left: ${SPACING[2]};
  }
`;

const ViewItem = ({ title, status, statusText, imgUrl, showStatus }) => {
  return (
    <ViewItemContainer>
      {imgUrl && <img height="42" src={getThumbnailUrl(imgUrl)} alt="course-img" />}
      <div className="item-name">{title}</div>
      {showStatus && (
        <div className="item-status-data">
          {statusText}
          <img className="m-auto" width="16" height="16" alt="..." src={status ? successIcon : pendingIcon} />
        </div>
      )}
    </ViewItemContainer>
  );
};

export default ViewItem;
