import { SelectOption } from '_components/DistributeAssetModal/useHandleRecipientEntitySearch/types';
import { Container } from './styles';
import { RecipientCategoryType } from 'utils/types/assets/assets';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { renderPartnershipDepartmentLabel, renderPartnershipLabel, renderRecipientLabel } from '_components/DistributeAssetModal/useHandleRecipientEntitySearch/utils';

interface Props {
  data: SelectOption;
  removeProps: { onClick: () => void };
}

export const CustomSelectValue = ({ data, removeProps }: Props) => {
  const renderDataByType = (data: SelectOption) => {
    switch (data.type) {
      case RecipientCategoryType.PARTNER_DEPARTMENT:
        return (
          <>
            <TangiSvgIcon component="department" color={NEUTRAL_SHADES.WHITE} />
            {renderPartnershipDepartmentLabel(data.name, data.partnershipData)}
          </>
        );
      case RecipientCategoryType.PARTNER:
        return <>{renderPartnershipLabel(data.name, data?.partnershipData?.logo)}</>;
      case RecipientCategoryType.INDIVIDUAL:
        return <>{renderRecipientLabel({displayName: data.name, email:data.email})}</>;
      case RecipientCategoryType.DEPARTMENT:
      case RecipientCategoryType.BUSINESS_UNIT:
        return <>{data.label}</>;
      default:
        return '';
    }
  };
  return (
    <Container>
      {renderDataByType(data)}
      <div style={{ cursor: 'pointer' }} onClick={removeProps.onClick}>
        <TangiSvgIcon component="remove" color={NEUTRAL_SHADES[1100]} />
      </div>
    </Container>
  );
};
