import { useTranslation } from 'react-i18next';

import ViewItem from './ViewItem';
import { getFormattedStringDate } from 'utils/dateUtils';
import { getNoDataDisplay } from 'utils/NoDataTable';
import { getSettingRoleName } from '../utils';

const AgreementsSection = ({ agreements: agreementsData, settings, role }) => {
  const { t } = useTranslation();

  switch (agreementsData?.length) {
    case 2:
      return agreementsData.map((agreement) => {
        const statusText = agreement.signed ? t('PEOPLE.VIEW_EMPLOYEE.EXECUTED_ON', { SIGNED_DATE: getFormattedStringDate(agreement.signed_date) }) : t('PEOPLE.VIEW_EMPLOYEE.PENDING');
        return <ViewItem key={agreement._id} showStatus title={agreement.agreementType.name} statusText={statusText} status={agreement.signed} />;
      });
    case 1: {
      // --- first agreement --- //
      const agreement = agreementsData[0];
      const statusText = agreement.signed ? t('PEOPLE.VIEW_EMPLOYEE.EXECUTED_ON', { SIGNED_DATE: getFormattedStringDate(agreement.signed_date) }) : t('PEOPLE.VIEW_EMPLOYEE.PENDING');

      // --- second agreement --- //
      const settingsAgreements = settings?.agreements[getSettingRoleName(role.name)].sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
      const agreementNotSigned = settingsAgreements.find((settingsAgreement) => settingsAgreement.id !== agreement.agreementType._id);

      return (
        <>
          <ViewItem showStatus title={agreement.agreementType.name} statusText={statusText} status={true} />
          <ViewItem showStatus title={agreementNotSigned?.name || 'N/A'} statusText="pending" status={false} />
        </>
      );
    }
    case 0: {
      const settingsAgreements = settings?.agreements[getSettingRoleName(role.name)].sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
      return settingsAgreements.map((agreement, index) => {
        const statusText = 'pending';
        return <ViewItem key={index} showStatus title={agreement.name} statusText={statusText} status={false} />;
      });
    }
    default:
      return getNoDataDisplay(`${t('PEOPLE.VIEW_EMPLOYEE.TABLE.NO_DATA')}`);
  }
};

export default AgreementsSection;
