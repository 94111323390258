import { createGlobalStyle } from 'styled-components';
import { DEFAULT_ASSET_TYPES, LOCKED_ASSET, CUSTOM_ASSET_TYPE } from './assetTypes';
import { HexColorArray } from 'pages/TradeSecretsAnalysis/components/Charts/types';

export const NEUTRAL_SHADES = {
  WHITE: '#FFFFFF',
  LIGHT_GRAY: '#FAFAFA',
  50: '#F8F9FA',
  100: '#F2F4F5',
  150: '#EFF1F3',
  200: '#E8EBEE',
  300: '#E1E4E7',
  400: '#D8DBDF',
  500: '#C6CBCF',
  600: '#AEB5BA',
  700: '#9AA2A7',
  800: '#73797E',
  900: '#555960',
  1000: '#43464F',
  1100: '#2F3135',
  BLACK: '#000000',
};

export const PRIMARY_SHADES = {
  50: '#F8FAFF',
  100: '#F2F6FD',
  200: '#E8F0FE',
  300: '#D0DFFC',
  400: '#A1C1FA',
  500: '#74A1F8',
  600: '#4A83F7',
  700: '#467EE5',
  800: '#0066FF',
  900: '#2151C5',
  1000: '#163C93',
  1100: '#0D2862',
  1200: '#041431',
  BOOTSTRAP_FOCUS: '#97c0f6',
};

export const THEME_COLORS = {
  PRIMARY_DEFAULT: PRIMARY_SHADES[800],
  PRIMARY_HOVER: PRIMARY_SHADES[900],
  PRIMARY_PRESSED: PRIMARY_SHADES[1000],
  PRIMARY_DISABLED: NEUTRAL_SHADES[150],
  PRIMARY_DISABLED_TEXT: NEUTRAL_SHADES[600],

  SECONDARY_DEFAULT: PRIMARY_SHADES[800],
  SECONDARY_HOVER: PRIMARY_SHADES[900],
  SECONDARY_DISABLED: NEUTRAL_SHADES[600],
  SECONDARY_PRESSED: PRIMARY_SHADES[1000],

  TERTIARY_BACKGROUND_HOVER: PRIMARY_SHADES[100],
  TERTIARY_TEXT_HOVER: PRIMARY_SHADES[800],
  TERTIARY_BACKGROUND_PRESSED: PRIMARY_SHADES[100],
  TERTIARY_TEXT_PRESSED: PRIMARY_SHADES[800],
  TERTIARY_TEXT_DISABLED: NEUTRAL_SHADES[600],
  TERTIARY_TEXT_DEFAULT: PRIMARY_SHADES[800],

  TERTIARY_GREY_TEXT_DEFAULT: PRIMARY_SHADES[1100],
  TERTIARY_GREY_BACKGROUND_HOVER: NEUTRAL_SHADES[300],
  TERTIARY_GREY_TEXT_DISABLED: NEUTRAL_SHADES[700],
};

export const SECONDARY_SHADES = {
  50: '#F6F3FB',
  100: '#F2EEF9',
  200: '#EEE7F8',
  300: '#DCCFF0',
  400: '#C5B5E5',
  500: '#A88FDA',
  600: '#8C6BCE',
  700: '#7743C9',
  800: '#5F10C5',
  900: '#3D1084',
  1000: '#22084C',
  1100: '#080213',
};

export const SUCCESS_SHADES = {
  50: '#E9F6F1',
  100: '#D4EEE3',
  200: '#A8DCC6',
  300: '#7DCBAA',
  400: '#51B98D',
  500: '#26A871',
  600: '#1E865A',
  700: '#176544',
  800: '#284029',
  900: '#19754E',
  1000: '#DCE4E1',
};

export const ERROR_SHADES = {
  50: '#FCEBED',
  100: '#F8D6DB',
  200: '#F1ADB6',
  300: '#EB8492',
  400: '#E45B6D',
  500: '#DD3249',
  600: '#B1283A',
  700: '#851E2C',
  800: '#58141D',
  BOOTSTRAP: '#FF0000',
};

export const WARNING_SHADES = {
  50: '#FDF8ED',
  100: '#F9EBCA',
  200: '#F5DEA7',
  300: '#F1D085',
  400: '#EDC366',
  500: '#F4BA40',
  600: '#DDA939',
  700: '#D4A94F',
  800: '#A5843D',
  900: '#765E2B',
  1000: '#EE9543',
  1100: '#FFB388',
};

export const LABEL_INACTIVE_SHADES = {
  50: '#FFF2F2',
  100: '#FFE7E7',
  200: '#F9D9D9',
  300: '#F0C8C8',
};

export const LABEL_PENDING_SHADES = {
  50: '#FFFEF1',
  100: '#FFFCDF',
  200: '#FBF6CD',
  300: '#F2EDBE',
};

export const LABEL_ACTIVE_SHADES = {
  50: '#F2FFFF',
  100: '#E4FEFF',
  200: '#D1F8FA',
  300: '#B6E7EA',
};

export const ASSET_TYPE_COLORS = {
  [DEFAULT_ASSET_TYPES.CONFIDENTIAL_OTHER]: '#F9B6FF',
  [DEFAULT_ASSET_TYPES.TRADE_SECRET_PATENT_CANDIDATE]: '#B0EDE2',
  [DEFAULT_ASSET_TYPES.UNCLASSIFIED]: ERROR_SHADES[300],
  [DEFAULT_ASSET_TYPES.TRADE_SECRET]: '#D6C3FE',
  [DEFAULT_ASSET_TYPES.TRADE_SECRET_UNPUBLISHED_PATENTS]: '#FFE793',
  [DEFAULT_ASSET_TYPES.NON_CONFIDENTIAL]: '#FFB388',
  [LOCKED_ASSET]: NEUTRAL_SHADES[600],
  [CUSTOM_ASSET_TYPE]: '#A3D3FF',
};

export const tradeSecretsTreeMapPalette: HexColorArray = [
  '#001f4c',
  '#002966',
  '#003380',
  '#003d99',
  '#0047b3',
  '#0052cc',
  '#005ce6',
  '#0066ff',
  '#1a75ff',
  '#3385ff',
  '#4d94ff',
  '#66a3ff',
  '#80b3ff',
  '#99c2ff',
  '#b3d1ff',
  '#cce0ff',
  '#e6f0ff',
];

export const tradeSecretsHeatMapPalette: HexColorArray = [
  '#e6f0ff',
  '#cce0ff',
  '#b3d1ff',
  '#99c2ff',
  '#80b3ff',
  '#66a3ff',
  '#4d94ff',
  '#3385ff',
  '#1a75ff',
  '#0066ff',
  '#005ce6',
  '#0052cc',
  '#0047b3',
  '#003d99',
  '#003380',
  '#002966',
  '#001f4c',
];

export const SPACING = {
  0: '0px',
  0.5: '2px',
  1: '4px',
  1.5: '6px',
  2: '8px',
  2.5: '12px',
  3: '16px',
  3.5: '20px',
  4: '24px',
  4.5: '28px',
  5: '32px',
  6: '40px',
  6.5: '44px',
  7: '48px',
  8: '64px',
  9: '128px',
  10: '256px',
  11: '512px',
};

export const BORDER_RADIUS = {
  2: '2px',
  4: '4px',
  8: '8px',
  40: '40px',
  44: '44px',
  50: '50px',
};

// GRAPHS PALETTES
export const darkColorsPalette: HexColorArray = ['#1a75ff', '#0066ff', '#005ce6', '#0052cc', '#0047b3', '#003d99', '#003380', '#002966', '#001f4c'];
export const PIE_CHART_COLORS = [PRIMARY_SHADES[900], PRIMARY_SHADES[700], PRIMARY_SHADES[500], SECONDARY_SHADES[600], SECONDARY_SHADES[300], NEUTRAL_SHADES[600]];
export const PIE_CHART_SHUFFLED = [PRIMARY_SHADES[900], PRIMARY_SHADES[500], SECONDARY_SHADES[600], SECONDARY_SHADES[300], PRIMARY_SHADES[500], NEUTRAL_SHADES[600]];
export const CUSTOM_ASSET_TYPE_COLORS = [ASSET_TYPE_COLORS[CUSTOM_ASSET_TYPE], ...PIE_CHART_COLORS];

export const GlobalStyle = createGlobalStyle`
  .tangi-modal-backdrop {
    opacity: 0.6 !important;
  }
`;

// ------ TYPES ------
export enum BUTTON_VARIANTS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TERTIARY_GREY = 'tertiary-grey',
  PRIMARY_PURPLE = 'primary-purple',
  SECONDARY_PURPLE = 'secondary-purple',
  DANGER = 'danger',
  OUTLINE_DARK = 'outline-dark',
}

export enum BUTTON_SIZES {
  XS = 20,
  SM = 32,
  MD = 36,
  LG = 40,
}

export enum AVATAR_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
// ------  ------
