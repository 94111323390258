import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { IdAndName } from 'utils/types/types';
import { MetaDataListDisplayType } from './types';
import { LANGUAGE } from 'translations/enums';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

interface Props {
  metadataArray: IdAndName[];
  type: MetaDataListDisplayType;
  isMoreThanOne: boolean;
}

const HoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopSection = styled(FlexContainer)`
  padding: 10px;
  display: flex;
  align-items: center;
  .tangi-typography {
    text-transform: lowercase;
  }
`;

const ListSection = styled(FlexContainer)`
  flex-direction: column;
  border-top: 1px solid ${NEUTRAL_SHADES[200]};
  padding: ${SPACING[3]} 10px 10px;
  gap: ${SPACING[3]};
  text-wrap: nowrap;
`;

const MAX_LIST_ITEMS = 3;

const MetaDataListDisplayHover = ({ metadataArray, type, isMoreThanOne }: Props) => {
  const { i18n, t } = useTranslation();

  const total = metadataArray.length;
  const hasMoreThanMax = metadataArray.length > MAX_LIST_ITEMS;

  const isEnglish = i18n.language === LANGUAGE.ENGLISH;

  return (
    <HoverWrapper>
      <TopSection>
        <TangiTypography>
          {total} {t(`PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.${type}`)}
          {isEnglish && isMoreThanOne && 's'}
        </TangiTypography>
      </TopSection>
      <ListSection>
        {metadataArray.slice(0, MAX_LIST_ITEMS).map((item) => (
          <TangiTypography weight="semibold" key={item._id}>
            {item.name}
          </TangiTypography>
        ))}
        {hasMoreThanMax && <TangiTypography weight="semibold">{t('PEOPLE.PEOPLE_AND_CONTRACTORS_TABLE.AND_MORE', { count: total - MAX_LIST_ITEMS })}</TangiTypography>}
      </ListSection>
    </HoverWrapper>
  );
};

export default MetaDataListDisplayHover;
