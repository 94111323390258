import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CURRENT_APP_VERSION, authConstants } from '_constants/authConstants';
import { RootState } from '_helpers';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { AuthContext } from '_hocs/Cognito/Auth';
import useTokenRefresh from '_hocs/Cognito/useTokenRefresh';
import { LogoutType } from '_services/utils/MixPanel/types';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';
import { writeLogToSentry } from 'utils/sentryLogs';

interface Props {
  children: React.ReactNode;
}

const VersionControlHandler = (props: Props) => {
  const loggedIn = useSelector((state: RootState) => state.authentication.loggedIn);
  const { logout, clearStorageKeepFirstLoginFlag } = useContext(AuthContext);

  useTokenRefresh();

  useEffect(() => {
    const checkAppVersion = () => {
      const storedVersion = loadStateFromLocalStorage(authConstants.APP_VERSION);
      writeLogToSentry({ message: `start checkAppVersion  Current version: ${CURRENT_APP_VERSION}  Stored version: ${storedVersion} loggedIn: ${loggedIn}`, level: 'info' });

      if (loggedIn && storedVersion !== CURRENT_APP_VERSION) {
        writeLogToSentry({ message: `checkAppVersion inside if(loggedIn && storedVersion !== CURRENT_APP_VERSION) Current version: ${CURRENT_APP_VERSION}  Stored version: ${storedVersion}`, level: 'info' });
        
        const oktaUser = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS);
        writeLogToSentry({ message: `checkAppVersion oktaUser: ${oktaUser}`, level: 'info' });
        if (oktaUser) {
          writeLogToSentry({ message: `checkAppVersion is oktaUser`, level: 'info' });
          clearStorageKeepFirstLoginFlag();
        } else {
          const message = `checkAppVersion: Version mismatch or not set. Current version: ${CURRENT_APP_VERSION}, Stored version: ${storedVersion}. Logging out...`;
          writeLogToSentry({ message, level: 'info' });
          logout({ logoutType: LogoutType.Automatic });
          writeLogToSentry({ message: `checkAppVersion after logout`, level: 'info' });
        }
      }

      writeLogToSentry({ message: `checkAppVersion before saveStateToLocalStorage Current version: ${CURRENT_APP_VERSION}, Stored version: ${storedVersion}`, level: 'info' });
      saveStateToLocalStorage(authConstants.APP_VERSION, CURRENT_APP_VERSION);
      
      const newStoredVersion = loadStateFromLocalStorage(authConstants.APP_VERSION);
      writeLogToSentry({ message: `checkAppVersion after saveStateToLocalStorage Current version: ${CURRENT_APP_VERSION}, Stored version: ${storedVersion} newStoredVersion: ${newStoredVersion}`, level: 'info' });
    };

    checkAppVersion();
  }, []);

  return props.children;
};

export default VersionControlHandler;
