/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import React, { useEffect, useState, useCallback } from 'react';
import { isEmpty, debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../_helpers/store';
import { joinDiscoveryEnquiry } from 'redux-toolkit/thunks/clientThunks';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { Asset, SharedRecipient, RecipientCategory, RecipientGroup, RecipientsPartners, PartnershipDetails, PartialPartnerships } from '../../utils/types/assets/assets';
import { Account } from 'utils/types/account/account';
import { TabKeyType } from '../../pages/Asset/Asset';
import { TangiTypography, TangiSvgIcon, TangiChip, TangiSearch, TangiButton, TangiIconButton, IconTypes, TangiToast, TangiCollapse } from '../../_components/TangiLibrary';
import { Avatar } from '../../_components/Avatar';
import { Permissions } from '../../utils/types/role/role';
import { ReactComponent as NoPeople } from '../../assets/img/people.svg';
import { ReactComponent as Person } from '../../assets/img/person.svg';
import { CREATED_FROM, RESULT_STATUS } from '../../utils/enums';
import { formatDate } from '../../utils/dateUtils';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { filterRecipientsByName, filterCategoriesByName, groupAndCombineCategories, filterPartnershipsRecipientsByName, checkIfPartnershipsAreEmpty } from './utils';
import { IRoles, isRoleMatch } from '../../utils/roles';
import emptyAvatar from '../../assets/icons/empty-avatar.svg';
import sendIcon from '../../assets/icons/send-white.svg';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SUCCESS_SHADES } from '../../utils/theme';
import {
  Container,
  RecipientsAndWidgets,
  RecipientsContainer,
  WidgetsContainer,
  RecipientsCard,
  RecipientRow,
  AvatarContainer,
  FlexRow,
  WidgetCard,
  WidgetNum,
  WidgetDataRow,
  EmptyCard,
  NoSearchTextContainer,
  ProChipContainer,
  TooltipContainer,
  RecipientContent,
  recipientsCardLabelStyles,
} from './style';
import { Client } from 'utils/types/client/client';
import { CategoryTypes } from './types';
import { FlexColumn } from '_components/AssetOverview/style';
import PartnershipGroup from './_components/PartnershipGroup';
import { PartnershipCard } from './_components/PartnershipCard';

type RecipientsState = {
  employees: SharedRecipient[];
  contractors: SharedRecipient[];
  partners?: RecipientsPartners;
  lawyers: SharedRecipient[];
  departments: RecipientCategory[];
  businessUnits: RecipientCategory[];
  noAccounts: SharedRecipient[];
};

interface Props {
  asset: Asset;
  assetRecipients: RecipientGroup;
  activeAccount: Omit<Account, 'client'> & {
    client: Omit<Client, 'id'> & {
      _id: string;
    };
  };
  activeTab: TabKeyType;
  assetSharePermission: boolean;
  handleAddAccount: (email: string) => void;
  permissions: Permissions;
  Role: IRoles;
}

const AssetRecipients = ({ asset, assetRecipients, activeAccount, activeTab, assetSharePermission, handleAddAccount, Role, permissions }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isNoSearchResult, setIsNoSearchResult] = useState<boolean>(false);
  const [, setShowDistributeModal] = useState(false);

  const clientToastProps = useSelector((state: RootState) => state.client.clientToastProps);
  const loading = useSelector((state: RootState) => state.client.loading);
  const user = useSelector((state: RootState) => state.authentication.user);
  const authors = useSelector((state: RootState) => state.assetMetaData.authors);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const [recipients, setRecipients] = useState<RecipientsState>({
    employees: [],
    contractors: [],
    partners: undefined,
    lawyers: [],
    departments: [],
    businessUnits: [],
    noAccounts: [],
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUnsetSearchResult = () => {
    const recipients = assetRecipients ?? {};
    setRecipients({
      employees: recipients.recipientsEmployees ?? [],
      contractors: recipients.recipientsContractors ?? [],
      partners: recipients.recipientsPartners ?? [],
      lawyers: recipients.recipientsLawyers ?? [],
      departments: recipients.categories?.filter((cat) => cat.categoryType === CategoryTypes.DEPARTMENT) ?? [],
      businessUnits: recipients.categories?.filter((cat) => cat.categoryType === CategoryTypes.BUSINESS_UNIT) ?? [],
      noAccounts: recipients.recipientsNoAccounts ?? [],
    });
  };

  const handleSearchRecipient = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      if (value === '') {
        handleUnsetSearchResult();
        setIsNoSearchResult(false);
      } else {
        const filteredEmployees = filterRecipientsByName(recipients.employees, value);
        const filteredContractors = filterRecipientsByName(recipients.contractors, value);
        const filteredPartners = filterPartnershipsRecipientsByName(recipients.partners, value);
        const filteredLawyers = filterRecipientsByName(recipients.lawyers, value);
        const filteredNoAccounts = filterRecipientsByName(recipients.noAccounts, value);
        const filteredDepartments = filterCategoriesByName(recipients.departments, value);
        const filteredBusinessUnits = filterCategoriesByName(recipients.businessUnits, value);

        const noResults =
          [filteredEmployees, filteredContractors, filteredLawyers, filteredNoAccounts].every(isEmpty) && !filteredDepartments.length && !filteredBusinessUnits.length && checkIfPartnershipsAreEmpty(filteredPartners)

        setIsNoSearchResult(noResults);

        setRecipients({
          employees: filteredEmployees,
          contractors: filteredContractors,
          partners: filteredPartners,
          lawyers: filteredLawyers,
          departments: filteredDepartments,
          businessUnits: filteredBusinessUnits,
          noAccounts: filteredNoAccounts,
        });
      }
    }, 300),
    [recipients],
  );

  const isRecipientContibutor = (accountId: string) => {
    return asset?.contributor?.find((contributor) => contributor?._id === accountId);
  };

  const isRenderAddUser = (recipient: any): boolean => {
    return !recipient?.account && !authors?.find((author: any) => author.email === recipient.email) && permissions.Employeecreate;
  };

  const handleJoinBeta = () => {
    dispatch(
      joinDiscoveryEnquiry({
        name: user?.fullName || activeAccount?.displayName || '',
        email: user.email,
        clientName: activeAccount?.client?.name ?? (activeClient?.name || ''),
        role: Role,
      }),
    );
  };

  useEffect(() => {
    setSearchValue('');
    handleUnsetSearchResult();
  }, [activeTab]);

  useEffect(() => {
    if (assetRecipients) {
      setRecipients({
        employees: assetRecipients?.recipientsEmployees || [],
        contractors: assetRecipients?.recipientsContractors || [],
        partners: assetRecipients?.recipientsPartners || [],
        lawyers: assetRecipients?.recipientsLawyers || [],
        departments: assetRecipients?.categories?.filter((cat: RecipientCategory) => cat.categoryType === 'department') || [],
        businessUnits: assetRecipients?.categories?.filter((cat: RecipientCategory) => cat.categoryType === 'businessUnit') || [],
        noAccounts: assetRecipients?.recipientsNoAccounts || [],
      });
    }
  }, [assetRecipients]);

  const renderRecipientRow = (recipient: SharedRecipient) => {
    return (
      <React.Fragment key={recipient._id}>
        <RecipientRow>
          <AvatarContainer>
            {recipient?.account?.isActive ? <Avatar name={recipient?.account?.displayName || recipient.email} email={recipient.email} /> : <Avatar image={emptyAvatar} />}
            <TangiTypography weight="semibold">{recipient?.account?.isActive ? recipient?.account?.displayName : recipient?.fullName || recipient?.email}</TangiTypography>
            {recipient?.account && renderMagicIcon(recipient?.account?.createdFrom, recipient?.account?.createdAt)}
            {recipient?.account && isRecipientContibutor(recipient?.account?._id) && <TangiChip text={t('ASSET_PAGE.CONTENT.CONTRIBUTOR')} maxWidth={120} />}
          </AvatarContainer>
          <FlexRow>
            {recipient?.acknowledged && recipient?.acknowledgedOn ? (
              <>
                <TangiTypography color={SUCCESS_SHADES[500]}>{formatDate(recipient.acknowledgedOn)}</TangiTypography>
                <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
              </>
            ) : (
              <>
                {isRenderAddUser(recipient) && (
                  <TangiButton svgIcon="addUser" text={t('GENERAL.BUTTONS_TEXT.ADD_USER')} onClick={() => handleAddAccount(recipient.email)} variant="tertiary" data-testid="add-user" />
                )}
                <TangiTypography color={NEUTRAL_SHADES[600]}>{t('ASSET_PAGE.CONTENT.NOT_SIGNED')}</TangiTypography>
              </>
            )}
          </FlexRow>
        </RecipientRow>
      </React.Fragment>
    );
  };

  const renderMagicIcon = (createdFrom: string, createdAt: string) => {
    return createdFrom === CREATED_FROM.EMAIL_FINDER ? (
      <Tippy content={<div className="text-xs">{t('ASSET_PAGE.TOOLTIP.CREATED_BY_FINDER', { createdAt: formatDate(createdAt) })}</div>}>
        <span>
          <TangiSvgIcon component="discovery" />
        </span>
      </Tippy>
    ) : (
      createdFrom === CREATED_FROM.EMAIL_TRACKER && (
        <Tippy content={<div className="text-xs">{t('ASSET_PAGE.TOOLTIP.CREATED_BY_TRACKER', { createdAt: formatDate(createdAt) })}</div>}>
          <span>
            <TangiSvgIcon component="discovery" />
          </span>
        </Tippy>
      )
    );
  };

  const renderCategoryRecipients = (category: RecipientCategory, headerTranslation: string) => {
    return (
      <RecipientsCard key={category.categoryId}>
        <TangiCollapse
          label={
            <FlexRow>
              <TangiTypography type="heading-md" weight="semibold">
                {`${category.categoryName} - ${headerTranslation}`}
              </TangiTypography>
              <TangiTypography type="subheading" weight="regular" color={NEUTRAL_SHADES[800]}>
                • {category.items.length} {t('ASSET_PAGE.CONTENT.RECIPIENTS')}
              </TangiTypography>
            </FlexRow>
          }
          content={<RecipientContent>{category.items?.map((recipient) => renderRecipientRow(recipient))}</RecipientContent>}
          labelStyles={recipientsCardLabelStyles}
        />
      </RecipientsCard>
    );
  };

  const renderAccountsByRole = (recipients: SharedRecipient[], headerTranslation: string) => {
    return (
      <RecipientsCard>
        <TangiCollapse
          label={
            <FlexRow>
              <TangiTypography type="heading-md" weight="semibold">
                {headerTranslation}
              </TangiTypography>
              <TangiTypography type="subheading" weight="regular" color={NEUTRAL_SHADES[800]}>
                • {recipients.length} {t('ASSET_PAGE.CONTENT.RECIPIENTS')}
              </TangiTypography>
            </FlexRow>
          }
          content={<RecipientContent>{recipients?.map((recipient) => renderRecipientRow(recipient))}</RecipientContent>}
          labelStyles={recipientsCardLabelStyles}
        />
      </RecipientsCard>
    );
  };
  
  
  const renderFullPartnership = (fullPartnership: PartnershipDetails) => {
    const { name, logo } = fullPartnership.partnership;
    const recipientsCount = fullPartnership.partners?.length ?? 0;
    return (
      <PartnershipCard
        name={name}
        logo={logo}
        recipientsCount={recipientsCount}
        labelSuffix={t('ASSET_PAGE.CONTENT.ALL')}
        labelStyles={recipientsCardLabelStyles}
      >
        <RecipientContent>
          {fullPartnership.partners?.map((recipient) => renderRecipientRow(recipient))}
        </RecipientContent>
      </PartnershipCard>
    );
  };
    
  const renderPartialPartnership = (partialPartnership: PartialPartnerships) => {
    const { name, logo } = partialPartnership.partnership;
    const recipientsCount = partialPartnership.partnersCount;
  
    return (
      <PartnershipCard
        name={name}
        recipientsCount={recipientsCount}
        labelStyles={recipientsCardLabelStyles}
        logo={logo}
      >
        <FlexColumn>
          {partialPartnership.individuals && (
            <PartnershipGroup
              icon="user"
              title="Individuals"
              partnersCount={partialPartnership.individuals.partners.length}
              partners={partialPartnership.individuals.partners}
              renderRecipientRow={renderRecipientRow}
              showSeparator={
                !!(partialPartnership.partnerDepartments?.length)
              }
            />
          )}
  
          {partialPartnership.partnerDepartments?.map((department, index, arr) => {
            const isLastItem = index === arr.length - 1;
  
            return (
              <PartnershipGroup
                key={department.department._id.toString()}
                icon="usersGroup"
                title={department.department.name}
                partnersCount={department.partners.length}
                partners={department.partners}
                renderRecipientRow={renderRecipientRow}
                showSeparator={!isLastItem}
              />
            );
          })}
        </FlexColumn>
      </PartnershipCard>
    );
  };

  const renderExternalUsersWidget = () => {
    const contractorsNum = asset?.recipientsContractors?.length || 0;
    const partnersNum = asset?.recipientsPartners?.length || 0;
    return (
      <Widget
        text={contractorsNum + partnersNum === 0 ? t('ASSET_PAGE.CONTENT.NO_EXTERNAL_USERS') : t('ASSET_PAGE.CONTENT.EXTERNAL_USERS')}
        icon="handshake"
        number={contractorsNum + partnersNum}
        isEmpty={contractorsNum + partnersNum === 0}
      />
    );
  };

  const renderSpecialAdminWidgets = () => {
    return (
      <WidgetsContainer>
        <Widget
          text={!!asset?.numAccountsCreatedFromFinder ? t('ASSET_PAGE.WIDGET.USERS_WERE_CREATED_BY_FINDER') : t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_FINDER')}
          icon="magnifier"
          number={asset?.numAccountsCreatedFromFinder || 0}
          isEmpty={!!!asset?.numAccountsCreatedFromFinder}
        />
        <Widget
          text={!!asset?.numAccountsCreatedFromTracker ? t('ASSET_PAGE.WIDGET.USERS_WERE_CREATED_BY_TRACKER') : t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_TRACKER')}
          icon="binoculars"
          number={asset?.numAccountsCreatedFromTracker || 0}
          isEmpty={!!!asset?.numAccountsCreatedFromTracker}
        />
        {renderExternalUsersWidget()}
      </WidgetsContainer>
    );
  };

  const renderAdminNotSpecialWidgets = () => {
    return (
      <WidgetsContainer>
        {renderExternalUsersWidget()}
        <Widget text={t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_FINDER')} icon="magnifier" number={0} isEmpty={true} invitation={true} handleTooltipClick={handleJoinBeta} loading={loading} />
        <Widget text={t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_TRACKER')} icon="binoculars" number={0} isEmpty={true} invitation={true} handleTooltipClick={handleJoinBeta} loading={loading} />
      </WidgetsContainer>
    );
  };

  return (
    <Container>
      <TangiSearch
        width="224px"
        placeholder={t('ASSET_PAGE.INPUT.SEARCH_BY_NAME_OR_EMAIL')}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleSearch={handleSearchRecipient}
        onChange={handleSearchRecipient}
        disabled={!!!asset?.recipients?.length}
      />
      <RecipientsAndWidgets>
        <>
          {!!asset?.recipients?.length ? (
            <>
              {isNoSearchResult ? (
                <EmptyCard data-testid="recipients-no-results">
                  <Person />
                  <NoSearchTextContainer>
                    <TangiTypography type="heading-md" weight="bold" color={NEUTRAL_SHADES[700]}>
                      {t('ASSET_PAGE.CARD.NO_RECIPIENT_FOUND')}
                    </TangiTypography>
                  </NoSearchTextContainer>
                </EmptyCard>
              ) : (
                <RecipientsContainer>
                  {!!recipients.employees?.length && renderAccountsByRole(recipients.employees, t('ASSET_PAGE.CARD.EMPLOYEES'))}
                  {!!recipients.contractors?.length && renderAccountsByRole(recipients.contractors, t('ASSET_PAGE.CARD.CONTRACTORS'))}
                  {!!recipients.lawyers?.length && renderAccountsByRole(recipients.lawyers, t('ASSET_PAGE.CARD.LAWYERS'))}
                  {!!recipients.departments?.length && groupAndCombineCategories(recipients.departments, renderCategoryRecipients, t('ASSET_PAGE.CARD.DEPARTMENT'))}
                  {!!recipients.businessUnits?.length && groupAndCombineCategories(recipients.businessUnits, renderCategoryRecipients, t('ASSET_PAGE.CARD.BUSINESS_UNIT'))}
                  {!!recipients.noAccounts?.length && renderAccountsByRole(recipients.noAccounts, t('ASSET_PAGE.CARD.NOT_REGISTERED'))}
                  {recipients.partners?.fullPartnerships?.map((fullPartnership) =>
                    renderFullPartnership(fullPartnership)
                  )}
                  {recipients.partners?.partialPartnerships?.map((partialPartnership) =>{
                    if(partialPartnership.partnersCount>0){
                     return renderPartialPartnership(partialPartnership)
                    }
                  }
                  )}
                </RecipientsContainer>
              )}
            </>
          ) : (
            <EmptyCard data-testid="recipients-no-people">
              <NoPeople />
              <TangiTypography type="heading-md" weight="bold" color={NEUTRAL_SHADES[700]}>
                {t('ASSET_PAGE.CARD.ASSET_HAS_NO_RECIPIENTS')}
              </TangiTypography>
              {assetSharePermission && (
                <div>
                  <TangiButton text={t('GENERAL.BUTTONS_TEXT.NOTIFY')} onClick={() => setShowDistributeModal(true)} icon={sendIcon} data-testid="notify" />
                </div>
              )}
            </EmptyCard>
          )}
          {isRoleMatch(Role, IRoles.EMPLOYEE_ADMIN) && (activeAccount?.client?.discoverySpecialAdminsCount ?? 0) > 0
            ? renderSpecialAdminWidgets()
            : isRoleMatch(Role, IRoles.EMPLOYEE_ADMIN) && renderAdminNotSpecialWidgets()}
        </>
      </RecipientsAndWidgets>
      <TangiToast
        {...clientToastProps}
        onSuccess={() => {
          dispatch(clientActions.setClientToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </Container>
  );
};

interface WidgetProps {
  text: string;
  icon: IconTypes;
  number: number;
  isEmpty?: boolean;
  invitation?: boolean;
  handleTooltipClick?: () => void;
  loading?: boolean;
}

const Widget = ({ text, icon, number, isEmpty = false, invitation = false, handleTooltipClick, loading }: WidgetProps) => {
  return (
    <WidgetCard isEmpty={isEmpty}>
      <WidgetDataRow>
        <TangiSvgIcon component={icon} color={isEmpty ? PRIMARY_SHADES[600] : NEUTRAL_SHADES.BLACK} />
        {invitation && (
          <Tippy
            disabled={!invitation}
            render={() => <ProTooltip handleTooltipClick={handleTooltipClick} loading={loading} />}
            trigger="mouseenter"
            placement="top-start"
            animation={false}
            interactive={true}
            // prevents Tippy from hiding if the cursor left it
            interactiveBorder={6}
            // makes tooltip to be behind the modal
            zIndex={1000}
          >
            <ProChipContainer>
              <TangiChip variant="primary" text="PRO" />
            </ProChipContainer>
          </Tippy>
        )}
        {!isEmpty && <WidgetNum>{number}</WidgetNum>}
      </WidgetDataRow>
      <TangiTypography color={isEmpty ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK}>{text}</TangiTypography>
    </WidgetCard>
  );
};

const ProTooltip = ({ handleTooltipClick, loading = false }: { handleTooltipClick: undefined | (() => void); loading?: boolean }) => {
  const { t } = useTranslation();

  return (
    <TooltipContainer>
      <TangiSvgIcon component="discovery" color={PRIMARY_SHADES[800]} size="22px" />
      <TangiTypography type="subheading" weight="semibold">
        {t('ASSET_PAGE.TOOLTIP.FINDER_IS_AVAILABLE')}
      </TangiTypography>
      <FlexRow>
        <TangiTypography>{t('ASSET_PAGE.TOOLTIP.CONTACT_US')}</TangiTypography>
        <TangiIconButton icon="arrowRight" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleTooltipClick ? handleTooltipClick : () => { }} loading={loading} />
      </FlexRow>
    </TooltipContainer>
  );
};

export default AssetRecipients;
