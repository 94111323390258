/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

import {
  createAgreementVersion,
  getAgreementVersions,
  getClientById,
  getUsers,
  updateRequiredCourses,
  joinDiscoveryEnquiry,
  getInsights,
  uploadFiles,
  downloadPolicy,
  deletePolicy,
  updateClientLanguage,
  uploadLlmFiles,
  deleteLlmPolicy,
  updateSystemSecurityIpAudit,
  updateHrProcessIpAudit,
  updateContractsIpAudit,
  downloadPolicyTemplate,
  updateClientIsDeclaredNoTradeSecretPolicy,
  updateClientIsDeclaredNoLlmPolicy,
  updateKeyAssetsSystemNoneSelectionIpAudit,
} from 'redux-toolkit/thunks/clientThunks';
import { Client, IClientXrayInsights } from 'utils/types/client/client';
import { ClientAgreementVersion } from 'utils/types/agreement/agreement';
import { RESULT_STATUS } from 'utils/enums';
import { LANGUAGE } from 'translations/enums';
import { FilePayload, ToastProps } from 'utils/types/types';
import { IpAuditActionStepSubmission, IpAuditStepKey } from '_components/IpAudit/types';

interface LoadersObject {
  [key: string]: boolean;
}

interface DataObject {
  [key: string]: any;
}

interface clientState {
  clientLoading: boolean;
  agreementVersions: ClientAgreementVersion[];
  newAgreementVersion: any;
  newAgreementVersionIndex: any;
  results: any[];
  loading: boolean;
  assetPDF: any[];
  errorMsg: boolean;
  clientDetails: Client | NonNullable<unknown>;
  tablesLoaders: LoadersObject;
  tablesData: DataObject;
  clientToastProps: ToastProps;
  policyToastProps: ToastProps;
  ipAuditToastProps: ToastProps;
  xRayInsights: IClientXrayInsights;
  discoveryDataLoaders: LoadersObject;
  policyLoader: boolean;
  policyTemplateLoader: boolean;
  policy: FilePayload;
  ipAudit: IpAuditSubmissionState;
  isDownloadingPolicy: boolean;
}

interface IpAuditSubmissionState {
  [IpAuditStepKey.TRADE_SECRET_POLICY]: IpAuditActionStepSubmission;
  [IpAuditStepKey.LLM_POLICY]: IpAuditActionStepSubmission;
  [IpAuditStepKey.HR_PROCESS]: IpAuditActionStepSubmission;
  [IpAuditStepKey.SYSTEM_SECURITY]: IpAuditActionStepSubmission;
  [IpAuditStepKey.CONTRACTS]: IpAuditActionStepSubmission;
  [IpAuditStepKey.KEY_ASSETS_SYSTEMS]: IpAuditActionStepSubmission;
}

const initialState: clientState = {
  clientLoading: false,
  agreementVersions: [],
  newAgreementVersion: null,
  newAgreementVersionIndex: null,
  results: [],
  loading: false,
  assetPDF: [],
  errorMsg: false,
  clientDetails: {},
  tablesLoaders: {
    contractorLoading: false,
    employeeLoading: false,
    partnerLoading: false,
    formerEmployeesLoading: false,
  },
  tablesData: { employee: [], contractor: [], partner: [], formerEmployees: [] },
  clientToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  policyToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  ipAuditToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  xRayInsights: { assetFromFinder: 0, assetFromPatents: 0, accountsFromTracker: 0, assetFromInventionDisclosure: 0 },
  discoveryDataLoaders: {
    joinDiscoveryLoading: false,
    insightsLoading: false,
  },
  policy: { base64: '', mimeType: '', file: '', fileSize: 0 },
  policyLoader: false,
  policyTemplateLoader: false,
  ipAudit: {
    [IpAuditStepKey.TRADE_SECRET_POLICY]: { hasData: false, isLoading: false },
    [IpAuditStepKey.LLM_POLICY]: { hasData: false, isLoading: false },
    [IpAuditStepKey.HR_PROCESS]: { hasData: false, isLoading: false },
    [IpAuditStepKey.SYSTEM_SECURITY]: { hasData: false, isLoading: false },
    [IpAuditStepKey.CONTRACTS]: { hasData: false, isLoading: false },
    [IpAuditStepKey.KEY_ASSETS_SYSTEMS]: { hasData: false, isLoading: false },
  },
  isDownloadingPolicy: false,
};

const clientSlice = createSlice({
  name: 'clientData',
  initialState,
  reducers: {
    setClearGetAgreementVersions(state) {
      state.newAgreementVersion = null;
      state.newAgreementVersionIndex = null;
      state.loading = false;
    },
    setClearClientResults(state) {
      state.results = [];
      state.loading = false;
    },
    setClientToastToastProps(state, action: PayloadAction<ToastProps>) {
      state.clientToastProps = action.payload;
    },
    setPolicyToastToastProps(state, action: PayloadAction<ToastProps>) {
      state.policyToastProps = action.payload;
    },
    setIpAuditToastProps(state, action: PayloadAction<ToastProps>) {
      state.ipAuditToastProps = action.payload;
    },
    clearIpAuditAction(state, action: PayloadAction<keyof IpAuditSubmissionState>) {
      state.ipAudit[action.payload] = { isLoading: false, hasData: false };
    },
  },
  extraReducers: (builder) => {
    // updateUser
    builder.addCase(getAgreementVersions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAgreementVersions.fulfilled, (state, action: PayloadAction<any>) => {
      state.agreementVersions = action.payload.data;
      state.newAgreementVersion = null;
      state.newAgreementVersionIndex = null;
      state.loading = false;
    });
    builder.addCase(getAgreementVersions.rejected, (state) => {
      state.agreementVersions = [];
      state.newAgreementVersion = null;
      state.newAgreementVersionIndex = null;
      state.loading = false;
    });

    builder.addCase(downloadPolicyTemplate.pending, (state) => {
      state.policyTemplateLoader = true;
    });
    builder.addCase(downloadPolicyTemplate.fulfilled, (state) => {
      state.policyTemplateLoader = false;
    });
    builder.addCase(downloadPolicyTemplate.rejected, (state) => {
      state.policyTemplateLoader = false;
    });

    // getClientById
    builder.addCase(getClientById.pending, (state) => {
      state.clientLoading = true;
      state.clientDetails = {};
    });
    builder.addCase(getClientById.fulfilled, (state, action: PayloadAction<any>) => {
      state.clientDetails = action.payload;
      state.clientLoading = false;
    });
    builder.addCase(getClientById.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      state.clientDetails = {};
      state.clientLoading = false;
    });

    // updateRequiredCourses
    builder.addCase(updateRequiredCourses.pending, (state) => {
      state.clientLoading = true;
    });
    builder.addCase(updateRequiredCourses.fulfilled, (state, action: PayloadAction<Client>) => {
      state.clientLoading = false;
      state.clientDetails = { ...state.clientDetails, requiredCourses: action.payload.requiredCourses };
    });
    builder.addCase(updateRequiredCourses.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      state.clientLoading = false;
    });

    // createAgreementVersion
    builder.addCase(createAgreementVersion.pending, (state) => {
      state.loading = true;
      state.newAgreementVersion = null;
      state.newAgreementVersionIndex = null;
    });
    builder.addCase(createAgreementVersion.fulfilled, (state, action: PayloadAction<any>) => {
      const [newAgreementVersion] = action.payload.res.newRecords; // use array destructuring to get the first element
      state.agreementVersions = [...state.agreementVersions, newAgreementVersion];
      state.newAgreementVersion = newAgreementVersion;
      state.newAgreementVersionIndex = action.payload.index;
      state.loading = false;
    });

    builder.addCase(createAgreementVersion.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      state.agreementVersions = [];
      state.newAgreementVersion = null;
      state.newAgreementVersionIndex = null;
      state.loading = false;
    });

    // getUsers
    builder.addCase(getUsers.pending, (state, action: ReturnType<typeof getUsers.pending>) => {
      if (action?.meta) {
        const { type }: any = action?.meta?.arg;
        setLoadingState(state, type, true);
      }
    });
    builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<any>) => {
      const { type, res } = action.payload;
      switch (type) {
        case 'Employee':
          state.tablesData.employee = res;
          setLoadingState(state, type, false);
          break;
        case 'Contractor':
          state.tablesData.contractor = res;
          setLoadingState(state, type, false);
          break;
        case 'Partner':
          state.tablesData.partner = res;
          setLoadingState(state, type, false);
          break;
        case 'FormerEmployees':
          state.tablesData.formerEmployees = res;
          setLoadingState(state, type, false);
          break;
        default:
          break;
      }
    });
    builder.addCase(getUsers.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      state.tablesLoaders.employeeLoading = false;
      state.tablesLoaders.contractorLoading = false;
      state.tablesLoaders.partnerLoading = false;
      state.tablesLoaders.formerEmployeesLoading = false;
    });

    // send joinDiscoveryEnquiry
    builder.addCase(joinDiscoveryEnquiry.pending, (state) => {
      state.discoveryDataLoaders.joinDiscoveryLoading = true;
    });
    builder.addCase(joinDiscoveryEnquiry.fulfilled, (state) => {
      const text = i18next.t('EMAIL_TRACKER.TOAST.SUCCESS_REQUEST_ACCESS');
      state.clientToastProps = { show: true, text, type: RESULT_STATUS.SUCCESS };
      state.discoveryDataLoaders.joinDiscoveryLoading = false;
    });
    builder.addCase(joinDiscoveryEnquiry.rejected, (state) => {
      const text = i18next.t('DISCOVERY_JOIN.TOAST.ERROR');
      state.clientToastProps = { show: true, text, type: RESULT_STATUS.ERROR };
      state.discoveryDataLoaders.joinDiscoveryLoading = false;
    });

    // getInsights
    builder.addCase(getInsights.pending, (state) => {
      state.discoveryDataLoaders.insightsLoading = true;
    });
    builder.addCase(getInsights.fulfilled, (state, action: PayloadAction<IClientXrayInsights>) => {
      state.xRayInsights = action.payload;
      state.discoveryDataLoaders.insightsLoading = false;
    });

    builder.addCase(getInsights.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      state.discoveryDataLoaders.insightsLoading = false;
    });

    // uploadLlm
    builder.addCase(uploadLlmFiles.pending, (state) => {
      state.policyLoader = true;
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: true, hasData: false });
    });
    builder.addCase(uploadLlmFiles.fulfilled, (state, action) => {
      updatePolicyToastProps(state, RESULT_STATUS.SUCCESS);
      state.policyLoader = false;
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: false, hasData: !!action.payload.data });
    });
    builder.addCase(uploadLlmFiles.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: false, hasData: false });
      state.policyLoader = false;
      updatePolicyToastProps(state, RESULT_STATUS.ERROR);
    });

    // uploadFiles
    builder.addCase(uploadFiles.pending, (state) => {
      state.policyLoader = true;
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: true, hasData: false });
    });
    builder.addCase(uploadFiles.fulfilled, (state, action) => {
      updatePolicyToastProps(state, RESULT_STATUS.SUCCESS);
      state.policyLoader = false;
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: false, hasData: !!action.payload.data });
    });
    builder.addCase(uploadFiles.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      updatePolicyToastProps(state, RESULT_STATUS.ERROR);
      state.policyLoader = false;
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: false, hasData: false });
    });

    // downloadPolicy
    builder.addCase(downloadPolicy.pending, (state) => {
      state.isDownloadingPolicy = true;
    });
    builder.addCase(downloadPolicy.fulfilled, (state, action: PayloadAction<FilePayload | null>) => {
      if (action?.payload) {
        state.policy = action.payload;
      }
      state.isDownloadingPolicy = false;
    });
    builder.addCase(downloadPolicy.rejected, (state, action: any) => {
      state.errorMsg = action?.error?.message || 'Something went wrong';
      state.isDownloadingPolicy = false;
    });

    // delete trade secret policy
    builder.addCase(deletePolicy.pending, (state) => {
      state.policyLoader = true;
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: true, hasData: false });
    });
    builder.addCase(deletePolicy.fulfilled, (state, action) => {
      state.policyLoader = false;
      if (!action.payload.isDeleteBeforeUpload) {
        updatePolicyToastProps(state, RESULT_STATUS.SUCCESS);
        updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: false, hasData: !!action.payload.data });
      }
    });

    builder.addCase(deletePolicy.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
      state.policyLoader = false;
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: false, hasData: false });
      updatePolicyToastProps(state, RESULT_STATUS.ERROR);
    });

    // delete Llm Policy
    builder.addCase(deleteLlmPolicy.pending, (state) => {
      state.policyLoader = true;
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: true, hasData: false });
    });
    builder.addCase(deleteLlmPolicy.fulfilled, (state, action) => {
      state.policyLoader = false;
      if (!action.payload.isDeleteBeforeUpload) {
        updatePolicyToastProps(state, RESULT_STATUS.SUCCESS);
        updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: false, hasData: !!action.payload.data });
      }
    });
    builder.addCase(deleteLlmPolicy.rejected, (state) => {
      state.policyLoader = false;
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: false, hasData: false });
      updatePolicyToastProps(state, RESULT_STATUS.ERROR);
    });

    builder.addCase(updateClientLanguage.pending, () => {});

    builder.addCase(updateClientLanguage.fulfilled, (state, action: any) => {
      state.clientDetails = { ...state.clientDetails, language: action?.payload?.result?.language || LANGUAGE.ENGLISH };
    });

    builder.addCase(updateClientLanguage.rejected, (state, action: any) => {
      state.errorMsg = action.error.message;
    });

    builder.addCase(updateClientIsDeclaredNoTradeSecretPolicy.pending, (state) => {
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: true, hasData: false });
    });

    builder.addCase(updateClientIsDeclaredNoTradeSecretPolicy.fulfilled, (state) => {
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: false, hasData: true });
    });

    builder.addCase(updateClientIsDeclaredNoTradeSecretPolicy.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.TRADE_SECRET_POLICY, { isLoading: false, hasData: false });
    });

    builder.addCase(updateClientIsDeclaredNoLlmPolicy.pending, (state) => {
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: true, hasData: false });
    });

    builder.addCase(updateClientIsDeclaredNoLlmPolicy.fulfilled, (state) => {
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: false, hasData: true });
    });

    builder.addCase(updateClientIsDeclaredNoLlmPolicy.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.LLM_POLICY, { isLoading: false, hasData: false });
    });

    builder.addCase(updateSystemSecurityIpAudit.pending, (state) => {
      updateIpAuditState(state, IpAuditStepKey.SYSTEM_SECURITY, { isLoading: true, hasData: false });
    });

    builder.addCase(updateSystemSecurityIpAudit.fulfilled, (state, action) => {
      updateIpAuditState(state, IpAuditStepKey.SYSTEM_SECURITY, { isLoading: false, hasData: !!action.payload.ipAuditData });
    });

    builder.addCase(updateSystemSecurityIpAudit.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.SYSTEM_SECURITY, { isLoading: false, hasData: false });
    });

    builder.addCase(updateHrProcessIpAudit.pending, (state) => {
      updateIpAuditState(state, IpAuditStepKey.HR_PROCESS, { isLoading: true, hasData: false });
    });

    builder.addCase(updateHrProcessIpAudit.fulfilled, (state, action) => {
      updateIpAuditState(state, IpAuditStepKey.HR_PROCESS, { isLoading: false, hasData: !!action.payload.ipAuditData });
    });

    builder.addCase(updateHrProcessIpAudit.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.HR_PROCESS, { isLoading: false, hasData: false });
    });

    builder.addCase(updateContractsIpAudit.pending, (state) => {
      updateIpAuditState(state, IpAuditStepKey.CONTRACTS, { isLoading: true, hasData: false });
    });

    builder.addCase(updateContractsIpAudit.fulfilled, (state, action) => {
      updateIpAuditState(state, IpAuditStepKey.CONTRACTS, { isLoading: false, hasData: !!action.payload.ipAuditData });
    });

    builder.addCase(updateContractsIpAudit.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.CONTRACTS, { isLoading: false, hasData: false });
    });

    builder.addCase(updateKeyAssetsSystemNoneSelectionIpAudit.pending, (state) => {
      updateIpAuditState(state, IpAuditStepKey.KEY_ASSETS_SYSTEMS, { isLoading: true, hasData: false });
    });

    builder.addCase(updateKeyAssetsSystemNoneSelectionIpAudit.fulfilled, (state, action) => {
      updateIpAuditState(state, IpAuditStepKey.KEY_ASSETS_SYSTEMS, { isLoading: false, hasData: !!action.payload.ipAuditData });
    });

    builder.addCase(updateKeyAssetsSystemNoneSelectionIpAudit.rejected, (state) => {
      updateIpAuditState(state, IpAuditStepKey.KEY_ASSETS_SYSTEMS, { isLoading: false, hasData: false });
    });
  },
});

const updatePolicyToastProps = (state: clientState, type: RESULT_STATUS.ERROR | RESULT_STATUS.SUCCESS) => {
  const toastText = `POLICY.TOAST.${type === RESULT_STATUS.SUCCESS ? 'SUCCESS' : 'ERROR'}`;
  const text = i18next.t(toastText);
  state.policyToastProps = { show: true, text, type };
};

const updateIpAuditState = (state: clientState, key: keyof IpAuditSubmissionState, submissionStatus: IpAuditActionStepSubmission) => {
  const { isLoading, hasData } = submissionStatus;
  state.ipAudit[key].isLoading = isLoading;
  state.ipAudit[key].hasData = hasData;
};

const setLoadingState = (state: any, type: string, value: boolean) => {
  switch (type) {
    case 'Employee':
      state.tablesLoaders.employeeLoading = value;
      break;
    case 'Contractor':
      state.tablesLoaders.contractorLoading = value;
      break;
    case 'Partner':
      state.tablesLoaders.partnerLoading = value;
      break;
    case 'FormerEmployees':
      state.tablesLoaders.formerEmployeesLoading = value;
      break;
    default:
      break;
  }
};
export const clientActions = clientSlice.actions;

export default clientSlice.reducer;
