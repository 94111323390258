import { getLabelValueFormat, returnValuesFromArray } from '../../utils/getOptions';

export function stripTimeFromDate(dateString) {
  return dateString?.split('T')[0] || '';
}

const formatAgreementData = (agreementItem) => {
  const { expiry_date: expDate, signed_date: signDate, signed, files, agreementType, _id, version } = agreementItem;
  const expiryDate = stripTimeFromDate(expDate);
  const signedDate = stripTimeFromDate(signDate);
  const agreementVersion = version ? { label: version?.name, value: version?._id } : null;
  return { expiry_date: expiryDate, signed_date: signedDate, signed, files, id: _id, agreementType: agreementType?._id, version: agreementVersion };
};

const apiAgreementsToFormFormat = (apiAgreements) => {
  const accountsAgreements = apiAgreements.map((agreementItem) => formatAgreementData(agreementItem));
  return accountsAgreements;
};

const apiToFormFormat = (apiData) => {
  const { businessUnits: userBusinessUnits, dateOfJoining, dateOfLeaving, departments: userDepartments, role, country } = apiData;

  const joiningDate = stripTimeFromDate(dateOfJoining);
  const leavingDate = stripTimeFromDate(dateOfLeaving);

  const accountsDepartments = getLabelValueFormat(userDepartments);
  const accountsBusinessUnits = getLabelValueFormat(userBusinessUnits);
  const accountsCountry = { label: country, value: country };
  const accountsRole = { label: role.name, value: role.name };

  return {
    ...apiData,
    country: accountsCountry,
    businessUnits: accountsBusinessUnits,
    dateOfJoining: joiningDate,
    dateOfLeaving: leavingDate,
    departments: accountsDepartments,
    role: accountsRole,
  };
};

const formToApiFormat = (formData) => {
  // Change fields with null to empty string
  Object.keys(formData).forEach((key) => {
    if (formData[key] === null) {
      formData[key] = '';
    }
  });

  const {
    country,
    businessUnits,
    agreements,
    departments,
    role,
    dateOfJoining,
    dateOfLeaving,
    displayName,
    employeeId,
    linkedin,
    location,
    others1,
    others2,
    phone,
    status,
    title,
    email,
    externalUser,
  } = formData;

  const formattedDepartments = returnValuesFromArray(departments ?? []);
  const formattedBusinessUnits = returnValuesFromArray(businessUnits ?? []);
  const formattedAgreements = agreements?.map((agreement) => {
    if (agreement?.signed) {
      return { ...agreement, version: agreement.version?.value };
    } else {
      return { ...agreement, version: undefined, expiry_date: undefined, signed_date: undefined, signed: false };
    }
  });

  return {
    departments: formattedDepartments,
    businessUnits: formattedBusinessUnits,
    agreements: formattedAgreements,
    role: role.value,
    country: country?.value,
    dateOfJoining,
    dateOfLeaving,
    displayName,
    employeeId,
    linkedin,
    location,
    others1,
    others2,
    phone,
    status,
    title,
    email: email.toLowerCase().trim(),
    externalUser,
  };
};

export { apiToFormFormat, formToApiFormat, apiAgreementsToFormFormat };
