/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from '_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isFunction } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { createMultipleUsers, updateUser } from 'redux-toolkit/thunks/usersThunks';
import { createDepartment } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { getPartnershipById } from 'redux-toolkit/thunks/partnershipThunks';
import { TangiButton, TangiCheckbox, TangiInput, TangiTypography, TangiToast, TangiAccountStatus, TangiCreateableSelector } from '_components/TangiLibrary';
import { validEmailPattern } from 'utils/formUtils';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { CREATED_FROM } from '../../utils/enums';
import { IRoles } from 'utils/roles';
import { StyledModal } from 'utils/globalStyles';
import { ActionButtonsContainer, ButtonContainer, Divider, DuoContainer, Separator, StyledAddUserComponent, StyledAddUsersInPartnershipForm, StyledHeader, StyledModalBody, StatusContainer } from './style';
import { getSelectorOptions } from '../../../src/utils/getOptions';
import { PartnerNotifyModal } from '_components/PartnerNotifyModal';
import { PartnerAccountInput, PartnerUserFormType, SelectorType, UserInput } from './types';
import { initFormValues } from './consts';



interface PartnershipInviteUsersModalProps {
  onFinish: () => void;
  partnerData: PartnerAccountInput;
  handleClose: () => void;
}
const PartnershipInviteUsersModal: React.FC<PartnershipInviteUsersModalProps> = ({ onFinish, partnerData }: PartnershipInviteUsersModalProps) => {
  //Params
  const { clientId } = useParams<{ clientId: string }>();
  //Redux
  const { usersModalIsOpen, partnership, usersModalType: formType } = useSelector((state: RootState) => state.partnership);
  const { departments, creatableLoading, metaDataLoading, newDepartment } = useSelector((state: RootState) => state.assetMetaData);
  const { user } = useSelector((state: RootState) => state.authentication);
  const { inviteMultipleLoading, loading: updateUserLoading, invitedMultipleAccounts, inviteMultipleError, status: userStatus } = useSelector((state: RootState) => state.users);
  const [inviteAccountsToastProps, setInviteAccountsToastProps] = useState({ show: false, text: '', type: '' });
  const [showPartnerNotifyModal, setShowPartnershipNotifyModal] = useState(false);
  const [pendingSubmitData, setPendingSubmitData] = useState< UserInput[] | null>(null);
  const [userDepartmentCreatedIndex, setUserDepartmentCreatedIndex] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEditMode = useMemo(() => formType === PartnerUserFormType.EDIT, [formType]);
  const isInitalAddition = useMemo(() => formType === PartnerUserFormType.ADD_INITAL, [formType]);
  const userLoading = inviteMultipleLoading || updateUserLoading;
  const defaultFormValues = useMemo(() => initFormValues(user.extrnalUser), [user]);
  //Form Props
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
    getValues,
  } = useForm<any>({
    mode: 'all',
    defaultValues: defaultFormValues,
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'data',
  });

  useEffect(() => {
    if (isEditMode) {
      setValue('data[0].email', partnerData.email);
      setValue('data[0].name', partnerData.displayName);
      setValue('data[0].externalUser', partnerData.externalUser);
      setValue('data[0].departments', getSelectorOptions(partnerData.departments));
    }
  }, [formType, partnerData]);

  const inviteAccountsAdditionalFields = useMemo(() => {
    return { createdFrom: CREATED_FROM.PARTNERSHIP, partnership: partnership._id, role: IRoles.PARTNER};
  }, [user, partnership]);

  //Functions
  const handleAddUser = () => {
    const newUser = { email: '', name: '', externalUser: user.externalUser, role: { value: IRoles.PARTNER }, isActive: false };
    append(newUser);
  };

  const handleFormContinue = (data: { data: UserInput[] }) => {
    const formattedData = data.data.map((item: UserInput) => {
      return {
        ...item,
        departments: (item.departments as SelectorType[])?.map((dep) => dep.value),
      };
    });
    setPendingSubmitData(formattedData);
    setShowPartnershipNotifyModal(true);
  };

  useEffect(() => {
    if (!usersModalIsOpen) {
      reset(defaultFormValues);
    }
  }, [usersModalIsOpen]);

  useEffect(() => {
    if (invitedMultipleAccounts && invitedMultipleAccounts.length) {
      setInviteAccountsToastProps({ show: true, text: `${invitedMultipleAccounts.length} ${t('PARTNERSHIP_ADD_EDIT_PARTNER.TOAST.USER_ADDED_SUCCESSFULLY')}`, type: 'success' });
      if (isFunction(onFinish)) {
        onFinish();
      }
      if (isInitalAddition) {
        dispatch(partnershipActions.setFinishCreationPartnershipModalState(true));
      }
    }
  }, [invitedMultipleAccounts]);

  useEffect(() => {
    if (inviteMultipleError) {
      setInviteAccountsToastProps({ show: true, text: inviteMultipleError, type: 'error' });
    }
  }, [inviteMultipleError]);

  useEffect(() => {
    if (newDepartment && userDepartmentCreatedIndex !== null) {
      const departmentsCurrentValues = getValues(`data[${userDepartmentCreatedIndex}].departments`);
      const departmentsValue = departmentsCurrentValues
        ? [...departmentsCurrentValues, { value: newDepartment.id, label: newDepartment.name }]
        : [{ value: newDepartment.id, label: newDepartment.name }];
      setValue(`data[${userDepartmentCreatedIndex}].departments`, departmentsValue);
      setUserDepartmentCreatedIndex(null);
    }
  }, [newDepartment]);

  //components
  const renderAddModalHeader = () => {
    const headerText = useMemo(() => {
      if (isEditMode) {
        return 'PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.EDIT';
      } else {
        return 'PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.ADD_USER_TO';
      }
    }, [isEditMode]);
    return (
      <StyledHeader data-testid="partnership-invite-users-header">
        <TangiTypography type="heading-lg">
          <Trans t={t} i18nKey={headerText} values={{ NAME: isEditMode ? partnerData.displayName : partnership.name }} />
        </TangiTypography>
      </StyledHeader>
    );
  };
  const handleCreateDepatments = (inputValue: string, index: number) => {
    dispatch(createDepartment({ name: inputValue, client: [partnership.client], partnership: partnership._id }));
    setUserDepartmentCreatedIndex(index);
  };

  const handleCancelSubmit = () => {
    setShowPartnershipNotifyModal(false);
  };

  const onClose = () => {
    dispatch(partnershipActions.setInviteUsersModalState(false));
    setShowPartnershipNotifyModal(false);
    onFinish();
  };

  const handleUsersSubmit = () => {
    if (!pendingSubmitData) return;

    let payload = { ...pendingSubmitData };
    if (isEditMode) {
      let updatedUser = payload[0];
      updatedUser = {
        ...updatedUser,
        role: IRoles.PARTNER,
        displayName: updatedUser.name,
      };
      delete updatedUser.status;
      delete updatedUser.email;
      delete updatedUser.externalUser;
      delete updatedUser.name;
      delete updatedUser.isActive;

      dispatch(updateUser({ user: updatedUser, id: partnerData.id }));
    } else {
      payload = pendingSubmitData.map((item: any) => {
        const newItem = {
          ...item,
          status: item.isActive ? 'Pending' : 'Disabled',
          displayName: item.name,
          ...inviteAccountsAdditionalFields
        };
        delete newItem.name; 
        return newItem;
      });
      const reqBody = { data: payload, clientId };
      dispatch(createMultipleUsers(reqBody));
    }
    setShowPartnershipNotifyModal(false);
  };

  useEffect(() => {
    if (userStatus) {
      dispatch(getPartnershipById(partnership._id ?? partnership.id));
    }
    onClose();
  }, [userStatus]);


  const renderAddUserComponent = () => {
    return (
      <>
        {fields &&
          fields.map((item: any, index: number) => {
            return (
              <StyledAddUserComponent key={index} index={index}>
                <div>
                  {isEditMode ? (
                    <StatusContainer>
                      {t('PARTNERSHIP_ADD_EDIT_PARTNER.TOGGLE.STATUS')}
                      <TangiAccountStatus status={partnerData.status} />
                    </StatusContainer>
                  ) : (
                    <TangiCheckbox
                          label={t('PARTNERSHIP_ADD_EDIT_PARTNER.TOGGLE.ACTIVE')}
                          register={register}
                          name={`data[${index}].isActive`}
                          disabled={isEditMode}
                        />
                  )}
                </div>
                <DuoContainer>
                  <TangiInput
                    type="email"
                    placeholder="email@example.com"
                    label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.EMAIL')}
                    name={`data.${index}.email`}
                    required
                    register={register}
                    registerRules={{
                      pattern: {
                        value: validEmailPattern,
                        message: t('PARTNERSHIP_ADD_EDIT_PARTNER.ERROR_MESSAGE.INVALID_EMAIL_MESSAGE'),
                      },
                    }}
                    smallText={''}
                    error={errors?.data?.[index]?.email}
                    disabled={isEditMode}
                  />
                  <TangiInput
                    label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.DISPLAY_NAME')}
                    name={`data.${index}.name`}
                    required
                    register={register}
                    error={errors?.data?.[index]?.name}
                    placeholder={undefined}
                    smallText={undefined}
                    registerRules={undefined}
                    disabled={false}
                  />
                </DuoContainer>
                <div>
                  <TangiCreateableSelector
                    options={getSelectorOptions(departments)}
                    label={t('PEOPLE.INVITE_FORM.SELECTOR.DEPARTMENTS')}
                    name={`data[${index}].departments`}
                    isLoading={creatableLoading || metaDataLoading}
                    onCreateOption={(value: string) => handleCreateDepatments(value, index)}
                    control={control}
                    error={errors?.data?.[index]?.departments}
                    isMulti
                    required={false}
                    isDisabled={false}
                  />
                </div>
                <TangiCheckbox label={t('PARTNERSHIP_ADD_EDIT_PARTNER.CHECKBOX.OKTA_MESSAGE')} register={register} name={`data[${index}].externalUser`} disabled={isEditMode} />
                <Divider />
              </StyledAddUserComponent>

            );
          })}
      </>
    );
  };

  const renderAddUserButton = () => {
    return (
      <ButtonContainer>
        <TangiButton onClick={handleAddUser} svgIcon="addUser" text={t('GENERAL.BUTTONS_TEXT.ADD_USER')} variant={BUTTON_VARIANTS.TERTIARY_GREY} data-testid="add-additional-user" />
      </ButtonContainer>
    );
  };
  const renderActionsButtons = () => {
    const submitButtonText = useMemo(() => {
      if (isEditMode) {
        return 'GENERAL.BUTTONS_TEXT.EDIT';
      } else {
        return 'GENERAL.BUTTONS_TEXT.ADD';
      }
    }, [isEditMode]);

    const cancelButtonText = useMemo(() => {
      if (isInitalAddition) {
        return 'GENERAL.BUTTONS_TEXT.LATER';
      } else {
        return 'GENERAL.BUTTONS_TEXT.CANCEL';
      }
    }, [isEditMode]);

    return (
      <ActionButtonsContainer>
        <TangiButton onClick={onClose} text={t(cancelButtonText)} variant={BUTTON_VARIANTS.TERTIARY} />
        <TangiButton type="submit" loading={userLoading} onClick={undefined} text={t(submitButtonText)} variant={BUTTON_VARIANTS.PRIMARY} data-testid="invite-multiple-partners" />
      </ActionButtonsContainer>
    );
  };

  return (
    <>
      <StyledModal transition="Fade" show={usersModalIsOpen} data-testid="add-users-partnership-modal" onHide={onClose}>
        <StyledAddUsersInPartnershipForm onSubmit={handleSubmit(handleFormContinue)}>
          {renderAddModalHeader()}
          <StyledModalBody>
            {renderAddUserComponent()}
            {!isEditMode && renderAddUserButton()}
          </StyledModalBody>
          <Separator />
          {renderActionsButtons()}
        </StyledAddUsersInPartnershipForm>
      </StyledModal>
      <TangiToast
        onError={undefined}
        {...inviteAccountsToastProps}
        onSuccess={() => {
          setInviteAccountsToastProps({ show: false, type: '', text: '' });
        }}
      />
      <PartnerNotifyModal showModal={showPartnerNotifyModal} onClose={handleCancelSubmit} selectedPartner={partnership} onContinue={handleUsersSubmit} />
    </>
  );
};

export default PartnershipInviteUsersModal;
