import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableContainer } from './style';

const PartnershipTableSkeleton = ({height = 40, count = 10}) => {
  return (
    <TableContainer>
      <Skeleton height={height} count={count} />
    </TableContainer>
  );
};
export default PartnershipTableSkeleton;
