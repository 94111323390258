import React, { useCallback, useState } from 'react';
import { ACCOUNT_ACTIONS } from 'utils/enums';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { MultiUpdateToast } from '../MultiUpdateToast/MultiUpdateToast';
import { PeopleActionToolbar } from '../PeopleActionToolbar/PeopleActionToolbar';
import { useSelector } from 'react-redux';
import { UserState } from 'utils/types/users/users';

interface PeopleMultiselectComponentsProps {
  getTotalSelected: () => number;
  deselectUsers: () => void;
  isDeactivatedUsersSelected: boolean;
  isActiveUsersSelected: boolean;
  isPendingUsersSelected: boolean;
  clientName: string;
  fetchAccounts: () => void;
  dispatchUserAction: (actionType: ACCOUNT_ACTIONS, isRetry?: boolean) => void;
}

export const PeopleMultiselectComponents = React.memo(
  ({ getTotalSelected, isDeactivatedUsersSelected, isActiveUsersSelected, isPendingUsersSelected, clientName, deselectUsers, fetchAccounts, dispatchUserAction }: PeopleMultiselectComponentsProps) => {
    const failedToUpdate = useSelector((state: UserState) => state.users.peoplePage.failedToUpdate) || [];
    const updatedAccountsLength = useSelector((state: UserState) => state.users.peoplePage.updatedAccountsLength);
    const isUpdateManyLoading = useSelector((state: UserState) => state.users.peoplePage.loadingUpdateMany);
    const [showUpdateMultipleToast, setShowUpdateMultipleToast] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [toolBarAction, setToolBarAction] = useState<ACCOUNT_ACTIONS>(ACCOUNT_ACTIONS.ACTIVATE);
    const openConfirmationModal = useCallback(
      (actionType: ACCOUNT_ACTIONS) => {
        setToolBarAction(actionType);
        setIsConfirmationModalOpen(true);
        setShowUpdateMultipleToast(false);
      },
      [setToolBarAction, setIsConfirmationModalOpen, setShowUpdateMultipleToast],
    );

    const handleActionForSelectedUsers = (actionType: ACCOUNT_ACTIONS, isRetry = false) => {
      setIsConfirmationModalOpen(false);
      setShowUpdateMultipleToast(true);
      dispatchUserAction(actionType, isRetry);
    };

    return (
      <>
        {getTotalSelected() > 0 && (
          <PeopleActionToolbar
            activateSelectedUsers={() => openConfirmationModal(ACCOUNT_ACTIONS.ACTIVATE)}
            deactivateSelectedUsers={() => openConfirmationModal(ACCOUNT_ACTIONS.DEACTIVATE)}
            getTotalSelected={getTotalSelected}
            deselectUsers={deselectUsers}
            isUpdateManyLoading={isUpdateManyLoading}
            isDeactivatedUsersSelected={isDeactivatedUsersSelected}
            isActiveUsersSelected={isActiveUsersSelected}
            isPendingUsersSelected={isPendingUsersSelected}
          />
        )}
        {isConfirmationModalOpen && (
          <ConfirmationModal
            action={toolBarAction}
            isOpen={isConfirmationModalOpen}
            activateSelectedUsers={() => handleActionForSelectedUsers(ACCOUNT_ACTIONS.ACTIVATE)}
            deactivateSelectedUsers={() => handleActionForSelectedUsers(ACCOUNT_ACTIONS.DEACTIVATE)}
            setIsConformationModalOpen={setIsConfirmationModalOpen}
            getTotalSelected={getTotalSelected}
            isDeactivatedUsersSelected={isDeactivatedUsersSelected}
            isActiveUsersSelected={isActiveUsersSelected}
            isPendingUsersSelected={isPendingUsersSelected}
          />
        )}
        {showUpdateMultipleToast && (
          <MultiUpdateToast
            action={toolBarAction}
            showUpdateMultipleToast={showUpdateMultipleToast}
            setShowUpdateMultipleToast={setShowUpdateMultipleToast}
            activateSelectedUsers={() => handleActionForSelectedUsers(ACCOUNT_ACTIONS.ACTIVATE, true)}
            deactivateSelectedUsers={() => handleActionForSelectedUsers(ACCOUNT_ACTIONS.DEACTIVATE, true)}
            getTotalSelected={getTotalSelected}
            isUpdateManyLoading={isUpdateManyLoading}
            failedToUpdate={failedToUpdate}
            clientName={clientName}
            deselectUsers={deselectUsers}
            fetchAccounts={fetchAccounts}
            updatedAccountsLength={updatedAccountsLength}
          />
        )}
      </>
    );
  },
);
