/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TangiTypography, TangiCollapse } from '_components/TangiLibrary';
import { EmptyStateContainer, MainContainer, emptyStateTypographyStyles, FlexContainerColumn, ActivityGridContainer } from './style';
import { RootState } from '_helpers';
import { ActivityTypeLabel } from './components/ActivityTypeLabel';
import { ActivityCollapseContent } from './components/ActivityCollapseContent';
import ActivityRow from './components/ActivityRow';
import ActivitySkeleton from './components/ActivitySkeleton';
import ActivityChip from './components/ActivityChip';
import ActivityFile from './components/ActivityFile';
import AssetActivityDisclaimer from './components/AssetActivityLog/AssetActivityDisclaimer';
import AssetTypeColorBlock from '_components/AssetTypeColorBlock/AssetTypeColorBlock';
import SharePointActivityHeader from './components/AssetActivityLog/SharePointActivityHeader';
import BreadCrumbs from '_components/BreadCrumbs/BreadCrumbs';
import ActivityAvatar from './components/ActivityAvatar';
import { useWindowSize } from '_hooks/useWindowSize';
import {
  AUDIT_TYPE,
  ActivityGroup,
  ActivityLogData,
  AssetFieldsMapping,
  INITIAL_LIMIT,
  MODIFICATION_TYPE,
  NormalizedData,
  SharePointSubAuditType,
  assetFields,
} from 'utils/types/activityLog/asset/types';
import { AssetActivityNotify } from './components/AssetActivityLog/AssetActivityNotify';
import { AssetActivityNotifyContent } from './components/AssetActivityLog/AssetActivityNotifyContent';
import { AssetActivityDeleteNotifyLabel } from './components/AssetActivityLog/AssetActivityDeleteNotifyLabel';
import { AssetActivityAcknowledge } from './components/AssetActivityLog/AssetActivityAcknowledge';
import { getAssetAuditLog } from 'redux-toolkit/thunks/assetsThunks';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { determineValueBasedOnAction, extractDisplayNames, getValues } from './utils';
import { parseAssetSharePointPath } from './sharePointUtils';
import { SHARE_POINT } from 'utils/assetTypes';
import { noActivityFound } from '../../assets/img';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { CenteredContainer } from 'utils/globalStyles';
import { AssetActivityGroupNotify } from './components/AssetActivityLog/AssetActivityGroupNotify';
import { AssetActivityGroupNotifyContent } from './components/AssetActivityLog/AssetActivityNotifyGroupContent';
import { AssetActivityNotifyAccess } from './components/AssetActivityLog/AssetActivityNotifyAccess';
import ActivityPartner from './components/ActivityPartner';
import { PartnershipData } from 'utils/types/partnership/partnership';
import AssetActivityPartnerLabel from './components/AssetActivityLog/AssetActivityPartnerLabel';
import { AVATAR_SIZES } from 'utils/componentUtils';

interface Props {
  isEmpty: boolean;
  loading: boolean;
}

const ActivityLog = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isSmallScreen } = useWindowSize();

  const { asset, assetAuditLog, assetAuditLogMetaData } = useSelector((state: RootState) => state.asset);

  const shouldShowHeader = !props.loading && !props.isEmpty && asset;

  const renderEmptyState = () => {
    return (
      <EmptyStateContainer>
        <img width="178px" alt="no-activity-found" src={noActivityFound} />

        <FlexContainerColumn>
          <TangiTypography customStyles={emptyStateTypographyStyles} type="heading-lg" weight="semibold">
            {t('ACTIVITY_LOG.ERROR_STATE')}
          </TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES[1100]}>{t('ACTIVITY_LOG.TRY_RELOAD')}</TangiTypography>
        </FlexContainerColumn>
      </EmptyStateContainer>
    );
  };

  const renderFiles = (event: ActivityLogData, isLast: boolean) => {
    const isEditAction = event.actionType === MODIFICATION_TYPE.EDIT;
    const createOrDeletedValue = determineValueBasedOnAction(event.actionType, event.from, event.to);

    return (
      <ActivityCollapseContent
        field={event.translation}
        modificationType={event.actionType}
        prevValueComponent={isEditAction && <ActivityFile fileNames={event.from} modificationType={event.actionType} />}
        currValueComponent={<ActivityFile fileNames={createOrDeletedValue} modificationType={event.actionType} />}
        showDivider={isLast}
      />
    );
  };
  const renderContributor = (event: ActivityLogData, isLast: boolean) => {
    const fromValues = getValues(event.from);
    const toValues = getValues(event.to);

    return (
      <>
        <ActivityCollapseContent
          field={event.translation}
          modificationType={event.actionType}
          prevValueComponent={<ActivityAvatar account={fromValues} client={fromValues} />}
          currValueComponent={<ActivityAvatar account={toValues} client={toValues} />}
          showDivider={isLast}
        />
      </>
    );
  };

  const renderTags = (event: ActivityLogData, isLast: boolean) => {
    const fromValues = extractDisplayNames(event.from);
    const toValues = extractDisplayNames(event.to);
    const isEditAction = event.actionType === MODIFICATION_TYPE.EDIT;

    const createOrDeletedValue = determineValueBasedOnAction(event.actionType, fromValues, toValues);
    return (
      <ActivityCollapseContent
        field={event.translation}
        modificationType={event.actionType}
        prevValueComponent={isEditAction && <ActivityChip value={fromValues} />}
        currValueComponent={<ActivityChip value={createOrDeletedValue} />}
        showDivider={isLast}
      />
    );
  };

  const renderAccessChange = (event: ActivityLogData, isLast: boolean) => {
    const groupTypeToFieldMap: Record<ActivityGroup, AssetFieldsMapping> = {
      [ActivityGroup.DEPARTMENT]: AssetFieldsMapping.DEPARTMENTS,
      [ActivityGroup.BUSINESS_UNIT]: AssetFieldsMapping.BUSINESS_UNITS,
      [ActivityGroup.PARTNER]: AssetFieldsMapping.PARTNERS,
    };

    const groupType = event.to[0].groupType as ActivityGroup;
    const field = groupTypeToFieldMap[groupType];
    const toValues = event.to[0].items;
    const partnership = event.to[0]?.items?.[0]?.partnership as PartnershipData;
    return (
      <>
      { partnership ?
        (<AssetActivityPartnerLabel
        partnershipName={partnership.name}
        partnershipLogo={partnership.logo}
        groupName={event.to[0]?.items?.[0].name}
        groupTypeDisplay={event.to[0]?.groupType}
        size={AVATAR_SIZES.XS}
        />)
        :
        (<ActivityCollapseContent
        field={field}
        modificationType={event.to[0].actionType}
        currValueComponent={groupType === ActivityGroup.PARTNER ? <ActivityPartner items={toValues} /> : <ActivityChip value={toValues.map((item: any) => item.name)} />}
        showDivider={isLast}
        />)
      }
      </> 
    )
  };

  const renderSharePoint = (event: ActivityLogData, isLast: boolean) => {
    const maxItemsToShow = isSmallScreen ? 3 : 4;
    const fromData = event?.from[0] ? event.from[0] : {};
    const toData = event.to && event.to[0] ? event.to[0] : {};

    const pathChanged = fromData.path !== toData.path;
    const siteNameChanged = fromData.siteName !== toData.siteName;

    if (pathChanged) {
      const fromBreadcrumbData = fromData.path ? parseAssetSharePointPath(fromData) : null;
      const toBreadcrumbData = toData.path ? parseAssetSharePointPath(toData) : null;

      const fromBreadcrumbs = fromBreadcrumbData ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <AssetTypeColorBlock assetTypeName={SHARE_POINT} />
          <BreadCrumbs items={fromBreadcrumbData.breadcrumbItems} maxItemsToShow={maxItemsToShow} />
        </div>
      ) : null;

      const toBreadcrumbs = toBreadcrumbData ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <AssetTypeColorBlock assetTypeName={SHARE_POINT} />
          <BreadCrumbs items={toBreadcrumbData.breadcrumbItems} maxItemsToShow={maxItemsToShow} />
        </div>
      ) : null;

      return (
        <ActivityCollapseContent
          field={event.translation}
          modificationType={event.actionType}
          prevValueComponent={fromBreadcrumbs}
          currValueComponent={toBreadcrumbs}
          showDivider={isLast}
          showModificationType={false}
        />
      );
    } else if (siteNameChanged) {
      return (
        <ActivityCollapseContent
          field={`${event.translation} - Site Name Changed`}
          modificationType={event.actionType}
          prevValueComponent={<div>{fromData.siteName || 'N/A'}</div>}
          currValueComponent={<div>{toData.siteName || 'N/A'}</div>}
          showDivider={isLast}
          showModificationType={false}
        />
      );
    } else {
      return null;
    }
  };

  const renderDefault = (event: ActivityLogData, isLast: boolean) => {
    const fromValues = extractDisplayNames(event.from);
    const toValues = extractDisplayNames(event.to);
    const isEditAction = event.actionType === MODIFICATION_TYPE.EDIT;
    const isDeleteAction = event.actionType === MODIFICATION_TYPE.DELETE;
    const renderValues = (values: string[]) => {
      return (
        <div>
          {values.map((value: string, index: number) => {
            return (
              <div key={index}>
                <TangiTypography type="subheading">{value}</TangiTypography>
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <ActivityCollapseContent
        field={event.translation}
        modificationType={event.actionType}
        prevValueComponent={isEditAction && renderValues(fromValues)}
        currValueComponent={renderValues(isDeleteAction ? fromValues : toValues)}
        showDivider={isLast}
      />
    );
  };

  const renderRecipientActivityRow = (activity: any, isLast: boolean) => {
    const { date, actionType, by, data, subAuditType } = activity;
    const isRecipients = data[0].type === assetFields.RECIPIENTS;
    const recipients = data[0].to?.flat() || [];

    if ((actionType === AUDIT_TYPE.CREATE || actionType === AUDIT_TYPE.NOTIFY_SINGLES || subAuditType === SharePointSubAuditType.SHARED) && isRecipients) {
      const hasMultipleRecipients = recipients.length >= 3;

      const activityContent = hasMultipleRecipients ? (
        <TangiCollapse label={<AssetActivityNotify initiator={by} data={data} auditType={actionType} subAuditType={subAuditType} />} content={renderActivityContent(activity)} key={activity.date} />
      ) : (
        <AssetActivityNotify initiator={by} data={data} auditType={actionType} subAuditType={subAuditType} />
      );

      return (
        <ActivityRow formattedDate={date} auditType={actionType} subAuditType={subAuditType} isLast={isLast}>
          {activityContent}
        </ActivityRow>
      );
    } else {
      return (
        <ActivityRow formattedDate={date} auditType={actionType} isLast={isLast}>
          <ActivityTypeLabel initiator={by} entityType={activity.entityType} auditType={actionType} />
        </ActivityRow>
      );
    }
  };

  const renderActivityGrid = () => {
    return (
      <ActivityGridContainer>
        {Object.entries(assetAuditLog).map(([month, activitiesArray]: [string, any], index: number) => {
          return (
            <React.Fragment key={index}>
              <ActivityRow formattedDate={month} auditType={AUDIT_TYPE.GROUP_TITLE} />
              {activitiesArray.map((activity: NormalizedData, index: number) => {
                const isLast = index === activitiesArray.length - 1;
                return <React.Fragment key={index}>{renderActivityComponent(activity, index, isLast)}</React.Fragment>;
              })}
            </React.Fragment>
          );
        })}
      </ActivityGridContainer>
    );
  };

  const renderActivityContent = (activityContent: NormalizedData) => {
    return activityContent.data.map((event: any, index: number, array: any[]) => {
      const isLast = index === array.length - 1;

      switch (event.type) {
        case assetFields.CONTRIBUTOR:
          return renderContributor(event, isLast);

        case assetFields.RECIPIENTS:
          return event.ActivityGroup ? <AssetActivityGroupNotifyContent people={event.to} marginTop={SPACING[4]} /> : <AssetActivityNotifyContent people={event.to} marginTop={SPACING[4]} />;

        case assetFields.FILES:
          return renderFiles(event, isLast);

        case assetFields.TAGS:
          return renderTags(event, isLast);
        case assetFields.SHAREPOINT:
          return renderSharePoint(event, isLast);
        case assetFields.ACCESS_CHANGE:
          return renderAccessChange(event, isLast);
        default:
          return renderDefault(event, isLast);
      }
    });
  };

  const renderActivityComponent = (activity: any, index: number, isLast: boolean) => {
    switch (activity.actionType) {
      case AUDIT_TYPE.CREATE:
      case AUDIT_TYPE.NOTIFY_SINGLES:
        return renderRecipientActivityRow(activity, isLast);
      case AUDIT_TYPE.EDIT:
        const isAcknowledgement = activity.data[0]?.type === assetFields.ACKNOWLEDGEMENT;

        if (isAcknowledgement) {
          return (
            <ActivityRow formattedDate={activity.date} auditType={AUDIT_TYPE.ACKNOWLEDGE} isLast={isLast}>
              <AssetActivityAcknowledge initiator={activity.by} data={activity.data} />
            </ActivityRow>
          );
        }
        return (
          <ActivityRow formattedDate={activity.date} auditType={activity.actionType} isLast={isLast}>
            <TangiCollapse
              label={<ActivityTypeLabel initiator={activity.by} entityType={activity.entityType} auditType={activity.actionType} />}
              content={renderActivityContent(activity)}
              key={activity.date}
            />
          </ActivityRow>
        );
      case AUDIT_TYPE.DELETE:
        //CAN be only Notify delete
        if (activity.data[0].type === assetFields.RECIPIENTS) {
          return (
            <ActivityRow formattedDate={activity.date} auditType={AUDIT_TYPE.DELETE_NOTIFY} isLast={isLast}>
              <AssetActivityDeleteNotifyLabel event={activity} initiator={activity.by} />
            </ActivityRow>
          );
        }
        break;
      case AUDIT_TYPE.DISCLAIMER:
        return (
          <ActivityRow formattedDate={activity.date} auditType={AUDIT_TYPE.DISCLAIMER} isLast={isLast}>
            <AssetActivityDisclaimer activity={activity} />
          </ActivityRow>
        );
      case AUDIT_TYPE.SHARE_POINT:
        if (activity.subAuditType === SharePointSubAuditType.DELETE || activity.subAuditType === SharePointSubAuditType.DISCONNECTED || activity.subAuditType === SharePointSubAuditType.CONNECTED) {
          return (
            <ActivityRow formattedDate={activity.date} auditType={activity.actionType} subAuditType={activity?.subAuditType} isLast={isLast}>
              <ActivityTypeLabel initiator={activity.by} entityType={activity?.subAuditType} auditType={activity.actionType} />
            </ActivityRow>
          );
        } else if (activity.subAuditType === SharePointSubAuditType.DELETE_SHARE) {
          const isRecipients = activity.data[0]?.type === assetFields.RECIPIENTS;
          if (isRecipients) {
            return (
              <ActivityRow formattedDate={activity.date} auditType={activity.actionType} subAuditType={activity.subAuditType} isLast={isLast}>
                <AssetActivityDeleteNotifyLabel event={activity} initiator={activity.by} />
              </ActivityRow>
            );
          }
        } else if (activity.subAuditType === SharePointSubAuditType.SHARED) {
          const isRecipients = activity.data[0]?.type === assetFields.RECIPIENTS;
          if (isRecipients) {
            return renderRecipientActivityRow(activity, isLast);
          }
        } else if (activity.subAuditType === SharePointSubAuditType.DISCLAIMER) {
          return (
            <ActivityRow formattedDate={''} auditType={AUDIT_TYPE.DISCLAIMER} isLast={isLast}>
              <AssetActivityDisclaimer activity={activity} />
            </ActivityRow>
          );
        }
        return (
          <ActivityRow formattedDate={activity.date} auditType={activity.actionType} subAuditType={activity?.subAuditType} isLast={isLast}>
            <TangiCollapse
              label={<ActivityTypeLabel initiator={activity.by} entityType={activity?.subAuditType} auditType={activity.actionType} />}
              content={renderActivityContent(activity)}
              key={activity.date}
            />
          </ActivityRow>
        );
        break;
      case AUDIT_TYPE.NOTIFY_GROUP:
        return (
          <ActivityRow formattedDate={activity.date} auditType={activity.actionType} isLast={isLast}>
            <TangiCollapse
              label={<AssetActivityGroupNotify initiator={activity.by} data={activity.data} auditType={activity.actionType} />}
              content={renderActivityContent(activity)}
              key={activity.date}
            />
          </ActivityRow>
        );

      case AUDIT_TYPE.ACCESS_CHANGE_BY_GROUP:
        return (
          <ActivityRow formattedDate={activity.date} auditType={activity.actionType} isLast={isLast} toData={activity?.data[0]?.to[0]}>
            <TangiCollapse label={<AssetActivityNotifyAccess data={activity.data} />} content={renderActivityContent(activity)} key={activity.date} />
          </ActivityRow>
        );
      default:
        return null;
    }
  };

  const fetchMoreData = useCallback(() => {
    batch(() => {
      dispatch(getAssetAuditLog({ assetId: asset.id, limit: INITIAL_LIMIT }));
      dispatch(assetsActions.resetAssetAuditLogLoader());
    });
  }, [asset.id, dispatch]);

  return (
    <>
      {shouldShowHeader && <SharePointActivityHeader asset={asset} />}
      <MainContainer>
        {assetAuditLogMetaData && props.loading ? (
          <ActivitySkeleton />
        ) : props.isEmpty ? (
          renderEmptyState()
        ) : (
          <InfiniteScroll
            dataLength={assetAuditLogMetaData.total}
            next={fetchMoreData}
            hasMore={assetAuditLogMetaData.hasMore}
            loader={
              <CenteredContainer>
                <Spinner animation="border" variant="primary" />
              </CenteredContainer>
            }
            style={{ overflowY: 'hidden' }}
          >
            {renderActivityGrid()}
          </InfiniteScroll>
        )}
      </MainContainer>
    </>
  );
};

export default ActivityLog;
