import React from 'react';

import { IconTypes, TangiTypography } from '_components/TangiLibrary';
import ActivityIcon from './ActivityIcon';
import { NEUTRAL_SHADES } from 'utils/theme';
import { ActivityRowDivider } from '../style';
import { ActivityGroup, AUDIT_TYPE, MODIFICATION_TYPE } from 'utils/types/activityLog/asset/types';
import { ACCESS_ICONS } from '../types';

interface toData {
  actionType: string;
  hasAccessChanged: boolean;
  groupType: string;
}

interface Props {
  children?: React.ReactNode;
  formattedDate: string;
  auditType: AUDIT_TYPE;
  isLast?: boolean;
  subAuditType?: string;
  toData?: toData;
}
const IconTypeMap: { [key: string]: IconTypes } = {
  [AUDIT_TYPE.CREATE]: 'assets',
  [AUDIT_TYPE.NOTIFY_GROUP]: 'send',
  [AUDIT_TYPE.NOTIFY_SINGLES]: 'send',
  [AUDIT_TYPE.ADDED]: 'send',
  [AUDIT_TYPE.REMOVED]: 'noAccess',
  [AUDIT_TYPE.EDIT]: 'edit',
  [AUDIT_TYPE.DELETE_NOTIFY]: 'deleteNotify',
  [AUDIT_TYPE.ACKNOWLEDGE]: 'check',
  [AUDIT_TYPE.DISCLAIMER]: 'info',
  [`${AUDIT_TYPE.CREATE}_${AUDIT_TYPE.NOTIFY}`]: 'send',
  [`${AUDIT_TYPE.SHARE_POINT}_move`]: 'move',
  [`${AUDIT_TYPE.SHARE_POINT}_delete`]: 'assetDeleted',
  [`${AUDIT_TYPE.SHARE_POINT}_shared`]: 'assetShared',
  [`${AUDIT_TYPE.SHARE_POINT}_deleteShare`]: 'unSharedAsset',
  [`${AUDIT_TYPE.SHARE_POINT}_connected`]: 'assets',
  [`${AUDIT_TYPE.SHARE_POINT}_disconnected`]: 'assetDeleted',
};

//TODO: add type for the subAuditType
const getIconType = (auditType: AUDIT_TYPE, subAuditType?: string): IconTypes => {
  const key = subAuditType ? `${auditType}_${subAuditType}` : auditType;
  return IconTypeMap[key] ?? undefined;
};

const getAccessChangeByGroupIcon = (toData?: toData): IconTypes => {
  if (!toData) {
    return ACCESS_ICONS.SEND;
  }

  const { actionType, hasAccessChanged, groupType } = toData;

  const key = (() => {
    if (groupType === ActivityGroup.PARTNER) {
      if (actionType === MODIFICATION_TYPE.ADDED && hasAccessChanged) return ACCESS_ICONS.SEND;
      if (actionType === MODIFICATION_TYPE.REMOVED && hasAccessChanged) return ACCESS_ICONS.NO_ACCESS;
      if (actionType === MODIFICATION_TYPE.ADDED && !hasAccessChanged) return ACCESS_ICONS.PARTNERSHIP;
      if (actionType === MODIFICATION_TYPE.REMOVED && !hasAccessChanged) return ACCESS_ICONS.PARTNERSHIP;
    } else {
      if (actionType === MODIFICATION_TYPE.ADDED && hasAccessChanged) return ACCESS_ICONS.SEND;
      if (actionType === MODIFICATION_TYPE.REMOVED && hasAccessChanged) return ACCESS_ICONS.NO_ACCESS;
      if (actionType === MODIFICATION_TYPE.ADDED && !hasAccessChanged) return ACCESS_ICONS.GROUP;
      if (actionType === MODIFICATION_TYPE.REMOVED && !hasAccessChanged) return ACCESS_ICONS.GROUP;
    }
    return ACCESS_ICONS.SEND;
  })();

  return key;
};

const ActivityRow = (props: Props) => {
  const renderGroupTitle = () => {
    return (
      <>
        <TangiTypography color={NEUTRAL_SHADES[900]} weight="bold" type="subheading">
          {props.formattedDate}
        </TangiTypography>
        <ActivityIcon type={props?.auditType} />
        <div />
      </>
    );
  };

  const renderActivityRow = () => {
    const iconType = props.auditType === AUDIT_TYPE.ACCESS_CHANGE_BY_GROUP ? getAccessChangeByGroupIcon(props.toData) : getIconType(props.auditType, props.subAuditType);
    return (
      <>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{props.formattedDate}</TangiTypography>
        <ActivityIcon component={iconType} />
        <div data-testid="activity-row">
          {props?.children}
          {!props?.isLast && <ActivityRowDivider />}
        </div>
      </>
    );
  };

  return props.auditType === AUDIT_TYPE.GROUP_TITLE ? renderGroupTitle() : renderActivityRow();
};

export default ActivityRow;
