import { useMemo, useRef, useState } from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import MetaDataListDisplayHover from './MetaDataListDisplayHover';
import { MetaDataListDisplayType } from './types';
import { IdAndName } from 'utils/types/types';
import { SPACING } from 'utils/theme';

export const StyledPopover = styled(Popover)`
  width: fit-content;
  min-width: 200px;
  max-width: fit-content;
  border: none;
  box-shadow: 0px 3px 16px 0px #0000001f;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: nowrap;
`;

export const EllipsisTypography = styled(TangiTypography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AmountTypography = styled(TangiTypography)`
  flex-shrink: 0;
  margin-left: ${SPACING[1]};
  white-space: nowrap;
`;

interface Props {
  metadataArray: IdAndName[];
  type: MetaDataListDisplayType;
}

const MetaDataListDisplay = ({ metadataArray, type }: Props) => {
  const [hovered, setHovered] = useState(false);

  const isItems = useMemo(() => !!metadataArray?.length, [metadataArray]);
  const isMoreThanOne = useMemo(() => metadataArray?.length > 1, [metadataArray]);

  const target = useRef(null);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <>
      {isItems && (
        <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={target} data-testid="metadata-list-display-">
          <EllipsisTypography>{metadataArray[0].name}</EllipsisTypography>
          {isMoreThanOne && <AmountTypography>+ {metadataArray.length - 1}</AmountTypography>}
          <Overlay target={target.current} show={hovered} placement="top">
            {(props) => (
              <StyledPopover {...props}>
                <MetaDataListDisplayHover isMoreThanOne={isMoreThanOne} metadataArray={metadataArray} type={type} />
              </StyledPopover>
            )}
          </Overlay>
        </Container>
      )}
    </>
  );
};

export default MetaDataListDisplay;
