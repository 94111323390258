import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import 'tippy.js/dist/tippy.css';

import { InviteAccountForm } from '../InviteAccountForm/InviteAccountForm';
import { ViewEmployee } from '../ViewEmployee/ViewEmployee';
import { TangiIconButton } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { getAccountAgreements } from 'redux-toolkit/thunks/usersThunks';
import { ModalHeader } from './style';
import './ViewPeople.scss';

function ViewPeople({ showModal, mode, resetView, permissionEditEmployee }) {
  const [isEditMode, setIsEditMode] = useState(mode === 'edit');
  const [show, setShow] = useState(false);

  const { account: accountData } = useSelector((state) => state.users.peoplePage.viewEditAccount);

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setIsEditMode(false);
    resetView();
  };

  useEffect(() => {
    setShow(showModal);
  }, [accountData]);

  useEffect(() => {
    setIsEditMode(mode === 'edit');
  }, [mode, show]);

  useEffect(() => {
    if (permissionEditEmployee && accountData?.id && !accountData?.agreements) {
      dispatch(getAccountAgreements(accountData.id));
    }
  }, [accountData, permissionEditEmployee]);

  return (
    <div className={isEditMode ? 'edit-people' : 'view-people'}>
      <Modal show={show} className="view-people" onHide={handleClose} id="user-view-modal" transition="Fade" backdrop="static" data-testid="view-people-modal">
        <Modal.Header closeButton>
          <ModalHeader>
            {isEditMode && <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => setIsEditMode(false)} />}
            {accountData?.displayName}
          </ModalHeader>
        </Modal.Header>
        <Modal.Body id={isEditMode ? 'user-edit-modal-body' : 'user-view-modal-body'}>
          {isEditMode ? (
            <InviteAccountForm permissionsToAddAgreements={permissionEditEmployee} isEditMode={true} handleClose={handleClose} />
          ) : (
            <ViewEmployee permissionsToAddAgreements={permissionEditEmployee} handleClose={handleClose} handleClickEdit={() => setIsEditMode(true)} />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { ViewPeople };
