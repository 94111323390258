import { isFunction } from 'lodash';

import { IRoles } from 'utils/roles';
import { ConfigurableMenuItem, AccessibleMenuItem, AccessCheckParams, AccessChecks, GetAccessibleMenuItemsParams, NavItemType } from './types';
import { generateRoute, GenerateRouteParams } from '../../routing/generateRoute';
import { AppRoutes } from '../../routing/consts';

const checkPatentPermission = ({ accountPermissions }: AccessCheckParams) => !!accountPermissions?.Patent; //X-Ray features are accessible if account.specialAdminPermissions.Patent is true
const checkContinuationXRayPermission = ({ accountPermissions }: AccessCheckParams) => !!accountPermissions?.ContinuationXRay;

const accessChecks: AccessChecks = {
  isNoSpecialPermissionsRequired: true,
  xrayFeatures: checkPatentPermission,
  continuationAnalysis: checkContinuationXRayPermission,
};

export const configurableMenuItems: ConfigurableMenuItem[] = [
  {
    id: 'home-lobby',
    translationKey: 'SIDE_BAR.HOME',
    path: AppRoutes.HOME_LOBBY,
    roles: [], // all roles
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.ITEM,
    icon: 'homepage',
  },
  {
    id: 'predicted-portfolio',
    translationKey: 'SIDE_BAR.PREDICTED_PORTFOLIO',
    path: AppRoutes.PREDICTED_PORTFOLIO,
    roles: [],
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.ITEM,
    icon: 'predictedPortfolio',
  },
  {
    id: 'competitive-analysis',
    translationKey: 'SIDE_BAR.COMPETITIVE_INTELLIGENCE',
    path: AppRoutes.COMPETITIVE_ANALYSIS,
    roles: [],
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.ITEM,
    icon: 'bulb',
  },
  {
    id: 'client-assets',
    translationKey: 'SIDE_BAR.ASSETS',
    path: AppRoutes.ASSETS_MAIN_PAGE,
    roles: [IRoles.EMPLOYEE_HR, IRoles.EMPLOYEE, IRoles.CONTRACTOR, IRoles.CONTRACTOR_OTHER, IRoles.PARTNER],
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.ITEM,
    icon: 'assets',
  },
  {
    id: 'people',
    translationKey: 'SIDE_BAR.PEOPLE',
    path: AppRoutes.PEOPLE,
    roles: [IRoles.EMPLOYEE_HR, IRoles.LEARN_EMPLOYEE_ADMIN],
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.ITEM,
    icon: 'people',
  },
  {
    id: 'manage-group',
    translationKey: 'SIDE_BAR.MANAGE',
    roles: [IRoles.LAWYER, IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER],
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.GROUP,
    icon: 'manage',
    subItems: [
      {
        id: 'client-dashboard',
        translationKey: 'SIDE_BAR.DASHBOARD',
        path: AppRoutes.CLIENT_DASHBOARD,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.isNoSpecialPermissionsRequired,
        icon: 'dashboard',
      },
      {
        id: 'client-assets',
        translationKey: 'SIDE_BAR.ASSETS',
        path: AppRoutes.ASSETS_MAIN_PAGE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.isNoSpecialPermissionsRequired,
        icon: 'assets',
      },
      {
        id: 'people',
        translationKey: 'SIDE_BAR.PEOPLE',
        path: AppRoutes.PEOPLE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.isNoSpecialPermissionsRequired,
        icon: 'people',
      },
      {
        id: 'partnerships',
        translationKey: 'SIDE_BAR.PARTNERS',
        path: AppRoutes.PARTNERSHIP,
        roles: [],
        type: NavItemType.PARTNERSHIP_DRAWER,
        access: accessChecks.isNoSpecialPermissionsRequired,
        icon: 'partnership',
      },
    ],
  },
  {
    id: 'xray-group',
    translationKey: 'SIDE_BAR.DISCOVER',
    roles: [IRoles.LAWYER, IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER],
    access: accessChecks.xrayFeatures,
    type: NavItemType.GROUP,
    icon: 'discovery',
    subItems: [
      {
        id: 'patent-xray',
        translationKey: 'SIDE_BAR.PATENT_XRAY',
        path: AppRoutes.PATENT_X_RAY_MAIN_PAGE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.xrayFeatures,
        icon: 'patent',
      },
      {
        id: 'continuation-analysis',
        translationKey: 'SIDE_BAR.CONTINUATION_ANALYSIS',
        path: AppRoutes.CONTINUATION_ANALYSIS_MAIN_PAGE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.continuationAnalysis,
        icon: 'continuationAnalysis',
      },
      {
        id: 'invention-disclosure',
        translationKey: 'SIDE_BAR.INVENTION_DISCLOSURE',
        path: AppRoutes.INVENTION_DISCLOSURE_MAIN_PAGE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.xrayFeatures,
        icon: 'pencil',
      },
      {
        id: 'contract-analyzer',
        translationKey: 'SIDE_BAR.CONTRACT_ANALYZER',
        path: AppRoutes.CONTRACT_ANALYZER_MAIN_PAGE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.xrayFeatures,
        icon: 'contract',
      },
      {
        id: 'email-tracker-marketing-page',
        translationKey: 'SIDE_BAR.EMAIL_TRACKER',
        path: AppRoutes.EMAIL_TRACKER_MARKETING_PAGE,
        roles: [],
        type: NavItemType.ITEM,
        access: accessChecks.xrayFeatures,
        icon: 'emailTracker',
      },
    ],
  },
  {
    id: 'training',
    translationKey: 'SIDE_BAR.TRAINING',
    path: AppRoutes.TRAINING,
    roles: [],
    access: accessChecks.isNoSpecialPermissionsRequired,
    type: NavItemType.ITEM,
    icon: 'learn',
  },
];

/**
 * Filters and returns accessible menu items based on the user's role, account permissions, and client permissions,
 * and generates dynamic paths where necessary.
 *
 * @param {GetAccessibleMenuItemsParams} params - The parameters for filtering and generating menu items.
 * @param {IRoles} params.role - The user's role.
 * @param {SpecialAdminPermissions} params.accountPermissions - The account's special admin permissions.
 * @param {object} params.client - The client object containing permissions and other information.
 * @param {object} params.client.permissions - The client's permissions.
 * @param {object} params.client.permissions.ipPortfolio - The IP portfolio permissions.
 * @param {string} params.client.permissions.ipPortfolio.portfolioId - The portfolio ID for IP portfolio.
 * @param {boolean} params.client.permissions.ipPortfolio.hasPermission - Indicates if the client has access to the IP portfolio.
 * @param {object} params.client.permissions.ipCompetitive - The IP competitive permissions.
 * @param {string} params.client.permissions.ipCompetitive.portfolioId - The portfolio ID for IP competitive.
 * @param {boolean} params.client.permissions.ipCompetitive.hasPermission - Indicates if the client has access to the IP competitive.
 * @param {boolean} params.client.isDemo - Indicates if the client is a demo account (predicted portfolio and competitive analysis are mock data).
 * @param {string} params.clientId - The ID of the client used for generating dynamic paths.
 * @returns {AccessibleMenuItem[]} - An array of accessible menu items with dynamic paths generated.
 *
 *
 * @example
 * const role = IRoles.LAWYER;
 * const accountPermissions = {
 *   Tracker: false,
 *   Finder: false,
 *   Patent: true,
 *   ContinuationXRay: true
 * };
 * const client = {
 *   permissions: {
 *     ipPortfolio: {
 *       portfolioId: 'portfolio123',
 *       hasPermission: true,
 *     },
 *     ipCompetitive: {
 *       portfolioId: 'portfolio456',
 *       hasPermission: false,
 *     },
 *   },
 *   isDemo: false
 * };
 * const clientId = '123';
 * const accessibleMenuItems = getAccessibleMenuItems({ role, accountPermissions, client, clientId });
 *
 * // accessibleMenuItems will be an array of menu items that the lawyer user with the specified permissions can access,
 * // with the paths generated dynamically based on the clientId.
 */
export const getAccessibleMenuItems = ({ role, accountPermissions, client, clientId, partnershipId }: GetAccessibleMenuItemsParams): AccessibleMenuItem[] => {
  const urlParams: GenerateRouteParams = { clientId, partnershipId };
  const filterItems = (items: ConfigurableMenuItem[]): AccessibleMenuItem[] => {
    return items
      .map((item: ConfigurableMenuItem) => {
        const accessParams = { accountPermissions, client };
        const hasAccess = isFunction(item.access) ? item.access(accessParams) : item.access;
        return {
          id: item.id,
          translationKey: item.translationKey,
          access: hasAccess,
          roles: item.roles,
          type: item.type,
          icon: item.icon,
          ...(item.subItems && { subItems: filterItems(item.subItems) }),
          ...(item?.path && { path: generateRoute(item.path, urlParams) }),
        };
      })
      .filter((item) => item.access && (!item.roles.length || item.roles.includes(role)) && (!item.subItems || item.subItems.length)); // Menu items without subItems or if they have at least one accessible subItem will be included
  };

  return filterItems(configurableMenuItems);
};
