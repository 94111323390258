import { Trans, useTranslation } from 'react-i18next';

import { StyledModal, Divider } from '../../utils/globalStyles';
import { ModalContentContainer, StyledModalBody, StyledModalHeader, TitleContainer, StyledModalFooter } from '../DepartmentNotifyModal/styles';

import { isEmpty } from 'lodash';
import { TangiTypography } from '_components/TangiLibrary';

import { TangiIconButton, TangiButton } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';

export const PartnerNotifyModal = ({ showModal, onClose, selectedPartner, onContinue }) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onContinue();
    onClose();
  };

  return (
    <>
      <StyledModal transition="Fade" show={showModal} onHide={onClose} data-testid="partner-notify-modal">
        <ModalContentContainer>
          <StyledModalHeader>
            <TitleContainer>
              <div>{t('PARTNER_NOTIFY_MODAL.TITLE')}</div>
              <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onClose} icon="close" />
            </TitleContainer>
          </StyledModalHeader>
          <StyledModalBody>
            <TangiTypography type="subheading" className="mb-3">
              <Trans t={t} i18nKey="PARTNER_NOTIFY_MODAL.SUBTITLE"/>
            </TangiTypography>
            <TangiTypography type="subheading" className="mt-3">
              <Trans t={t} i18nKey="PARTNER_NOTIFY_MODAL.NOTE" />
            </TangiTypography>
          </StyledModalBody>
          <Divider />
          <StyledModalFooter>
            <TangiButton variant="tertiary" text={t('GENERAL.BUTTONS_TEXT.CANCEL')} size="small" onClick={onClose} smallbtn={true} />
            <TangiButton text={t('GENERAL.BUTTONS_TEXT.SAVE_AND_CONFIRM')} size="small" disabled={isEmpty(selectedPartner)} onClick={handleConfirm} smallbtn={true} />
          </StyledModalFooter>
        </ModalContentContainer>
      </StyledModal>
    </>
  );
};
